import React, { useEffect, useState } from "react";
import Page from "src/components/Page";
import LayoutHeaderAuctions from "../auctionsRoom/header/Appbar";
import View from "./view"
import { getUser } from "../Model/LocalStorage";

function IndexData(props){

    const [id,setId] = useState("")

    useEffect(()=>{

        async function getUserList() {
            const getuser = await getUser();
            setId(getuser.id)

        }
        getUserList().catch(console.error);
    },[])

    return (
        <Page title="Profile">
        <div>
             <LayoutHeaderAuctions mediadQuery={props.mediadQuery} />
            <View userID={id}/>
        </div>
   </Page>
    )

}

export default IndexData;