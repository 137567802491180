import React from 'react';
import View from './view'
function index(props) {
    return (
        <div className="downSroll">
            <View/>
        </div>
    );
}

export default index;