import React from "react";
import Page from "src/components/Page";
import AboutUs from "./About";

function index(props) {
  localStorage.removeItem("@storeAuctionLot");
  return (
    <Page title="About Us">
      <AboutUs />
    </Page>
  );
}

export default index;
