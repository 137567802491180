
import React , { useState  } from "react";


function FormEmail(props) {

const [email, setEmail] = useState('')

const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  return (
    <section className="latest-blog-style3-area">
      <div className="container">
        <div className="sec-title text-center" style={{paddingBottom: '10px'}}>
          <div className="big-title">
            <h1>News Letter</h1>
          </div>
      
        </div>
        <div className="row clearfix">
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 wow fadeInUp"
            data-wow-delay="100ms"
          >
            <div className="single-footer-widget-style3">
              <div className="title" style={{paddingBottom: '20px'}}>
              <h6>
          Receive e-mail updates on our auctions, previews, exhibitions, events, and more........
          </h6>
              </div>
              <div className="touch-form-box">
                <form
                  id="touch-form"
                  name="touch_form"
                  className="default-form"
                  action="#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-xl-12">
                      <input
                       style={{ background: "#ebebeb",color: 'black', }}
                        type="email"
                        name="form_email"
                        value={email}
                        placeholder="Email"
                        required=""
                        onChange={handleEmailChange}
                      />
                    </div>
       
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="button-box text-center">
                        <button
                          type="submit"
                          data-loading-text="Please wait..."
                          style={{ backgroundColor: "#000000", color: "#ffff" ,width: '100%'}}
                        >
                          Send Now
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormEmail;
