import React, { useState, useRef, useCallback, useEffect } from "react";
import ModeAuction from "./ModeAuction";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Divider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Auction() {
  const matches = useMediaQuery("(min-width:1000px)");
  const [live, setLive] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [online, setOnline] = useState(null);
  const [exhibition, setExhibition] = useState(null);

  const [checked, setChecked] = React.useState(true);
  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(true);

  const { books, hasMore, loading, error } = ModeAuction(
    pageNumber,
    live,
    exhibition,
    online
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    lastBookElementRef();
  });
  // function handleSearch(e) {
  //   setQuery(e.target.value)
  //   setPageNumber(1)
  // }

  const handleChange = (event) => {
    if (event.target.checked === true) {
      setLive(event.target.value);
      setPageNumber(1);
    } else {
      setLive(null);
      setPageNumber(1);
    }
    setChecked(event.target.checked);
  };

  const handleChange1 = (event) => {
    if (event.target.checked === true) {
      setOnline(event.target.value);
      setPageNumber(1);
    } else {
      setOnline(null);
      setPageNumber(1);
    }
    setChecked1(event.target.checked);
  };

  const handleChange2 = (event) => {
    if (event.target.checked === true) {
      setExhibition(event.target.value);
      setPageNumber(1);
    } else {
      setExhibition(null);
      setPageNumber(1);
    }
    setChecked2(event.target.checked);
  };

  const GotoPage = (id, slug, type, imageCover) => {
    localStorage.setItem("items", id);
    localStorage.setItem("cover", imageCover);

    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${slug}/${type}`;
    } else {
      window.location.href = `/auction/lot/${slug}/${type}`;
    }
  };

  console.log("loading: ", loading);

  return (
    <div>
      <section className="blog-single-area lotArea">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className=" ">
                <div className="sidebar-search-box">
                  {/* <div className="search-form">
                    <input
                      placeholder="Search"
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="submit" onClick={handleSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div> */}
                </div>
                <div className="single-sidebar">
                  <div style={{ textAlign: "left" }}>
                    <h2>Past Auction</h2>
                  </div>
                  <hr></hr>
                  {matches === true ? 
                   <FormGroup>
                    <FormControlLabel
                
                      control={
                        <Checkbox
                          value={"LIVE_AUCTION"}
                          checked={checked[0] && checked[1]}
                          indeterminate={checked[0] !== checked[1]}
                          onChange={handleChange}
                        />
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }}}
                      label={<span style={{ fontSize: 22 }}>Live Auction</span>}
                      // label="Live Auction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"ONLINE_AUCTION"}
                          checked={checked1[0] && checked1[1]}
                          indeterminate={checked1[0] !== checked1[1]}
                          onChange={handleChange1}
                        />
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }}}
                      label={<span style={{ fontSize: 22 }}>Online Auction</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"EXHIBITION"}
                          checked={checked2[0] && checked2[1]}
                          indeterminate={checked2[0] !== checked2[1]}
                          onChange={handleChange2}
                        />
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }}}
                      label={<span style={{ fontSize: 22 }}>Exhibition</span>}
                    />
                  </FormGroup>:

                  <Box style={{display: 'flex',paddingTop:10}}>
                    
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"LIVE_AUCTION"}
                          checked={checked[0] && checked[1]}
                          indeterminate={checked[0] !== checked[1]}
                          onChange={handleChange}
                        />
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      label={<span style={{ fontSize: 22 }}>Live</span>}
                      // label="Live Auction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"ONLINE_AUCTION"}
                          checked={checked1[0] && checked1[1]}
                          indeterminate={checked1[0] !== checked1[1]}
                          onChange={handleChange1}
                        />
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      label={<span style={{ fontSize: 22 }}>Online</span>}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={"EXHIBITION"}
                          checked={checked2[0] && checked2[1]}
                          indeterminate={checked2[0] !== checked2[1]}
                          onChange={handleChange2}
                        />
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      label={<span style={{ fontSize: 22 }}>Exhibition</span>}
                    />
                  </Box>}
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div
                className="row"
                style={{ marginTop: matches === false ? 0 : "" }}
              >
                {/* <input type="text" value={live} onChange={handleSearch}></input> */}
                {books.map((value, i) => {
                  var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var date = new Date(value.date_start);

                  if (books.length === i + 1) {
                    return (
                      <div
                        className="col-xl-4 zoom"
                        ref={lastBookElementRef}
                        key={value}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            GotoPage(
                              value.id,
                              value.c_slug,
                              value.c_type,
                              value.cover
                            )
                          }
                        >
                          <div className="containerImage bg-image hover-zoom">
                            <img
                              className="imageCenter"
                              src={
                                value.cover === null
                                  ? "images/resources/welcome-image.png"
                                  : value.cover
                              }
                              alt="Awesome"
                              style={{ paddingTop: 40 }}
                            />
                          </div>
                          <div className="text">
                            <p style={{ marginBottom: 10, fontSize: 12 }}>
                              {value.c_type.replace("_", " ")}
                            </p>
                            <p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.d_desc}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                fontSize: 20,
                              }}
                            >
                              {date.toLocaleDateString("en-US", options)}
                            </p>
                          </div>
                        </a>
                      </div>
                    );
                    // <div ref={lastBookElementRef} key={book}>{book}</div>
                  } else {
                    return (
                      <div className="col-xl-4 zoom" key={value}>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            GotoPage(
                              value.id,
                              value.c_slug,
                              value.c_type,
                              value.cover
                            )
                          }
                        >
                          <div className="containerImage bg-image hover-zoom">
                            <img
                              className="imageCenter"
                              src={
                                value.cover === null
                                  ? "images/resources/welcome-image.png"
                                  : value.cover
                              }
                              alt="Awesome"
                              style={{ paddingTop: 40 }}
                            />
                          </div>
                          <div className="text">
                            <p style={{ marginBottom: 10, fontSize: 12 }}>
                              {value.c_type.replace("_", " ")}
                            </p>
                            <p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.d_desc}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                fontSize: 20,
                              }}
                            >
                              {date.toLocaleDateString("en-US", options)}
                            </p>
                          </div>
                        </a>
                      </div>
                    );
                  }
                })}

                {/* <div>{error && "Error"}</div> */}
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  // width: "10%",
                  // paddingTop: 105,
                }}
              >
                {/* {loading && (
                  <div sx={{ display: "flex" }}>
                    <CircularProgress /> Loading...
                  </div>
                )} */}
                <div>
                  {error ? (
                    <p></p>
                  ) : loading ? (
                    <div style={{
                      width: "100%",
                      padding: 100,
                      display: "flex",
                      justifyContent: "center",
                    }} >
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div>{error}</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
