import React, { useState, useRef, useCallback, useEffect } from "react";
export default function BlogSidebar({ categoriesList, totalResult, onChangeCategories }) {




  return (
    <div>
      <div className="sidebar-wrapper">
        {/*Start single sidebar*/}
        <div className="sidebar-search-box">
          <div className="title">
            <h5>SHOWING {totalResult} RESULTS</h5>
            <hr />
          </div>

        </div>
        {/*End single sidebar*/}
        {/*Start single sidebar*/}
        <div className="single-sidebar">
          <div className="title">
            <h3>Categories</h3>
          </div>
          <ul className="categories " >
            <li key={0} onClick={() => onChangeCategories(`3,4,5`)}><a href="javascript:void(0)" >ALL</a></li>
            {

              categoriesList.map((data, key) => {
                return (
                  <li key={key} onClick={() => onChangeCategories(data.id)}><a href="javascript:void(0)" >{data.name.toUpperCase()} <span className="badge badge-dark">{data.count}</span></a></li>
                )
              })
            }


          </ul>
        </div>

      </div>

    </div>
  );
}