import React, { useCallback, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import ImageOne from "./../../images/Banner/download.webp";
import { banner } from "../../Model/home";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Carousel from "react-multi-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";

import ViewListIcon from "@mui/icons-material/ViewList";
import DownloadIcon from "@mui/icons-material/Download";
import { Button, Tooltip, Typography } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 3, // optional, default t1o 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const BannerHomePage = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const phoneMatch = useMediaQuery("(min-width:900px)");
  const [catalogDownload, setCatalogDownload] = useState("");
  const [catalogView, setCatalogView] = useState("");
  const matchesPhone = useMediaQuery("(min-width:700px)");
  const FetchBanner = useCallback(async () => {
    setLoading(true);
    async function getBanner() {
      const results = await banner();

      if (results === false) {
        setBannerImage([]);
      } else {
        const Data1 = results.filter((x) => x.isMobile === 1);
        const Data2 = results.filter((x) => x.isMobile === 0);

        setBannerImage(matchesPhone === false ? Data1 : Data2);
      }
      setLoading(false);
    }
    getBanner().catch(console.error);
  }, []);

  const [imgsLoaded, setImgsLoaded] = useState(false);

  const goEventLocation = (link) => {
    if (link === "nodata" || link === null  || link === 'nenasaguil') {
      // alert("ok")
    } else {
      window.location.href = link;
    }
  };

  // const FetchCatalog =  () => {
  //   setLoading(true);
  //   async function getBanner() {

  //     var options = {
  //       weekday: "long",
  //       year: "numeric",
  //       month: "long",
  //       day: "numeric",
  //     };

  //     const results = await actionByID(id);

  //     var date = new Date(results[0].date_start);
  //     setDate(date.toLocaleDateString("en-US", options));
  //     setCatalogDownload(results[0].catalog_download)
  //     setCatalogView(results[0].catalog_url)
  //     console.log(results)
  //     setTitle(results[0].auction_name);
  //     setBannerImage(results[0].auction_cover);
  //     setLoading(false);
  //   }
  //   getBanner().catch(console.error);
  // };

  useEffect(() => {
    FetchBanner();
    // FetchCatalog();
  }, [FetchBanner]);

  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.banner_url;
        // wait 2 seconds to simulate loading time
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.banner_url);
          }, 2000);

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(bannerImage.map((image) => loadImage(image)))
      .then(() => setImgsLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  const Ecatalog = (e,isSequence) => {
    e.preventDefault();

    window.open(
      "https://www.calameo.com/read/004656305d63b7b47faf2",
      "_blank" // <- This is what makes it open in a new window.
    )

  };

  return (
    //   <section
    //   className="breadcrumb-area"
    //   style={{
    //     backgroundImage: `url(${AboutUSs})`
    //   }}
    // >
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-xl-12">

    //         <div className="inner-content clearfix">
    //           <div className="title">
    //             <h1>About Us</h1>
    //           </div>
    //           <div className="breadcrumb-menu">
    //             <ul className="clearfix">
    //               <li style={{ marginLeft: 60 }}>Home</li>
    //               <li className="" style={{ color: "#000000" }}>
    //                 About Us
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section
      className="main-slider style1"
      style={{
        background: "rgb(252 252 252)",
        // height: phoneMatch === true ? 750:'',
      }}
    >
      <div
        className="rev_slider_wrapper fullwidthbanner-container "
        // id="rev_slider_one_wrapper"
        data-source="gallery"
      >
        <Carousel
          responsive={responsive}
          className="container2"
          itemClass="carousel-itemAction"
          infinite={false}
        >
          {imgsLoaded ? (
            bannerImage.length ? (
              bannerImage.map((row, index) => {
                return (
                  <>
                    <div
                      key={index}
                      onClick={() => goEventLocation(row.banner_link)}
                    >
                      <img
                        key={index}
                        aria-hidden="true"
                        fetchpriority="high"
                        alt={row.banner_link}
                        key={row.id}
                        style={{ width: "100%" }}
                        src={row.banner_url}
                      />
                    </div>

<div style={{visibility: row.banner_link === 'nodata' ? 'hidden':'visible',bottom:35}}>
{matchesPhone === false ? (
                      <>
                        <a
                          style={{
                            width: 115,
                            padding: "5px 5px 5px",
                            right: "40%",
                            opacity: 0.8,
                            color: "white",
                            fontSize: 16,
                            zIndex: 999,
                          }}
                          className="btn2 btn-secondary"
                          id="view_ecatalog"
                          onClick={(e) => Ecatalog(e,row.isSequence)}
                        >
                          E-Catalog
                        </a>

                        <a
                          style={{
                            width: 130,
                            padding: "5px 5px 5px",
                            left: "70%",
                            color: "white",
                            fontSize: 16,
                            opacity: 0.8,
                            zIndex: 999,
                          }}
                          className="btn2 btn-secondary"
                          href={`../../../pdf/AlfredoandIreneRoces.pdf`}
                          download=""
                        >
                          Download PDF
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          style={{
                            padding: "5px 38px 6px",
                            right: "45%",
                            opacity: 0.7,
                            color: "white",
                            fontSize: 20,
                            zIndex: 999,
                          }}
                          className="btn btn-secondary"
                          id="view_ecatalog"
                          onClick={(e) => Ecatalog(e,row.isSequence)}

                          // target="_blank"
                        >
                          E-Catalog
                        </a>

                        <a
                          style={{
                            padding: "5px 38px 6px",
                            left: "60%",
                            color: "white",
                            fontSize: 20,
                            opacity: 0.7,
                            zIndex: 999,
                          }}
                          className="btn btn-secondary"
                          href={`../../../pdf/AlfredoandIreneRoces.pdf`}
                          download=""
                        >
                          <DownloadIcon /> Download PDF
                        </a>
                      </>
                      
                    )}
</div>

                    
                  </>
                  
                );
              })
            ) : (
              <div key="1" className="qwerty" style={{ textAlign: "center" }}>
                <h6 className="card-title title" style={{ lineHeight: 1.3 }}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress /> Loading...
                  </div>
                </h6>
              </div>
            )
          ) : (
            <div
              style={{ textAlign: "center", paddingTop: 100, margin: "auto" }}
            >
              <CircularProgress /> Loading...
            </div>
          )}
        </Carousel>
      </div>
    </section>
  );
};

export default BannerHomePage;
