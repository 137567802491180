import React, { useState, useRef, useCallback, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { maisonAuction } from "../../../Model/Auction";
import Masion from "./../../../images/resources/masion.png";

const View = (props) => {
  const matches = useMediaQuery("(min-width:1000px)");
  const itemData = [
    {
      img: "https://placehold.co/650x600/jpg",
      title: "Breakfast",
      rows: 2,
      cols: 2,
    },
    {
      img: "https://placehold.co/650x300/jpg",
      title: "Coffee",
      author: "@arwinneil",

      cols: 2,
    },
    {
      img: "https://placehold.co/650x300/jpg",
      title: "Coffee",
      author: "@arwinneil",

      cols: 2,
    },
  ];

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }


  // const [auction, setAuction] = useState([]);


  // const [loading,setLoading] = useState(false)


  // const  FetchData =  (auction_tatus) => {

  //   try {
  //     setLoading(true)
  //     async function getAuctionOne() {
  //       const results = await maisonAuction(auction_tatus);
  //       setAuction(results);
  //       setLoading(false)
  //     }
  //     getAuctionOne().catch(console.error);
  //   } catch (error) {
  //     setLoading(false)
  //   }
  // };

  // useEffect(() => {
  //   FetchData()
  // }, []);

  const handleUpcomming = (searchValue) => {
    // return FetchData(searchValue)
  };



  return (
    <div className="boxed_wrapper">
      <section
        className="breadcrumb-area"
        style={{
          backgroundImage: `url(${Masion})`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>{props.param}</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 50 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                      Maison
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="events-single-area">
        <div class="container">
          {/* <div class="row">
            <div class="col-xl-12">
                <div class="events-single-image-box">
                    <img src="images/events/event_single_img_1.jpg" alt="Awesome Image"/>    
                </div>   
            </div>
        </div> */}
          <div class="row">
            <div class="col-xl-8">
              <div class="events-single-text-box">
                <h2>Leon Gallery Maison</h2>
                <p>
                Nestled within the elegant confines of León Gallery International at Corinthian Plaza, León Gallery Maison epitomizes an exquisite haven tailored for discerning individuals who appreciate life's luxuries. Immerse yourself in a meticulously curated experience where opulence meets artistry. This distinguished space showcases an exclusive ensemble of objets d'art, home accessories, and jewelry, each piece handpicked to reflect the epitome of sophistication.
                </p>
                <p>
                  The ambiance at León Gallery Maison is a seamless blend of refinement and cultural resonance, inviting patrons to explore the intersection of 
                  aesthetics and craftsmanship. From captivating artworks to timeless home accents and exquisite jewelry, every item in this collection tells a 
                  story of unparalleled craftsmanship and refined taste.
                </p>
                <p>
                Indulge your senses in the allure of León Gallery Maison, where each visit promises a journey through the realms of beauty and elegance. Whether you seek a statement piece for your living space, a tasteful home accessory, or a piece of jewelry that exudes both rarity and allure, León Gallery Maison stands as a distinguished destination for those who seek the pinnacle of the finer things in life.
                </p>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="events-single-info-box">
                <ul class="events-info">
                  <li>
                    <p>Type :</p>
                    <span>Gallery Talk</span>
                  </li>
                  <li>
                    <p>Hours :</p>
                    <span>Monday–Saturday 9:00 AM - 6:00 PM</span>
                  </li>
                  <li>
                    <p>Venue :</p>
                    <span>
                      Eurovilla 1, Rufino corner Legazpi Street, Legazpi
                      Village, Makati, 1224
                    </span>
                  </li>
                </ul>
                <div class="social-links">
                  <p>Shear :</p>
                  <ul class="sociallinks-style-two">
                    <li>
                      <a href="#">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ExploreLeonGallery/> */}

      <section class="gallery-area">
        <div class="layer-outer"></div>
        <div className="container">
          <div class="visit-day-select">
            <h1>Auction</h1>

            <div class="sec-title text-center" style={{ paddingTop: 20 }}>
              <ul class="tab-btns tab-buttons text-center clearfix">
                <li
                  data-tab="#today"
                  class="tab-btn"
                  onClick={() => handleUpcomming("UPCOMING")}
                >
                  <span>Upcoming</span>
                </li>
                <li
                  data-tab="#tomorrow"
                  class="tab-btn"
                  onClick={() => handleUpcomming("PREVIOUS")}
                >
                  <span>Past</span>
                </li>
                {/* <li data-tab="#select-date" class="tab-btn active-btn"><span>Select Date</span></li> */}
              </ul>
            </div>
          </div>

          <div class="row items-container clearfix">
            {/* {auction.map((val) => {
                  var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var startDate = new Date(val.date_start);
                  var endDate = new Date(val.date_end);
                  return ( */}
            <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 masonry-item all objects pic">
              <div class="single-art-box-style2 marbtm50">
                <div class="img-holder">
                  <img src={'https://placehold.co/570x570/jpg'} alt="Awesome Image" />
                  <div class="overlay-content">
                    <div class="zoom-button">
                      <a
                        class="lightbox-image"
                        data-fancybox="gallery"
                        href={'https://placehold.co/570x570/jpg'}
                      >
                        <i
                          class="fa fa-search-plus"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="text-holder">
                  <h3>
                    <a href="#">{'Sample Link'}</a>
                  </h3>
                  <span>
                    {/* {startDate.toLocaleDateString("en-US", options)} To{" "} */}
                  </span>
                  <span>
                    {/* {" "}
                            {endDate.toLocaleDateString("en-US", options)} */}
                  </span>
                </div>
              </div>
            </div>
            {/* );
                })} */}

            <ImageList
              sx={{ width: ' 100%', paddingBottom: 10 }}
              variant="quilted"
              cols={4}
              rowHeight={matches === true ? 300 : 120}
            >
              {itemData.map((item) => (
                <ImageListItem

                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img

                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>
      </section>
    </div>
  );
};

export default View;
