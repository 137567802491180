import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Listcolor from "../assets/color";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import PlaceIcon from "@mui/icons-material/Place";
import ViewListIcon from "@mui/icons-material/ViewList";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import GavelIcon from "@mui/icons-material/Gavel";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import MuseumIcon from "@mui/icons-material/Museum";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import BookIcon from "@mui/icons-material/Book";
import Typography from "@mui/material/Typography";
import BallotIcon from '@mui/icons-material/Ballot';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import ColorLensIcon from "@mui/icons-material/ColorLens";
import Container from "@mui/material/Container";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import DeckIcon from "@mui/icons-material/Deck";
import FlagIcon from "@mui/icons-material/Flag";
import HomeIcon from "@mui/icons-material/Home";
import GiteIcon from "@mui/icons-material/Gite";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getUser } from "../../Model/LocalStorage";
import {getToken} from "../../Model/User";
const drawerWidth = 240;

const pages = ["Products", "Pricing", "Blog"];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const privateSale = [
  "Painting",
  "Sculpture",
  "Furniture",
  "Jewelry",
  "Objet D' Arts",
  "Books",
];
const galleryLocation = ["Main", "International", "La Fuerza"];
const accountdata = ["Profile", "My lots", "Logout"];

export default function ButtonAppBar(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const phoneMatch = useMediaQuery("(min-width:1200px)");
  const [openPrivate, setOpenPrivate] = React.useState(false);
  const [openGallery, setOpenGallery] = React.useState(false);
  const [openAccount, setOpenAccount] = React.useState(false);
  const token = getToken();
  const [username, setUsername] = React.useState("");
  const [group, setGroup] = React.useState("");

  const ClickPrivate = () => {
    setOpenPrivate(!openPrivate);
  };

  const ClickPGallery = () => {
    setOpenGallery(!openGallery);
  };

  const ClickAccount = () => {
    setOpenAccount(!openAccount);
  };


  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigatePage = (e, page) => {
    // 👇️ navigate to /contacts
    switch (page) {
      case "Home":
        window.location.href = "/home?data=home";
        break;
      case "Live":
        navigate("/auctions");
        break;
      case "Auction":
        navigate("/auctions");
        break;
      case "Exibition":
        navigate("/exhibition");
        break;
      case "Stories":
        navigate("/stories");
        break;
      case "Faq":
        navigate("/Faq");
        break;
      case "Painting":
        navigate("/private/sale?data=PAINTING");
        break;
      case "Sculpture":
        navigate("/private/sale?data=SCULPTURE");
        break;
      case "Objet D' Arts":
        navigate("/private/sale?data=OBJET%20D%27%20ARTS");
        break;
      case "Books":
        navigate("/private/sale?data=BOOKS");
        break;
      case "Jewelry":
        navigate("/private/sale?data=JEWELRY");
        break;
      case "Furniture":
        navigate("/private/sale?data=FURNITURE");
        break;
      case "Main":
        navigate("/location/main?data=MAIN");
        break;
      case "International":
        navigate("/location/international?data=INTERNATIONAL");
        break;
      case "LF":
        navigate("/location/international?data=La Fuerza");
        break;
      case "Logout":
        localStorage.removeItem("@storage_Key");
        localStorage.removeItem("token");
        window.location.href = "/login";
        break;
      case "Auctioneer":
          navigate("/declarator/room");
          break;
          case "Profile":
            navigate("/profile");
            break;

      default:
      // code block
    }
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [gallery, setGallery] = React.useState(null);
  const [account, setAccount] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenGallery = (event) => {
    setGallery(event.currentTarget);
  };

  const handleCloseGallery = () => {
    setGallery(null);
  };

  const handleOpenAccount = (event) => {
    setAccount(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAccount(null);
  };

  React.useEffect(() => {
    async function getUserList() {
      const getuser = await getUser();
      setGroup(getuser.group);
      setUsername(getuser.email);
    }
    getUserList().catch(console.error);
  });

  return (
    ///#282121 2e2c2c
    <div>
      <Box sx={{ display: "flex" }}>
        <AppBar
          // position="absolute"
          // open={open}
          style={{
            backgroundColor: Listcolor.textWhite,
            color: Listcolor.textBlack,
          }}
        >
          <Toolbar>
            {phoneMatch !== true ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <IconButton
                  // style={{marginLeft: 'auto' }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  // sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}

                  // sx={{ mr: 10, ...(open && { display:  { xs: 'flex', md: 'none' }, }) }}
                >
                  <MenuIcon />
                </IconButton>
                <Logo />
              </Box>
            ) : (
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <Typography
                    sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                  >
                    <Logo />
                  </Typography>

                  <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>

                  <Box sx={{ flexGrow: 0 }}>
                   
                    <Button
                      style={{ fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={(e) => navigatePage(e, "Home")}
                      color="inherit"
                    >
                      Home
                    </Button>
                    {group === "ADMIN" ? <><Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={(e) => navigatePage(e, "Auctioneer")}
                      color="inherit"
                    >
                      Auctioneer
                    </Button></>: ""}
                    <Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={(e) => navigatePage(e, "Auction")}
                      color="inherit"
                    >
                      Auction
                    </Button>
                    <Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={(e) => navigatePage(e, "Exibition")}
                      color="inherit"
                    >
                      Exhibition
                    </Button>
                    <Button
                         style={{fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={handleOpenUserMenu}
                      color="inherit"
                    >
                      Private Sale
                    </Button>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {privateSale.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={(e) => navigatePage(e, setting)}
                        >
                          <Typography  style={{fontFamily:  "Bell MT Semi Bold",fontSize:18 }} textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>

                    <Button  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:15 ,margin:5  }}
                      onClick={(e) => navigatePage(e, "Stories")}
                      color="inherit">Stories</Button>

                    <Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:16 }}
                      onClick={handleOpenGallery}
                      color="inherit"
                    >
                      Galleries
                    </Button>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={gallery}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(gallery)}
                      onClose={handleCloseGallery}
                    >
                      {galleryLocation.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={(e) => navigatePage(e, setting)}
                        >
                          <Typography   style={{ fontFamily:  "Bell MT Semi Bold",fontSize:18}} textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                    <Button    style={{ fontFamily:  "Bell MT Semi Bold",fontSize:16 }} color="inherit">
                      Faq
                    </Button>

                    {token === null ?    <Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:16 }}
                         onClick={(e) => navigatePage(e, "Logout")}
                      color="inherit"
                    >
                      Login
                    </Button> :
                    <Button
                         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:16 }}
                      onClick={handleOpenAccount}
                      color="inherit"
                    >
                      My Account
                    </Button>}
                    <Menu
             
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={account}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(account)}
                      onClose={handleCloseAccount}
                    >
                      {accountdata.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={(e) => navigatePage(e, setting)}
                        >
                          <Typography         style={{ fontFamily:  "Bell MT Semi Bold",fontSize:18 }} textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Toolbar>
              </Container>
              // <List sx={{ display: "flex" }}>
              //   <ListItem>
              //     <Logo />
              //   </ListItem>
              //   {navItems.map((item) => (
              //     <ListItem key={item} disablePadding>
              //       <ListItemButton sx={{ textAlign: "center" }}>
              //         <ListItemText primary={item} />
              //       </ListItemButton>
              //     </ListItem>
              //   ))}
              // </List>
            )}

            {/* <Button   style={{float: "right"}} onClick={handleMenu} color="inherit">Login</Button>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
              </Menu> */}
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          overflow: "auto",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="temporary"
        // anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box style={{ margin: "auto" }}>
            <Logo />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List >
        {group === "ADMIN" ? 
          <ListItem disablePadding>   
            <ListItemButton onClick={(e) => navigatePage(e, "Auctioneer")}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Auctioneer</Typography>} />
            </ListItemButton>
          </ListItem>: ""}

          <ListItem disablePadding>
            <ListItemButton onClick={(e) => navigatePage(e, "Auction")}>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Auctions</Typography>}  />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={(e) => navigatePage(e, "Exibition")}>
              <ListItemIcon>
                <MuseumIcon />
              </ListItemIcon>
              <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Exhibition</Typography>} />
            </ListItemButton>
          </ListItem>

          <ListItem onClick={ClickPrivate}>
            <ListItemIcon>
              <ViewListIcon />
            </ListItemIcon>
            <ListItemText   primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Private Sale</Typography>} />
            {openPrivate ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openPrivate} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Painting")}
              >
                <ListItemIcon>
                  <ColorLensIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Painting</Typography>}/>
              </ListItem>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Sculpture")}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Sculpture</Typography>} />
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Furniture")}
              >
                <ListItemIcon>
                  <DeckIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Furniture</Typography>}  />
              </ListItem>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Jewelry")}
              >
                <ListItemIcon>
                  <DiamondIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Jewelry</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Objet D Arts")}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Objet D' Arts</Typography>} />
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Books")}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Books</Typography>}/>
              </ListItem>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton onClick={(e) => navigatePage(e, "Stories")}>
              <ListItemIcon>
                <AutoStoriesIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Stories</Typography>} />
            </ListItemButton>
          </ListItem>

          <ListItem onClick={ClickPGallery}>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Galleries</Typography>}/>
            {openGallery ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={openGallery} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Main")}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Main</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "International")}
              >
                <ListItemIcon>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>International</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "LF")}
              >
                <ListItemIcon>
                  <GiteIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>La Fuerza</Typography>}/>
              </ListItem>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton onClick={(e) => navigatePage(e, "Faq")}>
              <ListItemIcon>
                <LiveHelpIcon />
              </ListItemIcon>
              <ListItemText   primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Faq</Typography>}/>
            </ListItemButton>
          </ListItem>


          <Collapse in={openGallery} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Main")}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Main</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "International")}
              >
                <ListItemIcon>
                  <FlagIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>International</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "LF")}
              >
                <ListItemIcon>
                  <GiteIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>La Fuerza</Typography>}/>
              </ListItem>
            </List>
          </Collapse>
          




          {token === null ?    "" : <ListItem onClick={ClickAccount}>
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>My Account</Typography>}/>
            {openAccount ? <ExpandLess /> : <ExpandMore />}
          </ListItem> }


          <Collapse in={openAccount} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Profile")}
              >
                <ListItemIcon>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Profile</Typography>}/>
              </ListItem>

              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Mylots")}
              >
                <ListItemIcon>
                  <BallotIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>My Lots</Typography>}/>
              </ListItem>
          
              <ListItem
                style={{ marginLeft: 30 }}
                onClick={(e) => navigatePage(e, "Logout")}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText  primary={<Typography variant="body2"  style={{ fontFamily:  "Bell MT Semi Bold",fontSize:20 }}>Logout</Typography>}/>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </div>
  );
}
