import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function SearchAuction(page, auctionID, search, searchCat,artistRowID) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lotdata, setLot] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setLot([]);
  }, [auctionID, search, searchCat,artistRowID]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;

    const fetchData = async () => {
      try {
        let cancel;

 
      console.log('searchCat ',searchCat)
        await axiosInstant({
          method: "GET",
          url: `private-sales/lot?id=&pgroup=PRIVATE_SALE&search=${
            search === undefined ? "" : search
          }&cat=${searchCat === undefined ? "" : searchCat}&page=${
            page === undefined ? 1 : page 
          }&artist_id=${artistRowID === undefined ? "" : artistRowID}`,
          //params: { q: query, page: pageNumber },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
          .then((res) => {
            if (res.data.status === true) {
              setLot((prevlotdata) => {
                return [...new Set([...prevlotdata, ...res.data.msg])];
              });
            } else {
              setErrorMsg(false);
            }

            setHasMore(res.data.msg.length > 0);
            setLoading(false);
          })
          .catch((e) => {
            // console.log("res.data.msg: ",e.response.data.msg)

            if (axios.isCancel(e)) return;
            setError(true);
            setErrorMsg(e.response.data.sucess);
          });
        return () => cancel();
      } catch (error) {
        console.log("catch error: ", error);
      }
    };
    fetchData();
  }, [page, auctionID, search, searchCat,artistRowID]);

  return { loading, error, lotdata, hasMore, errorMsg };
}
