import React, { useEffect, useState } from "react";
import { publicationApi, GetTwoCall } from "../../Model/home";
// import CarouselMobile from "react-material-ui-carousel";
import Carousel from "react-multi-carousel";
import {  useParams, Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import axios from "axios";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Publication(props) {
  let { slug } = useParams();
  const [listPost, setList] = React.useState([]);
  const matches = useMediaQuery("(min-width:480px)");


  useEffect(() => {
    const wordpress_api = "https://apiblog.leon-gallery.com/wp-json/wp/v2/";
    const request1 = axios.get(
      wordpress_api + `categories?_fields=id,count,name,slug&include=3,5,4`
    );

    const request2 = axios.get(
      wordpress_api +
        `posts?_embed=wp:featuredmedia,wp:term&_fields=id,date,slug,title,excerpt,_links&order=desc&categories=${`4`}&per_page=10&page=${1}`
    );

    axios
      .all([request2])

      .then(axios.spread(function(res) {
        // setcategoriesList(res1.data)
        // setTotalResult(res2.headers['x-wp-total'])
        // setTotalPage(res2.headers['x-wp-totalpages'])
        setList(res.data);

        // setIsLoading(false)
      }))
      .catch((error) => {
        console.log(error.response.data);
        //return error.response.data.message;
      });
  }, [slug]);

  //   results.map((val) => {

  return (
    <div>
      <section className="latest-blog-style3-area">
      <div className={matches === true ? 'container': ''}>
      <div className={matches === true ? 'row': ''}>
            <div className="col-xl-12 col-lg-6 col-md-6">
              <div className="top">
                <div className="sec-title pull-left">
                  {/* <p>OUR LATEST NEWS</p> */}
                  <div className="big-title">
                    <h1>VIDEOS</h1>
                  </div>
                </div>
                <div className="view-more-button pull-right">
                  <Link className="btn-two" to="/stories?page=1&categories=4">
                    View More
                  </Link>
                </div>
              
              </div>
            </div>
          </div>

            

          <div className="" >
          <Carousel
          arrows={true}
            responsive={responsive}
            // autoPlay={true}
            // swipeable={true}
            // draggable={true}
            // //    showDots={true}
            // infinite={true}
            // partialVisible={false}
            // dotListClass="custom-dot-list-style"
             //itemClass="carouselItem"
            // sliderClass="sliderClass"
            // centerMode={false}
          >
              {listPost.map((data, key) => {
                var today = new Date(data.date);
                
                let srcURL;

                try {
       
                  if(data._embedded["wp:featuredmedia"][0].source_url === undefined || data._embedded["wp:featuredmedia"][0].source_url === null ){
                    console.log('data._embedded["wp:featuredmedia"][0].source_url Video ',data._embedded["wp:featuredmedia"][0].source_url)
                    srcURL = data._embedded["wp:featuredmedia"][0].source_url
                  }else{
                    srcURL =  data._embedded["wp:featuredmedia"][0].source_url.replace("188.166.186.239", "apiblog.leon-gallery.com");
                  }
                } catch (error) {
                  
                }

                return (
                  <div
                  className={matches === true ? 'col-xl-12': ''}
               
                    key={key}
                    style={{marginBottom:50}}
                  >
                    {/*Start single blog post*/}
                    <div
                      className="single-blog-post style3 wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="img-holder">
                        <div
                          className="img-holder-cover"
                          style={{
                            marginBottom: 5,
                            width: "100%",
                            height: "350px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#ffff",
                            border: "solid 1px #e8e8e8",
                          }}
                        >
                          {data._embedded["wp:featuredmedia"] ? (

<div   style={{backgroundImage: `url(${srcURL})`,  
backgroundColor: '#ffffff',
width: '100%',
height: 370,
display: 'inline-block',
backgroundSize: 'contain',
backgroundPosition: 'center center',
backgroundRepeat: 'no-repeat',
padding: 10,
}}></div>

                            // <img
                            //   className="imgcover"
                            //   src={
                            //     srcURL
                            //   }
                            //   alt={
                            //     data._embedded["wp:featuredmedia"][0].alt_text
                            //   }
                            //   style={{
                            //     maxWidth: "100%",
                            //     maxHeight: "100%",
                            //     verticalAlign: "middle",
                            //   }}
                            // />
                          ) : null}
                        </div>

                        <div className="overlay-style-one bg1" />
                      </div>
                      <div className="text-holder">
                        <div className="border-botton" />
                        <div className="post-date">
                          <span>
                            {today
                              .toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })
                              .split(" ")
                              .join("-")}{" "}
                            |{" "}
                            {data._embedded["wp:term"][0][0].name.toUpperCase()}
                          </span>
                        </div>

                        <h3
                          className="blog-title"
                          style={{
                            whiteSpace: "initial",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            maxWidth: "100%",
                          }}
                        >
                          <a href={`/stories/${data.slug}`}>
                            {data.title.rendered}
                          </a>
                        </h3>
                        <div
                          className="text"
                          style={{
                            whiteSpace: "initial",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 2,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            maxWidth: "100%",
                          }}
                        >
                          {data.excerpt.rendered.replace(
                            /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
                            ""
                          )}
                        </div>
                        <div className="button-box">
                          <a href={`/stories/${data.slug}`}>Read More</a>
                        </div>
                      </div>
                    </div>
                    {/*End single blog post*/}
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Publication;
