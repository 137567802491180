import React from "react";
import Page from "src/components/Page";
import View from "./view";

function index(props) {
  localStorage.removeItem("@storeAuctionLot");
  return (
    <Page title="How To Buy">
      <View />
    </Page>
  );
}

export default index;
