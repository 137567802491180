import React, { useCallback, useEffect, useState, useRef } from "react";
import "./product.css";
import { getPrivateSingleData, postInequired } from "../../Model/SinglePage";
// import { useParams } from "react-router-dom";
import { getstorePrivateID } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { storePrivateID } from "../../Model/LocalStorage";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { colors } from "@mui/material";
// import ReCAPTCHA from "react-google-recaptcha";

function View() {
  // const recaptcha = useRef()
  const matches = useMediaQuery("(min-width:999px)");
  const phoneWidth = useMediaQuery("(max-width:1200px)");
  const phoneWidthsss = useMediaQuery("(max-width:480px)");

  const [loading, setLoading] = React.useState(false);
  const { id, group } = useParams();
  const [title, setTitle] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [size, setSize] = React.useState("");
  const [medium, setMedium] = React.useState("");
  const [lotHtml, setLotHtml] = React.useState("");
  const [startingPrice, setStartingPrice] = React.useState("");
  const [signedDated, setSignDate] = React.useState("");
  const [coa, setCoa] = React.useState("");
  const [hp, setHp] = React.useState(null);
  const [provenance, setProvinance] = React.useState("");
  const [literature, setLiterature] = React.useState("");
  const [write_up, setWriteUp] = React.useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [count, setCount] = React.useState(1);
  const [exhibited, setExhibited] = useState("");
  // const items = JSON.parse(localStorage.getItem("@storePrivateID"));
  const [lot_group, setGroup] = useState(0);
  const [next, setNext] = useState(2);
  const [lotNumber, setLotNumber] = useState(id);
  const [zoom, setZoom] = React.useState(false);
  const [imageData, setImageData] = React.useState([]);
  // const [id, setID] = useState(id);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const [inquireMessage, setInquireMessage] = React.useState("");
  const [openNotif, setOpenNotif] = React.useState(false);
  const [firstname, setFirstName] = React.useState("");
  const [lastname, setLastName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [colorSign, setColor] = React.useState(false);
  //   const [lotID, setLotID] = useState(items.lot_);
  const FetchData = useCallback(
    async (id) => {
      setLoading(false);
      async function getData() {
        const results = await getPrivateSingleData(id);
        // let val = {};
        if (results.length) {
          results.map((row) => {
            const dataArray = {
              id: row.data.id,
            };
            setGroup(row.data.lot_group);
            storePrivateID(dataArray);
            setLotNumber(row.data.id);
            setTitle(row.data.title);
            setArtist(row.data.artist);
            setSize(row.data.size);
            setMedium(row.data.medium);
            setCoa(row.data.coa);
            setSignDate(row.data.signed_dated);
            setStartingPrice(row.data.starting_price);
            setLotHtml(row.data.lot_details_html);
            setHp(row.data.hp);
            setProvinance(row.data.provenance);
            setLiterature(row.data.literature);
            setExhibited(row.data.exhibited);
            setWriteUp(row.data.write_up);
            setImageData(row.data.image);
            setSelectedImage(row.image[0].img_url);

            
          });
        } else {
          setNext(0);
        }

        // if (results === false) {
        //   setLoading(false);
        // } else {
        //   setLoading(false);
        // }
      }

      getData().catch(console.error);
    },
    [id]
  );

  useEffect(() => {
    FetchData(id);
  }, [FetchData]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setZoom(false);
    setOpen(false);
  };

  const previousButton = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
        // return (prevCount = 0);
      }
    });
    // console.log(parseInt(items.id))
    // return FetchData(parseInt(items.id) - count);
    // const dataArray = {
    //   lot_id: items.lot_id,
    //   lotNo: parseInt(lotNumber) - 1,
    // };
    // storeAuctionLot(dataArray);
    // setPlaceAmount1(null);
    // return FetchData(items.lot_id, parseInt(lotNumber) - 1);
  };

  const nextButton = () => {
    // var iddd = parseInt(items.id) + 1;

    setCount(function (prevCount) {
      return (prevCount += 1);
    });

    return FetchData(parseInt(id) + count);

    // return FetchData(iddd);
  };

  const [detailsShow, setDetailShow] = useState(false);
  const DetailsFunction = () => {
    setDetailShow(!detailsShow);
  };

  const [provinanceShow, setProvinanceShow] = useState(false);
  const ProvinanceFunction = () => {
    setProvinanceShow(!provinanceShow);
  };

  const [exhibitedShow, setExhibitedShow] = useState(false);
  const ExhibitedFunction = () => {
    setExhibitedShow(!exhibitedShow);
  };

  const [writeupShow, setWriteUpShow] = useState(false);
  const WriteupFunction = () => {
    setWriteUpShow(!writeupShow);
  };

  const [literatureShow, setLiteratureShow] = useState(false);
  const literatureFunction = () => {
    setLiteratureShow(!literatureShow);
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bottomScroll, setBottomScroll] = useState("");
  const [scrollPositionTrue, setScrollPositionTrue] = useState("");

  const handleScroll = (e) => {
    const position = window.pageYOffset;
    var testdd =
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight) /
      2;
    setBottomScroll(testdd + 200);
    setScrollPositionTrue(
      Math.ceil(
        document.documentElement.scrollHeight -
          document.documentElement.scrollTop
      ) === document.documentElement.clientHeight
    );
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const [errorMaximun, setErrorMaximum] = useState("");
  // const [errorMax, setErrorMax] = useState(false);

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };



   function inquirehandle(event) {


    event.preventDefault();

    setLoading(true);
    setOpen(false);

    const dataDetails = {
      firstname: firstname,
      lastname: lastname,
      email_address: email,
      phone_number: phone,
      message: message,
      id: id,
    };
    // const captchaValue = recaptcha.current.getValue()
    // console.log(captchaValue)
    // if (!captchaValue) {
    //   setLoading(false);
    //   alert('Please verify the reCAPTCHA!')
    // } else {
    //   // make form submission
    //   alert('Form submission successful!')
 


    async function postInquire() {

      const results = await postInequired(dataDetails);
      setLoading(false);
      setOpenNotif(true);
      if (results.sucess === false) {
        setColor(true);

        setInquireMessage(results.msg);
      } else {
        setLoading(false);
        setInquireMessage(results.msg);
      }
    }
    postInquire().catch((error) => {
      setLoading(false);
      setInquireMessage(error);

      //return error.response.data.message;
    });
 // } // end captcha
    // console.log("dataDetails ", dataDetails);
  };

  return (
    <div style={{  padding: 11, paddingTop: 30 }}>
      <div className="container">
        <nav style={{ paddingBottom: 30 }}>
          <ol className="breadcrumb" style={{ backgroundColor: "#ffff" }}>
            <li className="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">
                {" "}
                {group === "EXHIBITION" ? "Exhibition" : "Private"}
              </a>
            </li>

            <li className="breadcrumb-item">
              <a href={`/private/sale?data=${lot_group}`}>{lot_group}</a>
            </li>

            {phoneWidthsss === true ? (
              ""
            ) : (
              <>
                <li
                  className="breadcrumb-item"
                  style={{
                    maxWidth: "50ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {title === null ? "" : title.replace(/(<([^>]+)>)/gi, "")}{" "}
                </li>
              </>
            )}
          </ol>
        </nav>
        {/* {data.map((val) => {
            console.log(val.img_url)    dangerouslySetInnerHTML={{ __html: artist }}
          return (
            <> */}

        <div className="container-fluid">
          <div className="row">
            {/* {matches === false ? (
                <ul
                  class="images"
                  style={{ marginLeft: -13, marginBottom: 20 }}
                >
                  {imageData.map((row, i) => {
                    return (
                      <li class="loc-caption" title="location 1">
                        <img
                          d="loc1"
                          onClick={() => setSelectedImage(row.img_url)}
                          src={row.img_url}
                          alt={i}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul
                  className="image_list"
                  style={{ overflow: "auto", maxHeight: 525 }}
                >
                  {imageData.map((row, i) => {
                    console.log(row);
                    return (
                      <li>
                        <img
                          //   style={{ maxwidth : matches === true ? '45%':'95%'}}
                          onClick={() => setSelectedImage(row.img_url)}
                          src={row.img_url}
                          alt={i}
                        />
                      </li>
                    );
                  })}

                 
                </ul>
              )} */}

            <div
              className="col-xl-8 order-lg-2 order-1"
              style={{
                maxHeight: 900,
                paddingRight: phoneWidthsss === false ? 15 : 0,
                paddingLeft: phoneWidthsss === false ? 15 : 0,
              }}
            >
              <div
                onClick={(e) => setZoom(true)}
                className={`image_selected ${
                  matches === true ? "exampleSingle" : ""
                }  example-contain`}
                data-toggle="modal"
                data-target="#exampleModalCenter"
                style={{height: 500}}
              >
                {/* <div   style={{backgroundImage: `url(${selectedImage})`,  
                // backgroundColor: '#ffffff',
                width: '100%',
                // height:  '100%',
                display: 'inline-block',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                padding: 10,
                cursor: "pointer"
              }}>     </div> */}

                <img src={selectedImage} alt="1" />
              </div>
            </div>
            <div className="col-xl-4 order-5 ">
              <div style={{ maxHeight: 500 }}>
                <div
                  className={`product_description ${
                    phoneWidth === false
                      ? scrollPosition > 250
                        ? "positionfixed"
                        : ""
                      : ""
                  }`}
                  style={{
                    paddingTop: phoneWidthsss === false ? "" : 20,
                    top:
                      scrollPosition > bottomScroll
                        ? bottomScroll - scrollPosition
                        : "",
                  }}
                >
                  {/* <div
                  className="d-flex justify-content-between"
                  style={{
                    fontSize: 25,
                    letterSpacing: 15,
                    marginBottom: coa === null ? "" : 5,
                    marginTop: matches === false ? 30 : 0,
                  }}
                >
             
                    <button onClick={previousButton}>
                      <i className="fa fa-arrow-left" aria-hidden="true">
                        {" "}
                      </i>
                      {lotNumber-1}
                    </button>
        

                  <div style={{ margin: "auto" }}>
                    <h2 style={{ color: "red" }}>{lotNumber}</h2>
                  </div>
                  {next === 0 ? (
                    ""
                  ) : (
                    <button onClick={nextButton}>
                      {lotNumber+1}
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                  )} 
                </div> */}

                  {coa === null || coa === "" ? (
                    ""
                  ) : (
                    <div>
                      <span className="product_info">{coa}</span>{" "}
                      <hr className="singleline" />
                    </div>
                  )}

                  <p
                    style={{
                      fontWeight: "bold",
                      whiteSpace: " break-spaces",
                      fontSize: phoneWidth === false ? 25 : 20,
                    }}
                    dangerouslySetInnerHTML={{ __html: artist }}
                  ></p>
                  <p
                    style={{
                      fontWeight: "bold",
                      whiteSpace: " break-spaces",
                      fontSize: phoneWidthsss === false ? 20 : 18,
                    }}
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></p>

                  <hr className="singleline" />

                  {/* {lotHtml === '' ? (
                  <div style={{ fontSize: 18, paddingBottom: 20 }}>
                    <span
                      className="product_info"
                      
                      dangerouslySetInnerHTML={{ __html: signedDated }}
                    ></span>
                    <br /> <span className="product_info">{medium}</span>
                    <br /> <span className="product_info">{size}</span>
                  </div>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: lotHtml }} />
                )} */}

                  <p
                    style={{
                      textDecoration: "underline",
                      fontSize: phoneWidthsss === false ? 20 : 18,
                    }}
                  >
                    Price Available Upon Request
                  </p>
                  {/* <hr className="singleline" /> */}

                  <div className="">
                    <div className="">
                      {group === "EXHIBITION" ? (
                        ""
                      ) : (
                        <button
                          onClick={handleClickOpen}
                          type="button"
                          className="btn btn-dark btn-lg btn-block"
                        >
                          Inquire
                        </button>
                      )}
                      If you have a problem with your inquiry, please email{" "}
                      {group === "EXHIBITION" ? (
                        <span>
                          <a href="info@leon-gallery.com">
                            rinaformento@leon-gallery.com
                          </a>{" "}
                          or you may call this number (02)8856-2781)
                        </span>
                      ) : (
                        <span>
                          <a href="info@leon-gallery.com">
                            info@leon-gallery.com
                          </a>
                          or you may call this number (02)8856-2781)
                        </span>
                      )}
                    </div>
                  </div>

                  <div class={`footer-social-links`} style={{ paddingTop: 20 }}>
                    <div class="title">Follow Us : &nbsp;&nbsp; </div>
                    <ul
                      class="social-links-style1"
                      style={{
                        display: "contents",
                        position: "relative",
                        float: "left",
                        marginRight: 10,
                      }}
                    >
                      <li>
                        <a
                          href="https://www.facebook.com/LeonGalleryMakati/"
                          target="_blank"
                        >
                          <i
                            class="fa fa-facebook"
                            style={{
                              color: "#040404",
                              border: "1px solid #463a3a",
                              position: "relative",
                              // display: 'content',
                              background: "transparent",
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              fontSize: 14,
                              fontWeight: 400,

                              textAlign: "center",
                              transition: "all 500ms ease",
                            }}
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/leongalleryph"
                          target="_blank"
                        >
                          <i
                            class="fa fa-twitter"
                            style={{
                              color: "#040404",
                              border: "1px solid #463a3a",
                              position: "relative",
                              // display: 'content',
                              background: "transparent",
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              fontSize: 14,
                              fontWeight: 400,

                              textAlign: "center",
                              transition: "all 500ms ease",
                            }}
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a href="mailto:info@leon-gallery.com">
                          <i
                            class="fa fa-envelope"
                            style={{
                              color: "#040404",
                              border: "1px solid #463a3a",
                              position: "relative",
                              // display: 'content',
                              background: "transparent",
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              fontSize: 14,
                              fontWeight: 400,

                              textAlign: "center",
                              transition: "all 500ms ease",
                            }}
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCcgsLbrD84mc26EuWzVptzA"
                          target="_blank"
                        >
                          <i
                            class="fa fa-youtube-play"
                            style={{
                              color: "#040404",
                              border: "1px solid #463a3a",
                              position: "relative",
                              // display: 'content',
                              background: "transparent",
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              fontSize: 14,
                              fontWeight: 400,

                              textAlign: "center",
                              transition: "all 500ms ease",
                            }}
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 50 }}>
            <div className="col-xl-8">
              <hr className="singleline" />
              {/* <h2>{provenance === null ? '':'Lot Details'}</h2> */}
              <div
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  textAlign: "justify",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                      fontWeight: "bold",
                    }}
                  >
                    DETAILS
                  </p>
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                    onClick={DetailsFunction}
                  >
                    {detailsShow === true ? (
                      <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                    ) : (
                      <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                    )}
                  </p>
                </div>

                <div
                  style={{
                    display: detailsShow === true ? "none" : "block",
                    width: "90%",
                  }}
                >
                  <p
                    style={{
                      whiteSpace: " break-spaces",
                      marginBottom: "0rem",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: artist === null ? "" : artist,
                    }}
                  ></p>
                  <p
                    style={{
                      whiteSpace: " break-spaces",
                      marginBottom: "0rem",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: title === null || title === "" ? "" : title,
                    }}
                  ></p>
                  <p
                    style={{
                      whiteSpace: " break-spaces",
                      marginBottom: "0rem",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: signedDated === null ? "" : signedDated,
                    }}
                  ></p>
                  <p
                    style={{
                      whiteSpace: " break-spaces",
                      marginBottom: "0rem",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: medium === null ? "" : medium,
                    }}
                  ></p>
                  <p
                    style={{
                      whiteSpace: " break-spaces",
                      marginBottom: "0rem",
                      fontSize: phoneWidthsss === false ? 20 : 16,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: size === null ? "" : size,
                    }}
                  ></p>
                </div>

                {provenance === null || provenance === "" ? (
                  ""
                ) : (
                  <div>
                    {" "}
                    <hr className="singleline" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                      >
                        PROVENANCE
                      </p>
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                        onClick={ProvinanceFunction}
                      >
                        {provinanceShow === true ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: provinanceShow === true ? "none" : "block",
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: " break-spaces",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          marginBottom: 20,
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            provenance === null
                              ? ""
                              : provenance.replace(/PROVENANCE/g, ""),
                        }}
                      ></p>
                    </div>
                  </div>
                )}

                {exhibited === null || exhibited === "" ? (
                  ""
                ) : (
                  <div>
                    {" "}
                    <hr className="singleline" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                      >
                        EXHIBITED
                      </p>
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                        onClick={ExhibitedFunction}
                      >
                        {exhibitedShow === true ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: exhibitedShow === true ? "none" : "block",
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: " break-spaces",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          marginBottom: 20,
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            exhibited === null
                              ? ""
                              : exhibited.replace(/EXHIBITED/g, ""),
                        }}
                      ></p>
                    </div>
                  </div>
                )}

                {literature === null || literature === "" ? (
                  ""
                ) : (
                  <div>
                    <hr className="singleline" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                      >
                        LITERATURE
                      </p>
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                        onClick={literatureFunction}
                      >
                        {literatureShow === true ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: literatureShow === true ? "none" : "block",
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: " break-spaces",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          marginBottom: phoneWidth === true ? 20 : 16,
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            literature === null
                              ? ""
                              : literature.replace(/LITERATURE/g, ""),
                        }}
                      ></p>
                    </div>
                  </div>
                )}

                {write_up === null || write_up === "" ? (
                  ""
                ) : (
                  <div>
                    <hr className="singleline" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                      >
                        WRITE UP
                      </p>
                      <p
                        style={{
                          textAlign: "justify",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          fontWeight: "bold",
                        }}
                        onClick={WriteupFunction}
                      >
                        {writeupShow === true ? (
                          <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                        ) : (
                          <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                        )}
                      </p>
                    </div>

                    <div
                      style={{
                        display: writeupShow === true ? "none" : "block",
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          whiteSpace: " break-spaces",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                          marginBottom: 20,
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            write_up === null
                              ? ""
                              : write_up.replace(/WRITE UP/g, ""),
                        }}
                      ></p>
                    </div>
                  </div>
                )}

                <hr className="singleline" />
              </div>
            </div>
          </div>
        </div>
        {/* </>
          );
        })} */}
      </div>

      <div
        className="modal"
        id="exampleModalCenter"
        tabindex={lotNumber}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        // aria-hidden="true"
        // style={{ backgroundColor: "#00000" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: 600 }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "red",
              marginTop: phoneWidth === false ? -20 : 100,
            }}
          >
            <img src={selectedImage} alt="" />
          </div>
        </div>
      </div>

      <Dialog
        // fullWidth={true}
        maxWidth={"lg"}
        open={zoom}
        onClose={handleClose}
      >
        <div
          className={matches === false ? "" : "example example-contain"}
          style={{
            // maxHeight: 600,
            width: phoneWidthsss === false ? 700 : "100%",
            height: phoneWidthsss === false ? 700 : "100%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img
              aria-hidden="true"
              fetchpriority="high"
              style={{
                width: "100%",
                height: phoneWidthsss === false ? "45vw" : "",
              }}
              src={selectedImage}
              alt="1"
            />
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog className="dialog" open={open} onClose={handleClose}>
      <form onSubmit={(e) => inquirehandle(e)}>
        <DialogTitle>Enquiry</DialogTitle>
       
          <DialogContent>
            <DialogContentText>
              Contact one of our specialists. <br />
              info@leon-gallery.com will advise further on this item.
            </DialogContentText>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="firstname"
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  label="First Name"
                  type="text"
                  required={true}
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="name"
                  name="lastname"
                  onChange={(e) => setLastName(e.target.value)}
                  label="Last Name"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="name"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email Address"
                  required={true}
                  type="email"
                  fullWidth
                />

                <TextField
                  autoFocus
                  margin="normal"
                  variant="outlined"
                  id="name"
                  name="phone"
                  placeholder="0999-9999-999"
                  inputProps={{ minlength: "9", maxlength: "13" }}
                  onChange={(e) => setPhone(e.target.value)}
                  label="Phone Number (63+)"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  required={true}
                  margin="normal"
                  variant="outlined"
                  id="name"
                  name="message"
                  multiline
                  onChange={(e) => setMessage(e.target.value)}
                  rows={2}
                  label="Message"
                  type="text"
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptcha}
                size="invisible"
                sitekey="6LdlftoqAAAAANThJaY5ECBx5dnkbAkDDie-Qauo"
              />
            </Grid> */}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        style={{ margin: "auto", position: "absolute" }}
        open={openNotif}
        autoHideDuration={5000}
        onClose={handleClosess}
        message={inquireMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={vertical + horizontal}
      />
    </div>
  );
}

export default View;
