import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function ModelAuction(page,live, exhibition, online) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    setBooks([]);
  }, [live, exhibition, online]);
  

  useEffect(() => { 
    console.log(page)
    setLoading(true);
    setError(false);
     let cancel;

     const fetchData = async () => {
      try {
        
        let cancel;
       await  axiosInstant({
      method: 'GET',
      url:`auction?page=${page === null ? "" : page}${
          live === null ? "" : `&category=${live}`
        }${exhibition === null ? "" : `&category=${exhibition}`}${
          online === null ? "" : `&category=${online}`
        }`,
        //params: { q: query, page: pageNumber },
       cancelToken: new axios.CancelToken(c => cancel = c)
      })
      .then((res) => {

        const result = res.data.msg.filter(
          (x) =>
            x.c_type === "LIVE_AUCTION" || x.c_type === "ONLINE_AUCTION" 
        );

        
        setBooks((prevBooks) => {
          return [...new Set([...prevBooks, ...result])];
        })

        // setBooks((prevBooks) => {
        //   return [...new Set([...prevBooks, ...res.data.msg])];
        // })
        setHasMore(res.data.msg.length > 0);
        setLoading(false)
      })
      .catch((e) => {

        // if(e.response === undefined){
        //   setErrorMsg("error")
        //   console.log('error')
        // }else{
        //   setErrorMsg(e.response.data.msg)
        
        //         if (axios.isCancel(e)) return;
        //         setError(true); 
            
        // }

        setErrorMsg(e.response.data.msg)
        if (axios.isCancel(e)) return
        setError(true)
      });

    return () => cancel()

       } catch (error) {

      console.log("catch error: ",error)
      
    }
  }
  fetchData();
  }, [ page,exhibition, live, online]);

  return { books, hasMore, loading, error ,errorMsg};
} 
