import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import Page from "src/components/Page";
import { contactus } from "../Model/Auction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
function Contact(props) {

    const [state, setState] = React.useState({
        vertical: "top",
        horizontal: "center",
      });
      const { vertical, horizontal } = state;
    const [inquireMessage, setInquireMessage] = React.useState("");
    const [openNotif, setOpenNotif] = React.useState(false);

    const [values, setValues] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });

      const [loading, setLoading] = useState(false)
    
    const handleChange = (event) => {
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });

    }

    const handleClosess = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setOpenNotif(false);
      };

    function sendHandle (event){
        event.preventDefault();

        const ArrayData = {
            name: values.name,
            email:  values.email,
            phone:   values.phone,
            subject:  values.subject,
            message: values.message,
          };
          setLoading(true);

          async function postContact() {

            const results = await contactus(ArrayData);
            setLoading(false);
            setOpenNotif(true)
            setInquireMessage(results.msg)
          }
          postContact().catch((error) => {
            setLoading(false);
            setInquireMessage(error.response.data.msg)
            //return error.response.data.message;
          });
        }


  return (
    <Page title="Contact Us">
      <div className="container" style={{ paddingTop: 30 }}>
        <nav style={{ paddingBottom: 30 }}>
          <ol className="breadcrumb" style={{ backgroundColor: "#fff" }}>
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a style={{ color: "#000000" }} href="#">
                Contact Us
              </a>
            </li>
          </ol>
        </nav>

        <section class="">
          <div class="">
            <div class="sec-title text-center" style={{ paddingBottom: 35 }}>
              <div class="big-title">
                <h1>Get in Touch</h1>
              </div>
              {/* <p>Feel free to get in touch with me. we alwasy open to discussing now projects, createive ideas<br/> or opportunities to be part of your visions</p> */}
            </div>
            <div class="row clearfix">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="">
                  <div class="">
                    <form onSubmit={sendHandle}>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            id="outlined-select-currency-native"
                            margin="normal"
                            fullWidth
                            label="Name"
                            name="name"
                            // value={values.roles}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            // error={errorUsername ? true : false}
                            // helperText={validUsername}
                            // inputProps={{pattern:"[a-zA-Z0-9\-\_]$"}}
                            fullWidth
                            label="Email"
                            margin="normal"
                            name="email"
                            onChange={handleChange}
                            type="email"
                            // value={values.username}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            label="Subject"
                            margin="normal"
                            name="subject"
                            // error={error ? true : false}
                            onChange={handleChange}
                            type="text"
                            // value={values.password}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            required
                            fullWidth
                            // error={error ? true : false}
                            // helperText={valid}
                            label="Phone Number"
                            margin="normal"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            // value={values.confirm}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            // error={error ? true : false}
                            // helperText={valid}
                            label="Your Message"
                            margin="normal"
                            name="message"
                            multiline
                            rows={4}
                            onChange={handleChange}
                            type="text"
                            // value={values.confirm}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                        <div className="view-more-button" >
    
                          <Button type="submit" className="btn-two" variant="contained"  style={{width: '100%',height: 45,background: '#212529'}}>
                            Send
                          </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <Snackbar
        style={{ margin: "auto", position: "absolute" }}
        open={openNotif}
        autoHideDuration={5000}
        onClose={handleClosess}
        message={inquireMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={vertical + horizontal}
      />

    </Page>
  );
}

export default Contact;
