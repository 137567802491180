import { makeStyles } from "@mui/styles";

import Listcolor from "../assets/color";

const theme = makeStyles({
    root: {
      "&:hover": {
        backgroundColor: "red",
      },
    },
  
    media: {
      width: "50%",
      margin: "auto",
      "@media (max-width:900px)": {
        height: "20%",
        width: "20%",
        margin: "auto",
        // display: "flex",
        // flexDirection: "row",
      },
    },
    card: {
      width: "70%",
      margin: "auto",
      marginTop: "10px",
      "@media (max-width:900px)": {
        width: 300,
      },
    },
    textmobile: {
      color: "black",
      padding: 5,
      "@media (max-width:900px)": {
        color: "orange",
        fontSize: 10,
      },
    },
    cardTitle: {
      backgroundColor: Listcolor.headColor,
      color: "white",
      "@media (max-width:600px)": {
        "& .css-1qvr50w-MuiTypography-root": {
          fontSize: 16,
        },
      },
    },
  });

export default theme;