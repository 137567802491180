import { Box, Typography } from '@mui/material';
import React from 'react';

function NotFound(props) {
    return (
        <section className="events-single-area" style={{ paddingBottom: 50 }}>
            <div className="container">


                <div style={{ textAlign: 'center', width: '100%' }}>
                    <Box style={{ paddingBottom: 30 }}>


                        <Typography variant="h1" gutterBottom style={{ color: 'red' }}>
                            404!
                        </Typography>

                        <Typography variant="h4" gutterBottom>
                            Oops!, the page you are looking for doesn't seem to exist.<br />

                        </Typography>

                        <Typography variant="h4" gutterBottom>

                            You may have mistyped the address or the page may have moved.
                        </Typography>


                    </Box>

                    <a href='/home' style={{ fontSize: 25, textDecoration: 'underline' }}>
                        Return to Leon Gallery's homepage
                    </a>

                </div>
            </div>
        </section>
    );
}

export default NotFound;