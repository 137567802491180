import React, { useState, useEffect, memo ,useCallback} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import SideLayout from "../leftLayout";
import MidLayout from "./midLayout";
import RightLayout from "./rightLayout";
// import LeftLayout from "../leftLayout";
// import ImageRows from "./ImageRows";
import ListImage from "./listmage";
import NextSlot from "./nextlot";
// import BellSound from "../assets/bell_ring.mp3";
import { getUser } from "../../Model/LocalStorage";
import "./Center.css";
// import { Divider, Typography } from "@mui/material";
import { auctionsLive } from "../../Model/Auction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {getToken} from "../../Model/User";

// const Socket = io.connect(process.env.REACT_APP_CONN);
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  //   textAlign: 'center',
  color: theme.palette.text.secondary,
  //   width: "100%"
}));

function App({Socket,LiveData,mediadQuery,Type}) {
  // const [username, setUsername] = useState(123);
  const [loading, setLoading] = React.useState(false);
  const [room, setRoomId] = useState("");
  const [getData, setGetData] = useState("");
  const [auctionTitle, setAuctionTitle] = useState();
  const token = getToken();
  const [email, setEmail] = useState("");
  const [lotNumber, setLotNumber] = useState(null);

  const LiveAuction = () => {
      setLoading(true);
      try {
        async function getAuctionOne() {
          const results = await auctionsLive();
   
          if(results.success === false){
            alert(results.msg)
          }else{
            setGetData(results);
            setAuctionTitle(results.auction_details.auction_name)
            setLotNumber(parseInt(results.lot.details[0].lot_number));
            setRoomId(results.auction_details.id);
            Socket.emit("joint_room", results.auction_details.auction_id);
          }

          // setGetData(results);
         setLoading(false);
        }
        getAuctionOne().catch(console.error);
      } catch (error) {}
    };

  useEffect(() => {
    

      try {
        setGetData(LiveData);
        setAuctionTitle(LiveData.auction_details.auction_name)

        setLotNumber(parseInt(LiveData.lot.details[0].lot_number));
        setRoomId(LiveData.auction_details.id);
        Socket.emit("joint_room", LiveData.auction_details.auction_id);
      } catch (error) {
        setGetData(LiveData=== undefined ? getData:LiveData);
      }
    


    async function getUserList() {
      const getuser = await getUser();
      setEmail(getuser.email)
      Socket.emit("ConnectedUser", { 
        userId: getuser.id,
        email: getuser.email,
        mobile: Type});
    }

    getUserList().catch(console.error);

  Socket.on("disconnect", (reason) => {
    // console.log('disconnect reason ',reason)
   if(reason === "transport close" ||  reason === "transport error"){
        setLoading(true)
        setTimeout(() => {
          window.location.reload();
          setLoading(false)
        }, 2000);
    
      }
  });

  Socket.on("receive_status", (data) => {
    if(data.message === "NEXTSLOT"){
      LiveAuction();
    }
   
  })

  
  Socket.on("connect_error", () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
      clearTimeout()
    }, 30000);


  });


  Socket.on("connect", () => {
    setLoading(false)
  });


    return () => {
 

      Socket.off('disconnect')
      Socket.off('ConnectedUser')
      Socket.off('joint_room')
      Socket.off('connect_error')


      //  socketDeclarator.removeAllListeners("receive_status");
     //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
     //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
      };
  }, [Socket,LiveData,Type,LiveAuction]);

 


  return (
    <Box
      sx={{
        flexGrow: 1,
        height: mediadQuery.phoneMatch !== true ? "100vh" : "",
      }}
    >
      {mediadQuery.phoneMatch !== true ? (
        <Grid
          container
          justifyContent="center"
          spacing={3}
          style={{marginTop: 10}}
        >
          <Grid item xs={12}>
            <div style={{ height: "100%",paddingTop:35}}>
              <MidLayout
                socket={Socket}
                mediadQuery={mediadQuery.phoneMatch}
                details={getData === "" ?  LiveData:getData} 
                socket={Socket}
                room={room}
                titleAuction={auctionTitle}
                // username={username}
              />
            </div>
          </Grid>
  
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <div style={{ height: "100%" }}>
            
            {/* <ImageRows
                mediadQuery={mediadQuery.mediadQuery.phoneMatch}
                details={LiveData}
                room={room}
                socket={Socket}
                // username={username}
                isConnected={isConnected}
              />  */}
              
             <RightLayout
                mediadQuery={mediadQuery.phoneMatch}
                details={getData === "" ?  LiveData:getData} 
                room={room}
                socket={Socket}
                LotNUMBER={lotNumber}
                // isConnected={isConnected}
              /> 
            </div>
          </Grid>
          {/* <Grid item  xs={12}>
         <Item style={{ height: "100%" }}>
         
              <ImageRows
                details={LiveData}
                titleAuction={auctionTitle}
                mediadQuery={mediadQuery.mediadQuery.phoneMatch}
                lotNumber={lotNumber}
              />
            </Item>

          </Grid> */}
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          style={{
            backgroundColor: "rgb(239 233 233)",
            padding: 5,
            marginTop: 30,
          }}
        >
          <Grid item xs={12}>
            {/* <Box>
              <Typography
                style={{ color: "", fontSize: 26, textAlign: "center" }}
              >
                {" "}
                {auctionTitle}{" "}
              </Typography>
            </Box> */}
          </Grid>
          <Grid item md={3} xs={12}>
            <Item style={{ height: "100%" }}>
              
              <ListImage
                socket={Socket}
                details={getData === "" ?  LiveData:getData} 
                titleAuction={auctionTitle}
                // mediadQuery={mediadQuery.mediadQuery}
                lotNumber={lotNumber}
              />
            </Item>
            {/* <SideLayout/> */}
          </Grid>
          <Grid item md={6} xs={12} style={{ paddingLeft: 5 }}>
            <Item style={{ height: "100%" }}>
              <MidLayout socket={Socket}    mediadQuery={mediadQuery.phoneMatch} room={room} details={getData === "" ?  LiveData:getData} />
            </Item>
          </Grid>
          <Grid item md={3} xs={12} style={{ paddingLeft: 5 }}>
            <Item style={{ height: "100%" }}>
            <RightLayout
                mediadQuery={mediadQuery.phoneMatch}
                socket={Socket}
                details={getData === "" ?  LiveData:getData} 
                room={room}
                // isConnected={isConnected}
              /> 
              {/* {token === null ?
              
            <NextSlot
                socket={Socket}
                details={getData === "" ?  LiveData:getData} 
                titleAuction={auctionTitle}
                // mediadQuery={mediadQuery.mediadQuery}
                lotNumber={lotNumber}
              />:

            <RightLayout
                mediadQuery={mediadQuery.phoneMatch}
                socket={Socket}
                details={getData === "" ?  LiveData:getData} 
                room={room}
                // isConnected={isConnected}
              /> } */}
            </Item>
          </Grid>
        </Grid>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default memo(App);
