import React, { useState, useRef, useCallback, useEffect } from "react";
export default function Blog({ listPost,setPage, totalPage, currentPage}) {


var x = 1;
  return (
 
 <div>

    <div className="row">

          { 
             listPost.map(( data, key)=>{
              var today = new Date(data.date)
              let srcURL;

              try {
       

                if(data._embedded["wp:featuredmedia"][0].source_url === undefined || data._embedded["wp:featuredmedia"][0].source_url === null ){
                  console.log('data._embedded["wp:featuredmedia"][0].source_url Video ',data._embedded["wp:featuredmedia"][0].source_url)
                  srcURL = data._embedded["wp:featuredmedia"][0].source_url
                }else{
                  srcURL =  data._embedded["wp:featuredmedia"][0].source_url.replace("188.166.186.239", "apiblog.leon-gallery.com");
                }
              } catch (error) {
                
              }
             return(
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" key={key}>
                      {/*Start single blog post*/}
                      <div className="single-blog-post" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div className="img-holder">

                        <div className="img-holder-cover" 
                             style={{margin: "10px",

                                    width: "100%",
                                    height: "350px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor:"lightGray"
                                    }}>
                            {
                              (data._embedded['wp:featuredmedia'])?
                                 <img className="imgcover" 
                                src={srcURL}
                                 alt={data._embedded['wp:featuredmedia'][0].alt_text} 
                                  style={{ maxWidth:"100%",
                                    maxHeight:"100%",
                                    verticalAlign: "middle"}}
                                 />
                                 :null
                            }
                         
                           </div>

                          <div className="overlay-style-one bg1" />
                        </div>
                        <div className="text-holder">
                          <div className="border-botton" />
                          <div className="post-date">

                       
                          <span>{today.toLocaleDateString('en-GB', {
                                              day : 'numeric',
                                              month : 'short',
                                              year : 'numeric'
                                          }).split(' ').join('-') } | { data._embedded['wp:term'][0][0].name.toUpperCase()}</span>
                          </div>

                          <h3 className="blog-title" style={{
                                                            whiteSpace: "initial",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            WebkitLineClamp: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                            maxWidth: "100%"}}>
                    <a href={`/stories/${data.slug}`}>{data.title.rendered}</a></h3>
                          <div className="text" style={{ whiteSpace: "initial",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            WebkitLineClamp: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                            maxWidth: "100%"}}>{data.excerpt.rendered.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, "")}</div>
                          <div className="button-box">
                            <a href={`/stories/${data.slug}`}>Read More</a> 
                          </div>
                        </div>
                      </div>
                      {/*End single blog post*/}
                    </div>
                  )
              
             })
            
          }

                   

      <div className="col-xl-12">
          <div className="row">
          <div className="col-xl-12">
          
                    <ul className="pagination flex-wrap justify-content-center " style={{paddingTop:30}}>
                      <li className="page-item disabled">
                        <a className="page-link" href="javascript:void(0)" tabIndex={-1}>Previous</a>
                      </li>
                      {
                         
                         Array.from(
                                      { length: totalPage },
                                      (_, i) => (
                                              
                                            <li className={`page-item ` + (((parseInt(x) + i)) == currentPage ? 'active' : '')}
                                                onClick={()=>setPage(parseInt(x) + i)}
                                             >
                                            <a className={`page-link `} href="javascript:void(0)">
                                              {parseInt(x) + i}
                                            </a>
                                            </li>
                                      )
                                  )

                     
                      }
                      
  
                      

                      <li className="page-item">
                        <a className="page-link" href="javascript:void(0)">Next</a>
                      </li>
                    </ul>
                  <br/>

          </div>
        </div>

      </div>
      

          
   </div>
 </div>
  );
}