import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { nextAuction, declaratorNextImage } from "../../Model/DeclaratorModel";
import NoImage from "../../images/resources/noImage.jpg";
import Typography from "@mui/material/Typography";
import "./Center.css";
// import {socket} from "./../../Model/socket"
import { Box, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export default function TitlebarBelowImageList({ socketDeclarator, LiveData }) {
  const [count, setCount] = useState(0);
  //  const [getdata,setGetData]= useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dataNextAuction, setNExtData] = useState([]);

  const ListDatagetNExtAuctionOne = (LiveData, data) => {
    try {
      async function getNExtAuctionOne() {
        const results = await nextAuction(LiveData, data);
        //  console.log(":results declarator: ",results)
        setNExtData(results);
        //setAuctionTitle(results.auction_details.auction_name);
      }
      getNExtAuctionOne().catch(console.error);
    } catch (error) {}
  };

  const increment = (val) => {
    setLoading(true);
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: LiveData.auction_details.auction_id,
          setId: LiveData.auction_details.id,
          LotNo: count + 1,
        };

        const results = await declaratorNextImage(ArrayData);
        setNExtData(results);
        setLoading(false);
        if (results.sucess === false) {
          setNExtData([]);
        } else {
          setNExtData(results);
        }
        // await socket.emit("send_Bid_amount", messageData);
      }
      getNextAuction().catch((error) => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const decrement = (val) => {
    setLoading(true);
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: LiveData.auction_details.auction_id,
          setId: LiveData.auction_details.id,
          LotNo: count - 1,
        };
  
        const results = await declaratorNextImage(ArrayData);
        setNExtData(results);
        setLoading(false);
        if (results.sucess === false) {
          setNExtData([]);
        } else {
          setNExtData(results);
        }
        // await socket.emit("send_Bid_amount", messageData);
      }
      getNextAuction().catch((error) => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClickNextAuction = () => {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
    //console.log(lotNumber+count)
    return increment();
  };

  const handleClickPrevAuction = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
    return decrement();
  };

  useEffect(() => {
    try {
      setCount(parseInt(LiveData.lot.details[0].lot_number) + 1);
    } catch (error) {}

    ListDatagetNExtAuctionOne(LiveData);

    return () => {
      socketDeclarator.removeAllListeners();
      // socket.off('foo', onFooEvent);
    };
  }, [socketDeclarator, LiveData]);

  return (
    <div style={{ padding: 5 }}>
    <ImageList gap={20} sx={{ height: 200, display: "flex" }}>
        {dataNextAuction.length ? (
          dataNextAuction.sort((a, b) => a.lot_number > b.lot_number).map((item,i) => {
            let soldItem;
            
            if (item.lot_status === "UNSOLD") {
              soldItem = <div className="overlayClient" style={{color: 'rgb(251 49 49)',height: "100%"}}>Unsold</div>;
            } else if (item.lot_status === "SOLD") {
              soldItem = <div className="overlayClient" style={{color: 'rgb(4 245 4)',height: "100%"}}>Sold</div>;
            } else if (item.lot_status === "RESERVE_NOT_MET") {
              soldItem = <div className="overlayClient" style={{color: 'rgb(251 49 49)',height: "100%",paddingTop: "50%"}}>Reserve Not Met</div>;
            } else if (item.lot_status === "PASS") {
              soldItem = <div className="overlayClient" style={{color: 'rgb(251 49 49)',height: "100%",paddingTop: "50%"}}>PASS</div>;
              
            } else {
              soldItem = "";
            }
            return (
            


              <ImageListItem key={i}>

                <img
                 className="imageHover"
                  style={{ width: 100, border: i === 0 ?  "2px solid gray":"1px solid gray"}}
                  srcSet={item.img_url === null ? NoImage : item.img_url}
                  src={item.img_url === null ? NoImage : item.img_url}
                  alt={item.title}
                  loading="lazy"
                />
                {soldItem}

                <ImageListItemBar
                  style={{ fontSize: 6, maxWidth: 100 }}
                  title={
                    <React.Fragment>
                      <Typography
                        style={{ fontSize: 10 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                        
                      >
                                   {/* {item.lot_number + ". " + item.title.replace(/(<([^>]+)>)/ig, '')}  */}
                        {item.lot_number + ". " + item.title} 
                      </Typography> 
                    </React.Fragment>
                  }
                  subtitle={
                    <React.Fragment>
                      <Typography
                        style={{ fontSize: 9, maxWidth: 100 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.artist}
                      </Typography>
                    </React.Fragment>
                  }
                  position="below"
                />
                
              </ImageListItem>
    
            
            );
          })
        ) : (
          <Box sx={{ margin: "auto" }}>
                     <Typography>Auction End</Typography>
            {/* <CircularProgress /> */}
          </Box>
        )}

      </ImageList>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: " translate(-50%, -50%)",
          msTransform: "translate(-50%, -50%)",

          border: "none",
          cursor: "pointer",
          borderRadius: 5,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            // height: 100,
            width: "97%",

            margin: "auto",
          }}
        >
             {count === 1 ? <div></div>:
          <div className="nextButton" style={{ borderRadius: 5 }}>
            <Button
              style={{ color: "white" }}
              onClick={() => handleClickPrevAuction()}
              startIcon={<ArrowBackIosIcon style={{ marginLeft: 15 }} />}
            ></Button>
          </div>}

          {dataNextAuction.length < 5 ? "":
          <div className="nextButton" style={{ borderRadius: 5 }}>
            <Button
              className="nextButton"
              style={{ color: "white" }}
              onClick={() => handleClickNextAuction()}
              startIcon={<ArrowForwardIosIcon style={{ marginLeft: 15 }} />}
            >
              {" "}
            </Button>
          </div>}
        </Box>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <Typography>Loading!..</Typography>
        </Backdrop>
      </div>
      {/*       
<Box style={{display: "flex",  alignItems: "center",
  justifyContent: "center",    height: 100,
  margin: "auto" }}>
  <Button  onClick={() => handleClickPrevAuction()} startIcon={<ArrowBackIosIcon />}>    </Button>  <Button  onClick={() => handleClickNextAuction()} startIcon={<ArrowForwardIosIcon />}>  </Button>  
</Box> */}
    </div>
  );
}
