import React from 'react';
import View from './view'
import "./login.css";
function index(props) {
    return (
        <div>
            <View/>
        </div>
    );
}

export default index;