
const Listcolor = {
    
    Appbar11: "#282121",
    Appbar: "#282121",
    headColor: "#2e2c2c",
    textWhite: "white",
    textBlack: "black",
}

export default Listcolor;

