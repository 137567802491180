import React from "react";
import { Helmet } from "react-helmet";
import Page from "src/components/Page";
import Home from "./Home";

// import MetaTags from 'react-meta-tags';
function index(props) {
  localStorage.removeItem("@storeAuctionLot");

  return (
    <Page title="Home">


      <Home />
    </Page>
  );
}

export default index;
