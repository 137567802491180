import axios from 'axios';
import axiosInstant from "../axiosInstant";

export async function sendEstimate(ArrayData) {

    const config = {
        headers: { 'content-type': 'multipart/form-data' }
    }

    // console.log('ArrayData: ', ArrayData)
    //http://157.230.43.47:3031/api/request/estimate
//https://api01.leon-gallery.com/api/  prod

    const response = await axiosInstant.post('/request/estimate', ArrayData, config)
        .then((res) => {
            console.log(JSON.stringify(res.data));
            return res.data.msg;
        })
        .catch((error) => {
            console.log(error.data.response);
            return error.data.response.msg;
        });
    return response;
}