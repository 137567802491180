import React from "react";
import Lot from "./sell";
function view(props) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const data = params.get("data");

  return (
    <div>
      {/* <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-1.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">

              <div className="inner-content clearfix">
                <div className="title">
                  <h1>{data}</h1>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Lot data={data} />
    </div>
  );
}

export default view;
