import React, { useEffect, useState, useCallback } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import Paper from "@mui/material/Paper";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
// import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from "@mui/icons-material/History";
import CircularProgress from "@mui/material/CircularProgress";
import { getUser } from "../../Model/LocalStorage";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { postUndo, hallBid } from "../../Model/DeclaratorModel";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import GridViewIcon from "@mui/icons-material/GridView";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import useMediaQuery from "@mui/material/useMediaQuery";

const TableUserOnline = ({ socketDeclarator, userOnline, LiveData }) => {
  const phoneMatch = useMediaQuery("(min-width:1200px)");
  const [uniqueArr, setUniqueArr] = useState([]);
  const [bidder, setBidder] = useState([]);
  const [userID, setUserId] = useState("");
  const [hammer, setHammer] = useState("");

  const handleHallBid = () => {
    try {
      //setLoading(true);
      async function postHallBid() {
        const bidData = {
          auction_id: LiveData.auction_details.auction_id,
          setId: LiveData.auction_details.id,
          lot_number: LiveData.lot.details[0].lot_number,
        };

        const results = await hallBid(bidData);
        // setLoading(false);
        if (results.success === true) {
          socketDeclarator.emit("send_Bid_amount", { status: results.success });
        } else if (results.success === false) {
          alert(`Error !: ${results.msg}`);
        } else {
          // alert(results.msg);
        }
      }
      postHallBid().catch((error) => {
        // setLoading(false);
        console.log(error);
      });
    } catch (error) {}
  };

  const handleClickUndo = (val) => {
    const dataArray = {
      auction_id: LiveData.auction_details.auction_id,
      setId: LiveData.auction_details.id,
      lot_number: LiveData.lot.details[0].lot_number,
      bid_history_id: val,
    };

    try {
      async function functionUndo() {
        const results = await postUndo(dataArray);
        //  console.log(":results declarator: ",results)
        if (results.success === true) {
          socketDeclarator.emit("send_status", {
            status: results.success,
            message: "undo",
          });
        } else {
          alert(results.msg);
        }
        // console.log("functionUndo: ", results);
      }
      functionUndo().catch(console.error);
    } catch (error) {}
  };

  useEffect(() => {
    // socketDeclarator.on("receive_bid_amount", (data) => {
    //   ListData()
    // })
    // socketDeclarator.on("disconnect", (userId) => {
    //   ///alert("another user in")
    //   setUserOnline(Object.values(userId.userId));
    // });

    try {
      setHammer(LiveData.lot.details[0].hp);
    } catch (error) {}
    async function getUserList() {
      const getuser = await getUser();
      setUserId(getuser.id);
    }
    getUserList().catch(console.error);

    setUniqueArr([...new Set(userOnline)]);
    setBidder(LiveData.bid_history === undefined ? [] : LiveData.bid_history);
    //setHammer(LiveData.lot.details[0].hp);
    // socketDeclarator.on("JointEvent", (userId) => {
    //   console.log("online JointEvent: ", userId.userId);
    //   setUserOnline(Object.values(userId.userId));
    //   });
    return () => {
      socketDeclarator.removeAllListeners();
    };
  }, [socketDeclarator, userOnline, LiveData]);

  return (
    <Box style={{ padding: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={phoneMatch === true ? 5 : 12}>
          <div
            style={{
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>
              {" "}
              Online
              <b
                style={{
                  backgroundColor: "#12bc12",
                  color: "white",
                  padding: " 5px 10px",
                  borderRadius: 5,
                  marginLeft: 30,
                }}
              >
                {uniqueArr.length}
              </b>
            </Typography>
            <Typography> </Typography>
          </div>

          <Box
            style={{
              display: "flex",
              background: "white",
              justifyContent: "space-between",
            }}
          >
            <List
              style={{
                width: "100%",
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: 285,
                flexDirection: "column-reverse",
                display: "-webkit-inline-flex",
                // "& ul": { padding: 0 },
              }}
            >
              {uniqueArr.length ? (
                uniqueArr.map((season, index) => {
                  return (
                    <ListItem
                      key={index}
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      <ListItemText
                        // gutterBottom
                        align="left"
                        primary={
                          <React.Fragment>
                            <Typography
                              style={{
                                fontSize: 14,
                              }}
                              variant="body2"
                              color="text.primary"
                            >
                              {season.mobile === undefined ? (
                                ""
                              ) : season.mobile === true ? (
                                <StayCurrentPortraitIcon fontSize="small" />
                              ) : (
                                <DesktopWindowsIcon fontSize="small" />
                              )}
                              {season.email}
                              {/* {season.platform} */}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Box sx={{ display: "flex", marginLeft: "45%", marginTop: 5 }}>
                  <CircularProgress />
                </Box>
              )}
            </List>
          </Box>
        </Grid>

        <Grid item xs={phoneMatch === true ? 7 : 12}>
          <div style={{ background: "#e1dcdc", height: "100%" }}>
            <div
              style={{
                padding: 5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography style={{ fontWeight: "bold", fontStyle: "italic" }}>
                <HistoryIcon /> Bid History :
              </Typography>
            </div>

            <Box
              style={{
                display: "flex",
                background: "white",
                justifyContent: "space-between",
              }}
            >
              <List
                style={{
                  width: "100%",
                  background: "#e1dcdc",
                  position: "relative",

                  // overflow: "auto",

                  flexDirection: "column-reverse",
                  // display: "-webkit-inline-flex",
                  // "& ul": { padding: 0 },
                }}
              >
                {bidder.length ? (
                  bidder.map((val, index) => {
                    return (
                      <ListItem
                        key={index}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      >
                        <ListItemText
                          // gutterBottom
                          align="left"
                          primary={
                            <React.Fragment>
                              <Typography
                                style={{
                                  fontSize: 12,
                                }}
                                variant="body2"
                                color="text.primary"
                              >
                                {val.email}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                        <Box style={{ width: 100 }}>
                          {/* <ListItemText
                          gutterBottom
                          align="left"
                          primary={
                            <React.Fragment>
                              <Typography
                                style={{
                                  fontSize: 12,
                               
                                }}
                                variant="body2"
                                color="text.primary"
                              >
                                {val.bidder_type}
                              </Typography>
                            </React.Fragment>
                          }
                        />  */}
                        </Box>
                        <ListItemText
                          align="right"
                          // gutterBottom
                          primary={
                            <React.Fragment>
                              {(userID === val.userId &&
                                val.hammer === hammer &&
                                val.bidder_type === "FLOOR BIDDER") ||
                                val.bidder_type === "TELEPHONE BIDDER" &&
                                val.win_status === "WIN" ? (
                                <Button
                                  fullWidth
                                  variant="contained"
                                  style={{
                                    background:
                                      val.bidder_type === "FLOOR BIDDER"
                                        ? "red"
                                        : "green",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    handleClickUndo(val.bid_history_id)
                                  }
                                  startIcon={
                                    val.bidder_type === "FLOOR BIDDER" ? (
                                      <GridViewIcon />
                                    ) : (
                                      <PhoneInTalkIcon />
                                    )
                                  }
                                  // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
                                >
                                  {/* {val.bidder_type === "FLOOR BIDDER" ? "Floor Bid": "Phone Bid"} */}
                                  {"Php " + val.hammer.toLocaleString()}
                                </Button>
                              ) : val.hammer === hammer &&
                                val.win_status === "WIN" ? (
                                <Typography
                                  style={{
                                    fontSize: 12,
                                  }}
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleHallBid(e)}
                                    startIcon={<AccountBalanceIcon />}
                                    // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
                                  >
                                    {"Php " + val.hammer.toLocaleString()}
                                  </Button>
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    fontSize: 12,
                                  }}
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {" "}
                                  {"Php " + val.hammer.toLocaleString()}
                                </Typography>
                              )}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  // <Box
                  //   sx={{ display: "flex", marginLeft: "45%",  }}
                  // >
                  //   <CircularProgress />
                  // </Box>
                  <ListItemText
                    gutterBottom
                    align="left"
                    primary={
                      <React.Fragment>
                        <Typography
                          style={{
                            fontSize: 15,
                            textAlign: "center",
                          }}
                          variant="body2"
                          color="text.primary"
                        >
                          No Records Found
                        </Typography>
                      </React.Fragment>
                    }
                  />
                )}
              </List>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableUserOnline;
