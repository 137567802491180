import React, { useCallback, useEffect, useState } from "react";
// import { banner } from "../../Model/home";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Intenational from "./../../images/resources/international.png";
import axiosInstant from "../../axiosInstant";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import zIndex from "@mui/material/styles/zIndex";
import { getExhibition } from "../../Model/Auction";
import { Typography } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function AuctionBanner(props) {
  const matches = useMediaQuery("(min-width:1000px)");
  // const [bannerImage, setBannerImage] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const FetchBanner = useCallback(async () => {
  //   setLoading(true);
  //   async function getBanner() {
  //     const results = await banner();

  //     setBannerImage(results);
  //     setLoading(false);
  //   }
  //   getBanner().catch(console.error);
  // }, []);

  const [details, setDetails] = useState([]);
  const [Tille, setTitle] = useState("");
  const [count, setCount] = useState(0);
  const [data1, setData] = useState([]);

  useEffect(() => {
    async function getAuctionOne() {
      const results = await getExhibition();
      setTitle(results[0].d_name);
      setData(results);
      // console.log('getExhibition ' ,results[0].id)
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${axiosInstant}/private-sales/lot?id=${results[0].id}&pgroup=EXHIBITION&search=&cat=`,
        headers: {},
      };

      axiosInstant
        .get(
          `/private-sales/lot?id=${results[0].id}&pgroup=EXHIBITION&search=&cat=`
        )
        .then((response) => {
          setDetails(response.data.msg);

          setCount(response.data.count);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getAuctionOne().catch(console.error);
  }, [getExhibition]);

  const GotoPage = () => {
    // localStorage.setItem("items", id);
    // localStorage.setItem("cover", imageCover);

    window.location.href = `/exhibition/${data1[0].id}/${data1[0].c_slug}/${data1[0].c_type}`;
  };

  return (
    <section class="main-slider style1">
      <div className="container">
        <div className="row" style={{ backgroundColor: "#000000" }}>
          {data1.length ? (
            <div
              className="col-xl-12"
              style={{
                // position: "relative",
                textAlign: "center",
              }}
            >
              {/* <img style={{ width: "100%" }} src={Intenational} /> */}
              <div
                style={{
                  // fontSize: matches === false ? 30 : 60,

                  paddingTop: 30,
                  height: 100,
                  // fontSize: matches === false ? 25 : 30,
                  textAlign: "start",
                  display: "flex",
                  justifyContent: "space-between",
                  // position: "absolute",
                }}
              >
                <Typography
                  style={{
                    color: "rgb(190 186 186)",
                    fontSize: matches === false ? 20 : 25,
                  }}
                >
                  {Tille}&nbsp;
                  {/* <i
                  style={{
                    color: "#f3de92",
                    fontSize: matches === false ? 25 : 35,
                  }}
                >
                  {count}
                </i>{" "}
                items currently available{" "} */}
                </Typography>
                <div
                  className="view-more-button pull-right"
                  style={{ width: 135 }}
                >
                  <button
                    onClick={() => GotoPage()}
                    className="btn-two"
                    to="/auctions"
                    style={{ color: "#ffff", padding: "10px 10px 10px" }}
                  >
                    View More
                  </button>
                </div>
              </div>
              <div
              // style={{ position: "absolute", width: '100%', top:' 50% ',backgroundColor: '#000000'}}
              >
                <Carousel
                  responsive={responsive}
                  additionalTransfrom={0}
                  arrows
                  autoPlay
                  autoPlaySpeed={1}
                  centerMode={false}
                  className=""
                  containerClass="container-with-dots"
                  customTransition="all 10s linear"
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={2}
                  swipeable
                  transitionDuration={1000}
                >
                  {/* <div style={{margin:5}}>  <img  src={'https://placehold.co/400x400?text=Sample test'} /> </div>
  <div style={{margin:5}}>  <img  src={'https://placehold.co/400x400?text=Hello World'} /> </div>
  <div style={{margin:5}}>  <img  src={'https://placehold.co/400x400?text=Painting'} /> </div>
  <div style={{margin:5}}>  <img  src={'https://placehold.co/400x400?text=Books'}/> </div> */}

                  {details.map((row, index) => {
                    return (
                      <div>
                        <div
                          style={
                            {
                              // maxHeight: 600,
                              // height: 300,
                              // height: 200,
                              //  height:400,
                            }
                          }
                        >
                          <div style={{ margin: 5 }}>
                            <div>
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    row.image.length
                                      ? row.image[0].img_url
                                      : "https://placehold.co/400x400/jpg"
                                  })`,
                                  backgroundColor: "#ffffff",
                                  width: "100%",
                                  height: 370,
                                  display: "inline-block",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center center",
                                  backgroundRepeat: "no-repeat",
                                  padding: 10,
                                }}
                              ></div>

                              {/* <img
                              src={
                                row.image.length
                                  ? row.image[0].img_url
                                  : "https://placehold.co/400x400/jpg"
                              }
                              alt={index}
                              style={{
                                width: "100%",
                                height: matches === true ? "25vw" : "90vw",
                              }}
                            /> */}
                            </div>
                            <div
                              style={{
                                paddingTop: 35,
                                lineHeight: "15px",
                                paddingBottom: 20,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: matches === true ? 18 : 20,
                                  color: "rgb(190 186 186)",
                                  lineHeight: 1,
                                }}
                              >
                                {row.data.artist}
                              </p>
                              <p
                                style={{
                                  fontSize: matches === true ? 18 : 20,
                                  color: "rgb(190 186 186)",
                                  lineHeight: 1,
                                }}
                              >
                                {row.data.title}
                              </p>
                              <p
                                style={{
                                  fontSize: matches === true ? 18 : 20,
                                  color: "rgb(190 186 186)",
                                  lineHeight: 1,
                                }}
                              >
                                {/* Php: {row.data.price === null ? 0:row.data.price.toLocaleString()} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          ) : (
            <div
              style={{
                color: "white",
                padding: 10,
                textAlign: "center",
                margin: "auto",
              }}
            >
              <h2>No available data</h2>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default AuctionBanner;
