import makeStyles from '@mui/styles/makeStyles';


const theme = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    column1: {
      padding: 2,
      height: "200px",
      color: "white",
    },
    column2: {
      padding: 2,
  
      color: 'black',
    },
    control: {
      padding: 2,
    },
    img: {
      alignItems: "center",
      height: "200px",
      paddingLeft: 1,
      paddingBottom: 1,
    },
    buttonGreen: {
      margin:5,
      backgroundImage:
        "linear-gradient(to right, #134E5E 0%, #71B280  51%, #134E5E  100%)",
    },
    buttonRed: {
      margin:5,
      backgroundImage:
        "linear-gradient(to right, #e52d27 0%, #b31217  51%, #e52d27  100%)",
    },
    reverButton: {
      margin:5,
      backgroundImage:
        "linear-gradient(to right, #000428 0%, #004e92  51%, #000428  100%)",
    },
  
    pauseButton: {
      margin:5,
      backgroundImage:
        "linear-gradient(to right, #f9c318 0%, #e7b208  50%, #826303  100%)",
    },
    buttonGray: {
      margin:'5px',
      backgroundImage:
        "linear-gradient(to right, #283048 0%, #859398  51%, #283048  100%)",
    },
    buttonOrange: {
      margin:'5px',
      backgroundImage:
        "linear-gradient(to right, #f37b48 0%, #f7890e  51%, #a63a07  100%)",
    },

    ButtonCustom: {
      // fontSize: 30,
      margin: 3,
      textAlign: "center",
      textTransform: "uppercase",
      transition: "0.5s",
      backgroundSize: " 200% auto",
      color: "white",
      borderRadius: 5,
      display: "block",
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
      "&:hover": {
        backgroundPosition: "right center",
        color: " #fff",
        textDecoration: "none",
      },
    },
    table: {
      minWidth: 650,
    },
    container: {
      maxHeight: 500,
    },
  }));
  
  export default theme;