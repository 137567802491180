import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
// import Logo from "./../images/logo.jpg";
import Logo from "./../images/resources/dddd.png";
import { getstoreAuctionLot } from "../Model/LocalStorage";
// import { Helmet, HelmetProvider } from "react-helmet-async";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const Layout = (props) => {
  const [loading, setLoading] = React.useState(true);


  const [isOnline, setOnline] = useState(true);

  // function CallReload(){
  
  //     if(!window.location.hash) {
  //         window.location = window.location + '#loaded';
  //         window.location.reload();
  //     }
  
  // }


  useEffect(() => {
  
    var counter = 0;

    var timer = setInterval(function () {

      if (navigator.onLine) {
        setLoading(false)
      
    
      }else{
        setLoading(true);

        if(counter === 2 ){
          alert('No Internet Connection!..')
          setLoading(true);
          clearInterval(timer);
        }
       
        // clearInterval(timer);
      }
  
     
      counter++;
  
  }, navigator.onLine === true ? 1000:9000);

    // To see changes in the network state, use addEventListener
    window.addEventListener("offline", (e) => {
      // clearInterval(timer);
     return setLoading(true);
    });
    
    window.addEventListener("online", (e) => {
      clearInterval(timer);
     return setLoading(false);
   
        
    
    });
   
  }, [navigator.onLine,loading]);


  // const [networkState, setNetworkState] = useState({
  //   isOnline: navigator.onLine,
  //   effectiveType: "",
  //   downlink: 0,
  //   rtt: 0,
  // });

  // useEffect(() => {
  //   const updateNetState = () => {
  //     const connection = navigator.connection;
  //     if (connection) {
  //       setNetworkState({
  //         isOnline: navigator.onLine,
  //         effectiveType: connection.effectiveType,
  //         downlink: connection.downlink,
  //         rtt: connection.rtt,
  //       });
  //     }
  //   };
  //   window.addEventListener("load", updateNetState);
  //   window.addEventListener("online", updateNetState);
  //   window.addEventListener("offline", updateNetState);

  //   return () => {
  //     window.removeEventListener("load", updateNetState);
  //     window.removeEventListener("online", updateNetState);
  //     window.removeEventListener("offline", updateNetState);
  //   };
  // }, []);

  // return networkState;

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          background: '#f3f1eb',
          zIndex: (theme) => theme.zIndex.drawer + 999,
          display: loading === true ? "block" : "none",
        }}
        open={loading}
        // onClick={handleClose}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 600,
          }}
        >
          <div
            style={{
              verticalAlign: " top",
              display: "inline-block",
              textAlign: "center",
              width: 120,
            }}
          >
            <img src={Logo} alt="Awesome Logo" title="Leon Gallery" />

            <CircularProgress size="30px"  sx={{color: "black"}} />
          </div>
        </div>
      </Backdrop>

      <div style={{ visibility: loading === true ? "hidden" : "visible" }}>
        <Header />
        <Outlet />
        <Footer />
      </div>
      
    </>
  );
};
export default Layout;
