import React, { useState, useEffect, useMemo,useRef } from "react";
import MessageIcon from "@mui/icons-material/Message";
import Box from "@mui/material/Box";
// import CardMedia from "@mui/material/CardMedia";
import { Button, Card, Divider, Typography } from "@mui/material";
// import TextField from "@mui/material/TextField";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";
import NoImage from "../../images/resources/noImage.jpg";
import theme from "./theme";
import { auctionsLive } from "../../Model/Auction";
import "./Center.css";


import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
// import { WebRTCPlayer } from "@eyevinn/webrtc-player";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled("div")({
  color: "red",
  animation: `${blink} 1s linear infinite`,
});




// document.addEventListener("visibilitychange", () => {
//   if (document.visibilityState === "visible") {
//     // Reinitialize or reload the Wowza WebRTC player
//     // console.log("Tab is active again. Reconnecting the player...");
//     reconnectPlayer();
//   }
// });

// async function reconnectPlayer() {
  
//   try {
//     const videos = document.querySelector("video");
//   const player = new WebRTCPlayer({
//     video: videos,
//     // timeoutThreshold: 3000,
//     type: "whep",
    
//   });

//   // console.log("player ",player)

//   await player.load(
//     new URL(
//       "https://customer-gc73aqvl9oq83lia.cloudflarestream.com/359346f60f669e61d8b1efb7353292da/webRTC/play"
//     )
//   );
//   player.unmute();

//   player.on("no-media", () => {
//     console.log("media timeout occured");
//   });
//   player.on("media-recovered", () => {
//     console.log("media recovered");
//   });

//   // Subscribe for RTC stats: `stats:${RTCStatsType}`
//   player.on("stats:inbound-rtp", (report) => {
//     if (report.kind === "video") {
//      // console.log("report");
      
//     }
  
//   });
//   } catch (error) {
    
//   }
  
  
// }


export default function Midlayout({
  details,
  mediadQuery,
  socket,
  titleAuction,
}) {
  const classes = theme();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [sold, setSold] = useState("");
  const [getData, setGetData] = useState([]);
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState("");
  const [announcement, setAnnouncement] = useState("");
  const [switchMode, setSwitch] = useState(true);

  const [more, setMore] = useState(false);
  const [lotnumber, setLotNumber] = useState(null);

  const handleMore = () => {
    setMore(!more);
  };

  function ListData() {
    async function getAuctionOne() {
      const results = await auctionsLive();

      if (results.success === true) {
        setSold("");
        setImage(results.lot.image_lot);
      } else {
      }
    }
    getAuctionOne().catch(console.error);
  }

  useEffect(() => {

    // reconnectPlayer();
    try {
      console.log("getData ", details.lot.details[0].lot_number);
      setImage(details.lot.image_lot);
      setStatus(details.lot.details[0].lot_status);

      if (details.lot.details[0].lot_status === "SOLD") {
        setSold(
          <div className="overlay11" style={{ color: "#59bb59" }}>
            <div className="statusCss">SOLD</div>
          </div>
        );
      } else if (details.lot.details[0].lot_status === "UNSOLD") {
        setSold(
          <div className="overlay11" style={{ color: "#ff4949" }}>
            <div className="statusUnsold">UNSOLD</div>
          </div>
        );
      } else if (details.lot.details[0].lot_status === "PASS") {
        setSold(
          <div className="overlay11" style={{ color: "#0bf8bf" }}>
            PASS
          </div>
        );
      } else if (details.lot.details[0].lot_status === "RESERVE_NOT_MET") {
        setSold(
          <div className="overlay11" style={{ color: "#fffd00" }}>
            <div
              className="statusUnsold"
              style={{ lineHeight: "normal", fontSize: 45 }}
            >
              RESERVE NOT MET
            </div>
          </div>
        );
      } else {
        setSold("");
      }
      setAnnouncement(details.announcement[0].announcement);
      setGetData(details.lot.details);
      setLotNumber(details.lot.details[0].lot_number);
    } catch (error) {
      setImage("");
      setGetData([]);
    }

    socket.on("receiveMessage", (data) => {
      // alert("receive_status 2")
      setAnnouncement(data.message);
    });

    socket.on("receive_status", (data) => {
      // alert(data.message)
      if (data.message === "SOLD") {
        setSold(
          <div className="overlay11" style={{ color: "#59bb59" }}>
            <div className="statusCss">SOLD</div>
          </div>
        );
      } else if (data.message === "UNSOLD") {
        setSold(
          <div className="overlay11" style={{ color: "#ff4949" }}>
            <div className="statusUnsold">UNSOLD</div>
          </div>
        );
        // } else if (data.message === "PASS") {
        //   setSold(
        //     <div className="overlay11" style={{ color: "#0bf8bf" }}>
        //         <div className="statusUnsold">PASS</div>
        //     </div>
        //   );
      } else if (data.message === "RESERVE_NOT_MET") {
        setSold(
          <div className="overlay11" style={{ color: "#fffd00" }}>
            <div
              className="statusUnsold"
              style={{ lineHeight: "normal", fontSize: 45 }}
            >
              RESERVE NOT MET
            </div>
          </div>
        );
      } else if (data.message === "NEXTSLOT" || data.message === "PASS") {
        setTimeout(() => {
          return ListData();
        }, 1000);
      } else {
        setSold("");
      }

      setStatus(data.message);
    });

    return () => socket.removeAllListeners();
  }, [details, socket]);

  const handleSwitch = () => {
    setSwitch(!switchMode)
  }
  return (
    <>
      {mediadQuery === false ? (
        <Typography
          gutterBottom
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          {titleAuction}
        </Typography>
      ) : (
        ""
      )}

      <div>
        <div
          className=""
          style={{
            border: "1px solid rgb(204, 204, 204)",
            padding: 5,
            maxWidth: mediadQuery === false ? "" : "100%",
            margin: mediadQuery === false ? "" : "auto",
          }}
        >
          <div
            className="container11"
            style={{
              // height: mediadQuery === false ? 300 : 400,
              // width: mediadQuery === false ? 300 : "",
              width:
                mediadQuery === false
                  ? image.slice(1).length
                    ? "50%"
                    : switchMode === true ? '80%': '100%'
                  : "50%",
              margin: mediadQuery === false ? "auto" : "",
              // border: "1px solid #ccc",
            }}
          >
            {status === "OPEN" ? (
              ""
            ) : (
              <BlinkedBox
                style={{
                  fontSize: 18,
                  display: status !== "" ? "block" : "none",
                  display:
                    status === "FAIR_WARNING" || status === "OPEN"
                      ? "block"
                      : "none",
                }}
              >
                <div className="boxStatusOne" style={{ position: "static" }}>
                  <div
                    className="boxStatus"
                    style={{
                      backgroundColor:
                        status === "FAIR_WARNING" ? "red" : "green",
                      color: "white",
                    }}
                  >
                    <Typography style={{ margin: "auto" }}>{status}</Typography>
                  </div>
                </div>
              </BlinkedBox>
            )}

      {mediadQuery === false ? 
      <div>

      <img
                style={{display: switchMode === true ? 'inline': 'none'}}
                    className="image11"
                    src={image.length ? image[0].img_url : NoImage}
                  />


      <div    className="image11" style={{display: switchMode === true ? 'none': 'inline',}}>

      <video style={{width: '100%', paddingTop:20}} loop controls autoPlay muted playsInline></video>


      </div> 
      </div> : <img
            // style={{display: switchMode === true ? 'inline': 'none'}}
              className="image11"
              src={image.length ? image[0].img_url : NoImage}
            />
        }

        

            {sold}
            {/* <div class="overlay_sold">Sold</div> */}
          </div>
          <div   style={{display: switchMode === true ? 'inline': 'none',}}>
          <div
            className=""
            style={{
              display: image.slice(1).length ? "block" : "none",
              maxWidth: mediadQuery === false ? "" : "100%",
              // margin: mediadQuery === false ? '' : "auto",
            }}
          >

            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                height: 100,
                overflow: "auto",
                // width: mediadQuery === false ? 300 : "",
                margin: mediadQuery === false ? "auto" : "",
              }}
            >
              {image.slice(1).length ? (
                image
                  .slice(1)
                  .map((val, i) => (
                    <img key={i} className="centerImageOne" src={val.img_url} />
                  ))
              ) : (
                <img className="centerImageOne" src={NoImage} />
              )}
            </div>
          </div>
          </div>

          {mediadQuery === false ? 
          <div style={{maxWidth: "100%",}} >
            <div style={{textAlign: 'end',}}>
        
   <Box style={{ width:'10%',display: switchMode === false ? 'none': 'inline',padding: 20, backgroundColor: '#333333',borderRadius: 5}}>
     {/* <IconButton aria-label="previous">
       {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
     </IconButton> */}
     <IconButton onClick={handleSwitch}  aria-label="play/pause">
       <PlayArrowIcon sx={{ height: 40, width: 40,color: '#ffff' }} />
     </IconButton>
     {/* <IconButton aria-label="next">
       {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
     </IconButton> */}
 
 </Box>

            
          <img
          onClick={handleSwitch}
             style={{width:'30%',display: switchMode === false ? 'inline': 'none'}}
              src={image.length ? image[0].img_url : NoImage}
            />

            </div></div> : ""} 

        </div>
      </div>

      {mediadQuery === false ? (
        <div>
          {/* <Divider style={{ padding: 5 }} /> */}
          {/* <div style={{ padding: 10, marginTop: 5 }}>
        <Typography style={{ fontSize: 14 }}>Pasko Sa Nayon</Typography>
        <Typography style={{ fontSize: 14 }}>Jose Blanco (1932 - 2008)</Typography>
        <div style={{display: 'flex',  justifyContent: "space-between"}}>
        <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
          Starting Price 
        </Typography>
        <Typography style={{ fontSize: 16,fontWeight: 'bold',color: 'red' }}>
          PHP 400,000
        </Typography>
        </div>
        </div> */}
        </div>
      ) : (
        <div style={{ padding: 10, marginTop: 5 }}>
          <div>
            <Typography
              gutterBottom
              variant="h6"
              style={{ fontWeight: "bold" }}
            >
              Lot {lotnumber}
            </Typography>
            <Divider />

            {getData.map((val, i) => {
              let data =
                val.signed_dated + val.medium + val.size + val.property;
              return (
                <div key={i}>
                  {/* {data.length > 1000} */}

                  <p style={{ marginTop: 10 }}>
                    <Typography
                      style={{ fontSize: 14, fontWeight: 'bold' }}
                      dangerouslySetInnerHTML={{ __html: val.artist }}
                    ></Typography>
                     <Typography
                      style={{ fontSize: 13,fontStyle: 'italic' }}
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Typography>
                  </p>
                 
                  <p>
                    <Typography
                      style={{ fontSize: 14 }}
                      dangerouslySetInnerHTML={{ __html: val.signed_dated }}
                    ></Typography>
               
                    <Typography
                      style={{ fontSize: 14 }}
                      dangerouslySetInnerHTML={{ __html: val.medium }}
                    ></Typography>
                 
                    <Typography
                      style={{ fontSize: 14 }}
                      dangerouslySetInnerHTML={{ __html: val.property }}
                    ></Typography>
                  </p>

                  {data.length > 1000 ? (
                    <div style={{ marginTop: 15 }}>
                      {" "}
                      <Button
                        variant="outlined"
                        onClick={handleMore}
                        style={{ textTransform: "capitalize" }}
                      >
                        {" "}
                        {more === false ? "Read more" : "Less"}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <Typography style={{ fontSize: 14 }}   dangerouslySetInnerHTML={{__html: val.medium}} ></Typography>
                <Typography style={{ fontSize: 14 }}  dangerouslySetInnerHTML={{__html: val.size}} ></Typography>
                <Typography style={{ fontSize: 14 }}>{val.property}</Typography> */}
                </div>
              );
            })}

            <Divider></Divider>
          </div>
          <Box style={{ marginTop: 15 }}>
            <Typography style={{ fontWeight: "bold" }}>
              <MessageIcon /> Announcement :
            </Typography>
            <Typography
              style={{ fontSize: 14, whiteSpace: "pre-line", marginTop: 10 }}
            >
              {announcement}
            </Typography>
          </Box>
        </div>
      )}
    </>
  );
}

//"https://leongallery.sgp1.digitaloceanspaces.com/v2/private-sale/PRIVATE_SALE/69.jpg" "https://wallpapers.com/images/featured/hd-a5u9zq0a0ymy2dug.jpg"
