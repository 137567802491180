
export const storeUser = async (value) => {
  // AsyncStorage.setItem("points", value.points);
// console.log('value.points: ',value.points)
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@storage_Key", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// getting data
export const getUser =  () => {
    let jsonValue =  localStorage.getItem("@storage_Key");
  try {

    const jasonParse = jsonValue != null ? JSON.parse(jsonValue) : null;
    return  jasonParse;
    
  } catch (error) {
    return null

  }
  
};

export const storeMetadetails = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@storeMetadetails", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getMetadetails =  () => {
  let jsonValue =  localStorage.getItem("@storeMetadetails");
try {
  const jasonParse = jsonValue != null ? JSON.parse(jsonValue) : null;
  return  jasonParse;
  
} catch (error) {
  return null
}

};

export const storeAuctionLot = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@storeAuctionLot", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};


// getting data
export const getstoreAuctionLot =  () => {
  let jsonValue =  localStorage.getItem("@storeAuctionLot");
try {
  const jasonParse = jsonValue != null ? JSON.parse(jsonValue) : null;
  return  jasonParse;
  
} catch (error) {
  return null
}

};


export const stroreAuctionList = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@stroreAuctionList", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};



export const getAuctionList =  () => {
  let jsonValue =  localStorage.getItem("@stroreAuctionList");
try {
  const jasonParse = jsonValue != null ? JSON.parse(jsonValue) : null;
  return  jasonParse;
  
} catch (error) {
  return null
}

};


export const storePrivateID = async (value) => {
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@storePrivateID", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};


export const getstorePrivateID =  () => {
  let jsonValue =  localStorage.getItem("@getstorePrivateID");
try {
  const jasonParse = jsonValue != null ? JSON.parse(jsonValue) : null;
  return  jasonParse;
  
} catch (error) {
  return null
}

};



export const alluserBid = async (value) => {
  // AsyncStorage.setItem("points", value.points);
// console.log('value.points: ',value.points)
  try {
    const jsonValue = JSON.stringify(value);
    // console.log('valuess: ',value);
    const response = await localStorage.setItem("@storage_Key", jsonValue);
    return response;
  } catch (error) {
    console.log(error);
  }
};

