import React, { useState, useRef, useCallback, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { lafuerzaAuction } from "../../../Model/Auction";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import lafuerza from "./../../../images/resources/lafuerza.png";
import lafuerza1 from "./../../../images/resources/lafu1.jpg";
import lafuerza2 from "./../../../images/resources/lafu2.jpg";
import lafuerza3 from "./../../../images/resources/lafu3.jpg";
const View = (props) => {
  const matches = useMediaQuery("(min-width:1000px)");
  const itemData = [
    {
      img: lafuerza1,
      title: "Breakfast",
      rows: 2,
      cols: 2,
    },
    {
      img: lafuerza2,
      title: "Coffee",
      author: "@arwinneil",

      cols: 2,
    },
    {
      img:lafuerza3,
      title: "Coffee",
      author: "@arwinneil",
    
      cols: 2,
    },
  ];

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const [auction, setAuction] = useState([]);
 
  
  const [loading,setLoading] = useState(false)


  const  FetchData =  (auction_tatus) => {

    try {
      setLoading(true)
      async function getAuctionOne() {
        const results = await lafuerzaAuction(auction_tatus);
        setAuction(results);
        setLoading(false)
      }
      getAuctionOne().catch(console.error);
    } catch (error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    FetchData()
  }, []);

  const [active,setActive] = useState(false)
  const [active1,setActive1] = useState(false)
  const handleUpcomming = (searchValue) => {
    if(searchValue=== 'UPCOMING'){
      setActive(true)
      setActive1(false)
    }else{
      setActive(false)
      setActive1(true)
    }

    return FetchData(searchValue)
  };


  const GotoPage = (id, slug, type, imageCover) => {
    localStorage.setItem("items", id);
    localStorage.setItem("cover", imageCover);

    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${slug}/${type}`;
    } else {
      window.location.href = `/auction/lot/${id}/${slug}/${type}`;
      // window.location.href = `/auction/lot/${slug}/${type}`;
    }
  };


  return (
    <div className="boxed_wrapper">
      <section
        className="breadcrumb-area"
        style={{padding: "185px 0 25px"}}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
            <img style={{ width: "100%" }} src={lafuerza} />
            {/* <div className="inner-content clearfix">
                <div className="title">
                <h1>{props.param}</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 50 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                   lafuerza
                    </li>
                  </ul>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>

      <section class="events-single-area" style={{    padding: "50px 0 0"}}>
        <div class="container">
          {/* <div class="row">
            <div class="col-xl-12">
                <div class="events-single-image-box">
                    <img src="images/events/event_single_img_1.jpg" alt="Awesome Image"/>    
                </div>   
            </div>
        </div> */}
          <div class="row">
            <div class="col-xl-8">
              <div class="events-single-text-box">
                <h2>Leon Gallery Lafuerza</h2>
                <p>
                LeonExchange serves as your digital portal to discover your next treasure. Conducted quarterly, it acts as a curated hub for vintage, high-quality, and previously cherished items sourced from distinguished collections and esteemed estates. Whether you're a collector of art, jewelry, furniture, decor, or more, each auction promises an opportunity to uncover your next beloved piece.
                </p>
          
              </div>
            </div>
            <div class="col-xl-4">
              <div class="events-single-info-box">
                <ul class="events-info">
                  <li>
                    <p>Type :</p>
                    <span>Gallery Talk</span>
                  </li>
                  <li>
                    <p>Hours :</p>
                    <span>Monday–Saturday 9:00 AM - 6:00 PM</span>
                  </li>
                  <li>
                    <p>Venue :</p>
                    <span>
                    León Gallery, W14 La Fuerza Plaza, 2241 Chino Roces Ave, Makati
                    </span>
                  </li>
                </ul>
                <div class="social-links">
                  <p>Shear :</p>
                  <ul class="sociallinks-style-two">
                    <li>
                      <a href="#">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ExploreLeonGallery/> */}

      <section class="gallery-area">
        <div class="layer-outer"></div>
        <div className="container">
          <div class="visit-day-select">
            <h1>Online Auction</h1>

            <div class="sec-title text-center" style={{ paddingTop: 20 }}>
              <ul class="tab-btns tab-buttons text-center clearfix">
              <li
                
                data-tab="#today"
                className={`tab-btn ${active === true ? 'active-btn': ''}`}
                onClick={() => handleUpcomming("UPCOMING")}
              >
                <span>Upcoming</span>
              </li>
              <li
                data-tab="#tomorrow"
                className={`tab-btn ${active1 === true ? 'active-btn': ''}`}
                onClick={() => handleUpcomming("PREVIOUS")}
              >
                <span>Past</span>
              </li>
                {/* <li data-tab="#select-date" class="tab-btn active-btn"><span>Select Date</span></li> */}
              </ul>
            </div>
          </div>

          {loading== true ? <Box sx={{ textAlign: "center" ,paddingBottom:10,paddingTop:10,position: 'relative'}}>
              {/* <span style={{position: 'absolute',fontSize:12}}>Loading</span> */}
                 <CircularProgress size="3rem"/> 
          </Box> : 
          <div class="row items-container clearfix">
            {auction ?
            auction.map((val) => {
                  var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var startDate = new Date(val.date_start);
                  var endDate = new Date(val.date_end);
                  return (
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 masonry-item all objects pic">
                      <div class="single-art-box-style2 marbtm50">
                        <div class="img-holder"  onClick={() =>
                            GotoPage(
                              val.id,
                              val.c_slug,
                              val.c_type,
                              val.cover
                            )
                          }>
                          <img src={val.cover === null ? "https://placehold.co/500x708?text=NO%20IMAGE%20AVAILABLE":val.cover} alt="Awesome Image" />
                          <div class="overlay-content">
                            <div class="zoom-button">
                              <a
                                class="lightbox-image"
                                data-fancybox="gallery"
                                href={val.cover}
                              >
                                <i
                                  class="fa fa-search-plus"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="text-holder">
                          <h3 >
                          <a style={{cursor: 'pointer'}}  onClick={() =>
                            GotoPage(
                              val.id,
                              val.c_slug,
                              val.c_type,
                              val.cover
                            )
                          }>{val.d_name}</a>
                        </h3>
                          <span>
                            {startDate.toLocaleDateString("en-US", options)} To{" "}
                          </span>
                          <span>
                            {" "}
                            {endDate.toLocaleDateString("en-US", options)}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }): <div style={{textAlign: 'center',margin: 'auto',padding: 10}}> No Record Found </div>}
                </div>}

            <ImageList
              sx={{ width:' 100%' ,paddingBottom:10}}
              variant="quilted"
              cols={4}
              rowHeight={matches === true ? 300: 120}
            >
              {itemData.map((item) => (
                <ImageListItem
              
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img
          
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>

        </div>
      </section>
    </div>
  );
};

export default View;
