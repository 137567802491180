
import { window } from 'globalthis/implementation';
import React from 'react';
import Page from 'src/components/Page';
import View from "./view"
import { useParams } from "react-router-dom";
const  Sindex = (props) => {
  
    const { group } = useParams();
    
    return (
        <Page title={group === "EXHIBITION" ? 'Exhibition':'Private Sale'}>
            <View />
        </Page>
    );
}

export default Sindex;