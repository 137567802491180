import React from "react";
import Page from "src/components/Page";
import View from "./view";

function index(props) {
  localStorage.removeItem("@storeAuctionLot");
  const search = window.location.search;
  const params = new URLSearchParams(search); 
  const data = params.get('data');
  return (
    <Page title="Galleries">
     <View param={data}/>
    </Page>
  );
}

export default index;
