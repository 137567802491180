import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Card, CardContent, MenuItem, TextField } from "@mui/material";
import Page from "src/components/Page";
import useMediaQuery from "@mui/material/useMediaQuery";
import { sendEstimate } from "../Model/Estimate"
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from '@mui/material/Alert';

const steps = ["Contact Details", "Artwork Details", "Upload Photo"];

export default function HorizontalLinearStepper() {
  localStorage.removeItem("@storeAuctionLot");
  const matches = useMediaQuery("(min-width:1000px)");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(null);

  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);

    }
  if(activeStep === 0){
    if(fullname === ""  ){
      setFullname(null)
     }else if(email === ""){
      setEmail(null)
     }else if(phoneNumber === ""){
      setPhonumber(null)
     }else if(category === ""){
      setCategory(null)
     }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
     }

  }else if(activeStep === 1){
    if(artist === ""  ){
      setArtist(null)
     }else if(title === ""){
      setTitle(null)
    //  }else if(year === ""){
    //   setYear(null)
    //  }else if(medium === ""){
    //   setmedium(null)
    // }else if(details === ""){
    //   setDetails(null)
     }else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
     }
  }else{

  }
    

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [fullname, setFullname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneNumber, setPhonumber] = React.useState('');
  const [category, setCategory] = React.useState('');
  
  const [artist, setArtist] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [year, setYear] = React.useState('');
  const [medium, setmedium] = React.useState('');
  const [details, setDetails] = React.useState('');
  const [frontImage, setFrontImage] = React.useState(null);
  const [backImage, setBackImage] = React.useState(null);

  const submitValue = (e) => {
    e.preventDefault();
    setLoading(true);
    let dataForm = new FormData();
   
    if( frontImage.size >  2000000  ){
      alert("Front Image Failed to upload. The image maximum size is 2MB.");
      setLoading(false);
    }else if(backImage.size >  2000000){
      alert("Back Image Failed to upload. The image maximum size is 2MB.");
      setLoading(false);
    }else{

      dataForm.append('fullname', fullname);
      dataForm.append('email', email);
      dataForm.append('phoneNumber', phoneNumber);
      dataForm.append('artist', artist);
      dataForm.append('title', title);
      dataForm.append('year', year);
      dataForm.append('medium', medium);
      dataForm.append('details', details);
      dataForm.append('frontImage', frontImage);
      dataForm.append('backImage', backImage);
      dataForm.append('category', category);

    try {
      async function fetchData() {
        const results = await sendEstimate(dataForm);
        setLoading(false);
        setMessage(results)
        setFullname('')
        setEmail('')
        setPhonumber('')
        setArtist('')
        setTitle('')
        setYear('')
        setmedium('')
        setDetails('')
        setFrontImage('')
        setBackImage('')
        setCategory('')

      }
      fetchData().catch((error) => {
        setLoading(false);
        setMessage(error)
    });
      

    } catch (error) {

      console.log(error)
    }
    }


}



  return (
    <Page title="  Request for Estimate">
      <div className="container" style={{ paddingTop: 30 }}>
        <nav style={{ paddingBottom: 30 }}>
          <ol className="breadcrumb" style={{ backgroundColor: "#fff" }}>
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a style={{ color: "#000000" }} href="#">
                Request for Estimate
              </a>
            </li>
          </ol>
        </nav>

        <section class="contact-form-area">
          <div class="auto-container">
            <div class="sec-title text-center">
              <div class="big-title">
                <h1>Request for estimate</h1>
              </div>
              <p>Tell us about your item</p>
              <p> All fields are required unless labeled as *</p>
              <div class="alert alert-success" style={{ display: "none" }}>
                Your request was successfully sent. We will contact you if we
                have queries about your request or piece.
              </div>
            </div>
          </div>

          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption"></Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {activeStep === 0 ? (
                      <div class="row">
                        <div class="col-xl-12" >
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Full Name"
                            error={fullname === null ? true: false}
                            required
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            value={fullname}
                            onChange={e => setFullname(e.target.value)}
                          />
                        </div>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Email"
                            error={email === null ? true: false}
                            required
                            id="fullWidth"
                            type="Email"
                            margin="normal"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Phone Number"
                            required
                            error={phoneNumber === null ? true: false}
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            value={phoneNumber}
                            onChange={e => setPhonumber(e.target.value)}
                          />
                        </div>

                        <div class="col-xl-12">
                        <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            id="outlined-select-currency"
                            select
                            error={category === null ? true: false}
                            margin="normal"
                            value={category}
                            label="Select Category"
                            onChange={e => setCategory(e.target.value)}
                            variant="outlined"
                          >
                          <MenuItem  key={0} value="" >Select Category</MenuItem> 
                           <MenuItem key={1} value="Painting" >Painting</MenuItem>
                           <MenuItem key={2} value="Sculpture" >Sculpture</MenuItem>
                           <MenuItem key={3} value="Furniture" >Furniture</MenuItem>
                           <MenuItem key={4} value="Jewelry" >Jewelry</MenuItem>
                           <MenuItem key={5} value="Objet D arts" >Objet D arts</MenuItem>
                           <MenuItem key={6} value="Books" >Books</MenuItem>
                          </TextField>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeStep === 1 ? (
                      <div class="row">
                        {category === "Jewelry" ? "":
                        <>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Artist"
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            error={artist === null ? true: false}
                            value={artist}
                            onChange={e => setArtist(e.target.value)}
                          />
                        </div>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Title"
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            error={title === null ? true: false}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                          />
                        </div>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Year"
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            error={year === null ? true: false}
                            value={year}
                            onChange={e => setYear(e.target.value)}
                          />

                        </div>

                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Medium"
                            type="text"
                            id="fullWidth"
                            margin="normal"
                            error={medium === null ? true: false}
                            value={medium}
                            onChange={e => setmedium(e.target.value)}
                          />
                        </div>
                        </>}

                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Other Details"
                            id="fullWidth"
                            type="text"
                            margin="normal"
                            multiline
                            rows={4}
                            // error={details === null ? true: false}
                            value={details}
                            onChange={e => setDetails(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {activeStep === 2 ? (
                      <div class="row">
                        <div class="col-xl-12" >
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Photo Front "
                            id="fullWidth"
                            required
                            type="file"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="normal"
                            inputProps={{ accept: "image/jpeg", multiple: true }}
                            onChange={e => setFrontImage(e.target.files[0])}
                          />
                        </div>
                        <div class="col-xl-12">
                          <TextField
                            style={{ width: matches === false ? 362 : 500 }}
                            label="Photo Back "
                            id="fullWidth"
                            required 
                            type="file"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="normal"
                            inputProps={{ accept: "image/jpeg", multiple: true }}
                            onChange={e => setBackImage(e.target.files[0])}
                          />


                        </div>
                        <div class="col">
                          <div class="" style={{ justifyContent: "center", display: "flex" }}>
                            <Card style={{ height: 200, width: 200, marginRight: 20 }}>
                              <CardContent>
                                <img
                                  alt="Front"
                                  src={frontImage ? URL.createObjectURL(frontImage) : "https://placehold.co/200x200?text=Front%20Image"}
                                // style={{ width: 500, height: "100px" }}
                                />
                              </CardContent>
                            </Card>

                            <Card style={{ height: 200, width: 200 }}>
                              <CardContent>
                                <img
                                  alt="Back"
                                  src={ backImage ? URL.createObjectURL(backImage) : "https://placehold.co/200x200?text=Verso%20Image"}
                                // style={{ width: 500, height: "100px" }}
                                />
                              </CardContent>
                            </Card>
                          </div>
                        </div>

                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>

                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )} */}
                  <Button onClick={activeStep === steps.length - 1 ? submitValue : handleNext}>
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
         {message === null ? "":<Alert severity="success">{message}</Alert>}
        </section>

        <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      </div>
     

    </Page>
  );
}
