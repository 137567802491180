
import { window } from 'globalthis/implementation';
import React from 'react';
import View from "./view"
import Page from "src/components/Page";
function index(props) {
    localStorage.removeItem("@storeAuctionLot");
    return (
        <Page title="Exhibition">
            <View/>
        </Page>
    );
}

export default index;