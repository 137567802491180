import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import axios from 'axios';
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { countrylist } from "../../Model/country";
import { CityData } from "../../Model/city";
import { AddNewAccount } from "../../Model/User";

import Autocomplete from "@mui/material/Autocomplete";
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const [loading, setLoading] = React.useState(false);
  const [countryData, setCountryData] = React.useState([]);
  const [cityList, setCityData] = React.useState([]);
  const [codeNumber, setCodeNumber] = React.useState("");
  const [countryPick, setCountryPick] = React.useState("");
  const [cityPick, setCityPick] = React.useState("");
  const [dataRow, setDataRow] = React.useState();

  const [form, setValues] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    address: "",
    second_address_line: "",
    postal_code: "",
    // city: "",
    // country: "",
    email: "",
    password: "",
    phone: "",
    nationality: "",
  });


  const [nameError, setNameError] = useState(false);
  const [lastError, setLastNamerror] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [errorMaximum, setErrorMaximum] = useState("");
 
  const [postalError, setPostalError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nationalError, setNationalError] = useState(false);



  const [cardType, setCardType] = React.useState("");
  const [card_number, setCardNumber] = React.useState("");
  const [exp_month, setExpMonth] = React.useState("");
  const [exp_year, setExpYear] = React.useState("");
  const [cvc, setCvc] = React.useState("");
  
  const [customer_id, setCustmerID] = React.useState(null);
  const [payment_id, setPaymentID] = React.useState(null)

  const handleCreateAccountSubmit = (e) => {
    e.preventDefault();


    // comment need muna send agad ung customer details pag nagerror maglagy na ng message or notification pero pagnagsave 
    // procedd sa payment method for card accunt verification then pag ok both save sa api
    try {

      setAddressError(form.address === "" ? true:false);
      setEmailError(form.email === "" ? true:false);
      setPhoneError(form.phone === "" ? true:false);
      setPostalError(form.postal_code === "" ? true:false);
      setCountryError(countryPick === "" ? true:false);
      setCityError(cityPick === "" ? true:false);
      setNationalError(form.nationality === "" ? true:false);
      setNameError(form.firstname === "" ? true:false);
      setLastNamerror(form.lastname === "" ? true:false);
      setDobError(form.dob === "" ? true:false);
      setLoading(true)

       
    if(form.address === ""  || form.email === "" || form.phone === "" || form.postal_code === "" || countryPick === "" || cityPick === ""  || form.nationality === "" ||  form.firstname === "" || form.lastname === "" || form.dob === "" ){
        alert("failed is required")
    }else{

              const option1 = {
                method: 'POST',
                url: 'https://api.paymongo.com/v1/customers',
                headers: {
                  accept: 'application/json',
                  'content-type': 'application/json',
                  authorization: 'Basic c2tfdGVzdF9YdzdmaEs4NEpmTVdqUHA2cmpFaUxKaXo6'
                },
                data: {
                  data: {
                    attributes: {
                      first_name:form.firstname,
                      last_name: form.lastname,
                      phone: `+63${form.phone}`,
                      email: form.email,
                      default_device: 'email'
                    }
                  }
                }
              };


              const option2= {
                method: 'POST',
                url: 'https://api.paymongo.com/v1/payment_methods',
                headers: {
                  accept: 'application/json',
                  'Content-Type': 'application/json',
                  authorization: 'Basic c2tfdGVzdF9YdzdmaEs4NEpmTVdqUHA2cmpFaUxKaXo6'
                },
                data: {
                  data: {
                    attributes: {
                      details: {
                        card_number: card_number,
                        exp_month: parseInt(exp_month),
                        exp_year: parseInt(exp_year),
                        cvc: cvc,
                        bank_code: cardType
                      },
                      billing: {
                        address: {
                          line1:form.address,
                          line2: form.second_address_line,
                          city: cityPick,
                          state: cityPick,
                          postal_code: form.postal_code,
                          country: "PH"
                        },
                    
                        name: form.firstname+ ' '+ form.lastname,
                        email:form.email,
                        phone: `+63${form.phone}`,
                      },
                      type: 'card' //gcash or card
                    }
                  }
                }
              };


              const requestOne = axios.request(option1);
              const requestTwo = axios.request(option2);

        axios.all([requestOne, requestTwo])
        .then(axios.spread((responseOne, responseTwo) => {
                console.log('Data from first request:', responseOne.data.data.id);
                console.log('Data from second request:', responseTwo.data.data.id);

                const InfoData = {
                  firstname: form.firstname,
                  lastname: form.lastname,
                  dob: form.dob,
                  address: form.address,
                  second_address_line: form.second_address_line,
                  postal_code: form.postal_code,
                  city: cityPick,
                  country: countryPick,
                  email: form.email,
                  password: form.password,
                  phone:`09${form.phone}`,
                  nationality: form.nationality,
                  customer_id: responseOne.data.data.id,
                  payment_id:responseTwo.data.data.id
                };
                setCustmerID(responseOne.data.data.id)
                setPaymentID(responseTwo.data.data.id)

                async function PostData() {
                  const results = await AddNewAccount(InfoData);
                  // console.log('results: ',results)

                  if(results.sucess === false ){
                    if(results.msg === "Email already Exist! please verify your account"){
                      console.log(results.data)
                      console.log(results.data.id)
                      console.log('else 1: ', results.msg)
                      alert("messge 1", results.msg);
                      setLoading(false)
                        setDataRow(results.data)
                    }else{
                      setLoading(false)
                      console.log('else 2: ', results.msg)
                      alert("messge 2: ", results.msg);
                    }
                  }else{
                    console.log('else 3: ', results.msg)
                    alert(`messge 3 `+ results.msg);
                    setLoading(false)
                  }
                }
                PostData().catch("console.error", console.error);
        }))
        .catch(errors => {
          setLoading(false)
          console.error('Error fetching data:', errors);
          // alert(errors.response.data.errors[0].detail);
                      if(errors.response.data.errors[0].code ===  "resource_exists"){
                        // console.log("customer_id.data.id ",customer_id)
                        //   const options = {
                        //     method: 'DELETE',
                        //     url: `https://api.paymongo.com/v1/customers/${customer_id}/payment_methods/${payment_id}`,
                        //     headers: {
                        //       accept: 'application/json',
                        //       authorization: 'Basic c2tfdGVzdF9YdzdmaEs4NEpmTVdqUHA2cmpFaUxKaXo6'
                        //     }
                        //   };
                          
                        //   axios
                        //     .request(options)
                        //     .then(res => console.log(res.data))
                        //     .catch(err => console.error(err));

                          alert(errors.response.data.errors[0].code.detail)
                    } else if(errors.response.data.errors[0].code ===  "parameter_invalid") {

    
                          const options = {
                            method: 'DELETE',
                            url: `https://api.paymongo.com/v1/customers/${customer_id}`,
                            headers: {
                              accept: 'application/json',
                              authorization: 'Basic c2tfdGVzdF9YdzdmaEs4NEpmTVdqUHA2cmpFaUxKaXo6'
                            }
                          };

                          axios
                            .request(options)
                            .then(res => console.log(res.data))
                            .catch(err => console.error(err));

                            alert(errors.response.data.errors[0].detail)
                     
                      }else{
                        console.error('Error Here!!');
                      }
                        console.error('Error fetching data:', errors.response.data.errors[0].detail);
                      }); 

      }


          // async function PostData() {
          //   const results = await AddNewAccount(InfoData);
          //   // console.log('results: ',results)
          //   if(results.sucess === false ){
          //      if(results.msg === "Email already Exist! please verify your account"){
          //        console.log(results.data)
          //        console.log(results.data.id)
          //        alert("messge", results.msg);
          //       setDataRow(results.data)
          //      }else{
          //       console.log('else: ', results.data)
          //       alert("messge: ", results.msg);
          //      }
          //   }else{
          //     console.log('else: ', results)
          //     // alert("messge", results);
          //   }
          // }
          // PostData().catch("console.error", console.error);


    } catch (error) {}
  };

  useEffect(() => {
    setCountryData(countrylist.countries);
  }, [countrylist]);



  const handleChange = (event) => {
    event.preventDefault();


    setValues({
      ...form,
      [event.target.name]: event.target.value,
    });


     if (event.target.name === "phone") {
     
        if (10 === event.target.value.length) {
          setPhoneError(false);
          // setDisAble(true)
          setErrorMaximum("");
        } else {
          setPhoneError(true);
          setErrorMaximum("Sample Format Number +63 9123456789");
          // setDisAble(false)
          //setErrorMaximum("Max Digit 11")
        }
    }else{
    }



  };

  // function getCountryByCode(code) {
  //   return data.filter(
  //       function(data){ return data.code == code }
  //   );
  // }

  const handlepick = (e, v) => {
    e.preventDefault();
    try {
      // console.log(CityData[v.name])
      setCountryPick(v.name);
      if (CityData[v.name] === undefined) {
      } else {
        console.log(CityData[v.name]);
        setCityData(CityData[v.name]);
        setCodeNumber(v.code);
      }
    } catch (error) {
      // console.log("error");
    }
    // setEventId(v.id=);
  };

  const handlepickOne = (e, v) => {
    e.preventDefault();
    try {
      setCityPick(v);
      // console.log(v);
    } catch (error) {
      // console.log("error");
    }
    // setEventId(v.id=);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Avatar sx={{ m: 2, bgcolor: "secondary.main" }}>
            <PersonPinIcon />
          </Avatar> */}
          <p>
          TEST  Sign up to receive the Leon Gallery's weekly art news update and
            your personalised weekly Leon Gallery's auction update. You can
            unsubscribe at any time by clicking on the unsubscribe links in our
            emails.
          </p>
    
      
          <Card>
       <CardHeader title="Personal Information" />
          
          <CardContent>

            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  error={false}
                  helperText="Email is required"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={emailError}
                  helperText={emailError ? "Please enter your Email" : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="firstname"
                  label="Firstname"
                  type="text"
                  id="text"
                  autoComplete="current-firstname"
                  error={nameError}
                  helperText={nameError ? "Please enter your first name" : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="lastname"
                  label="Last Name"
                  type="text"
                  id="password"
                  autoComplete="current-lastname"
                  error={lastError}
                  helperText={lastError ? "Please enter your last name" : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // defaultValue={form.dob}
                  name="dob"
                  label="Birthday"
                  type="date"
                  id="dob"
                  autoComplete="current-dob"
                  error={dobError}
                  helperText={dobError ? "Please enter your Birth of date" : ""}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={countryData}
                  getOptionLabel={(option) =>
                    option.name === null ? "" : option.name
                  }
                  onChange={(e, v) => {
                    handlepick(e, v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      label="Country"
                      error={countryError}
                      helperText={countryError ? "Please enter your Country" : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth={true}
                  options={cityList}
                  // getOptionLabel={cityData}
                  onChange={(e, v) => {
                    handlepickOne(e, v);
                  }}
                  // style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      margin="dense"
                      required={true}
                      variant="outlined"
                      error={cityError}
                  helperText={cityError ? "Please enter postal City" : ""}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="postal_code"
                  label="Postal Code"
                  placeholder="Postal Code"
                  type="text"
                  // style={{ padding: "15px 20px" }}
                  id="number"
                  autoComplete="current-phone"
                  error={postalError}
                  helperText={postalError ? "Please enter postal code" : ""}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="city"
                  label="City"
                  type="text"
                  id="city"
                  autoComplete="current-city"
                />
              </Grid> */}
              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="phone"
                  placeholder="Phone Number"
                  label="Phone Number"
                  type="text"
                  // style={{ padding: "15px 20px" }}
                  id="number"
                   inputProps={{ minlength: "9", maxlength: "10" }}
                  autoComplete="current-phone"
                  error={phoneError}
                  helperText={errorMaximum}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="nationality"
                  label="Nationality"
                  type="text"
                  id="nationality"
                  autoComplete="current-nationality"
                  error={nationalError}
                      helperText={nationalError ? "Please enter your Nationality" : ""}
                />
              </Grid>
              </Grid>
              </CardContent>
       </Card>

       <Card>
       <CardHeader title="Card Information" />
          
          <CardContent>


      
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>

                <TextField
                  onChange={(e) =>setCardNumber(e.target.value)}
                  margin="dense"
                  required
                  fullWidth
                disabled={form.email === "" || form.phone === "" || form.firstname === "" ||  form.lastname === ""  ? true:false }
                  name="nationality"
                  label="Card Number"
                  type="text"
                  placeholder="1111-2222-3333-4444"
                  id="nationality"
                  autoComplete="current-nationality"
                  error={nationalError}
                      helperText={nationalError ? "Please enter your Nationality" : ""}
                />
              </Grid>
              <Grid item md={6} xs={12}>  
              <TextField
              fullWidth={true}
              margin="dense"
              select
              SelectProps={{
                native: true,
              }}
              label="Select Bank"

              onChange={(e) => setCardType(e.target.value)}
              variant="outlined"
            >
        
              <option value={"bdo"}>BDO</option>
              <option value={"test_bank_one"}>BPI</option>
              {/* <option value={"ubp"}>UBP</option> */}
              <option value={"landbank"}>LANDBANK</option>
              <option value={"metrobank"}>METROBANK</option>
              <option value={"Unionbank"}>Unionbank</option>
            </TextField>

                 {/* <label for="fname" style={{marginBottom: "-0.5rem"}}>Accepted Cards</label>
              <div className="icon-container" >
              <i class="fa fa-cc-visa"  style={{color:"navy",marginRight:10,fontSize: 40}}></i>
              <i class="fa fa-cc-amex" style={{color:"navy",marginRight:10,fontSize: 40}}></i>
              <i class="fa fa-cc-mastercard"style={{color: "navy",marginRight:10,fontSize: 40}}></i>
              <i class="fa fa-cc-discover" style={{color:"navy",marginRight:10,fontSize: 40}}></i>
              </div>  */}
      </Grid>
              <Grid item  xs={12}>  
              <Grid  xs={4} md={12}>
              <TextField
                  onChange={(e) => setExpMonth(e.target.value)}
                  margin="dense"
                  required
                  
                  name="exp_month"
                  label="Exp Month"
                  type="text"
                  placeholder="Exp Month"
                  id="nationality"
                  autoComplete="current-nationality"
                  // error={nationalError}
                  //     helperText={nationalError ? "Please enter your Nationality" : ""}
                />  <TextField
                onChange={(e) => setExpYear(e.target.value)}
                margin="dense"
                required
                  style={{marginRight:5}}
                name="exp_year"
                label="Exp Year"
                type="text"
                placeholder="Exp Year"
                id="nationality"
                autoComplete="current-nationality"
                // error={nationalError}
                //     helperText={nationalError ? "Please enter your Nationality" : ""}
              /> 
               <TextField
             onChange={(e) => setCvc(e.target.value)}
                margin="dense"
                required
                
                name="cvc"
                label="CVC"
                type="text"
                placeholder="cvc"
                id="nationality"
                autoComplete="current-nationality"
                // error={nationalError}
                //     helperText={nationalError ? "Please enter your Nationality" : ""}
              /> </Grid>
                 
            
              </Grid>

</Grid>
</CardContent>
       </Card>


       <Card>
       <CardHeader title="Billing Information" />
          
          <CardContent>

<Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  multiline
                  rows={2}
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="address"
                  label="Address"
                  type="text"
                  id="address"
                  autoComplete="current-address"
                  error={addressError}
                  helperText={addressError ? "Please enter your address" : ""}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  multiline
                  rows={2}
                  onChange={handleChange}
                  margin="dense"
                  required
                  fullWidth
                  name="second_address_line"
                  label="Second Address"
                  type="text"
                  id="second_address_line"
                  autoComplete="current-second_address_line"
                />
              </Grid>
              <Grid item md={12} xs={12}>

              <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={
                <Typography sx={{}}>
                  I agree to Leon Gallery Auction's  <b>Terms of Use</b> and <b>Privacy Notice</b>, and confirm that I am 18 or over.
                </Typography>
              }
             
            />
    </Grid>
            </Grid>


            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleCreateAccountSubmit}
              sx={{ mt: 3, mb: 2, background: "#636665" }}
            >
              
              Submit
            </Button>

            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                <Typography style={{fontSize: 20}}>  Cancel</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href="/login" variant="body2">
                <Typography style={{fontSize: 20}}>  Login</Typography>
                </Link>
              </Grid>
            </Grid>
           
</CardContent>
       </Card>
          {/* </Box> */}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}
