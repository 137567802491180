import React, { useCallback, useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "./image.png";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
// import Collapse from '@mui/material/Collapse';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

function Banner({ data }) {
  // const {data} = props.data;
  // const { titleBanner } = data.title;

  const matches = useMediaQuery("(min-width:900px)");
  const auctionID = localStorage.getItem("items");
  const [bannerImage, setBannerImage] = useState("");

  const [title, setTitle] = useState("");
  const [date_start, setDate] = useState("");
  const [catalog, setCatalog] = useState("");

  useEffect(() => {
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    try {
      // console.log("live data" ,data)
      var date = new Date(data.auction_details.date_start);
      setCatalog(data.auction_details.catalog_url);
      setTitle(data.auction_details.auction_name);
      setDate(date.toLocaleDateString("en-US", options));
      setBannerImage(data.auction_details.auction_cover);
    } catch (error) {}
  }, [data]);

  const gotLivePage = () => {
    window.location.href = `/auctions/room`;
  };

  return (
    <>
      <section
        class="main-slider style1"
        style={{ paddingBottom: 50}}
      >
        <div>
          <div
            class="row"
            style={{
              backgroundColor: "#eaecef",
              padding: 20,
              width: "100%",
              margin: "auto",
            }}
          >
            <div class="col text-center">
              <div style={{padding:10}}>
                <div
                  style={{ fontSize: matches === false ? 30 : 60}}
                >
                  LIVE AUCTIONS
                </div>
              </div>
              <div class="col-md-12 text-center"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-single-area" style={{paddingTop: 50}}>
      <div className="container">
      <div className="row"> <div className="col-xl-6">
      <Card style={{  border: "solid 1px #e8e8e8",
                              boxShadow: "rgb(0, 0, 1) 1px 1px 5px",cursor: "pointer"}}  onClick={() => gotLivePage()}>
      <CardHeader
       
      
        title={title}
        subheader="September 14, 2016"
      />
      <CardMedia
        component="img"
        height="194"
        image={Image}
        alt="Paella dish"
      />
      {/* <CardContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      
      </CardActions> */}
      
    </Card>
    </div>
    </div>
       </div>
</section>
      {/* <section className="blog-single-area">
      <div className="container" style={{}}>
          <div
            style={{ marginTOp: 100, marginBottom: 50, cursor: "pointer" }}
            onClick={() => gotLivePage()}
          >
            <div
              className="single-blog-post-style2  wow fadeInLeft animated"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <div
                class="img-holder"
                style={{
                  // marginRight: matches === true ? "" : 35,
                  // marginLeft: matches === true ? "" : 35,
                  marginTop: matches === true ? "" : 50,
                }}
              >
                <div class="inner">
                  <img
                    style={{ width: matches === true ? "50%" : "100%" }}
                    src={Image}
                    alt="Awesome Image"
                  />
                  <div class="overlay-style-one bg1"> </div>
                </div>
                <div>
       
                  <h3 class="blog-title">{title}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Banner;
