import React from 'react';
import Leonlogo from "../assets/image/Logo.png";
function Logo(props) {
    return (
        <div style={{margin: 'auto',marginTop: 10,}}>
              <img
                    src={Leonlogo}
                    alt="Awesome Logo"
                    title="Leon Gallery"
                    style={{ width: "150px", height: "46px" }}
                  />
        </div>
    );
}

export default Logo;