import React from 'react';
import Lot from "./exhibtionDetails"
import Banner from './banner'
function view(props) { 
    return (
        <div>
          <Banner param={props.param}/>
          <Lot/>
        </div>
    );
}

export default view;