import React, { useState, useRef, useCallback, useEffect, memo } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CircularProgress from "@mui/material/CircularProgress";
import { nextAuction, prevAuction } from "../../Model/Auction";
import NoImage from "../../images/resources/noImage.jpg";
import Typography from "@mui/material/Typography";
import "./Center.css";
import Box from "@mui/material/Box";
import { Button, Divider, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

// import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

export default function TitlebarBelowImageList({
  details,
  lotNumberNo,
  mediadQuery,
}) {
  const [getData, setGetData] = useState([]);
  const [count, setCount] = useState(0);
  // const [getdata,setGetData]= useState([]);
  const [loadingData, setLoading] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [lot_Number, setLotnumber] = React.useState("");
  const [starting_price, setHp] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (row) => {
    setImage(row.img_url);
    setTitle(row.title);
    setArtist(row.artist);
    setLotnumber(row.lot_number);
    setHp(row.starting_price);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNextAuction = () => {

    setCount(function (prevCount) {
      return (prevCount += 1);
    });
    //console.log(lotNumber+count)

    
    return increment();
  };

  const increment = (val) => {

    setLoading(true);
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: count + 1,
        };
        // setLoading(false);
        const results = await nextAuction(ArrayData);
        setLoading(false);
        setGetData(results);
        if (results.sucess === false) {
          setGetData([]);
        } else {
          setGetData(results);
        }
        // await socket.emit("send_Bid_amount", messageData);
      }
      getNextAuction().catch((error) => {
       // setLoading(false);
      });
    } catch (error) {
     // setLoading(false);
    }
  };

  const handleClickPrevAuction = () => {
    
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
    
    

   return decrement();
 
    

  };

  const decrement =() => {

    setLoading(!loadingData)

    try {
      async function getPrevAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: count - 1,
        };

        const results = await prevAuction(ArrayData);
        setGetData(results);
        setLoading(false);
        // await socket.emit("send_Bid_amount", messageData);
      }
      getPrevAuction().catch((error) => {
        setLoading(false);
        // console.log("handleClickPrevAuction: ", error);
      });
    } catch (error) {
      setLoading(false);
      // console.log("handleClickPrevAuction: ", error);
    }
  }

  const listImage = () => {
  //  setLoading(true)
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: parseInt(lotNumberNo) + 1,
        };

        const results = await nextAuction(ArrayData);
        setGetData(results);
       // setLoading(false)
        if (results.sucess === false) {
          setGetData([]);
        } else {
          setGetData(results);
        }
      }
      getNextAuction().catch((error) => {
       // setLoading(false);
        // alert(error)
        // console.log("getNextAuction: ", error);
      });
    } catch (error) {
     // setLoading(false);
      // console.log("getNextAuction: ", error);
    }
  };

  useEffect(() => {
    setCount(parseInt(lotNumberNo) + 1);

    if (mediadQuery === false) {
      listImage();
    } else {
      //handleClickNextAuction(details);
    }
  }, [details, lotNumberNo, mediadQuery]);

  return (
    <>
      <ImageList gap={10} sx={{ height: 200, display: "flex"}} style={{padding:5}}>
        {getData.length ? (
          getData
            .sort((a, b) => a.lot_number > b.lot_number)
            .map((item, i) => {
              let soldItem;
              if (item.lot_status === "UNSOLD") {
                soldItem = (
                  <div
                    className="overlayClient"
                    style={{ color: "rgb(251 49 49)", height: "100%" }}
                  >
                    Unsold
                  </div>
                );

              } else if (item.lot_status === "RESERVE_NOT_MET") {
                soldItem = (
                  <div
                    className="overlayClient"
                    style={{ color: "rgb(251 49 49)", height: "100%" ,paddingTop: "50%"}}
                  >
                    Reserve Not Met
                  </div>
                );

              } else if (item.lot_status === "SOLD") {
                soldItem = (
                  <div
                    className="overlayClient"
                    style={{ color: "rgb(4 245 4)", height: "100%" }}
                  >
                    Sold
                  </div>
                );
              } else if (item.lot_status === "PASS") {
                soldItem = (
                  <div
                    className="overlayClient"
                    style={{ color: "rgb(251 49 49)", height: "100%" }}
                  >
                    PASS
                  </div>
                );
              } else {
                soldItem = "";
              }
              return (
                <ImageListItem key={i} onClick={(e) => handleClickOpen(item)}>
                  <img
                    className="imageHover"
                    style={{
                      width: 100,
                      border: i === 0 ? "2px solid gray" : "1px solid gray",
                    }}
                    srcSet={item.img_url === null ? NoImage : item.img_url}
                    src={item.img_url === null ? NoImage : item.img_url}
                    alt={item.title}
                    loading="lazy"
                  />
                  {soldItem}

                  <ImageListItemBar
                    style={{ fontSize: 6, maxWidth: 100 }}
                    title={
                      <React.Fragment>
                        <Typography
                          style={{ fontSize: 10 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                            {item.lot_number +
                            ". " +
                            item.title}
                          {/* {item.lot_number +
                            ". " +
                            item.title.replace(/(<([^>]+)>)/gi, "")} */}
                        </Typography>
                      </React.Fragment>
                    }
                    subtitle={
                      <React.Fragment>
                        <Typography
                          style={{ fontSize: 9, maxWidth: 100 }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {item.artist}
                        </Typography>
                      </React.Fragment>
                    }
                    position="below"
                  />
                </ImageListItem>
              );
            })
        ) : (
          <Box sx={{ margin: "auto" }}>
           <Typography>Auction End</Typography>
          </Box>
        )}
        {/* <Box sx={{ margin: "auto" }} style={{display: loadingData === true ? "block":"none"}}>
            <CircularProgress />
          </Box> */}
      </ImageList>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: " translate(-50%, -50%)",
          msTransform: "translate(-50%, -50%)",

          border: "none",
          cursor: "pointer",
          borderRadius: 5,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            // alignItems: "center",
            justifyContent: "space-between",
            // height: 100,
            width: "98%",
            // opacity: .8,
            margin: "auto",
          }}
        >
          {count === 1 ? (
            <div></div>
          ) : (
            <div className="nextButton" style={{ borderRadius: 5 }}>
              <Button
                className="nextButton"
                style={{ color: "white" }}
                onClick={() => handleClickPrevAuction()}
                startIcon={<ArrowBackIosIcon style={{ marginLeft: 15 }} />}
              >
                {" "}
              </Button>
            </div>
          )}
          {getData.length ? (
            <div className="nextButton" style={{ borderRadius: 5 }}>
            <Button
              className="nextButton"
              style={{ color: "white" }}
              onClick={() => handleClickNextAuction()}
              startIcon={<ArrowForwardIosIcon style={{ marginLeft: 15 }} />}
            >
              {" "}
            </Button>
          </div>
          ) : (
           ""
          )}
        </Box>
      </div>


      <Dialog
      sx={{height: "80%"}}
  
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography component="div" variant="h5">
            Lot: {lot_Number}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Card sx={{ display: "flex", boxShadow: 0 }}>
          <Grid container columns={{ xs: 4, md: 12 }}>
        <Grid item xs={6}>
               
                    <Typography component="div"    dangerouslySetInnerHTML={{ __html: artist }}    style={{fontSize: 20}}>
                 
                    </Typography>
                    <Typography component="div"  dangerouslySetInnerHTML={{ __html: title }}   style={{fontSize: 18}}>
                   
                    </Typography>
                    <Typography
                      component="div"
                      sx={{ color: "text.secondary", fontSize: 16 }}
                    >
                      Starting Price: Php {starting_price.toLocaleString()}
                    </Typography>
                
                  
              </Grid>
       

      
              <Grid item xs={6}>
              <Divider style={{marginTop:20}}></Divider>
<Box mt={3}>


                <CardMedia
                  component="img"
              
                  image={image === null ? NoImage:image}
                  alt="Live from space album cover"
                />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingData}
      >
        <Typography>Loading!...</Typography>
      </Backdrop> 

    </>
  );
}
