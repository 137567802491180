
export default function BlogSingleArea({ data }) {


 var today = new Date(data.date)

  return (
     <div>

            <div className="blog-post">
              <div className="blog-details-content">
                
                <div className="text-holder">
                  <ul className="meta-info">
                    <li><a href="#">{today.toLocaleDateString('en-GB', {
                                        day : 'numeric',
                                        month : 'short',
                                        year : 'numeric'
                                    }).split(' ').join('-') } </a></li>
                                         </ul>
                  <h2 className="blog-title">{data.title?.rendered}</h2>
                  <div className="text">
                    <p className="" style={{marginBottom: 0 }} dangerouslySetInnerHTML={{__html: data.content?.rendered}}></p>
                  </div>
                </div>
               
                
              </div>
              {/*Start tag box*/}
             
            
            </div>
              
      </div>
  );
}