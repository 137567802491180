import { Box, Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState ,useRef} from "react";
import theme from "./theme";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";
import { postStatus,postUndo,nextLot } from "../../Model/DeclaratorModel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import results from "src/pages/resultAuction/Results";

// import {socket} from "./../../Model/socket"
const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled("div")({
  color: "red",
  animation: `${blink} 1s linear infinite`,
});

const ButtonsFunction = ({socketDeclarator,LiveDataddd}) => {
  const classes = theme();
  const [loading, setLoading] = useState(false);
  const [lotStatus, setLotStatus] = useState("");
  const [results1, setResults1] = useState([]);
  // const [AuctionID, setLotAuctionID] = useState("LiveDataddd.auction_details.auction_id");
  // const [setId, setSetID] = useState("LiveDataddd.auction_details.set_slug");
  const [lot_number, setlotNumber] = useState("");
  // const [count, setCount] = useState(0); declarator/lot/undo
  const [highestBid, setHighestBid] = useState(0);
  const [reserved, setReserved] = useState("");

  
  const handleClick =  useCallback(async (message) => {
 
    //  setLotStatus(reserved <  highestBid ?  message:"RESERVE NOT MET");
    const DataBidStatus = {
      auction_id: LiveDataddd.auction_details.auction_id,
      setId: LiveDataddd.auction_details.id,
      lot_number: LiveDataddd.lot.details[0].lot_number,
      status: message,
    };
    if(message=== "NEXTSLOT" ){

    }else if(message=== "fresh"){
      
    // }else if(message=== "PASS"){
      

    }else{
      setLotStatus(LiveDataddd.lot.details[0].lot_status);
    }
   
    try {
        if (message !== "") {
          let count = parseInt(lot_number) + 1;
          if (message === "NEXTSLOT"  ) {
             socketDeclarator.emit("send_status", {
              status: true,
              message: message,
              nextCount: count,
            });
             try {
                    async function nextLotData() {
                      const results = await nextLot(DataBidStatus);
                      // console.log(results.msg)
                      // setLotStatus('PENDING');
                      // console.log("results",results)
                      // return ListData();
                      
                    }
                    nextLotData().catch(console.error);
                  } catch (error) {}
                 setLotStatus('PENDING');
                //  return ListData();
              }else if(message === "fresh" ){
                socketDeclarator.emit("send_status", {
                message: message,
          
           });
          } else {
            try {
              setLoading(true)
              const results = await postStatus(DataBidStatus);
              setLoading(false)
              // setLotStatus(message);
              //alert(results.msg)
              if (results.success === true) {
                setLotStatus(results.status);
                // alert(results.status)
                if (message === "SOLD" || message === "UNSOLD" ) {
                   socketDeclarator.emit("send_status", {
                    status: true,
                    message: results.status,
                    nextCount: parseInt(lot_number),
                  });
                  
                } else {
                  // setLotStatus('PENDING');
                    socketDeclarator.emit("send_status", {
                    status: true,
                    message: message,
                    nextCount: parseInt(lot_number),
                  });
                  
                }
                //return ListData();
              } else {
                // alert(results.msg);
              }
              // setStatusMessage(message);
            } catch (error) {
              // setStatusMessage(message);
              console.log("error");
            }
          }
        }

    //  }
    //  getAuctionOne().catch(console.error);
    } catch (error) {}
    


  },[socketDeclarator,LiveDataddd]);

//   const handleClickUndo = () => {
//     const dataArray = {
//       auction_id: LiveDataddd.auction_details.auction_id,
//       setId: LiveDataddd.auction_details.id,
//       lot_number:LiveDataddd.lot.details[0].lot_number,
//     }

//     try {
//        setLoading(true)
//       async function functionUndo() {
//         const results = await postUndo(dataArray);
//         setLoading(false)
//       //  console.log(":results declarator: ",results)
//       if(results.success === true){
//         socketDeclarator.emit("send_status", {
//           status: results.success,
//           message: 'undo'
//         });
//       }else{

//       }
//         console.log('functionUndo: ',results)

//       }
//       functionUndo().catch(console.error);
//     } catch (error) {}

//  }


  useEffect(() =>{


    
     try {

      socketDeclarator.on("receive_bid_amount", (data) => {
        console.log("receive amount ",data)
 
        
      });

      
      setReserved(LiveDataddd.lot.details[0].reserve_price);
      setHighestBid(LiveDataddd.bid_history[0].hammer);
      // console.log(LiveDataddd.lot.details[0].reserve_price)
      // console.log(LiveDataddd.bid_history[0].hammer)

        setLotStatus(LiveDataddd.lot.details[0].lot_status)
       setlotNumber(LiveDataddd.lot.details[0].lot_number)
        } catch (error) {
          

          setReserved(LiveDataddd.lot?.details[0].reserve_price);


       setlotNumber(LiveDataddd.lot?.details[0].lot_number)
          setLotStatus(LiveDataddd.lot?.details[0].lot_status)
   
        }

  

      return () => {
        socketDeclarator.off("receive_bid_amount");
        socketDeclarator.off("send_status");
        
      };
          // alert(LiveDataddd.lot.details[0].lot_status)
  },[socketDeclarator,LiveDataddd])



  return (
    <div style={{ padding: 10 }}>
      <div style={{margin: "auto"}}>

    
      <Box style={{display: "flex",  alignItems: "center",justifyContent: "center"}}>
      <Typography align="center" variant="h5">
       Lot Status :  
        </Typography>
      
        <Typography align="center" variant="h5" style={{whiteSpace:"break-spaces"}}>
            <BlinkedBox> {"  "+lotStatus}</BlinkedBox>
        </Typography>
      </Box>
      </div>
      <Grid item xs={12} spacing={4}>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: "20px" }}
        >
          {/* <Button
            fullWidth
            disabled={lotStatus === "OPEN" || lotStatus === "PAUSE" || lotStatus === "PENDING" ? true : false}
            style={{ margin: 2 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("UNSOLD")}
            className={`${classes.buttonRed} ${classes.ButtonCustom}`}
          >
            UNSOLD
          </Button> */}

          <Button
            fullWidth
            disabled={lotStatus === "OPEN" || lotStatus === "PAUSE"  || lotStatus === "PENDING" ? true : false}
            style={{ margin: 2 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("SOLD")}
            className={lotStatus === "SOLD" ?`${classes.buttonGreen} ${classes.ButtonCustom}`:""}
            // className={`${classes.buttonGreen} ${classes.ButtonCustom}`}
          >
            SOLD
          </Button>

          <Button
            fullWidth
            disabled={lotStatus === "SOLD" || lotStatus === "UNSOLD" || lotStatus === "RESERVE_NOT_MET"  ? true : false}
            style={{ margin: 3 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("FAIR_WARNING")}
            className={lotStatus === "FAIR_WARNING" ?`${classes.buttonOrange} ${classes.ButtonCustom}`:""}
          >
            FAIR_WARNING
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} spacing={4}>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: "20px" }}
        >
          <Button
            fullWidth
            style={{ margin: 3 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("PAUSE")}
            className={lotStatus === "PAUSE" ?`${classes.pauseButton} ${classes.ButtonCustom}`:""}
            // className={`${classes.pauseButton} ${classes.ButtonCustom}`}
          >
            PAUSE
          </Button>

          <Button
            style={{ margin: 3 }}
            fullWidth
            variant="contained"
            color={lotStatus === "OPEN" ?"primary":"inherit"} 
            onClick={() => handleClick("OPEN")}
           
            // className={`${classes.reverButton} ${classes.ButtonCustom}`}
          >
            OPEN
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} spacing={4}>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: "20px" }}
        >
          <Button
            fullWidth
            style={{ margin: 2 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("PASS")}
            // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
          >
            PASS
            {/* PASS
                  OPEN
                  PENDING
                  RESERVE_NOT_MET
                  UNSOLD */}
          </Button>
          <Button
            disabled={
              lotStatus === "UNSOLD" || lotStatus === "SOLD" ||  lotStatus === "RESERVE_NOT_MET" ? false : true
            }
            fullWidth
            style={{ margin: 2 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClick("NEXTSLOT")}
            className={lotStatus === "NEXTSLOT" ?`${classes.buttonGreen} ${classes.ButtonCustom}`:""}
            // className={`${classes.buttonGreen} ${classes.ButtonCustom}`}
          >
            NEXT SLOT
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} spacing={4}>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: "20px" }}
        >
          {/* <Button
            fullWidth
            style={{ margin: 2 }}
            variant="contained"
            color="inherit"
            onClick={() => handleClickUndo()}
            className={`${classes.buttonGray} ${classes.ButtonCustom}`}
          >
            UNDO
           
          </Button> */}
          <Button
           
            fullWidth
            style={{ margin: 2 }}handleClickUndo
            variant="contained"
            color="inherit"
            onClick={() => handleClick("fresh")}
            // className={`${classes.buttonGreen} ${classes.ButtonCustom}`}
          >
            REFRESH ALL CONNECTED
          </Button>
        </Box>
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


    </div>
  );
};

export default ButtonsFunction;
