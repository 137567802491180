import React from 'react';
import HeadImage from "./HeadImage"
import Products from "../products/"
function view(props) {
    return (
        <div>
          <HeadImage param={props.param}/>
          <Products/>
        </div>
    );
}

export default view;