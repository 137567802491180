import Box from "@mui/material/Box";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import React, { useState, useRef, useCallback, useEffect } from "react";
import SearchAuction from "./SearchAuction";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { getFilter, privateGroupFilter, categories } from "../../Model/Auction";
import SearchIcon from "@mui/icons-material/Search";

import { storePrivateID } from "../../Model/LocalStorage";
import { makeStyles } from "@mui/styles";
import { storeAuctionLot } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemIcon from "@mui/material/ListItemIcon";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "pink",
      color: "red",
      "&:hover": {
        backgroundColor: "tomato",
      },
    },
  },
  selected: {},
});

export default function ExhibitionDetails() {
  const matches = useMediaQuery("(min-width:1000px)");
  var { id, slug, type } = useParams();
  const [pageNumber, setPageNumber] = useState(1);

  // const [nameID, setNameID] = useState([]);
  const styles = useStyles();
  const [search, setSearch] = useState("");

  // const [selectDays, setSelectdays] = useState("");
  // const [expanded, setExpanded] = React.useState(true);
  // const [expanded1, setExpanded1] = React.useState(true);
  // const [expanded2, setExpanded2] = React.useState(true);
  const search_url = window.location.search;
  const params = new URLSearchParams(search_url);
  const url_data = params.get("data");
  const [searchCat, setSearcHCategory] = useState();

  const { lotdata, hasMore, loading, error } = SearchAuction(
    pageNumber,
    id,
    search,
    searchCat
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const gotSinglePage = (auction_id, group, title) => {
    // const dataArray = {
    //   id: auction_id,
    // };
    // // storePrivateID(dataArray);
    window.location.href = `/private/buy/${auction_id}/${group}/${title}`;
  };

  return (
    <section
      className="gallery-area"
      style={{
        width: "100%",
        backgroundImage: " linear-gradient(#313030, #313030, #292828)",
        padding: 20,
        top: "20px",
      }}
    >
      <div class="container">
        <div>
          <div
            class="row items-container clearfix"
            style={{ position: "relative" }}
          >
            {lotdata.length ? (
              lotdata.map((value, i) => {
                if (lotdata.length === i + 1) {
                  return (
                    <div
                      ref={lastBookElementRef}
                      class="col-xl-3 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                    >
                      <div class="single-art-box-style2 marbtm50">
                        <div
                          class="img-holder"
                          onClick={() =>
                            gotSinglePage(
                              value.data.id,
                              value.data.privateSalesGroup,
                              value.data.title
                            )
                          }
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                value.image.length
                                  ? value.image[0].img_url
                                  : "https://placehold.co/400x400/jpg"
                              })`,
                              backgroundColor: "#ffffff",
                              width: "100%",
                              height: 370,
                              display: "inline-block",
                              backgroundSize: "contain",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              padding: 10,
                            }}
                          ></div>

                          {/* <img  src={
                                    value.image.length
                                      ? value.image[0].img_url
                                      : 'https://placehold.co/400x400/jpg'
                                  }
                                  alt={value.image}
                                  // style={{ paddingTop: 40 }}
                                /> */}
                        </div>
                        <div class="text-holder">
                          <h3>
                            <a href="#">{value.data.title}</a>
                          </h3>
                          <span> {value.data.artist}</span>
                          {/* <h3 > <a href="#">Php: {value.data.price === null ? 0:value.data.price.toLocaleString()}</a></h3> */}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta">
                      <div class="single-art-box-style2 marbtm50">
                        <div
                          class="img-holder"
                          onClick={() =>
                            gotSinglePage(
                              value.data.id,
                              value.data.privateSalesGroup,
                              value.data.title
                            )
                          }
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                value.image.length
                                  ? value.image[0].img_url
                                  : "https://placehold.co/400x400/jpg"
                              })`,
                              backgroundColor: "#ffffff",
                              width: "100%",
                              height: 370,
                              display: "inline-block",
                              backgroundSize: "contain",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              padding: 10,
                            }}
                          ></div>

                          {/* <img  src={
                                value.image.length
                                  ? value.image[0].img_url
                                  : 'https://placehold.co/400x400/jpg'
                              }
                              alt={value.image}
                              // style={{ paddingTop: 40 }}
                            /> */}
                        </div>
                        <div class="text-holder">
                          <h3>
                            <a href="#">{value.data.title}</a>
                          </h3>
                          <span> {value.data.artist}</span>
                          {/* <h3 > <a href="#">Php: {value.data.price === null ? 0:value.data.price.toLocaleString()}</a></h3> */}
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "100%",
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                {/* No Record Found */}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
