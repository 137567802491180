import axios from "axios";
// import { useNavigate } from "react-router-dom";

//  export const metaApi = 'http://157.230.43.47:8081/';  // local
// //export const metaApi = 'https://leon-gallery.com/';  // prod

export const axiosInstant = axios.create({

// baseURL: "http://157.230.43.47:3031/api/", // local
 baseURL: "https://api01.leon-gallery.com/api/", // prod
 maxBodyLength: Infinity,
//  timeout: 5000,
});

// http://157.245.62.138:3031 prod
// http://157.230.43.47:3031 local
// const instance = axios.create();x
// instance.interceptors.request.use(function () {/*...*/});
// axiosInstant.defaults.timeout = 10000;
// axiosInstant.defaults.timeout = 2500;

 axiosInstant.interceptors.request.use(
  function (config) {
    
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`;
    // Do something before request is sent
    // const token = localStorage.getItem('token');
    // config.headers.Authorization = `Bearer ${token}`;
    // config.headers['X-User'] = 1;
    return config;
  },
  function (error) {


    // Do something with request error
    // console.log('error: ',error.message)
    return Promise.reject(error);
  }
);

// Add a response interceptor

axiosInstant.interceptors.response.use(
  function (response) {
  // console.log('Add a response interceptor response: ',response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    return response;

  },
  function (error) {
    // console.log('Add a response interceptor error: ',error.message); canceled


  
    if(error.response.status ===  403 || error.response.status ===  401){
      localStorage.removeItem("@storage_Key")
      localStorage.removeItem('token')
      
      // alert('Token Expired Please Login')
     // navigate("/login")
     return  window.location.href = '/login'
    }
    

    if(error.message === 'Network Error'){
      alert('Show Alert');
    }else if(error.message === 'canceled'){
      alert('Slow Internet Please Refresh')
    }else{
    
    }


    return Promise.reject(error);

  }
);




export default axiosInstant;


