
import { window } from 'globalthis/implementation';
import React,{useEffect,useState} from 'react';
import View from "./view"



function index(props) {
    // localStorage.removeItem("@storeAuctionLot");
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const data = params.get('data');



    return (
        <div>
            
            <View param={data}  />
        </div>
    );
}

export default index;