import {
  Box,
  Grid,
  TextField,
  ListItem,
  Typography,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { hallBid, floorBidPost, phoneBid } from "../../Model/DeclaratorModel";
// import {socket} from "./../../Model/socket"
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const RightPannel = ({ socketDeclarator, LiveData }) => {
  const [incrEment, setIncrement] = useState("");
  const [absentee, setAbsentee] = useState("");



  const controller = new AbortController();
  const [hp, setHammer] = useState(null);
  const [startBid, setStartbid] = useState("");
  const [loading, setLoading] = useState(false);

  const [floorbidvalue, setFloorBidValue] = useState("");
  const [highestBid, setHighestBid] = useState(0);
  const [reserved, setReserved] = useState("");
  
  // const handleNumberChange = (value) => {
  //   setFloorBidValue(value)
  // }
  
  const handleFloorBid = () => {
    // alert(floorbidvalue)
    try {
      setLoading(true);
      async function postFloorBid() {
        const florrData = {
          auction_id: LiveData.auction_details.auction_id,
          setId: LiveData.auction_details.id,
          lot_number: LiveData.lot.details[0].lot_number,
          amount: floorbidvalue,
        };
        const results = await floorBidPost(florrData);
        setLoading(false);
        if (results.success === true) {
          socketDeclarator.emit("send_Bid_amount", { status: results.success });
          // setLotStatus('PENDING');
        } else {
          alert(results.msg);
          // window.location.reload();
        }
        // setLotStatus('PENDING');
        // console.log("results",results)
        // return ListData();
      }
      postFloorBid().catch((error) => {
        setLoading(false);
        console.log(error);
      });
    } catch (error) {}
  };

  const handlePhoneBid = useCallback((amount) => {
    const florrData = {
      auction_id: LiveData.auction_details.auction_id,
      setId: LiveData.auction_details.id,
      lot_number: LiveData.lot.details[0].lot_number,
      amount: amount,
    };

    try {
      setLoading(true);
      async function postphoneBid() {
        const results = await phoneBid(florrData);
        setLoading(false);
        if (results.success === true) {
          socketDeclarator.emit("send_Bid_amount", { status: results.success });
          // setLotStatus('PENDING');
        } else if (results.success === false) {
          alert(`Error !: ${results.msg}`);
        } else {
          // alert(results.msg);
        }
        // console.log("results",results)
        // return ListData();
      }
      postphoneBid().catch((error) => {
        setLoading(false);
        console.log(error);
      });
    } catch (error) {}
  },[LiveData]);



  // const handleHallBid = () => {
  //   try {
  //     setLoading(true);
  //     async function postHallBid() {
  //       const bidData = {
  //         auction_id: LiveData.auction_details.auction_id,
  //         setId: LiveData.auction_details.id,
  //         lot_number: LiveData.lot.details[0].lot_number,
  //       };

  //       const results = await hallBid(bidData);
  //       setLoading(false);
  //       if (results.success === true) {
  //         socketDeclarator.emit("send_Bid_amount", { status: results.success });
  //       } else if (results.success === false) {
  //         alert(`Error !: ${results.msg}`);
  //       } else {
  //         // alert(results.msg);
  //       }
  //     }
  //     postHallBid().catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  //   } catch (error) {}
  // };

  useEffect(() => {




    //  console.log('increment: ',LiveData)
    try {

    
      
      setIncrement(LiveData.increment);
      setAbsentee(LiveData.absentee);
      
      setHammer(LiveData.lot.details[0].hp);
      // alert(LiveData.lot.details[0].starting_price)
      setStartbid(LiveData.lot.details[0].starting_price);
      setReserved(LiveData.lot.details[0].reserve_price);
      setFloorBidValue(
        LiveData.lot.details[0].hp === 0 || LiveData.lot.details[0].hp === null
        ? parseInt(LiveData.lot.details[0].starting_price)
        : parseInt(LiveData.increment)
      )
      // setHighestBid(
      //   LiveData.bid_history
      //     .filter(
      //       (val) =>
      //         val.hammer ===
      //           Math.max(...LiveData.bid_history.map((o) => o.hammer === o.hp)) &&
      //         val.win_status === "WIN"
      //     )
      //     .map((row) => row.userId)
      // );

      const result = LiveData.bid_history.filter((x) => x.win_status === "WIN");
      setHighestBid(result[0].email);
 
      // setHighestBid(LiveData.bid_history[0].email);

 
    } catch (error) {

      // setIncrement(LiveData.increment);
      // setLotGroup("");
      // setHammer(LiveData.lot.details[0].hp);
      // setStartbid(LiveData.lot.details[0].starting_price);
    }

    return () => {
      socketDeclarator.off("send_Bid_amount");
      // socket.off('foo', onFooEvent);
    };
  }, [socketDeclarator, LiveData]);

  return (
    <Box>    
        <Grid container columns={{ xs: 4, md: 12 }}>
        <Grid item xs={6}>
   
              <Box style={{ display: "flex", margin: 5,  }}>
                <Typography align="right" variant="h6" style={{ margin:5,fontSize:30 }}>
                  Php
                </Typography>
                <TextField
                  type="text"
                  size="large"
                  style={{width: "100%", margin:5}}
                  inputProps={{
                    style: { fontSize:30 ,padding: "5.5px 14px"},
                  }}
                  name="floorbidvalue"
                 onChange={(e)=> setFloorBidValue(parseInt(e.target.value))}
                  value={
                   floorbidvalue
                  }
                />
              </Box>
       </Grid>


       <Grid item xs={6}>
              <Box style={{ display: "flex",justifyContent: "space-between" }}>
                <Button
                  fullWidth
                  onClick={() =>
                    handleFloorBid()}
                  variant="contained"
                  color="primary"
                  style={{ margin: 5 ,
                    backgroundColor: "red",
                    color: "white",}}
                >
                  Floor Bid{" "}
                  <br/>
                  {incrEment === "" ? 0 : parseInt(floorbidvalue).toLocaleString()}
                </Button>

                <Button
                  fullWidth   
                  style={{
                    margin: 5,
                    backgroundColor: "green",
                    color: "white",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handlePhoneBid(incrEment === "" ? 0 : parseInt(incrEment))
                  }
                  // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
                >
                  Phone bid{" "}
                  {incrEment === "" ? 0 : parseInt(floorbidvalue).toLocaleString()}
                </Button>
              </Box>
     
          </Grid>
        </Grid>

      <Divider />
     
      <Box style={{ paddingTop: 10, padding: 20 }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">STARTING BID:</Typography>
          <Typography variant="h6">
            {startBid === "" ? 0 : parseInt(startBid).toLocaleString()}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">CURRENT BID:</Typography>

          <Typography variant="h6">
            {hp === "" || hp === null ? 0 : parseInt(hp).toLocaleString()}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">INCREMENT:</Typography>
          <Typography variant="h6">
            {incrEment === "" ? 0 : parseInt(incrEment).toLocaleString()}
          </Typography>
        </Box>
        {/* <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">BID:</Typography>

          <Typography variant="h6">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={(e) => handleHallBid(e)}
              // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
            >
              Hall bid{" "}
              {incrEment === "" ? 0 : parseInt(incrEment).toLocaleString()}
            </Button>
          </Typography>
        </Box> */}

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" style={{color: 'blue'}}>Absentee Bid:</Typography>
          <Typography variant="h5" style={{color: 'blue'}}>
            {absentee === "" || absentee === null? 0 : parseInt(absentee).toLocaleString()}
          </Typography>
        </Box>


        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" style={{color: '#db0808'}}>Reserved Price:</Typography>
          <Typography variant="h5" style={{color: '#db0808'}}>
            {reserved === "" || reserved === null? 0 : parseInt(reserved).toLocaleString()}
          </Typography>
        </Box>


        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            HIGH BIDDER:
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {hp === 0 || hp === null ? "" : highestBid}
          </Typography>
        </Box>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default RightPannel;
