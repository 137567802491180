import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function LotData(page,auctionID) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lotdata, setLot] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');


  useEffect(() => {
    setLot([]);
  }, [auctionID]);

  useEffect(() => {
    setLoading(true);
    setErrorMsg(true)
    setError(false);

    const fetchData = async () => {
    try {
      
      let cancel;
     await  axiosInstant({
      method: "GET",
      url: `lot?auction_id=${auctionID === undefined ? "" : auctionID.auction_details.auction_id}&search=&set_id=&page=${
        page === undefined ? "": page
      }&cat=`,
      //params: { q: query, page: pageNumber },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      
    })
      .then((res) => {
    
        if(res.data.status === true){
          setLot((prevlotdata) => {
            return [...new Set([...prevlotdata, ...res.data.msg])];
          });
        }else{
          setErrorMsg(false)
        }
        setHasMore(res.data.msg.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name === 'AbortError') {
           console.log("abort",e.name)
        } else {
          console.log("abort",e.name)
        }

      
        if (axios.isCancel(e)) return;
        console.log("eeeeeorrrorrr canceled: ",e)
        setError(true);
        // setLot([])
        setErrorMsg(e.response.data.sucess)
       
      });
    return () => cancel();

    } catch (error) {

      console.log("catch error: ",error)
      
    }
  }
  fetchData();
  }, [page,  auctionID]);

  return { loading, error, lotdata, hasMore, errorMsg};
}
