import { Routes, Route } from "react-router-dom";
import LayoutHeader from "./mainLayouts/Layout";
import Home from "./pages/home/index";
import About from "./pages/aboutUs/";
import HowTobuy from "./pages/howTobuy";
import HowToSell from "./pages/howTosale";

import Faq from "./pages/Faq";

import PrivateSales from "./pages/PrivateSales";

import News from "./pages/News";
// import Videos from "./pages/Videos";
import Login from "./pages/Login/";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import UpcommingAuctions from "./pages/upcommingAuction";
import ResultsAuction from "./pages/resultAuction/";
import Jewelry from "./pages/Jewelry/";
import Watches from "./pages/Watches/";

import Handbags from "./pages/Handbags/";
import useMediaQuery from "@mui/material/useMediaQuery";

// import LayoutHeaderAuctions from "./view/auctionsRoom/header";
import AuctionsRoom from "./view/auctionsRoom/auctions/";
import Declarator from "./view/declarator/console/";
import Live from  "./pages/live";
import Profile from "./view/profile";

// import Live from "./view/declarator/jointRoom";

// import Room from "./room/";

import LotView from "./pages/Lot";
import SearchLot from "./pages/SearchLot";

import PrivateSalesAndExhibition from "./pages/PrivateSell";

import SinglePageProduct from "./pages/SinglePageProduct";
import SinglePrivate from "./pages/SInglePrivateProduct";

import Exhibition from "./pages/Exhibtion";
import ExhibitionPage from "./pages/ExhibtionPage";

import LeonGalleryMain from "./pages/Location/main/index";
import LeonGalleryInternational from "./pages/Location/international/index";
import LeonGalleryLafuerza from "./pages/Location/lafuerza/index";
import LeonGalleryMaison from "./pages/Location/maison/index";

import BlogSingle from "./pages/Blog-single";
import Blog from "./pages/Blog";
import RequestEstimate from "./pages/requestEstimate";
import SignIn from "./pages/Signup/";
// import { getToken } from "../src/Model/User";
// import Blog from "./pages/Blog";
import PrivateRoutes from "./PrivateRoute";


function App({}) {


  ///useMediaQuery("(min-width:900px)");
  const phoneMatch = useMediaQuery("(min-width:900px)");
  const matches = { phoneMatch };

  // console.log("token app: ", token);
  return (
    
    <>

      <Routes>
   
        <Route element={<LayoutHeader />}>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/howtobuy" element={<HowTobuy />} />
          <Route path="/howtosell" element={<HowToSell />} />
          <Route path="/Live" element={<Live />} />
          
          <Route path="/request" element={<RequestEstimate />} />

          {/* Auction */}
          <Route path="/auctions" element={<UpcommingAuctions />} />
          <Route path="/results" element={<ResultsAuction />} />

          {/* BUY NOW */}
          <Route path="/buy/handbags" element={<Handbags />} />
          <Route path="/buy/watches" element={<Watches />} />
          <Route path="/buy/jewelry" element={<Jewelry />} />

          {/* PRIVATE SALE */}
          <Route path="/buy/private-sale/jewelry" element={<Jewelry />} />

          {/* <Route path="/private_sales" element={<PrivateSales />} /> */}
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/news" element={<News />} />
          {/* <Route path="/videos" element={<Videos />} /> */}
          <Route path="/contact" element={<Contact />} />

          {/* auction list <a className="hide" href={`/auction/${value.c_slug}/${value.id}`}> */}
          <Route path="/auction/lot/:id/:slug/:type" element={<LotView />} />

          <Route path="/live" element={<Live />} />

          
          <Route path="/search/:searching" element={<SearchLot />} />

          <Route path="/private/sale" element={<PrivateSalesAndExhibition />} />

          <Route path="/auction/:id/:lotNo/:slug/:title" element={<SinglePageProduct />} />

          <Route path="/private/buy/:id/:group/:title" element={<SinglePrivate />} />

          <Route path="/private_sales" element={<PrivateSales />} />
          <Route path="/stories" element={<Blog />} />
          <Route path="/stories/:slug" element={<BlogSingle />} />

          <Route path="/exhibition/:id/:slug/:type" element={<Exhibition />} />
          <Route path="/exhibition" element={<ExhibitionPage />} />
          <Route path="/location/main" element={<LeonGalleryMain />} />
          <Route
            path="/location/international"
            element={<LeonGalleryInternational />}
          />
          <Route path="/location/lafuerza" element={<LeonGalleryLafuerza />} />
          <Route path="/location/maison" element={<LeonGalleryMaison />} />

          <Route path="/faq" element={<Faq />} />

          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        {/* <Route path="/room" element={<Room />} /> */}

        <Route path="/login" element={<Login />} />

        <Route path="/Signup" element={<SignIn />} />

        <Route path="/home" element={<Home mediadQuery={matches} />} />

        {/* <Route element={<PrivateRoutes mediadQuery={matches} />}> */}
        <Route path="/profile" element={<Profile />}  mediadQuery={matches}/> 
          <Route path="/declarator/room" element={<Declarator />} />]
          {/* <Route path="/joint/room" element={<JointRoom />} /> */}
          <Route
            path="/auctions/room"
            element={<AuctionsRoom 
            mediadQuery={matches} />}
          />
        {/* </Route> */}
      </Routes>
    </>
  );
}

export default App;
