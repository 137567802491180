import React from 'react';
import View from './SignUp'

import Page from '../../components/Page';
function index(props) {
    return (
        <Page  title="Signup">
            <View/>
        </Page>
    );
}

export default index;