import axiosInstant from "../axiosInstant";

export async function auctionsList(page, live, exhibition, online) {
  

  const response = await axiosInstant
    .get(
      `auction?page=${page === null ? 1 : page}${
        live === null ? "" : `&category=${live}`
      }${exhibition === null ? "" : `&category=${exhibition}`}${
        online === null ? "" : `&category=${online}`
      }`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}



export async function auctionsLive() {
  
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get(`live/current`)
    .then((res) => {
      // //console.log('res.data.lot.detils: ',res.data)
      return res.data;
    })
    .catch((error) => {
    return error.response.data;
    });
  return response;
}


export async function nextAuction(liveData,data) {
  const response = await axiosInstant.get(`live/lot/next?auction_id=${liveData.auction_id}&setId=${liveData.setId}&lot_number=${data === undefined ?  liveData.LotNo: data.nextCount}`)
    .then((res) => {
      // //console.log("ot/next: ",res.data)
      return res.data;

    })

    .catch((error) => {
    //  //console.log("ot/next: ",error.response.data)
    return error.response.data;
    });
  return response;
}


export async function mainAuction(auction_status) {
   //console.log('model auction_status: ',auction_status)
  const response = await axiosInstant
    .get(
      `/auction?category=LIVE_AUCTION&page=1&auction_status=${auction_status=== undefined ? 'UPCOMING':auction_status}`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}

export async function lafuerzaAuction(auction_status) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get(
      `/auction?category=ONLINE_AUCTION&page=1&auction_status=${auction_status=== undefined ? 'UPCOMING':auction_status}`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}


export async function international(auction_status) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get(
      `/auction?category=EXHIBITION&page=1&auction_status=${auction_status === undefined ? '':auction_status}`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}

export async function privatefilterArtist(id) {
  const response = await axiosInstant
    .get(
      `private-sales/filtering?id=${id}&pgroup=PRIVATE_SALE`
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
      // //console.log(error.response.data);
      //console.log(error.response.data);
    });
  return response;
}



export async function getUpcommingAuction() {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get(`/auction?page=1`)
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}






// export async function getFilterSetname() {
//   // //console.log('model auction: ',exhibition)
//   const response = await axiosInstant
//     .get( `lot/set?auction_id=1`
//     )
//     .then((res) => {
//       return res.data.msg;
//     })
//     .catch((error) => {
//       //console.log(error.response.data);
//       //return error.response.data.message;
//     });
//   return response;
// }



export async function getFilter(id) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get( `lot/set?auction_id=${id=== undefined ? '': id}`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
    return error.response.data.msg;
    });
  return response;
}



export async function getPreview(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get( `lot/preview_amount?auction_id=${data.auction_id}&amount=${data.amount}&lot_number=${data.lotNumber}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      //console.log(error.response.data);
    return error.response.data;
    });
  return response;
}




export async function getPlaceAmount(auction_id,set_id,lotNumber) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get( `lot/place_amount?auction_id=${auction_id=== undefined ? '': auction_id}&set_id=${set_id=== undefined ? '': set_id}&lot_number=${lotNumber=== undefined ? '': lotNumber}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
  
    return error.response.data.msg;
    });
  return response;
}

export async function postBid(data) {

  const response = await axiosInstant
    .post(
      `live/placeBid`,data
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
  
      return error.response.data;
    });
  return response;
}



export async function filterArtist(id) {
  const response = await axiosInstant
    .get(
      `lot/filtering?auction_id=${id}&limit=10`
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
   
      //return error.response.data.message;
    });
  return response;
}



export async function privateGroupFilter(group) {
  const response = await axiosInstant
    .get(
      `private-sales/filtering?id=0&pgroup=PRIVATE_SALE`
    )
    .then((res) => {
// //console.log('res data: ',res.data.data)
      return res.data.data;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}


export async function categories() {
  const response = await axiosInstant
    .get(
      `private-sales/categories`
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}

export async function actionByID(id) {
  const response = await axiosInstant
    .get(
      `auction/${id}`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}



export async function exhibitionBannerByID(id) {
  const response = await axiosInstant
    .get(
      `private-sales/details?id=${id}&page=`
    )
    .then((res) => {
      return res.data.msg;
    })
    .catch((error) => {
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}


export async function addfavorite(data) {

  const response = await axiosInstant
    .post(
      `lot/addFavorites`,data
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
  
      return error.response.data;
    });
  return response;
}

export async function removefavorite(data) {

  const response = await axiosInstant
    .post(
      `lot/removeFavorites`,data
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
  
      return error.response.data;
    });
  return response;
}


export async function getExhibition(data) {

const response = await axiosInstant
.get(
  `auction?page=1&category=EXHIBITION`
)
.then((res) => {

  const result = res.data.msg.filter(
    (x) =>
      x.auction_status === "ONGOING"  
  );
console.log('result ',result)

  return result;
})
.catch((error) => {
  //console.log(error.response.data);
  //return error.response.data.message;
});
return response;
}

export async function contactus(data) {

  const response = await axiosInstant
  .post(
    `request/contactus`,data
  )
  .then((res) => {
  console.log(res)
    return res.data;
  })
  .catch((error) => {
    //console.log(error.response.data);
    return error.response.data;
  });
  return response;

}

