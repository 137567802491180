import React from 'react';
import View from './view'
function index(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search); 
    const data = params.get('data'); 
    return (
        <div>
            <View param={data}/>
        </div>
    );
}

export default index;