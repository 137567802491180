import axiosInstant from "../axiosInstant";

export async function getSingaleData(id,lotNumber) {
  const response = await axiosInstant
    .get(
      `lot/details?auction_id=${id}&lot_number=${lotNumber=== undefined ? 1:lotNumber}`
    )
    .then((res) => {

      return res.data;
    })
    .catch((error) => {
      console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}



export async function postInequired(ArrayData) {


  const response = await axiosInstant
  .post(
    `private-sales/inquire`,ArrayData
  )
  .then((res) => {

    return res.data;
  })
  .catch((error) => {
    return error.response.data;
    //return error.response.data.message;
  });
return response;

  
}





export async function getPrivateSingleData(id) {
  const response = await axiosInstant
    .get(
      `private-sales/byId/${id}`
    )
    .then((res) => {

      return res.data.msg;
    })
    .catch((error) => {
      console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}






