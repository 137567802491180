import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function LotData(page, searching) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lotdata, setLot] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setLot([]);
  }, [searching]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    const fetchData = async () => {
      try {
        let cancel;
        await axiosInstant({
          method: "GET",
          url: `search?search=${
            searching === undefined ? "" : searching
          }&page=${page === undefined ? "" : page}`,
          //params: { q: query, page: pageNumber },
          cancelToken: new axios.CancelToken((c) => (cancel = c)),
        })
          .then((res) => {
            if (res.data.count === 0) {
              setLot([]);
              setHasMore(res.data.msg.length > 0);
              setLoading(false);
            } else {
              setLot((prevlotdata) => {
                return [...new Set([...prevlotdata, ...res.data.msg])];
              });
              setHasMore(res.data.msg.length > 0);
              setLoading(false);
            }
          })
          .catch((e) => {
            console.log("e.response.data.msg ", e.response.data.msg);
            // if(e.response === undefined){
            //   setErrorMsg("error")
            //   console.log('error')
            // }else{
            //   setErrorMsg(e.response.data.msg)

            //         if (axios.isCancel(e)) return;
            //         setError(true); console.log('isCancel',e)

            // }
            setErrorMsg(e.response.data.msg);
            if (axios.isCancel(e)) return;
            setError(true);
          });
        return () => cancel();
      } catch (error) {
        console.log("catch error: ", error);
      }
    };
    fetchData();
  }, [page, searching]);

  return { loading, error, lotdata, hasMore, errorMsg };
}
