
import React from 'react';

function HeadImage(props) {

    return (
    <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-1.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>{props.param}</h1>
                </div>
                {/* <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li>Home</li>
                    <li className="active">About Us</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default HeadImage;