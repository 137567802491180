import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/resources/dddd.png";
import { homehandle } from "../../mainLayouts/RouteFunction";
import { login } from "../../Model/Login";
import { storeUser } from "../../Model/LocalStorage";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {getToken} from "../../Model/User"

function View(props) {
  const [ipaddress, setIpaddress] = useState("");
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    axios.get("http://api64.ipify.org?format=json").then((response) => {
      let _ipaddress = response.data?.ip || "0.0.0.0";
      sessionStorage.setItem("ip_address", _ipaddress);
      setIpaddress(_ipaddress);
    }).catch(function(error) {
      console.log(error);
    });
    
    console.log( getToken())
    try {
      return () =>  getToken() === null ?  "": window.location.href = "/home";
    } catch (error) {
     
    }

  }, [ipaddress]);

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
  setLoading(true);
    async function LoginData() {
      const ArraData = {
        email: values.username,
        password: values.password,
        ip: ipaddress,
      };

      const results = await login(ArraData);
     

      if (results.sucess === true) {
        const userInfo = {
          email: results.details.email,
          firstname: results.details.firstname,
          lastname: results.details.lastname,
          phone: results.details.phone,
          id: results.details.id,
          group: results.details.group,
        };


        localStorage.setItem("token", results.token);
        // console.log("results: ", results.details.group); "http://localhost:3005"
        storeUser(userInfo);
        window.location.href = "/home";

        // if (results.details.group === "ADMIN") {
        //   //window.location.href = "/joint/room"
        //   window.location.href = "/declarator/room";
        // } else {
        //   window.location.href = "/auctions/room";
        // }   
      }else if(results.success =false){
        alert(results.errors)
      } else {
        alert(results.errors)
        window.location.reload();
        setLoading(false);
        // alert("login Failed");
        // console.log(results);
      }
    }
    LoginData().catch(console.error);
    //return homehandle();
    //alert(values.username,values.password);
  };

  // console.log("process.env.NODE_ENV: ",process.env.REACT_APP_CONN)
  function handleBack() {
    window.location.href = "/home";
  }
  return (
    <div>
      {/* <button
        type="button"
        className="close"
        style={{
          cursor: "pointer",
          paddingTop: "25px",
          marginRight: "25px",
          fontSize: "30px",
        }}
        aria-label="Close"
        onClick={handleBack}
      >
        <span aria-hidden="true">×</span>
      </button> */}

      <div className="logincontainer">
        <input type="checkbox" id="check" />
        <div className="login form">

          {/* <h1>Client</h1> */}
          <div className="imageLog">
            <img
              style={{ width: "100%" }}
              src={Logo}
              alt="Awesome Logo"
              className="logo"
              title="Leon Gallery"
            />
          </div>
          <p>Sign in to your account to get recommendations, place bids and register for sales</p>
          <input
            autoComplete="true"
            className="textfield"
            type="text"
            placeholder="Enter your username"
            name="username"
            onChange={handleChange}
          />
          <input
            autoComplete="true"
            className="textfield"
            type="password"
            placeholder="Enter your password"
            name="password"
            onChange={handleChange}
          />
          <Link style={{ color: "#1d3ae2" }} to="#">
            Forgot password?
          </Link>
          <input
            type="button"
            className="button"
            value="Login"
            onClick={handleSubmit}
          />
          <div className="signup">
            <Link to="/signup" variant="body2" style={{color: "blue"}}>
              {"CREATE NEW ACCOUNT"}
            </Link>

            {/* <span className="signup">
              Don't have an account?
              <Link to="/signup"  > Signup</Link>
            </span> */}
          </div>
        </div>
        <div className="registration form">
          <header>Signup</header>
          <form action="#">
            <input
              autoComplete="true"
              type="text"
              placeholder="Enter your email"
            />
            <input
              autoComplete="true"
              type="password"
              placeholder="Create a password"
            />

            <input
              autoComplete="true"
              type="password"
              placeholder="Confirm your password"
            />
            <input
              autoComplete="true"
              type="button"
              className="button"
              value="Signup"
            />
          </form>
          <div className="signup">
            <span className="signup">
              Already have an account?
              <label htmlFor="check">Login</label>
            </span>
          </div>

          {/* email,
      pword,
      firstname,
      lastname,
      dob,
      phone
      address,
      second_address_line,
      postal_code,
      city,
      country,
      nationality */}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default View;
