import { Link } from "react-router-dom";

import React, { useState,useEffect } from "react";
import StickyHeader from "./StickyHeader";
import Logo from "./../images/resources/dddd.png";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { abouthandle, homehandle, } from "./RouteFunction";
import Menu from "./Menu";
import { useParams } from "react-router-dom";
// import { Button } from "@mui/material";
import { getUser } from "../Model/LocalStorage";




const Header = (props) => {
  const matches = useMediaQuery("(min-width:1000px)");
  var { slug, type } = useParams();
  const [search, setSearch] = useState("");
  const [firstname, setFirstname] = useState("");
  const [isActive, setActive] = useState(false);
  function searchbox() {
    setActive(!isActive);
  }

  function handleSearch(e) {
    e.preventDefault();
    // alert("alert")
    // let searching = search.replace([a-zA-Z0-9\s]+$)
    return (window.location.href = `/search/${search}`);
  }

  const [token, setToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("token");
    const initialValue = saved;
    return initialValue || "";
  });


  const ClickLogOut = () => {
    localStorage.removeItem("token")
    window.location.href = '/login';
  }

  useEffect(() => {
    // listArtist();  
   
    async function getUserList() {
      const getuser = await getUser();
      // console.log("getuser" ,getuser)
      setFirstname(getuser.firstname);
    }
    getUserList().catch(console.error);

  },[slug, type ]);

  return (
    <header className="main-header main-header-style3" style={{position: 'unset'}}>
      <div className="header-top-style2">
        <div className="hide_aboutUS">
          <div className="container" style={{ backgroundColor: "white" }}>
            <div className="outer-box clearfix">
              <div className="header-top-left-style2 float-left">
                <ul>
                  <li>
                    <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:13}} href="/about">ABOUT US</a>
                  </li>
                  <li>
                    <a  style={{fontFamily:  "Bell MT Semi Bold",fontSize:13}} href="/contact">CONTACT US</a>
                  </li>
                  <li>
                    <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:13}} href="/howtobuy">HOW TO BUY</a>
                  </li>
                  <li>
                    <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:13}} href="/howtosell">HOW TO SELL</a>
                  </li>
                  <li>
                    <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:13}} href="/faq">FAQ</a>
                  </li>
                </ul>
              </div>
              <div className="header-top-right-style2 float-right" style={{maxWidth:500}}>
         
             
                <div className="login-register-box  ">
           

                 
                {/* <div className="userHeader">
                {token !== "" ? (<div>
                      <a className="userHeader" style={{ textTransform: 'capitalize'}} >
                        Hi, {firstname} <span className="flaticon-user" style={{fontSize: 'large'}}> </span>
                      </a>
                        <div className="userHeader-content1">
                                      <ul className="navigation clearfix" id="navOne">
                                              <li>
                                                  <a>Profile</a>
                                                </li>
                                                <li>
                                                  <a >Favorite</a>
                                                </li>
                                         
                                                  <li>
                                                    <a onClick={ClickLogOut}>Log Out</a>
                                                  </li>
                                               
                                             
                                      </ul>

                        </div>   
                        </div>
                        ): (<a href="/login">Sign In</a>) }
                </div> */}
          
          

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="header-upper">
        <div className="container clearfix" style={{ backgroundColor: "#fff" }}>
          <div className="outer-box clearfix" style={{ height: "100%" }}>
            <div className="header-upper-left clearfix float-left">
              <div className="logo"  >
                <a href="/home" style={{zIndex: 999}}>
                  <img
                    src={Logo}
                    alt="Awesome Logo"
                    title="Leon Gallery"
                    id="logoLeon"
                  />
                </a>
              </div>
            </div>
            <div
            // marginBottom: "-30px",
              style={{  marginTop: 5 }}
              className="header-upper-right float-right clearfix"
            >
              <div className="nav-outer clearfix">
                {/*Main Menu*/}
                <Menu />
                {/*Main Menu End */}
                <div className="menu-right-content">
                  <div className="outer-search-box-style1">
                    <div className="main-menu style4 navbar-expand-lg">
                      <div className="navbar-header">
                        {/* <div className="seach-toggle" onClick={searchbox}>
                          <i className="fa fa-search"></i>
                        </div> */}
                        {/* <ul className="">
                          <li>
                            <div className="form-group">
                              <form onSubmit={handleSearch}>
                                <input
                                  type="search"
                                  name="search"
                                  style={{padding:5}}
                                  onChange={(e) =>
                                    setSearch(e.target.value.trim())
                                  }
                                  placeholder="Search Here"
                                  required
                                />
                                <button type="submit">
                                  <i className="fa fa-search"></i>
                                </button>
                              </form>
                            </div>
                          </li>
                        </ul> */}
                      </div>

                      {/* <div
                         style={{padding:12}}
                          id="navbarSupportedContent"
                          className="iconSearch"
                        >
                      <button  >
                                <i  style={{fontSize:24}} className="fa fa-search"></i>
                           </button>
                    </div> */}

                      <div className="">
                        <div
                          className="navbar-collapse collapse clearfix"
                          id="navbarSupportedContent"
                        >
                          <div
                          className="bbbccc"
                            style={{
                              lineHeight: -0.5,
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip:' padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                              height: 54,  
                              top: "-10px",
                              // width: 265,
                            }}
                    
                          >
                            <form onSubmit={handleSearch}>
                            <div className="innerForm">
                              <input
                                className="cccaaa"
                                type="search"
                                name="search"
                                placeholder="Search Here"
                                required
                                // style={{padding:5}}
                                onChange={(e) =>
                                  setSearch(e.target.value.trim())
                                }
                              />
                              <button type="submit" 
                              className="aaabbb"
                              style={{
                                height: 44,
                                color: '6c757d',
                                // marginLeft: -22,
                                width: 40,
                                // padding: 5,
                     
                                position: 'absolute',
                                borderRadiu: 5,
                                borderBottomRightRadius: 2,
                                borderTopRightRadius: 2,
                                cursor: 'pointer'
                              }} >
                                <i className="fa fa-search"></i>
                              </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <StickyHeader />
    </header>
  );
};

export default Header;
