
import { window } from 'globalthis/implementation';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Page from 'src/components/Page';
import View from "./view"



function index(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const data = params.get('data');

    


    return (
        <Page title="Auctions">
     
            <View />
        </Page>
    );
}

export default index;