import React, {
  useState,
  useEffect,
} from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box, Button, ListItemButton, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { nextAuction, prevAuction } from "../../Model/Auction";
import NoImage from "../../images/resources/noImage.jpg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Center.css";
import Backdrop from "@mui/material/Backdrop";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import UpcomingIcon from '@mui/icons-material/Upcoming';

export default function ListImage({
  socket,
  details,
  lotNumber,
  mediadQuery,
  titleAuction,
}) {
  const [getData, setGetData] = useState([]);
  const [count, setCount] = useState(0);
  const [countNext, setCountNext] = useState(lotNumber + 1);
  const phoneMatch = useMediaQuery("(min-width:1200px)");
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [lot_Number, setLotnumber] = React.useState("");
  const [starting_price, setHp] = React.useState("");


  const handleClickOpen = (row) => {
    setImage(row.img_url)
    setTitle(row.title)
    setArtist(row.artist)
    setLotnumber(row.lot_number)
    setHp(row.starting_price)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNextAuction = () => {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
    //console.log(lotNumber+count)
    return increment();
  };

  const increment = (val) => {

    setLoading(true);
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: count + 1,
        };
       
        const results = await nextAuction(ArrayData);
        setGetData(results);
        setLoading(false);
        if (results.sucess === false) {
          setGetData([]);
        } else {
          setGetData(results);
        }
        // await socket.emit("send_Bid_amount", messageData);
      }
      getNextAuction().catch((error) => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClickPrevAuction = () => {
    setCount(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
    return decrement();
  };

  function decrement() {
    setLoading(true);
    try {
      async function getPrevAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: count - 1,
        };
       
        const results = await prevAuction(ArrayData);
        setLoading(false);
        setGetData(results);
        // await socket.emit("send_Bid_amount", messageData);
      }
      getPrevAuction().catch((error) => {
        setLoading(false);
        // console.log("handleClickPrevAuction: ", error);
      });
    } catch (error) {
      setLoading(false);
      // console.log("handleClickPrevAuction: ", error);
    }
  }

  const listImage = () => {
    try {
      async function getNextAuction() {
        const ArrayData = {
          auction_id: details.auction_details.auction_id,
          setId: details.auction_details.id,
          LotNo: lotNumber + 1,
        };

        const results = await nextAuction(ArrayData);
        setGetData(results);

        if (results.sucess === false) {
          setGetData([]);
        } else {
          setGetData(results);
        }
      }
      getNextAuction().catch((error) => {
        setLoading(false);

        // alert(error)
        // console.log("getNextAuction: ", error);
      });
    } catch (error) {
      console.log("getNextAuction error: ", error);
    }
  };

  useEffect(() => {
    setCount(lotNumber + 1);
    listImage();
    socket.on("receive_status", (data) => {
      if (data.message === "NEXTSLOT") {
        try {
          async function getNextAuction() {
            const ArrayData = {
              auction_id: details.auction_details.auction_id,
              setId: details.auction_details.id,
              LotNo: data.nextCount + 1,
            };

            const results = await nextAuction(ArrayData);
            setGetData(results);

            if (results.sucess === false) {
              setGetData([]);
            } else {
              setGetData(results);
            }
          }
          getNextAuction().catch((error) => {
            setLoading(false);
            // alert(error)
            // console.log("getNextAuction: ", error);
          });
        } catch (error) {
          console.log("getNextAuction error: ", error);
        }
      }
    });

    return () => socket.removeAllListeners();
    /// live/lot/next?auction_id=1&setId=1&lot_number=10
  }, [socket, details, lotNumber, mediadQuery]);

  return (
    <div>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Box
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            display: mediadQuery === false ? "none" : "block",
          }}
        >
          <Typography
            style={{
              padding: 5,
              fontWeight: "bold",
            }}
          >
            {titleAuction}  
          </Typography>
          {/* <div style={{ display: "flex", width: "100%" }}>
          <Typography
            style={{
              fontWeight: "bold",
            }}
          >
            Status
          </Typography>
          <Typography
            style={{
              marginLeft: "25%",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <BlinkedBox style={{ fontSize: 18 }}>{status}</BlinkedBox>
          </Typography>
        </div> */}
          {/* <Button style={{padding: 10,}} size="small">Over View</Button>  */}
        </Box>
        <Divider variant="fullWidth" component="li" />

        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>              
        <Stack direction="row" alignItems="center" gap={1}>
          <UpcomingIcon />
          <Typography variant="body1">Upcoming Lots</Typography>
        </Stack>
  
         
        </div> 
        <Divider variant="fullWidth" component="li" />
        <Box
        style={{
          padding: 10,
          // paddingbottom: 20,
          display: "flex",
          justifyContent: "space-between",
          // marginRight:10,
          // marginLeft:10
        }}
      >
             {count === 1 ? <div></div>:
        <Button onClick={() => handleClickPrevAuction()}>
          {" "}
          <KeyboardArrowLeftIcon /> Previous
        </Button>}
        {getData.length ?   <Button onClick={() => handleClickNextAuction()}>
          Next <KeyboardArrowRightIcon />
        </Button>:
      ""}
      </Box>
        <Box
          style={{
            maxHeight: phoneMatch === true ? "100%" : 500,
            overflowY: "scroll",
          }}
        >
          {getData.length ? (
            getData.sort((a, b) => a.lot_number > b.lot_number).map((row,i) => {
              // console.log('row: ',row)
              let soldItem;
              if (row.lot_status === "UNSOLD") {
                soldItem = <div className="overlay_Unsold">Unsold</div>;
              } else if (row.lot_status === "RESERVE_NOT_MET") {
                soldItem = <div className="overlay_Unsold" style={{paddingTop: 15, fontSize: 20}}>Reserve Not Met</div>;
              } else if (row.lot_status === "SOLD") {
                soldItem = <div className="overlay_sold">Sold</div>;
              } else if (row.lot_status === "PASS") {
                soldItem = <div className="overlay_Unsold">Pass</div>;
              } else {
                soldItem = "";
              }

              return (
                <div key={i}>
                  <Divider variant="fullWidth" component="li" />

                  <ListItem alignItems="flex-start">
                    <ListItemButton onClick={(e) => handleClickOpen(row)} style={{    backgroundColor: i === 0 ?  "rgb(231 231 232)": "" ,borderRadius:5}}>
                    <Box
                      className="centerImage"
                      style={{ margin: "auto", position: "relative" }}
                    >
                      <div
                        style={{
                          height: 80,
                          width: 80,
                          border: "1px solid #ccc",
                        }}
                      >
                        <img
                          className="centerImageOne"
                          alt="Remy Sharp"
                          src={row.img_url === null ? NoImage : row.img_url}
                        />
                      </div>
                      {soldItem}
                    </Box>
                    <ListItemText
                      sx={{
                        paddingLeft: 2,
                        fontSize: 8,
                        // whiteSpace: 'nowrap',
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 200,
                      }}
                      primary={
                        <React.Fragment>
                          <Typography
                            style={{ fontSize: 13 }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                            dangerouslySetInnerHTML={{
                              __html: row.lot_number + ". " + row.artist,
                            }}
                          ></Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            style={{ fontSize: 11 }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                            dangerouslySetInnerHTML={{ __html: row.title }}


                          >
                                                  {/* {item.lot_number + ". " + item.title.replace(/(<([^>]+)>)/ig, '')}  */}
                          </Typography>
                        </React.Fragment>
                      }
                    />              </ListItemButton>
                  </ListItem>
                </div>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                marginLeft: "45%",
                overflowY: "hidden",
                marginTop: 10,
              }}
            >
              No Data
              {/* <CircularProgress /> */}
            </Box>
          )}
        </Box>
        
      </List>
     

      <Dialog
       fullWidth={true}
       maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        <Typography component="div" variant="h5">
        Lot: {lot_Number}
          </Typography>
        
        </DialogTitle>
        <DialogContent>
        <Card sx={{ display: 'flex',boxShadow: 0}}>

      <Box sx={{ display: 'flex',}}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {artist}
          </Typography>
          <Typography
           component="div" variant="h6"
          >
           {title}
          </Typography>
          <Typography 
        
          component="div"
          sx={{ color: 'text.secondary',fontSize:20 }}>
           Starting Price: Php  {starting_price.toLocaleString()}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
       
          {/* <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton>
          <IconButton aria-label="play/pause">
            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          <IconButton aria-label="next">
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton> */}
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: "50%" }}
        image={image === null ? 'https://placehold.co/450x274?text=NO%20IMAGE%20AVAILABLE':image}
        alt="Live from space album cover"
      />
    </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
       <Typography>Loading!...</Typography>
      </Backdrop> 

    </div>
  );
}
