import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function ModelAuction(page,live, exhibition, online) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [live, exhibition, online]);
  

  useEffect(() => { 
    setLoading(true);
    setError(false);
     let cancel;
    axiosInstant({
      method: 'GET',
      url:`auction?page=${page === null ? "" : page}&auction_status=PREVIOUS${
          live === null ? "" : `&category=${live}`
        }${exhibition === null ? "" : `&category=${exhibition}`}${
          online === null ? "" : `&category=${online}`
        }`,
        //params: { q: query, page: pageNumber },
       cancelToken: new axios.CancelToken(c => cancel = c)
      })
      .then((res) => {
        setBooks((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.msg])];
        })
        setHasMore(res.data.msg.length > 0);
        setLoading(false)
      })
      .catch((e) => {
        if (axios.isCancel(e)) return
        setError(true)
      });

    return () => cancel()
  }, [ page,exhibition, live, online]);

  return { books, hasMore, loading, error };
}
