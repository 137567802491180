
import { window } from 'globalthis/implementation';
import React from 'react';
import View from "./view"
import Page from "src/components/Page";
import AuctionBanner from "./AuctionBanner";
function index(props) {
    localStorage.removeItem("@imageSingle");
    return (
        <Page title="Exhibition">
                <AuctionBanner/>
            <View/>
        </Page>
    );
}

export default index;