import { Button } from "@mui/material";
import React from "react";
import HowtoSellImage from "./../../images/resources/sellwidleon.png";
import useMediaQuery from "@mui/material/useMediaQuery";

function HowTosell(props) {  
  const matches = useMediaQuery("(min-width:1000px)");
  const matchesPhone = useMediaQuery("(min-width:480px)");
  return (
    <section className="subscribe-area style3 howSell"  style={{ background: "#f2f2f2", paddingTop: matchesPhone === false ? 25: '' }}>
     <div className={matchesPhone === true ? 'container': ''}>
        <div className={matchesPhone === true ? 'row': ''} style={{ textAlign: "left" }}>
          <div className={matchesPhone === true ? 'col-xl-6': ''}> 
          <div className="sec-title" style={{paddingBottom: 10,paddingLeft: matchesPhone === true ? 0: 15}}> 
            <div className="big-title">
              <h1>Sell with Leon</h1>
            </div>
            </div>
            <div className="welcome-content" >
              <p style={{ fontSize: 18 }} style={{paddingLeft: matchesPhone === true ? 0: 15}}>
                Consign with the Philippines' most trusted auction house.
                <br />
                Request an estimate now and our experts will get back to you
                regarding the value of your items.
              </p>

              <div >
                <Button
                  className="btn"
                  style={{
                    background: "#000000",
                    color: "#ffff",
                    borderRadius:  matchesPhone === true ? 5: 0,
                    width: '100%',
                    height: 50,
                  }}
                  href="/howtosell"
                >
                  Start Selling
                </Button>
              </div>
            </div>
          </div> 
          <div className={matchesPhone === true ? 'col-xl-6': ''}>
            <div
            style={{ marginTop: 50 }}
              className=""
              //   data-aos="fade-left"
              //   data-aos-easing="linear"
              //   data-aos-duration="1500"
            >
              <img
                style={{width: '100%' }}
                src={ HowtoSellImage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowTosell;

{
  /* <section className="contact-form-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="top">
              <div className="sec-title pull-left">
                {/* <p>OUR LATEST NEWS</p> 
                <div className="big-title">
                  <h1>HOW TO SELL </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="howToSell fullwidthbanner-container"
         // style={{display: 'none'}}
        >
         {/* <div className="" style={{marginBottom: 20,padding: 15}} >
            <h2 style={{color: '#fff'}}>Sell with Leon Gallery</h2>
              <p>
                Curious to know if your item is suitable for one of our upcoming
                sales?
                <br />
                 Provide information and share images to request an online
                estimate now.
              </p>
              <div>
                <Button className="btn"  style={{
                          borderColor: "#000",
                          width: "-webkit-fill-available",
                        }}>
                  Start Selling
                </Button>
              </div>
            </div> 

          <div className="grid12-6">
            <img src={'https://sothebys-com.brightspotcdn.com/dims4/default/8d39ab8/2147483647/strip/true/crop/1420x1040+0+0/resize/710x520!/format/webp/quality/90/?url=http%3A%2F%2Fsothebys-brightspot.s3.amazonaws.com%2Fdotcom%2Ff3%2Fb2%2F960d317e43a78f46b41a198ab1c9%2F21693106-sell-with-sothebys-webbanners-1420x1040-banner2.jpg'} />
            <div className="inner_box" >
            <h2 style={{color: '#fff'}}>Sell with Leon Gallery</h2>
              <p>
                Curious to know if your item is suitable for one of our upcoming
                sales?
                <br />
                 Provide information and share images to request an online
                estimate now.
              </p>
              <div>
                <Button className="btn" style={{ background: "#000000" }}>
                  Start Selling
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */
}
