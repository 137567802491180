// import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import React, { useState, useRef, useCallback, useEffect } from "react";
import LotData from "./LotData";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
// import { getFilter} from "../../Model/Auction";

import NoImage from "./../../images/Banner/no_image.jpeg";
import WITHDRAWN_IMAGE from "./../../images/Banner/withdrawn.jpg";
import { makeStyles } from "@mui/styles";
import { storeAuctionLot } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "pink",
      color: "red",
      "&:hover": {
        backgroundColor: "tomato",
      },
    },
  },
  selected: {},
});

export default function Lot(props) {

  
  const matches = useMediaQuery("(min-width:1000px)");
  const matchesphone = useMediaQuery("(max-width:430px)");
  var { slug, searching } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  // const auctionID = localStorage.getItem("items");
  // const [nameID, setNameID] = useState([]);

  const { lotdata, hasMore, loading, error,errorMsg } = LotData(pageNumber, searching);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );

  // console.log("matches=== true ? false:true ", matches);

  // useEffect(() => {
  //   try {
  //     async function getAuctionOne() {
  //       const results = await getFilter(auctionID);

  //       setNameID(results);
  //     }
  //     getAuctionOne().catch(console.error);
  //   } catch (error) { }
  // }, []);

  // const [setname, setSetname] = useState([]);
  // useEffect(() => {
  //   try {
  //     async function getSetName() {
  //       const results = await getFilterSetname();

  //       setSetname(results);
  //     }
  //     getSetName().catch(console.error);
  //   } catch (error) {}
  // }, []);

  // useEffect(() => {
  //   console.log("lotdata: ", lotdata, hasMore, loading, error );
  // });

  const gotSinglePage = (auction_name, title, auction_id, lotNumber) => {
    const dataArray = {
      lot_id: auction_id,
      lotNo: lotNumber,
    };
    storeAuctionLot(dataArray);
    
    window.location.href = `/auction/${auction_id}/${lotNumber}/${auction_name}/${title === null ? "untitle":title.replace(/[^a-zA-Z0-9-_]/g, '')}`;

    // window.location.href = `/auction/${slug}/${title}`;
  };

  return (
    <div>
      <section className="blog-single-area lotArea" style={{width:'100%'}}>
        <div className="container">
          <div class="sec-title" style={{ marginBottom: 20 }}>
            <div class="big-title">
              
              <h2>{lotdata.length === 0  ?  `No results for: ${searching}`: `Results for: ${searching}` }</h2>
            </div>
          </div>
          <div >
          <div className="col"  style={{paddingRight: matchesphone=== false ? 15:0, paddingLeft: matchesphone=== false ? 15:0,  }}>
              <div
                className="row"
                style={{ marginTop: matches === false ? 0 : 0 }}
              >
                {
                  lotdata.map((value, i) => {

                    let STATUS;
              if (lotdata.length === i + 1) {
                
                    if( value.data.isWithdrawn === 1 ){
                      STATUS = 'Lot Withdrawn ';
                    }else if(value.data.hammer_price > 1) {
                      STATUS = 'Sold ';
                    }else if(value.data.lot_status === "SOLD" && value.data.hammer_price > 1) {
                      STATUS = 'Sold ';
                    
                    }else{
                        STATUS = '';
                    }

                  
                      return (
                        <div
                          ref={lastBookElementRef}
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                          key={value}
                          style={{ marginBottom: 25 }}
                        >
                         <a
                              className="lotDisplayCenter"
                            style={{
                              width: '100%',
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            href={`/auction/${value.data.auction_id}/${value.data.lot_number}/${value.data.auction_name}/${value.data.title === null ? "untitle":value.data.title.replace(/[^a-zA-Z0-9-_]/g, '')}`}
                            // onClick={() =>
                            //   gotSinglePage(      
                            //     value.data.auction_name,
                            //     value.data.title,
                            //     value.data.auction_id,
                            //     value.data.lot_number,
                            //     // value.image.length ? value.image[0].img_url:"https://placehold.co/560x500?text=NO%20IMAGE%20AVAILABLE"
                            //     )
                            // }
                          >
                                  <div
                              // className="containerImage zoom"
                              style={{
                                width: "100%",
                                border: "solid 1px #e8e8e8",
                                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div
                                className={
                                  matches === false
                                    ? ""
                                    : "example example-contain"
                                }
                                // style={{
                                //   // maxHeight: 600,
                                //   // width: 200px;
                                //   //  height:500,
                                  
                                //    width: 300,
                                //    height: 200,
                         

                                //   display: 'flex',
                                //   justifyContent: 'center',
                                //   alignItems: 'center'
                                // }}
                              >

<div  className="boxImage" style={{backgroundImage: `url(${  value.data.isWithdrawn === 1 ? WITHDRAWN_IMAGE:
                                    value.image.length
                                      ? value.image[0].img_url
                                      : NoImage})`,  
    backgroundColor: '#ffffff',
    width: '100%',
    height: 470,
    display: 'inline-block',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: 20,
   }}>

                     
                              </div>
                              </div>
                            </div>
                            <div style={{ paddingBottom: 50 }}>
                              
                              <div style={{ fontSize: 18, textAlign: "start" ,lineHeight: 1.6,paddingTop:10}}>
                              

                              <div style={{display: 'flex', justifyContent: 'space-between'}}> 

                        
                    <span className="product_info" dangerouslySetInnerHTML={{ __html:  "Lot "+value.data.lot_number }} >
                    
                      </span>
                        <p
                            style={{
                              marginBottom: 10,
                              fontSize: 12,
                              color: 
                              value.data.auction_status === "UPCOMING"
                                  ? "red"
                                  : "",
                            }}
                          >
                            {value.data.auction_status === "UPCOMING" ? value.data.auction_status: ''}
                          </p>
                        </div>
                               <b style={{fontSize: 15}}>{value.data.auction_name}</b>
                             
                               { value.data.isWithdrawn === 1 ? "": 
                               <div>

                                 {value.data.artist === null ? '':
                                <span
                                  className="product_info title_lot"
                                  style={{
                                    fontWeight: "bold",
                                    display: "inline-block",
                                    width:  "100%",
                                    // height: 100,
                                    overflow: " hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  dangerouslySetInnerHTML={{ __html: value.data.artist }} 
                                >
                                  
                                </span>}
                            

                                {value.data.title === null ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="product_info title_lot" dangerouslySetInnerHTML={{ __html: value.data.title }} >
                                
                                    </span>
                                   
                                  </>
                                )}
                                </div>}
                                <h4 style={{ fontSize: 20,color: '#00000' }}>
                                {value.data.isWithdrawn === 1 ? "" : value.data.hammer_price === 0 ||  value.data.hammer_price === null  ?  
                                   `${STATUS}Php ${value.data.starting_price === null  ? 0:value.data.starting_price.toLocaleString()}`:  `${STATUS}Php ${value.data.hammer_price === null  ? 0:value.data.hammer_price.toLocaleString()}`}
                                </h4>
                                {/* <h3>
                                {"PHP: " +
                                  value.data.starting_price.toLocaleString()}
                              </h3> */}
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    } else {

                      let STATUS;
                      if( value.data.isWithdrawn === 1 ){
                        STATUS = 'Lot Withdrawn ';
                      }else if(value.data.hammer_price > 1) {
                        STATUS = 'Sold ';
                      }else if(value.data.lot_status === "SOLD" && value.data.hammer_price > 1) {
                        STATUS = 'Sold ';
                      
                      }else{
                          STATUS = '';
                      }
  
                      
              
                      return (
                        <div
                              className={matchesphone === false ? 'col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta':'col'} 
                              key={value}
                              style={{ marginBottom: 25, paddingRight: matchesphone=== false ? 15:0, paddingLeft: matchesphone=== false ? 15:0,  }}
                        >
                         <a
                              className="lotDisplayCenter"
                            style={{
                              width: '100%',
                              marginLeft: "auto",
                              marginRight: "auto",
                              color: '#000000'
                            }}

                            href={`/auction/${value.data.auction_id}/${value.data.lot_number}/${value.data.auction_name}/${value.data.title === null ? "untitle":value.data.title.replace(/[^a-zA-Z0-9-_]/g, '')}`}
                            
                            // onClick={() =>
                            //   gotSinglePage(      
                            //     value.data.auction_name,
                            //     value.data.title,
                            //     value.data.auction_id,
                            //     value.data.lot_number,
                            //     // value.image.length ? value.image[0].img_url:"https://placehold.co/560x500?text=NO%20IMAGE%20AVAILABLE"
                            //     )
                            // }
                          >
                                  <div
                              // className="containerImage zoom"
                              style={{
                                width: "100%",
                                border: "solid 1px #e8e8e8",
                                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div
                                // className={
                                //   matches === false
                                //     ? ""
                                //     : "example example-contain"
                                // }
                                // style={{
                                //   // maxHeight: 600,
                                //   // width: 200px;
                            
                                //   width: 300,
                                //   height: 200,
                        
                                  
                                //   display: 'flex',
                                //   justifyContent: 'center',
                                //   alignItems: 'center'
                                // }}
                              >

                                <div  className="boxImage" style={{backgroundImage: `url(${  value.data.isWithdrawn === 1 ? WITHDRAWN_IMAGE:
                                    value.image.length
                                      ? value.image[0].img_url
                                      : NoImage})`,  
                                        backgroundColor: '#ffffff',
                                        width: '100%',
                                        height: 470,
                                        display: 'inline-block',
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center center',
                                        backgroundRepeat: 'no-repeat',
                                        padding: 20,
                                }}>
                                {/* <img
                                  // className="imageCenterPrivate"
                                  src={
                                    value.data.isWithdrawn === 1 ? WITHDRAWN_IMAGE:
                                    value.image.length
                                      ? value.image[0].img_url
                                      : NoImage
                                  }
                                  alt={value.image}
                                  style={{
                                    width: '100%',
                                    // height: '100%',
                                    objectFit: 'cover',
                                   height: matchesphone=== false ? '30vw':'90vw' 
                                  
                                  }}
                                /> */}

                                {/* <h3>View</h3> */}
                              </div>
                              </div>
                            </div>
                            <div style={{ paddingBottom: 50,paddingLeft: matchesphone=== false ? 0:15,paddingRight: matchesphone=== false ? 0:15 }}>
                              
                              <div style={{ fontSize: 16, textAlign: "start" ,lineHeight: 1.5,paddingTop:10, }}>
                              <div style={{display: 'flex', justifyContent: 'space-between'}}> 

                        
                              <span className="product_info" dangerouslySetInnerHTML={{ __html:  "Lot "+value.data.lot_number }} >
                               
                           
                            
                               </span>
                               <p
                                  style={{
                                    marginBottom: 10,
                                    fontSize: 12,
                                    color: 
                                    value.data.auction_status === "UPCOMING"
                                        ? "red"
                                        : "",
                                  }}
                                >
                                  {value.data.auction_status === "UPCOMING" ? value.data.auction_status: ''}
                                </p>
                               </div>
                        
                               <b style={{fontSize: 15}}>{value.data.auction_name}</b>
                             
                               { value.data.isWithdrawn === 1 ? "": <div>
                               {value.data.artist === null ? '':
                                <span
                                  className="product_info title_lot"
                                  style={{
                                    fontWeight: "bold",
                                    display: "inline-block",
                                    width:  "100%",
                                    // height: 100,
                                    overflow: " hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  dangerouslySetInnerHTML={{ __html: value.data.artist }} 
                                >
                                  
                                </span>}
                        
                                {value.data.title === null ? (
                                  ""
                                ) : (
                                  <>
                                    <span className="product_info title_lot" dangerouslySetInnerHTML={{ __html: value.data.title ===  null ? "":value.data.title }} ></span>
                                  </>
                                )}
                                </div>}
                                <span style={{ fontSize: 20 ,color: '#00000'}}>
                                {value.data.isWithdrawn === 1 ? "" : value.data.hammer_price === 0 ||  value.data.hammer_price === null  ?  
                                   `${STATUS}Php: ${value.data.starting_price === null  ? 0:value.data.starting_price.toLocaleString()}`:  `${STATUS}Php: ${value.data.hammer_price === null  ? 0:value.data.hammer_price.toLocaleString()}`}
                                </span>
                                {/* <h3>
                                {"PHP: " +
                                  value.data.starting_price.toLocaleString()}
                              </h3> */}
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    }
                  })
                }
              </div>

              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "10%",
                  // paddingTop: 50,
                  marginBottom: 50,
                }}
              >
                <div>{}</div>
                {/* {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}*/}
                {/* <div>{loading &&   <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>}</div> */}
                {error ? (
                  <p></p>
                ) : loading ? (
                  <div style={{
                    width: "100%",
                    // padding: 100,
                    display: "flex",
                    justifyContent: "center",
                  }} >
                  <Box sx={{ display: "flex", position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "2px",
                        fontSize: 12,
                      }}
                    >
                      Loading
                    </span>
                    <CircularProgress size="3rem" />
                  </Box>
                  </div>
                ) : (
                ""
                )}
              </div>
            {/* {error === true ? (<div 
              style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "100%",
                  paddingTop: 50,
                  // paddingBottom: 50,
                }}>{lotdata.length === 0  ? `No results for: ${searching}`:"" }
                {error}
                </div>) : " " } */}
            </div>
          </div>
        </div>
      </section>

      {/* {isLoading && (
        <Box sx={{ display: "flex"}}>
          <CircularProgress />
        </Box>
      )} */}
    </div>
  );
}
