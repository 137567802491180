import React from 'react';
import AuctionBanner from "./AuctionBanner";
// import UpcomingAuction from "../auction/UpcomingAuction";
// import Product from "../products/"
import Auctions from "./Auctions"
import Page from "src/components/Page";
function Index(props) {
    return (
        <Page title="Past Auctions Event">
                  <AuctionBanner/>
                  {/* <Product/> */}
                  <Auctions/>
                  {/* <UpcomingAuction/> */}
        </Page>
    );
}

export default Index;


