import axiosInstant from "../../axiosInstant";



export async function auctionsLive() {
  const response = await axiosInstant 
  //declarator/current
    .get(`declarator/current`) 
    .then((res) => {
      // //console.log('ressssss data  dot', res)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}
 
export async function postStatus(data) {
  // //console.log('model auction: ',exhibition)
  
  const response = await axiosInstant
    .post(`declarator/lot/update_status`, data)
    .then((res) => {
      //  //console.log('res.data.lot.declarator: ',res.data)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}


export async function floorBidPost(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/bid/floor`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}


export async function phoneBid(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/bid/phone`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}



export async function hallBid(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/bid/hall`, data)
    .then((res) => {
      return res.data;
    }).catch((error) => {
      return error.response.data;
    });
    // controller.abort()
  return response;
}



export async function postUndo(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/lot/undo`, data)
    .then((res) => {
    //console.log('res.data.lot.postUndo: ',res.data)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}




export async function declaratorNextImage(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .get(`live/lot/next?auction_id=${data.auction_id}&setId=${data.setId}&lot_number=${data.LotNo}`)
    .then((res) => {
      //  //console.log('res.data.lot.declarator: ',res.data)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}



export async function nextLot(data) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/lot/next_lot`, data)
    .then((res) => {
      //  //console.log('res.data.lot.declarator: ',res.data)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}


export async function postMessage(message) {
  // //console.log('model auction: ',exhibition)
  const response = await axiosInstant
    .post(`declarator/announcement`, {msgs:message })
    .then((res) => {
      //  //console.log('res.data.lot.declarator: ',res.data)
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
  return response;
}


export async function nextAuction(LiveData, data) {
  // //console.log("nextAuction: ", LiveData.lot.details[0].lot_number);
//  //console.log("nextAuction: data ",LiveData);
  const response = await axiosInstant
    .get(
      `declarator/lot/next?auction_id=${LiveData.auction_details.auction_id}&setId=${
        LiveData.auction_details.id
      }&lot_number=${
        data === undefined ? parseInt(LiveData.lot.details[0].lot_number)+1 : data.nextCount+1
      }`
    )
    .then((res) => {
      // //console.log('res.data.lot.declarator: ',res.data)
      return res.data;
    })
    .catch((error) => {
      // //console.log('res.data.lot.declarator: ',error.response.data)
      return error.response.data;
    });
  return response;
}
