import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import PDFDOWNLOAD from './../../pdf/TKTA_2023.pdf';
import AboutUSs from "./../../images/resources/howtobuy.png";
import { getUpcommingAuction } from "../../Model/Auction"
const About = (props) => {

  const [cataglog, setCatalog] = useState('')
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')

  useEffect(() => {
    try {
      async function getAuctionOne() {
        const results = await getUpcommingAuction();
        // results.map((val) => {

        //     setCatalog(val.cover)
        // })
        // "date_start": "2023-12-02T00:00:00.000Z",
        // "date_end": "2023-12-02T00:00:00.000Z",


        setCatalog(results[0].cover)
        setTitle(results[0].d_name)
        setDetails(results[0].d_desc)
      }
      getAuctionOne().catch(console.error);
    } catch (error) { }
  }, []);


  return (
    <div className="boxed_wrapper">
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${AboutUSs})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>How To Buy</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 60 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                      How to Buy
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-style1-area">
        <div className="container">
          <div className="row">
    
            <div className="col-xl-12 col-lg-6" style={{background:'#fbf9f4', padding: 50  }}>
              <div className="about-style1-content">
                <div class="">
                  <div class="big-title">
                    <h1 class="blog-title">How To Buy</h1>
                  </div>
                </div>
                {/* <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr> */}
                <div className="text para">
                  <h3 class="blog-title">Browse</h3>
            
                  <p class="mar-bottom">
                    Stay up to date with upcoming sales by visiting our website
                    or downloading our PDF catalogue to learn more about the
                    pieces, track upcoming sales, or even spot new work. You may
                    also take part in pre-auction viewings which typically take
                    place a few days prior to the auction.
                  </p>
                </div>
              </div>
              {/* <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr> */}
              <div className="about-style1-content">
           
             
              
                <div className="text para">
                <h3 class="blog-title">Register and Bid</h3>
                  <p class="mar-bottom">
                    If you prefer to bid in person, you will need to register in
                    advance. Simply bring a valid ID and collect your paddle.
                    New clients are asked to register at least one week in
                    advance to allow us enough time to get everything in order
                    for you.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-6  col-lg-6" style={{background:'rgb(36 36 36)',padding: 50 }}>
              <div className="about-style1-left-content clearfix" style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
                <div className="">
                  <img
                    style={{ width: '100%' }}
                    src="https://leon-gallery.com/assets/img/buy/HOW-TO-SELL-BUY1.jpg"
                    alt="Awesome"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>


      


      <section className="google-map-area" style={{ paddingTop: "50px", paddingBottom: 100}}>
        <div className="container">
          <div className="" style={{ backgroundColor: "#0000" ,marginTop: 20 }}>
            <div className="big-title">
              <h3>Online</h3>
              <div className=" para">
                Experience the thrill from the comfort of your own home. Those
                who would like to bid online may register at <a href="https://www.leonexchange.com/en/auctions">LeonExchange.com</a>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
           


            </div>
          </div>

          <div className="" style={{ backgroundColor: "#0000",marginTop: 20 }}>
            <div className="big-title">
              <h3>By Telephone</h3>
              <div className=" para">
                Experience the thrill from the comfort of your own home. Those
                who would like to bid online may register at <a href="https://www.leonexchange.com/en/auctions">LeonExchange.com</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {/* <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr> */}

            </div>
          </div>

          <div className="" style={{ backgroundColor: "#0000",marginTop: 20  }}>
            <div className="big-title">
              <h3>Absentee Bid</h3>
              <div className=" para">

                If you are unable to come in to bid in person, online, or on the
                phone, you may submit your highest bid using one of our León
                Gallery Absentee forms.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {/* <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr> */}

            </div>
          </div>

          <div className="" style={{ backgroundColor: "#0000",marginTop: 20  }}>
            <div className="big-title">
              <h3>Online Auction</h3>
              <div className=" para">
                León Gallery offers sales which are only available online and
                typically have a 2-week bidding window. You may check out more
                information and keep up with these auctions through our website.
                <a href="/auctions">View Auction</a>
              </div>
            </div> 
          </div>
          <div className="row" >
            <div className="col-xl-12">
              {/* <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }}></hr> */}

            </div>
          </div>
        </div>
      </section>

      {/* <section class="about-style1-area" style={{ paddingBottom: 100 }}>
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="about-style1-left-content clearfix" style={{ marginLeft: 'auto',
                marginRight: 'auto',}}>
                <div class="img-box">
                  <img src={cataglog} alt="Awesome Image" />
                </div>
              </div>
            </div>
            <div className="col-xl-6  col-lg-6" style={{ padding: 50  }}>
              <div class="about-style1-content">
                <div class="sec-title">
                  <p>Catalog</p>
                  <div class="big-title"><h1>{title}</h1></div>
                </div>
                <div class="inner-content">
                  <h3>{details}</h3>
                  <div class="text"></div>
                  <div class="bottom-box">
                    <div class="button">
                      <a class="btn-one" href={PDFDOWNLOAD} download="" >Download</a>
                    </div>
                    {/* <div class="phone-number">
                                <div class="icon"><span class="flaticon-phone-1"></span></div>
                                <div class="title">
                                    <span>Tell Us:</span>
                                    <h1><a href="tel:16234569400">1800 123 4659</a></h1>
                                </div>
                            </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section > */}
      
    </div>
  );
};

export default About;
