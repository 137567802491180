import React, { useState, useRef, useCallback, useEffect } from "react";
import ModeAuction from "./ModeAuction";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Divider, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import NoImage from "./../../images/Banner/no_image.jpeg";
import { Link } from "react-router-dom";
import { international } from "../../Model/Auction";
export default function Auction() {
  const matches = useMediaQuery("(min-width:1000px)");
  const [live, setLive] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [online, setOnline] = useState(null);
  const [books, setBooks] = useState([]);
  const [auctionstatus, setAuctionstatus] = useState('');
  const [loading1,setLoading] = useState(false)



  const observer = useRef();
  
  // function handleSearch(e) {
  //   setQuery(e.target.value)
  //   setPageNumber(1)
  // }

  
  const handleUpcomming = (searchValue) => {
    var ssss = searchValue === 'All' ? '':searchValue
        setAuctionstatus(ssss);
        setPageNumber(1);

    return FetchData(ssss)
  };

 
  const  FetchData =  (auction_tatus) => {
    try {
      setLoading(true)
      async function getAuctionOne() {
        const results = await international(auction_tatus);
       
        if(results.length){
          setBooks(results);
        }else{
          setBooks([]);
        }
     
        setLoading(false)
        
      }
      getAuctionOne().catch(console.error);
    } catch (error) {
      setLoading(false)
    }
  };

useEffect(() => {
  setAuctionstatus('');
  FetchData()
}, []);





  const GotoPage = (id, slug, type, imageCover) => {
    // localStorage.setItem("items", id);
    // localStorage.setItem("cover", imageCover);
    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${id}/${slug}/${type}`;
    } else {
      window.location.href = `/auction/lot/${id}/${slug}/${type}`;
    }
  };



  return (
    <div>
      <section className="blog-single-area" style={{paddingTop: 30}}>
        <div className="container">
        <div className="row">
            <div className="col-xl-12">
        <div class="visit-day-select">
       { matches === true ?  <h1>Exhibition</h1>: <h3>Exhibition</h3>}


            <div class="sec-title text-center" style={{ paddingTop: matches === true ? 30 :0 }}>

              <div style={{width: '100%', display: 'flex', justifyContent:  matches === true ? 'start' :'space-between'}}>
              <div className="view-more-button">
                  <Link 
                  onClick={(e) => handleUpcomming("All")}

                   style={{marginRight: matches === true ? 15 :'', fontSize: 'large', textDecoration: auctionstatus === '' ? 'underline':'',color: '#212529',textUnderlineOffset: 10}}>
                  Show all
                  </Link>
                </div>

              <div className="view-more-button">
                  <Link 
                  onClick={(e) => handleUpcomming("UPCOMING")}
                   style={{marginRight: matches === true ? 15 :'',fontSize: 'large',textDecoration: auctionstatus === 'UPCOMING' ? 'underline':'' ,color: '#212529',textUnderlineOffset: 10}}>
                   Ongoing
                  </Link>
                </div>
              

                

                <div className="view-more-button">
                  <Link 
                     onClick={(e) => handleUpcomming("PREVIOUS")}
                    style={{marginRight: matches === true ? 15 :'',fontSize: 'large',textDecoration: auctionstatus === 'PREVIOUS' ? 'underline':'' ,color: '#212529',textUnderlineOffset: 10}}
              
                   >
                    Previous
                  </Link>
           
                </div>
              </div>
            </div>
          </div>
       
              <div
                className="row items-container clearfix"
                style={{ marginTop: matches === false ? 20: "" }}
              
              >
                {/* <input type="text" value={live} onChange={handleSearch}></input> */}
            {
                books.map((value, i) => {
                  var options = {
                    // weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var dateStart = new Date(value.date_start);
                  var dateEnd = new Date(value.date_end);
                  //                   const d = new Date("July 21, 1983 01:15:00");
                  // let day = dateStart.getDay();

                  var options = {
                    // weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
            
           

                  if (books.length === i + 1) {
                    return (
                      <div
                        //  ref={lastBookElementRef}
                        className="col-xl-3 zoom" 
                        key={value}
                        style={{margin: 10, 
                        //   border: "solid 1px #e8e8e8",
                        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                        }}>
                          
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            GotoPage(
                              value.id,
                              value.c_slug,
                              value.c_type,
                              value.cover
                            )
                          }
                        >
                          <div
                            className="containerImage bg-image hover-zoom"
                            style={{ height: value.cover === null ? 468 : "" }}
                          >

<div   style={{backgroundImage: `url(${ value.cover === null ? NoImage : value.cover})`,  
                              backgroundColor: '#ffffff',
                              width: '100%',
                              height: 400,
                              display: 'inline-block',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              padding: 10,
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                            }}></div> 


                            
                            {/* <img
                              className="imageCenter"
                              src={value.cover === null ? NoImage : value.cover}
                              alt="Awesome"
                              // style={{ paddingTop: 40 }}
                            /> */}
                          </div>
                          <div className="text" style={{ textAlign: "left" }}>
                            {/* <p style={{ marginBottom: 10, fontSize: 12 }}>
                              {value.c_type.replace("_", " ")}
                            </p> */}


<p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.auction_status}
                            </p>
                            <p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.d_desc}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                fontSize: 20,
                              }}
                            >
                              {dateStart.toLocaleDateString("en-US", options)}  -  {dateEnd.toLocaleDateString("en-US", options)} 
                            </p>
                          </div>
                        </a>
                      </div>
                    );
                    // <div ref={lastBookElementRef} key={book}>{book}</div>
                  } else {
                    return (
                      <div className="col-xl-3 zoom" 
                      key={value}
                      style={{margin: 10, 
                      //   border: "solid 1px #e8e8e8",
                      // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                      }}>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            GotoPage(
                              value.id,
                              value.c_slug,
                              value.c_type,
                              value.cover
                            )
                          }
                        >
                          <div
                            className="containerImage bg-image hover-zoom"
                            style={{ height: value.cover === null ? 468 : "", }}
                          >
                            <div   style={{backgroundImage: `url(${ value.cover === null ? NoImage : value.cover})`,  
                              backgroundColor: '#ffffff',
                              width: '100%',
                              height: 400,
                              display: 'inline-block',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              padding: 10,
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                            }}></div> 

                            {/* <img
                              className="imageCenter"
                              src={value.cover === null ? NoImage : value.cover}
                              alt="Awesome"
                              // style={{ paddingTop: 40 }}
                            /> */}
                          </div>
                          <div className="text" style={{ textAlign: "left" }}>
                            {/* <p style={{ marginBottom: 10, fontSize: 12 }}>
                              {value.c_type.replace("_", " ")}
                            </p> */}


<p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.auction_status}
                            </p>
                            <p
                              className="title"
                              style={{ marginBottom: 0, fontSize: 20 }}
                            >
                              {value.d_desc}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                                fontSize: 20,
                              }}
                            >
                           {dateStart.toLocaleDateString("en-US", options)}  -  {dateEnd.toLocaleDateString("en-US", options)} 
                            </p>
                          </div>
                        </a>
                      </div>
                    );
                  }
                })
              }

                {/* <div>{error && "Error"}</div> */}
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "10%",
                  paddingBottom: 50,
                  paddingTop: 50,
                }}
              >
                
                {/* <div
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    width: "100%",
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  No Record Found
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
