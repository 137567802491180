import React, { useCallback, useEffect, useState } from "react";
import "./product.css";
import { getSingaleData } from "../../Model/SinglePage";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import { getUser } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getFilter,
  getPlaceAmount,
  postBid,
  getPreview,
} from "../../Model/Auction";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getToken } from "../../Model/User";
import Button from "@mui/material/Button";
import { storeAuctionLot, storeMetadetails } from "../../Model/LocalStorage";
import {
  Box,
  DialogContentText,
  Divider,
  List,
  setRef,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";
import WITHDRAWN_IMAGE from "./../../images/Banner/withdrawn.jpg";
import { useNavigate } from "react-router-dom";

function View() {
  const matches = useMediaQuery("(min-width:992px)");
  const phoneWidth = useMediaQuery("(max-width:1200px)");
  const phoneWidthsss = useMediaQuery("(max-width:480px)");
  const navigate = useNavigate();
  const token = getToken();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  const { id, lotNo, slug } = useParams();

  const [previous, setPrevious] = useState(0);
  const [next, setNext] = useState(2);
  const [LotStatus, setLot_status] = React.useState("");
  const [idSet, setId] = React.useState("");
  const [group, setGroup] = React.useState("");
  // const [image2, setImage2] = React.useState("");
  // const [image3, setImage3] = React.useState("");
  // const [image4, setImage4] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [artist, setArtist] = React.useState("");
  const [size, setSize] = React.useState("");
  const [medium, setMedium] = React.useState("");
  const [lotHtml, setLotHtml] = React.useState("");
  const [startingPrice, setStartingPrice] = React.useState("");
  const [signedDated, setSignDate] = React.useState("");
  const [coa, setCoa] = React.useState("");
  const [hp, setHp] = React.useState(null);
  const [provenance, setProvinance] = React.useState("");
  const [literature, setLiterature] = React.useState("");
  const [write_up, setWriteUp] = React.useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImage1, setSelectedImage1] = useState("");
  // const id = localStorage.getItem("items_single");
  const items = JSON.parse(localStorage.getItem("@storeAuctionLot"));
  const [imageData, setImageData] = React.useState([]);
  const [lotNumber, setLotNumber] = useState(items === null ? 0 : lotNo);
  const [acceptBid, setAcceptBid] = useState(0);
  const [amountBid, setPlaceAmount1] = useState(null);
  const [property, setProperty] = useState("");
  const [exhibited, setExhibited] = useState("");
  const [bidHistory, setBidHistory] = React.useState([]);
  const [tokenExpire, setTokenExpired] = useState("");
  const [message, setMessage] = useState("");
  const [openNotif, setOpenNotif] = React.useState(false);
  const [auctionId, setAuctionID] = useState("");
  const [bidderCount, setBidder] = useState(0);
  const [buyerPremium, setbuyerPremium] = useState(null);
  const [isWidraw, setIsWidraw] = useState(0);
  const [current, setCurrentBid] = useState(null);
  const [incrementData, setIncrementData] = useState("");
  const [requiredAmount, setRequireAmount] = useState(false);

  const [zoom, setZoom] = React.useState(false);

  const filter = createFilterOptions();

  const FetchData = async (lotID, lotNumber) => {

    setLoading(true);
    async function getData() {
      const results = await getSingaleData(id, lotNumber);
      if (results.msg === "no record found") {
        setLoading(false);
        setOpenNotif(true);
        setMessage("No Record Found!");
      }
      const values = await getFilter(results.msg.data.auction_id);
      setAcceptBid(values[0].accept_bid);
      setBidHistory(results.bid_history);
      setAuctionID(results.msg.data.auction_id);
      setId(results.msg.data.set_id);
      setPrevious(results.prev);
      setNext(results.next);
      setTitle(results.msg.data.title);
      setArtist(results.msg.data.artist);
      setProperty(results.msg.data.property);
      setSize(results.msg.data.size);
      setMedium(results.msg.data.medium);
      setCoa(results.msg.data.coa);
      setSignDate(results.msg.data.signed_dated);
      setStartingPrice(results.msg.data.starting_price);
      setLotHtml(results.msg.data.lot_details_html);
      setExhibited(results.msg.data.exhibited);
      setIsWidraw(results.msg.data.isWithdrawn);
      setHp(
        results.msg.data.hammer_price === null || 0
          ? results.msg.data.hp
          : results.msg.data.hammer_price
      );
      setLotNumber(results.msg.data.lot_number);
      setProvinance(results.msg.data.provenance);
      setLiterature(results.msg.data.literature);
      setWriteUp(results.msg.data.write_up);
      setbuyerPremium(results.msg.data.bp);
      setBidder(results.count_bid);
      setCurrentBid(results.msg.data.hp);

      let STATUS;

      if (results.msg.data.isWithdrawn === 1) {
        STATUS = "Withdraw";
      } else if (results.msg.data.hammer_price > 1) {
        STATUS = "Sold";
      } else if (
        results.msg.data.lot_status === "SOLD" &&
        results.msg.data.hammer_price > 1
      ) {
        STATUS = "Sold";
      } else {
        STATUS = "Starting";
      }

      setLot_status(STATUS);
      //   console.log(results.msg.image)
      setImageData(results.msg.image);
      // console.log(results.increment);
      setIncrementData(results.increment);
      setSelectedImage(
        results.msg.image.length
          ? results.msg.image[0].img_url
          : "https://placehold.co/600x400/EEE/31343C?font=lora&text=No%20Image%20Available"
      );

    
      setSelectedImage1(
        results.msg.image.length
          ? results.msg.image[0].img_url
          : "https://placehold.co/600x400/EEE/31343C?font=lora&text=No%20Image%20Available"
      );

      setTokenExpired(results.tokenExpired);
      setLoading(false);
      if (results.tokenExpired === 1) {
        localStorage.removeItem("@storage_Key");
        localStorage.removeItem("token");
      }
      // console.log( results.bid_history.map((val)=> group === "ADMIN" ? val.email:val.myBidAmount))
      if (values[0].accept_bid === 1) {
        const response = await getPlaceAmount(
          results.msg.data.auction_id,
          results.msg.data.set_id,
          results.msg.data.lot_number
        );

        // console.log("place bid: ", response.amount);
        setPlaceAmount1(response.amount);

        // lot/place_amount?auction_id=1&set_id=1&lot_number=1
      } else {
        setLoading(false);
      }
    }
    getData().catch(console.error);

    // window.location.reload()
  };

  // async function callMetaData  (dataSend){
  //   await fetch(`http://localhost:8081/meta`, {
  //     method: "POST",
  //     body: JSON.stringify(dataSend),
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => response.json())

  //     .then((data) => {

  //     })
  //     .catch((error) => {
  //       // alert.error(error)
  //       console.log("error ",error);

  //     });
  // }
  // const [token, setToken] = useState(() => {
  //   // getting stored value
  //   const saved = localStorage.getItem("token");
  //   const initialValue = saved;
  //   return initialValue || "";
  // });

  const handlepostBid = () => {
    setOpen(false);
    setLoading(true);
    //   var datatext = amountBid === null ? 0:amountBid.replace(/Php/g, "")
    //  var datatext1 = amountBid === null ? 0:parseInt(datatext.replace(/,/g, ''), 10)
    // console.log("amountBid ;",amountBid)
    // console.log(startingPrice)
    async function handlepostBiddd() {
      const Data = {
        auction_id: auctionId,
        setId: idSet,
        lot_number: lotNumber,
        amount:
          amountBid === null ? parseInt(startingPrice) : parseInt(amountBid),
      };
      console.log(parseInt(startingPrice));
      //setError(false)
      const results = await postBid(Data);
      setLoading(false);
      if (results.success === false) {
        var errorr =
          results.possible_increment === undefined
            ? results.total_amount
            : results.possible_increment;
        var messag = results.msg + "\npossible Increment is " + errorr;
        setOpenNotif(true);
        setMessage(messag);
      } else {
        setOpenNotif(true);
        setMessage(results.msg);
        return FetchData(id, lotNo);
        // window.location.reload();
      }
    }

    handlepostBiddd().catch(console.error);
  };

  // const [countDown, setCountDown] = useState("")
  // const [expireCount, setExpiredCount] = useState(false)

  const previousButton = () => {
    const dataArray = {
      lot_id: id,
      lotNo: parseInt(lotNumber) - 1,
      // setDetails: idSet,
      // imageSingle: imageData[0].img_url,
      // title:title,
      // slug:slug
    };
    storeAuctionLot(dataArray);
    setPlaceAmount1(null);

    FetchData(id, parseInt(lotNumber) - 1);
    navigate(
      `/auction/${id}/${parseInt(lotNumber) - 1}/${slug}/${
        title === null ? "untitle" : title.replace(/[^a-zA-Z0-9-_]/g, "")
      }`
    );

    // return FetchData(id, parseInt(lotNumber) - 1);
  };

  const nextButton = async () => {
    const dataArray = {
      lot_id: id,
      lotNo: parseInt(lotNumber) + 1,
      // setDetails: idSet,
      // imageSingle: imageData[0].img_url,
      // title:title,
      // slug:slug
    };

    storeAuctionLot(dataArray);
    setPlaceAmount1(null);
    FetchData(id, parseInt(lotNumber) + 1);
    navigate(
      `/auction/${id}/${parseInt(lotNumber) + 1}/${slug}/${
        title === null ? "untitle" : title.replace(/[^a-zA-Z0-9-_]/g, "")
      }`
    );
  };

  // let dataSend = {
  //   title: title,
  //   discription: slug,
  //   lotNo: parseInt(lotNumber) +1,
  //   imagUrl: `https://leongallery.sgp1.digitaloceanspaces.com/v2/auction/88/115/lot/${parseInt(lotNumber) +1}.jpg`
  // };

  // await fetch(`https://leon-gallery.com/meta`, {
  //   method: "POST",
  //   body: JSON.stringify(dataSend),
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  // })
  //   .then((response) => response.json())

  //   .then((data) => {

  //     console.log("data send here ",data);
  //   })
  //   .catch((error) => {
  //     // alert.error(error)
  //     console.log("error ",error);

  //   });

  const [readmoretext, setReadmoreText] = useState(false);
  const Readmore = () => {
    setReadmoreText(!readmoretext);
  };

  const gotoLogin = () => {
    window.location.href = `/login`;
  };

  const [open, setOpen] = React.useState(false);
  const [preview, setPreviewData] = React.useState([]);

  const [total, setTotal] = React.useState("");
  const [buyersPremium, setBuyerpremium] = React.useState("");
  const [bp, setBp] = React.useState("");

  // const handleClickOpen = () => {
  //   setOpen(true);
  // }

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotif(false);
  };

  const handleClickOpen = () => {
    setLoading(true);

    var datatext = amountBid === null ? 0 : amountBid.replace(/Php/g, "");
    var datatext1 =
      amountBid === null ? 0 : parseInt(datatext.replace(/,/g, ""), 10);
    // console.log("amountBid ", parseInt(datatext.replace(/,/g, ''), 10))
    // alert(amountBid)
    if (amountBid === null) {
      setLoading(false);
      setRequireAmount(true);
    } else {
      setRequireAmount(false);

      if (datatext1 % 1000 !== 0) {
        setOpenNotif(true);
        setMessage("Invalid Amount");
        setLoading(false);
      } else {
        setPlaceAmount1(datatext1);
        async function functiionPreview() {
          const Data = {
            auction_id: id,
            amount: amountBid === null ? startingPrice : datatext1,
            lotNumber: lotNo,
          };

          // setError(false)
          const results = await getPreview(Data);
          setLoading(false);
          if (results.sucess === false) {
            setOpenNotif(true);
            setMessage(results.msg);
            //window.location.reload();
            // alert(results.msg);
          } else {
            results.data.map((val) => {
              setTotal(val.total);
              setBuyerpremium(val.buyers_premium);
              setBp(val.bp);
            });

            setOpen(true);
          }
        }
        functiionPreview().catch(console.error);
      }
    }
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setZoom(false);
    setOpen(false);
  };

  useEffect(() => {
    FetchData(id, lotNo);

    async function getUserList() {
      const getuser = await getUser();
      setGroup(getuser.group);
    }
    // window.location.reload()
    getUserList().catch(console.error);
  }, [slug]);

  // const handleChange1 = async (e) => {
  //   e.preventDefault();
  //   setPlaceAmount1(e.target.value);
  // };

  const [detailsShow, setDetailShow] = useState(false);
  const DetailsFunction = () => {
    setDetailShow(!detailsShow);
  };

  const [provinanceShow, setProvinanceShow] = useState(false);
  const ProvinanceFunction = () => {
    setProvinanceShow(!provinanceShow);
  };

  const [exhibitedShow, setExhibitedShow] = useState(false);
  const ExhibitedFunction = () => {
    setExhibitedShow(!exhibitedShow);
  };

  const [writeupShow, setWriteUpShow] = useState(false);
  const WriteupFunction = () => {
    setWriteUpShow(!writeupShow);
  };

  const [literatureShow, setLiteratureShow] = useState(false);
  const literatureFunction = () => {
    setLiteratureShow(!literatureShow);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const [bottomScroll, setBottomScroll] = useState("");
  // const [scrollPositionTrue, setScrollPositionTrue] = useState('');

  const handleScroll = (e) => {
    const position = window.pageYOffset;
    //  console.log(  (document.documentElement.scrollHeight - document.documentElement.clientHeight ) / 2)
    //  console.log( position)
    //  console.log(window.pageYOffset)
    // var dadada = 376.5 - document.documentElement.scrollHeight;

    var testdd =
      (document.documentElement.scrollHeight -
        document.documentElement.clientHeight) /
      2;
    console.log(testdd);
    setBottomScroll(testdd);
    //  setScrollPositionTrue( Math.ceil(document.documentElement.scrollHeight - document.documentElement.scrollTop) === document.documentElement.clientHeight )

    setScrollPosition(position);
  };

  useEffect(() => {


    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [imgsLoaded, setImgsLoaded] = useState(false);

  return (
    <div style={{ padding: 11, paddingTop: 30 }}>
      <div className="container">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/home" style={{ color: "black" }}>
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/auctions" style={{ color: "black" }}>
                Auction
              </a>
            </li>
            <li
              className="breadcrumb-item"
              style={{ display: phoneWidthsss === true ? "none" : "block" }}
            >
              <a onClick={() => navigate(-1)} style={{ color: "black" }}>
                {slug}
              </a>
            </li>

            <li
              className="breadcrumb-item"
              style={{
                maxWidth: "50ch",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: phoneWidthsss === true ? "none" : "block",
              }}
            >
              {title === null ? "" : title.replace(/(<([^>]+)>)/gi, "")}{" "}
            </li>
          </ol>
        </nav>
        {/* {data.map((val) => {
            console.log(val.img_url)
          return (
            <> */}
        <div className="container-fluid">
          <div className="row" style={{ paddingTop: 40 }}>
            <div
              style={{ display: imageData.slice(1).length ? "block" : "none" }}
              className={`${
                imageData.slice(1).length ? "col-lg-2" : "col-lg-2"
              } order-lg-1 order-2`}
            >
              {matches === false ? (
                <ul
                  class="images"
                  style={{
                    marginTop: 10,
                    width: matches === false ? "100%" : "",
                  }}
                >
                  <li
                    class="loc-caption"
                    title="location 1"
                    // style={{ margin: 5 }}
                  >

<div   onClick={() => setSelectedImage1(selectedImage)} style={{backgroundImage: `url(${selectedImage})`,  
                backgroundColor: '#ffffff',
                width: '100%',
                height:  '100%',
                display: 'inline-block',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                padding: 10,
                cursor: "pointer"
              }}>     </div>

                    {/* <img
                      aria-hidden="true"
                      fetchpriority="high"
                      //   style={{ maxwidth : matches === true ? '45%':'95%'}}
                      onClick={() => setSelectedImage1(selectedImage)}
                      src={selectedImage}
                      alt={"image1"}
                      style={{ cursor: "pointer" }}
                    /> */}
                  </li>
                  {imageData.slice(1).map((row, i) => {
                    return (
                      <li
                        key={i}
                        class="loc-caption"
                        title="location 1"
                        style={{ margin: 5, cursor: "pointer" }}
                      >


<div     onClick={() => setSelectedImage1(row.img_url)} style={{backgroundImage: `url(${row.img_url})`,  
                backgroundColor: '#ffffff',
                width: '100%',
                height:  '100%',
                display: 'inline-block',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                padding: 10,
                cursor: "pointer"
              }}>     </div>

                        {/* <img
                          aria-hidden="true"
                          fetchpriority="high"
                          d="loc1"
                          onClick={() => setSelectedImage1(row.img_url)}
                          src={row.img_url}
                          alt={i}
                        /> */}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul
                  className="image_list"
                  style={{ overflow: "auto", maxHeight: 680 }}
                >
                  <li style={{ paddingTop: 10 }}>
                    <img
                      aria-hidden="true"
                      fetchpriority="high"
                      alt={"image2"}
                      onClick={() => setSelectedImage1(selectedImage)}
                      src={selectedImage}
                      style={{ cursor: "pointer" }}
                    />
                  </li>

                  {imageData.slice(1).map((row, i) => {
                    return (
                      <li style={{ paddingTop: 10 }}>

            <div     onClick={() => setSelectedImage1(row.img_url)} style={{backgroundImage: `url(${row.img_url})`,  
                            backgroundColor: '#ffffff',
                            width: '100%',
                            height:  '100%',
                            display: 'inline-block',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            padding: 10,
                            cursor: "pointer"
                          }}>     </div>

                        {/* <img
                          aria-hidden="true"
                          fetchpriority="high"
                          //   style={{ maxwidth : matches === true ? '45%':'95%'}}
                          onClick={() => setSelectedImage1(row.img_url)}
                          src={row.img_url}
                          style={{ cursor: "pointer" }}
                          alt={i}
                        /> */}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <div
              style={{
                maxHeight: 700,
                paddingRight: phoneWidthsss === false ? 15 : 0,
                paddingLeft: phoneWidthsss === false ? 15 : 0,
              }}
              className={`${
                imageData.slice(1).length
                  ? "col order-lg-2"
                  : phoneWidthsss === false
                  ? "col-xl-8 order-lg-2"
                  : "col"
              } `}
            >
              <div
                // className="image_selected2"
                data-toggle="modal"
                onClick={(e) => setZoom(true)}
                data-target="#exampleModalCenter"
                style={{
                  boxShadow: " 0px 0px 5px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  cursor: "zoom-in",
                  // maxWidth: phoneWidthsss === false ? 550: '',
                  // marginTop: phoneWidthsss === false ? 100: 100,
                }}
              >
                <div style={{ textAlign: "center" }}>

                <div   style={{backgroundImage: `url(${isWidraw === 1 ? WITHDRAWN_IMAGE : selectedImage1})`,  
                backgroundColor: '#ffffff',
                width: '100%',
                height:  500,
                display: 'inline-block',
                backgroundSize: 'contain',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                padding: 10,
              }}>     </div>
                  {/* <img
                    aria-hidden="true"
                    fetchpriority="high"
                    id="main"
                    src={isWidraw === 1 ? WITHDRAWN_IMAGE : selectedImage1}
                    alt="1"
                  /> */}
                </div>
              </div>
            </div>

            <div className="col-xl-4 order-5 ">
              <div style={{ maxHeight: 500 }}>
                <div
                  className={`product_description ${
                    phoneWidth === false
                      ? scrollPosition > 250
                        ? "positionfixed"
                        : ""
                      : ""
                  }`}
                  style={{
                    width: matches === true ? 460 : "",
                    paddingTop: phoneWidthsss === false ? "" : 20,
                    top:
                      scrollPosition > bottomScroll
                        ? bottomScroll - scrollPosition
                        : "",
                  }}
                >
                  <i
                    dangerouslySetInnerHTML={{
                      __html:
                        property === null
                          ? ""
                          : property.replace(/PROVENANCE/g, ""),
                    }}
                  ></i>
                  <div
                    className="d-flex justify-content-between"
                    style={{
                      fontSize: 25,
                      letterSpacing: 15,
                      marginBottom: coa === null ? "" : 5,
                      marginTop: matches === false ? 20 : "",
                    }}
                  >
                    {previous === 0 ? (
                      ""
                    ) : (
                      <button
                        onClick={previousButton}
                        style={{ color: "#4d4d4e" }}
                      >
                        <i
                          style={{ color: "#4d4d4e" }}
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                        ></i>
                        &nbsp;&nbsp;&nbsp;{previous}
                      </button>
                    )}

                    <div style={{ margin: "auto" }}>
                      <h2 style={{ color: "red" }}>{lotNumber}</h2>
                    </div>

                    {next === 0 ? (
                      ""
                    ) : (
                      <button onClick={nextButton} style={{ color: "#4d4d4e" }}>
                        {next}&nbsp;&nbsp;&nbsp;
                        <i
                          style={{ color: "#4d4d4e" }}
                          className="fa fa-arrow-right"
                          aria-hidden="true"
                        >
                          {" "}
                        </i>
                      </button>
                    )}
                  </div>
                  <hr className="singleline" />

                  <div>
                    {isWidraw === 1 ? (
                      ""
                    ) : (
                      <div>
                        {artist === null ? (
                          ""
                        ) : (
                          <div>
                            <h4
                              className="product_info"
                              style={{
                                fontWeight: "bold",
                                fontSize: matches === false ? 18 : 23,
                              }}
                              dangerouslySetInnerHTML={{
                                __html: artist === null ? "" : artist,
                              }}
                            ></h4>
                          </div>
                        )}

                        <h5
                          style={{ whiteSpace: " break-spaces" }}
                          dangerouslySetInnerHTML={{
                            __html: title === null ? "" : title,
                          }}
                        ></h5>

                        <p
                          style={{
                            marginBottom: "0rem",
                            lineHeight: 1.3,
                            fontSize: phoneWidthsss === false ? 18 : 16,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: signedDated === null ? "" : signedDated,
                          }}
                        ></p>
                        <p
                          style={{
                            marginBottom: "0rem",
                            lineHeight: 1.3,
                            fontSize: phoneWidthsss === false ? 18 : 16,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: medium === null ? "" : medium,
                          }}
                        ></p>
                        <p
                          style={{
                            marginBottom: "0rem",
                            lineHeight: 1.3,
                            fontSize: phoneWidthsss === false ? 18 : 16,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: size === null ? "" : size,
                          }}
                        ></p>

                        {/* <hr className="singleline" /> */}
                        <hr className="singleline" />
                      </div>
                    )}

                    {isWidraw === 1 ? (
                      ""
                    ) : (
                      <div className="">
                        <div className="" style={{ marginTop: 10 }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent:
                                LotStatus === "Starting"
                                  ? "flex-start"
                                  : "space-between",
                              fontSize: 14,
                            }}
                          >
                            <h3>{LotStatus === "Starting" ? "" : LotStatus}</h3>
                            {matches === true ? (
                              <h3>
                                {hp === 0 || hp === null
                                  ? `Php: ${startingPrice === null ? 0:startingPrice.toLocaleString()}`
                                  : `Php : ${hp === null ? 0:hp.toLocaleString()}`}
                              </h3>
                            ) : (
                              <h3>
                                {hp === 0 || hp === null
                                  ? `Php: ${startingPrice === null ? 0:startingPrice.toLocaleString()}`
                                  : `Php : ${hp === null ? 0:hp.toLocaleString()}`}
                              </h3>
                            )}
                          </div>

                          {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: 14,
                        }}
                      >
                        <h3>Current Bid </h3>
                        <h3 >
                        PHP :  {current === null ? 0 :  current.toLocaleString()}
                        </h3>
                      </div> */}

                          {/* {token !== null ? (
                        <div
                          style={{
                            paddingTop: 10,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {matches === true ? (
                            <div>
                              {" "}
                              <h5>Total bids</h5>
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <h3>Total bids</h3>{" "}
                            </div>
                          )}

                          {matches === true ? (
                            <div>
                              {" "}
                              <h5>
                                {bidderCount === 0 || bidderCount === null
                                  ? 0
                                  : bidderCount}
                              </h5>
                            </div>
                          ) : (
                            <div>
                              <h3>
                                {bidderCount === 0 || bidderCount === null
                                  ? 0
                                  : bidderCount}
                              </h3>{" "}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )} */}
                        </div>

                        {/* {token !== null ? (
                      isWidraw === 1 ? (
                        ""
                      ) : LotStatus === "PENDING" ? (
                        <Autocomplete
                          required={true}
                          onChange={(e, v) => {
                            setPlaceAmount1(v);
                          }}
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          getOptionLabel={(option) =>
                            option ? `Php ` + option.toLocaleString() : ""
                          }
                          options={incrementData}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Amount"
                              error={requiredAmount}
                              sx={{ width: "100%", marginTop: 2 }}
                              fullWidth={true}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              onChange={(e) => handleChange1(e)}
                            />
                          )}
                        />
                      ) : (

                        ""
                      )
                    ) : (
                      ""
                    )} 
                     {token !== null ? (
                      isWidraw === 1 ? (
                        ""
                      ) : LotStatus === "PENDING" ? (
                        <button
                          style={{ marginTop: 15 }}
                          type="button"
                          className="btn btn-dark btn-lg btn-block"
                          onClick={handleClickOpen}
                        >
                          Bid
                        </button>
                      ) : (
                        ""
                      )
                    ) : LotStatus === "SOLD" ? (
                      ""
                    ) : (
                      <button
                        style={{ marginTop: 15 }}
                        type="button"
                        className="btn btn-dark btn-lg btn-block"
                        onClick={() => gotoLogin()}
                      >
                        Login To Bid
                      </button>
                    )}  dito ung login bid */}
                      </div>
                    )}

                    <div style={{ marginTop: 10 }}>
                      {/* <hr className="singleline" /> */}
                      {/* <div style={{ marginTop: 5, marginBottom: 5 }}>
                        {bidHistory.length ? (
                          <h3>
                            {" "}
                            <i class="fa fa-history" aria-hidden="true">
                              {" "}
                            </i>{" "}
                            Bid History
                          </h3>
                        ) : (
                          ""
                        )}
                      </div> */}
                      {/* <div style={{ maxHeight: 175, overflow: "auto" }}>
                        <table style={{ width: "100%" }}>
                          {bidHistory.length
                            ? bidHistory.map((val, i) => {
                                return (
                                  <tr>
                                    <td>
                                      {group === "ADMIN" ? val.email : "My Bid"}
                                    </td>
                                    <td>
                                      Php:{" "}
                                      {group === "ADMIN"
                                        ? val.amount === null
                                          ? 0
                                          : val.amount.toLocaleString()
                                        : val.myBidAmount === null
                                        ? 0
                                        : val.myBidAmount.toLocaleString()}
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </table>

                        {bidHistory.map((val, i) => {
                          return (
                            <div
                              style={{
                                paddingTop: 5,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h6>
                                {" "}
                                {group === "ADMIN"
                                  ? val.email
                                  : val.myBidAmount}
                              </h6>
                              <h6></h6>
                              <h6>
                                {" "}
                                Php:{" "}
                                {group === "ADMIN" ? val.amount : val.amount}
                              </h6>
                            </div>
                          );
                        })}
                      </div> */}

                      <div
                        class={`footer-social-links`}
                        style={{ paddingTop: 20 }}
                      >
                        <div class="title">Follow Us : &nbsp;&nbsp; </div>
                        <ul
                          class="social-links-style1"
                          style={{
                            display: "contents",
                            position: "relative",
                            float: "left",
                            marginRight: 10,
                          }}
                        >
                          <li>
                            <a
                              href="https://www.facebook.com/LeonGalleryMakati/"
                              target="_blank"
                            >
                              <i
                                class="fa fa-facebook"
                                style={{
                                  color: "#040404",
                                  border: "1px solid #463a3a",
                                  position: "relative",
                                  // display: 'content',
                                  background: "transparent",
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                  fontSize: 14,
                                  fontWeight: 400,

                                  textAlign: "center",
                                  transition: "all 500ms ease",
                                }}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://twitter.com/leongalleryph"
                              target="_blank"
                            >
                              <i
                                class="fa fa-twitter"
                                style={{
                                  color: "#040404",
                                  border: "1px solid #463a3a",
                                  position: "relative",
                                  // display: 'content',
                                  background: "transparent",
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                  fontSize: 14,
                                  fontWeight: 400,

                                  textAlign: "center",
                                  transition: "all 500ms ease",
                                }}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="mailto:info@leon-gallery.com">
                              <i
                                class="fa fa-envelope"
                                style={{
                                  color: "#040404",
                                  border: "1px solid #463a3a",
                                  position: "relative",
                                  // display: 'content',
                                  background: "transparent",
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                  fontSize: 14,
                                  fontWeight: 400,

                                  textAlign: "center",
                                  transition: "all 500ms ease",
                                }}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/channel/UCcgsLbrD84mc26EuWzVptzA"
                              target="_blank"
                            >
                              <i
                                class="fa fa-youtube-play"
                                style={{
                                  color: "#040404",
                                  border: "1px solid #463a3a",
                                  position: "relative",
                                  // display: 'content',
                                  background: "transparent",
                                  width: 40,
                                  height: 40,
                                  borderRadius: "50%",
                                  fontSize: 14,
                                  fontWeight: 400,

                                  textAlign: "center",
                                  transition: "all 500ms ease",
                                }}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: 100 }}>
            <div
              className={phoneWidthsss === false ? "col-xl-8" : ""}
              style={{ width: "100%" }}
            >
              {/* <hr className="singleline" /> */}
              {/* <h2>{provenance === null ? '':'Lot Details'}</h2> */}
              <div style={{ marginTop: 20, textAlign: "justify" }}>
                {/* <div style={{ display: "flex", justifyContent: "space-between",}}>
                       <p style={{ textAlign: "justify",fontSize:  phoneWidthsss ===  false ? 20:18 , fontWeight: 'bold' }}>
                        CERFIFICATE OF AUTHENTICITY
                      </p>


                      <p style={{ textAlign: "justify",fontSize: phoneWidthsss ===  false ? 20:18, fontWeight: 'bold' }} onClick={DetailsFunction}>
                        {detailsShow === true ? <KeyboardArrowUpIcon style={{fontSize: 40,}}/>:<KeyboardArrowDownIcon style={{fontSize: 40,}}/>}
                      </p>
                  </div> */}

                {isWidraw === 1 ? (
                  "LOT WITHDRAWN"
                ) : (
                  <div>
                    <div
                      style={{
                        display: detailsShow === true ? "none" : "block",
                        width: phoneWidthsss === false ? "90%" : "100%",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0rem",
                          fontSize: phoneWidthsss === false ? 20 : 16,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: coa === null ? "" : coa,
                        }}
                      ></p>
                    </div>

                    {provenance === null ? (
                      ""
                    ) : (
                      <div>
                        {" "}
                        <hr className="singleline" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                          >
                            PROVENANCE
                          </p>
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                            onClick={ProvinanceFunction}
                          >
                            {provinanceShow === true ? (
                              <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                            ) : (
                              <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            display: provinanceShow === true ? "none" : "block",
                            width: phoneWidthsss === false ? "90%" : "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                provenance === null
                                  ? ""
                                  : provenance.replace(/PROVENANCE/g, ""),
                            }}
                          ></p>
                        </div>
                      </div>
                    )}

                    {exhibited === null ? (
                      ""
                    ) : (
                      <div>
                        {" "}
                        <hr className="singleline" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                          >
                            EXHIBITED
                          </p>
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                            onClick={ExhibitedFunction}
                          >
                            {exhibitedShow === true ? (
                              <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                            ) : (
                              <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            display: exhibitedShow === true ? "none" : "block",
                            width: phoneWidthsss === false ? "90%" : "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                exhibited === null
                                  ? ""
                                  : exhibited.replace(/EXHIBITED/g, ""),
                            }}
                          ></p>
                        </div>
                      </div>
                    )}

                    {literature === null ? (
                      ""
                    ) : (
                      <div>
                        <hr className="singleline" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                          >
                            LITERATURE
                          </p>
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                            onClick={literatureFunction}
                          >
                            {literatureShow === true ? (
                              <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                            ) : (
                              <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                            )}
                          </p>
                        </div>
                        <div
                          style={{
                            display: literatureShow === true ? "none" : "block",
                            width: phoneWidthsss === false ? "90%" : "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                literature === null
                                  ? ""
                                  : literature.replace(/LITERATURE/g, ""),
                            }}
                          ></p>
                        </div>
                      </div>
                    )}

                    {write_up === null ? (
                      ""
                    ) : (
                      <div>
                        <hr className="singleline" />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                          >
                            WRITE UP
                          </p>
                          <p
                            style={{
                              textAlign: "justify",
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              fontWeight: "bold",
                            }}
                            onClick={WriteupFunction}
                          >
                            {writeupShow === true ? (
                              <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
                            ) : (
                              <KeyboardArrowDownIcon style={{ fontSize: 40 }} />
                            )}
                          </p>
                        </div>

                        <div
                          style={{
                            display: writeupShow === true ? "none" : "block",
                            width: phoneWidthsss === false ? "90%" : "100%",
                          }}
                        >
                          <p
                            style={{
                              fontSize: phoneWidthsss === false ? 20 : 16,
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                write_up === null
                                  ? ""
                                  : write_up.replace(/WRITE UP/g, ""),
                            }}
                          ></p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <hr className="singleline" />
              </div>
            </div>
          </div>
        </div>
        {/* </>
          );
        })} */}
      </div>

      <Dialog
        open={open}
        fullWidth={true}
        sx={{ height: matches === true ? "100%" : "80%" }}
        maxWidth={"md"}
        onClose={handleClose}
      >
        <DialogTitle
          style={{
            textAlign: "center",
          }}
        >
          Preview your bid
        </DialogTitle>
        <Divider></Divider>
        <DialogContent>
          <div className="row">
            <div className="col-xl-6">
              <div
                className="image_selected2"
                data-toggle="modal"
                data-target="#exampleModalCenter"
              >
                <img style={{ width: "100%" }} src={selectedImage} alt="1" />
              </div>
            </div>

            <div className="col-xl-6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Starting Price
                </Typography>
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Php: {parseInt(startingPrice).toLocaleString()}
                </Typography>
              </div>
              <Divider></Divider>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Current Bid
                </Typography>
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Php:{" "}
                  {current === null ? 0 : parseInt(current).toLocaleString()}
                </Typography>
              </div>
              <Divider></Divider>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Your Bid
                </Typography>
                <Typography style={{ fontSize: matches === true ? 18 : 14 }}>
                  Php:{" "}
                  {amountBid === null
                    ? parseInt(startingPrice).toLocaleString()
                    : parseInt(amountBid).toLocaleString()}
                </Typography>
              </div>
              <Divider></Divider>
            </div>
          </div>

          {/* <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Amount"
            onChange={(e) => setAmount(e.target.value)}
            type="number"
            fullWidth
            variant="outlined"
          /> */}
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button
            variant="outlined"
            style={{ color: "#343a40" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#343a40" }}
            variant="contained"
            onClick={handlepostBid}
          >
            Place Bid
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        // fullWidth={true}
        maxWidth={"lg"}
        open={zoom}
        onClose={handleClose}
      >
        <div
          className={matches === false ? "" : "example example-contain"}
          style={{
            // maxHeight: 600,
            width: phoneWidthsss === false ? 700 : "100%",
            height: phoneWidthsss === false ? 700 : "100%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <img
              aria-hidden="true"
              fetchpriority="high"
              style={{
                width: "100%",
                height: phoneWidthsss === false ? "45vw" : "",
              }}
              src={isWidraw === 1 ? WITHDRAWN_IMAGE : selectedImage1}
              alt="1"
            />
          </div>
        </div>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <div
        className="modal"
        id="exampleModalCenter"
        tabindex={lotNumber}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        // aria-hidden="true"
        // style={{ backgroundColor: "#00000" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: 600 }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "red",
              marginTop: phoneWidth === false ? -20 : 100,
            }}
          >
            <img src={selectedImage1} alt="" />
          </div>
        </div>
      </div>

      <Snackbar
        style={{ margin: "auto", position: "absolute" }}
        open={openNotif}
        autoHideDuration={5000}
        onClose={handleClosess}
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key={vertical + horizontal}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default View;
