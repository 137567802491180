import React, { useState, useEffect, memo, useCallback, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Logo from "../../auctionsRoom/header/Logo";
import Grid from "@mui/material/Grid";
import BellSound from "../../auctionsRoom/assets/bell_ring.mp3";
import theme from "./theme";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TableUserOnline from "./tableUserOnline";
import ButtonsFunction from "./ButtonFunction";
import ImageNextRow from "./ImageNextRow";
import RightPannel from "./RightPannel";
import Current from "./Current";
import { Divider, Typography } from "@mui/material";
import { getUser } from "../../Model/LocalStorage";
//  import {socketDeclarator} from "./../../Model/socketDeclarator"

import { auctionsLive,postStatus } from "../../Model/DeclaratorModel";
import Announcement from "./announcenment";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { postBid } from "src/view/Model/Auction";

function View({ socketDeclarator, LiveData }) {
  const soundRef = useRef();
  const [userOnline, setUserOnline] = useState([]);
  const [Status, setStatus] = useState("");
  const [LiveDataNew, setGetLiveData] = useState("");
  const [loading, setLoading] = useState(false);

  const [openMessage, setOpenMessag] = React.useState(false);
  const [msg, setmsg] = React.useState("");
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessag(false);
  };


  function SoundTrip(){
    var resp =  new Audio(BellSound).play();
      if (resp!== undefined) {
          resp.then(_ => {
            new Audio(BellSound).play();
          }).catch(error => {
            //show error
          });
      }

  }
  
  

  const playSound = (data) => {

    const datass = data.bid_history.filter(
      (x) =>
        x.bidder_type === "INTERNET BIDDER"
    );
        function loopThroughSplittedText() {
        for (var i = 0; i < datass.length; i++) {
          // for each iteration console.log a word
          // and make a pause after it
          (function (i) {
              setTimeout(function () {
                document.getElementById("sound").click();
              }, 500 * i);
          })(i);
      };
      }
      loopThroughSplittedText();

  };
  


  const ListData = (val) => {
  
    try {
      async function getAuctionOne() {
        // if(val === "FAIR_WARNING"){
        //   alert("12x")
        //   const DataBidStatus = {
        //     auction_id: LiveData.auction_details.auction_id,
        //     setId: LiveData.auction_details.id,
        //     lot_number: LiveData.lot.details[0].lot_number,
        //     status: "OPEN",
        //   };
        //   await postStatus(DataBidStatus)
        // }
        const results = await auctionsLive();
        if (results.success === false) {
          alert(results.msg);
        } else {
          if(val === "NEXTSLOT" || val === "PASS"){
            setGetLiveData(results);
            // setGetLiveData(results);
           return  playSound(results); 
          }else{
            setGetLiveData(results);
           // return  playSound(results); 
          }
          // setGetLiveData(results);
          // return  playSound(results); 
          // return  playSound(results); 
         // return    playSound(results); DOMContentLoaded
        }
        // setAuctionTitle(results.auction_details.auction_name);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  };

  useEffect(() => {
    // ListData();
    
    soundRef.current = new Audio(BellSound);
    socketDeclarator.on("JointEvent", (userId) => {
      // console.log("userId: ", userId);
      setUserOnline(Object.values(userId.userId));
    });

    socketDeclarator.on("disconnect", (reason) => {
      if (reason === "transport close" || reason === "transport error") {
        setLoading(true);
        setTimeout(() => {
          window.location.reload();
          setLoading(false);
        }, 5000);
      }
    });

    socketDeclarator.on("connect_error", (err) => {
      setLoading(true);
      setOpenMessag(true);
      setmsg(err.message);
    });

    socketDeclarator.on("connect_failed", (err) => {
      setLoading(true);
      setOpenMessag(true);
      setmsg(err.message);
    });

    socketDeclarator.on("connect", (err) => {
      setLoading(false);
      setOpenMessag(true);
      setmsg("Connected");
      // alert("connected Successfully")
    });

    socketDeclarator.on("receive_status", (data) => {
      // alert(data.message)
      if (data.message === "NEXTSLOT" || data.message === "PASS") {
        // alert("receive_status",data.message)
        setStatus(data.message);

        return ListData(data.message);
      } else if (data.message === "undo") {
        setStatus(data.message);
        return ListData();
      } else {
        // console.log('data.message ',data.message)
        setStatus(data.message);
       // return ListData();
        // console.log("error in view receive_status");  window.location.reload();
      }
    });

    socketDeclarator.on("receive_bid_amount", (data) => {
      // console.log('receive_bid_amount ',data)
      if (data.status === true) {
        document.getElementById("sound").click();
        // window.scrollTo(0, 0)
        return ListData();
      } else {
      }
    });

    return () => {
      socketDeclarator.off("connect");
      socketDeclarator.off("connect_failed");
      socketDeclarator.off("connect_error");
      socketDeclarator.off("disconnect");
      socketDeclarator.off("JointEvent");
      socketDeclarator.off("receive_status");
      socketDeclarator.off("receive_bid_amount");
      // socketDeclarator.removeAllListeners();
      //  socketDeclarator.removeAllListeners('disconnect');
      //   socketDeclarator.removeAllListeners("JointEvent");
      //  socketDeclarator.removeAllListeners("receive_status");
      //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
      //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
    };
  }, [socketDeclarator, LiveData, BellSound, ListData]);

  return (
    <div>
      <Box
        style={{
          // padding: "15px",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        <Box style={{ padding: 5 }}>
          <Logo />
        </Box>

        <Grid container>
          <Grid item xs={12} sm={5} style={{ border: "1px solid #ccc" }}>
            <Current
              LiveData={LiveDataNew === "" ? LiveData : LiveDataNew}
              socketDeclarator={socketDeclarator}
              Datastatus={Status}
            />

            <ButtonsFunction
              socketDeclarator={socketDeclarator}
              LiveDataddd={LiveDataNew === "" ? LiveData : LiveDataNew}
            />
            <Divider></Divider>
            <ImageNextRow
              socketDeclarator={socketDeclarator}
              LiveData={LiveDataNew === "" ? LiveData : LiveDataNew}
            />
          </Grid>

          {/* Right box Start */}
          <Grid item xs={12} sm={7} style={{ border: "1px solid #ccc" }}>
            <RightPannel
              socketDeclarator={socketDeclarator}
              LiveData={LiveDataNew === "" ? LiveData : LiveDataNew}
            />

            <Divider></Divider>

            <TableUserOnline
              socketDeclarator={socketDeclarator}
              userOnline={userOnline}
              LiveData={LiveDataNew === "" ? LiveData : LiveDataNew}
            />

            <Divider></Divider>
<Button id="sound" onClick={SoundTrip} style={{display: "none"}}></Button>
            <Announcement
              socketDeclarator={socketDeclarator}
              message={
                LiveDataNew === ""
                  ? LiveData.announcement
                  : LiveDataNew.announcement
              }
            />
          </Grid>
          {/* Right box End */}
        </Grid>


      </Box>

      <Snackbar
        open={openMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={msg === "Connected" ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <b style={{ fontSize: 20 }}> Reconnect...&nbsp;&nbsp;&nbsp;&nbsp; </b>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default View;
