import React from "react";
import Page from "src/components/Page";
import View from "./view";

function index(props) {
  localStorage.removeItem("@imageSingle");
  return (
    <Page title="How To Sell">
      <View />
    </Page>
  );
}

export default index;
