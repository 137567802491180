import React from 'react';
import Lot from "./Lot"
import Banner from './Banner'
function view(props) { 
    return (
        <div style={{marginTop:50}}>
          {/* <Banner param={props.param}/> */}
         
          <Lot />
        </div>
    );
}

export default view;