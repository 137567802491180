import React from 'react';
// import {Link} from 'react-router-dom';

function HeadImage(props) {
    return (
    <div>
        <section
        className="breadcrumb-area"
        style={{ backgroundImage: "url(/images/breadcrumb/breadcrumb-1.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>{props.param}</h1>
                </div>
                {/* <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li>Home</li>
                    <li className="active">About Us</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{paddingTop: '100px'}}>
        <div className="layer-outer"></div>
        <div className="container">
  
          <div className="row">
            <div className="col-xl-12">
              <div className="partner-box">
                <div className="single-partner-logo-box">
                  <Link to="#">
                    <img src="/images/brand/1.png" alt="Awesome" />
                  </Link>
                </div>

                <div className="single-partner-logo-box">
                  <Link to="#">
                    <img src="/images/brand/2.png" alt="Awesome" />
                  </Link>
                </div>

                <div className="single-partner-logo-box">
                  <Link to="#">
                    <img src="/images/brand/3.png" alt="Awesome" />
                  </Link>
                </div>

                <div className="single-partner-logo-box">
                  <Link to="#">
                    <img src="/images/brand/4.png" alt="Awesome" />
                  </Link>
                </div>

                <div className="single-partner-logo-box">
                  <Link to="#">
                    <img src="/images/brand/5.png" alt="Awesome" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      </div>
    );
}

export default HeadImage;