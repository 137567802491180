import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import BlogSingleArea from "./blogSingleArea"
import BlogSingleAreaSidebar from "./blogSingleAreaSidebar"
import Page from "src/components/Page";

import axios from "axios";

function Index(props) {
 const [data, setData] = React.useState([]);
 const [categoriesList, setcategoriesList] = React.useState([]);
 const [pastPost, setPasPost] = React.useState([]);
 const [isLoading, setIsLoading] = useState(true)
 const [categoriesRef, setCategoriesRef] = useState(`3,4,5`)
 let { slug } = useParams();
  useEffect(() => {
        const wordpress_api = "https://apiblog.leon-gallery.com/wp-json/wp/v2/";
        const request1 = axios.get(wordpress_api + `categories?_fields=id,count,name,slug&include=3,5,4` );

        const request2 = axios.get(wordpress_api + `posts?slug=${slug}&_embed=wp:featuredmedia,wp:term&_fields=id,date,slug,title,excerpt,content,_links` );

        const request3 = axios.get(wordpress_api + `posts?_embed=wp:featuredmedia,wp:term&_fields=id,date,slug,title,excerpt,_links&orderby=relevance&search=title&order=asc&categories=3,4,5&per_page=2`);

        axios.all([request1,request2,request3])

        .then(axios.spread(function(res1, res2, res3) {
                    setcategoriesList(res1.data)
                   
                    setData(res2.data[0])
                     setPasPost(res3.data)
                     setIsLoading(false)
          }))

        .catch((err)=>{

       setIsLoading(false)
        console.log(err)});

     
  }, [ slug ]);


    return (
    	<>

    		 <Page title="Stories">
        	
        	
             <section className="blog-single-area" style={{marginTop: 50}}>
                 <div className="container">

                   {
                    (isLoading)?
                
                             <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden" style={{fontSize:"100px"}}></span>
                              </div>
                            </div>
                    

                    :
                      <div className="row">
                         <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12">
                                 <BlogSingleArea data={data}/>
                         </div>
               
                         <div className="col-xl-4 col-lg-5 col-md-9 col-sm-12">
                                <BlogSingleAreaSidebar categoriesList={categoriesList} pastPost={pastPost}/>
                         </div>
                       </div>
                   }
                    </div>
              </section>
        </Page>
    	
       
        </>
    );
}

export default Index;


