import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Blog from "./blog";
import BlogSidebar from "./blogsidebar";
import Page from "src/components/Page";

import axios from "axios";

function Index(props) {
  const [listPost, setList] = React.useState([]);
  const [categoriesList, setcategoriesList] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [totalResult, setTotalResult] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [categoriesRef, setCategoriesRef] = useState(`3,4,5`);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const cur_page = searchParams.get("page");
  const cur_categories = searchParams.get("categories");

  const onChangeCategories = (id) => {
    localStorage.removeItem("@storeAuctionLot");
    setCategoriesRef(cur_categories);

    if (cur_page > 1) {
      setSearchParams({ page: 1, categories: id });
    } else {
      setSearchParams({ page: page, categories: id });
    }
    window.scrollTo(0, 0);
  };

  const changePage = (id) => {
    setSearchParams({ page: id, categories: categoriesRef });
    window.scrollTo(0, 0);
    setPage(cur_page);
  };

  useEffect(() => {
    const wordpress_api = "https://apiblog.leon-gallery.com/wp-json/wp/v2/";
    const request1 = axios.get(
      wordpress_api + `categories?_fields=id,count,name,slug&include=3,4`
    ); // na remove ung 5

    const request2 = axios.get(
      wordpress_api +
        `posts?_embed=wp:featuredmedia,wp:term&_fields=id,date,slug,title,excerpt,_links&order=desc&categories=${
          cur_categories ? cur_categories : `3,4`
        }&per_page=10&page=${cur_page ? cur_page : 1}`
    );
    // na remove ung 5

    axios
      .all([request1, request2])
      .then(
        axios.spread(function (res1, res2) {
          setcategoriesList(res1.data);

          setTotalResult(res2.headers["x-wp-total"]);
          setTotalPage(res2.headers["x-wp-totalpages"]);
          setList(res2.data);

          setIsLoading(false);
        })
      )

      .catch((err) => {
        setIsLoading(false);
	
        console.log(err);
      });
  }, [categoriesRef, cur_page]);

  return (
    <>
      <Page title="Stories">
        <section className="latest-blog-style3-area blog-page">
          <div className="container">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span
                    className="visually-hidden"
                    style={{ fontSize: "100px" }}
                  ></span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="top">
                    <div className="sec-title pdbtm42 text-center">
                      <div className="big-title">
                        <h1>Latest stories</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-3">
                      <BlogSidebar
                        categoriesList={categoriesList}
                        totalResult={totalResult}
                        onChangeCategories={onChangeCategories}
                      />
                    </div>

                    <div className="col-xl-9">
                      <Blog
                        listPost={listPost}
                        setPage={setPage}
                        totalPage={totalPage}
                        currentPage={cur_page}
                        setPage={changePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </Page>
    </>
  );
}

export default Index;
