import React, { useCallback, useEffect, useState } from "react";
import BannerHome from "./BannerHome";
import AuctionAndExhibition from "./AuctionAndExhibition";
import Publication from "./Publication";
import Video from "./Video";
import FormEmail from "./FormEmail";
import HowTosell from "./HowTosell";


import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SensorsIcon from "@mui/icons-material/Sensors";
import useMediaQuery from "@mui/material/useMediaQuery";
import VideocamIcon from '@mui/icons-material/Videocam';

import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider } from "@mui/material";

function PaperComponent(props) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}

function Home(props) {

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const matchesPhone = useMediaQuery("(min-width:700px)");


  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  // const [getauction, setAuctions] = React.useState();

  // const fetchData = async () => {
  //   try {
  //     async function getAuctionOne() {
  //       const results = await auction();
  //      // setAuctions(results);
  //       setAuctions([...getauction, ...results]);
  //     }
  //     getAuctionOne().catch(console.error);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData()
  // }, [])

  return (
    <div className="boxed_wrapper">
      <BannerHome />

{open === false ?  <div

     aria-labelledby="draggable-dialog-title"
        style={{
          position: "fixed",
          bottom:  matchesPhone === true ?  0:100,
          right: '0%',
          marginRight: '-275px',
          // width: matchesPhone === true ? 164 : 164,
          // height: matchesPhone === true ? 160 : "100%",
          // padding:' 5px 5px',
          padding: 2,
          borderRadius: 5,
          cursor: 'pointer',
          zIndex: 99999,
          // background: "rgb(167 36 36)",

        
          margin: matchesPhone === true ? 5 : 0,
        }}
        // fullWidth={fullWidth}
        // maxWidth={maxWidth}
        open={true}
        onClose={handleClose}
      >
      <div
      onClick={handleClose}
        style={{
          // position: "relative",
          background: "red",
          color: "white",
          width: 60,
          height: 60,
          // padding: 2,
          borderRadius: 60,
          textAlign: "center",
          fontSize:  20,
          lineHeight: "55px",
          // padding:"px 3px 0 3px"
          
        }}
      >
        {/* <SensorsIcon /> */}
      <VideocamIcon style={{ fontSize: 35}}/>
      </div>


  </div> :  <Dialog
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
         style={{
          //  position: "fixed",
          //  top: "100px",
          //  width: matchesPhone === true ? "580px" : "100%",
      
          //  // padding:' 5px 5px',
          //  borderRadius: 5,
          //  zIndex: 99999,
          //  background: "rgb(242 242 242)",

           margin: matchesPhone === true ? 5 : 0,
         }}
         fullWidth={fullWidth}
         maxWidth={maxWidth}
         open={true}
         onClose={handleClose}
       > 

 <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
          id="draggable-dialog-title"
            style={{
              background: "red",
              color: "white",
              width: 80,
              padding: 2,
              borderRadius: 5,
              textAlign: "center",
              fontSize:  14,
              cursor: 'move' 
            }}
          >
            <SensorsIcon />
            LIVE
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{      width:  "100%",}}>
            <iframe
              id="ifvideo"
              width="100%"
              height={`${matchesPhone === true ? "380" : "100%"}`}
              scrolling="no"
              frameborder="0"
              allowfullscreen=""
              src="https://demo.nanocosmos.de/nanoplayer/embed/1.3.3/nanoplayer.html?entry.rtmp.streamname=TKGUW-bCyQT&amp;playback.latencyControlMode=balancedadaptive"
            ></iframe>
          </div>
        </DialogContent>
        <Divider></Divider>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> 
      </Dialog> }

      <AuctionAndExhibition />

      <Publication />
      <Video />
      <HowTosell />
      {/* <FormEmail/> */}
    </div>
  );
}

export default Home;
