import React from "react";
import { Link } from "react-router-dom";
import Page from "src/components/Page";
function results(props) {
  return (
    <Page title="Auctions Results">
      <section className="art-style3-area" style={{ padding: "50px 0 120px" }}>
        <div className="container">
          <div
            className="sec-title"
            style={{ backgroundColor: "#0000", paddingBottom: "0px" }}
          >
            {/* <p>DON’T MISS</p> */}
            <div className="big-title">
              <h1>Auction Results</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-single-area" style={{ padding: "50px 0 120px" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12">
              <div className="">
                <div className="row items-container clearfix">
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta">
                    <div className="single-art-box-style2 marbtm50">
                      <div className="img-holder">
                        <img src="images/art/art-v2-1.jpg" alt="Awesome" />
                        <div className="overlay-content">
                          <div className="zoom-button">
                            <Link
                              className="lightbox-image"
                              data-fancybox="gallery"
                              to="images/art/art-v2-1.jpg"
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="text"
                        style={{ fontSize: "15px", padding: "5px" }}
                      >
                        In a 1982 essay, Halley wrote that the idealist square
                        becomes the prison made Geoetry is revealed as
                        confinementIn other
                      </div>
                      <div
                        className="button-box button button5"
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        VIEW RESULTS
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all objects pic">
                    <div className="single-art-box-style2 marbtm50">
                      <div className="img-holder">
                        <img src="images/art/art-v2-2.jpg" alt="Awesome" />
                        <div className="overlay-content">
                          <div className="zoom-button">
                            <Link
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href="images/art/art-v2-2.jpg"
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        className="text"
                        style={{ fontSize: "15px", padding: "5px" }}
                      >
                        In a 1982 essay, Halley wrote that the idealist square
                        becomes the prison made Geoetry is revealed as
                        confinementIn other
                      </div>
                      <div
                        className="button-box button button5"
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        VIEW RESULTS
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all objects pic">
                    <div className="single-art-box-style2 marbtm50">
                      <div className="img-holder">
                        <img src="images/art/art-v2-2.jpg" alt="Awesome" />
                        <div className="overlay-content">
                          <div className="zoom-button">
                            <Link
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href="images/art/art-v2-2.jpg"
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="text"
                        style={{ fontSize: "15px", padding: "5px" }}
                      >
                        In a 1982 essay, Halley wrote that the idealist square
                        becomes the prison made Geoetry is revealed as
                        confinementIn other
                      </div>
                      <div
                        className="button-box button button5"
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        VIEW RESULTS
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all objects pic">
                    <div className="single-art-box-style2 marbtm50">
                      <div className="img-holder">
                        <img src="images/art/art-v2-2.jpg" alt="Awesome" />
                        <div className="overlay-content">
                          <div className="zoom-button">
                            <Link
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href="images/art/art-v2-2.jpg"
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="text"
                        style={{ fontSize: "15px", padding: "5px" }}
                      >
                        In a 1982 essay, Halley wrote that the idealist square
                        becomes the prison made Geoetry is revealed as
                        confinementIn other
                      </div>
                      <div
                        className="button-box button button5"
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        Preview
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-5 col-md-9 col-sm-12">
              <div className="sidebar-wrapper">
                <div className="sidebar-search-box">
                  <form className="search-form" action="#">
                    <input placeholder="Search" type="text" />
                    <button type="submit">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </form>
                </div>

                <div className="single-sidebar">
                  <div className="title">
                    <h3>Categories</h3>
                  </div>
                  <ul className="categories clearfix">
                    <li>
                      <Link to="#">Contemporary Art</Link>
                    </li>
                    <li>
                      <Link to="#">Impressionist & Modern Art</Link>
                    </li>
                    <li>
                      <Link to="#">Jewelry</Link>
                    </li>
                    <li>
                      <Link to="#">Watches</Link>
                    </li>
                    <li>
                      <Link to="#">Painting</Link>
                    </li>
                  </ul>
                </div>

                {/* <div className="single-sidebar">
                    <div className="title">
                      <h3>SALES</h3>
  
                    </div>
                    <ul className="categories clearfix">
                      <li>
                        <Link to="#">Exhibition</Link>
                      </li>
                      <li>
                        <Link to="#">Online Only</Link>
                      </li>
                      <li>
                        <Link to="#">Live</Link>
                      </li>
                  <li>
                        <Link to="#">Watches</Link>
                      </li>
                      <li>
                        <Link to="#">Painting</Link>
                      </li> 
                    </ul>
                  </div> */}

                <div className="single-sidebar">
                  <div className="title">
                    <h3>Recent Posts</h3>
                  </div>
                  <div className="recent-post">
                    <div className="recent-post-carousel owl-carousel owl-theme owl-nav-style-one">
                      <div className="single-recent-post">
                        <div className="img-holder">
                          <img
                            src="images/sidebar/recent-post-1.jpg"
                            alt="Awesome"
                          />
                          <div className="overlay-style-one bg1"></div>
                        </div>
                        <div className="title-holder text-center">
                          <p>March 25, 2019</p>
                          <h3>
                            <Link to="#">
                              Scenes from Stepan Hawkin’s World
                            </Link>
                          </h3>
                        </div>
                      </div>

                      <div className="single-recent-post">
                        <div className="img-holder">
                          <img
                            src="images/sidebar/recent-post-1.jpg"
                            alt="Awesome"
                          />
                          <div className="overlay-style-one bg1"></div>
                        </div>
                        <div className="title-holder text-center">
                          <p>March 25, 2019</p>
                          <h3>
                            <Link to="#">
                              Scenes from Stepan Hawkin’s World
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-sidebar">
                  <div className="title">
                    <h3>Archives</h3>
                  </div>
                  <ul className="archives clearfix">
                    <li>
                      <Link to="#">January 2020</Link>
                    </li>
                    <li>
                      <Link to="#">December 2019</Link>
                    </li>
                    <li>
                      <Link to="#">November 2019</Link>
                    </li>
                    <li>
                      <Link to="#">October 2019</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export default results;
