import React,{useEffect, useState} from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Button, TextField } from "@mui/material";
import { postMessage} from "../../Model/DeclaratorModel";

const Announcement = ({socketDeclarator,message}) => {



  const [announcement,setPostAnouncement] = useState("")

  const handleClick =  async() => {

    async function nextLotData() {
      const results = await postMessage(announcement);
      if(results === "SUCCESS"){
        socketDeclarator.emit("postAnnouncement", {
          message: announcement, });
        }
      }
  
    nextLotData().catch(console.error);
 
    // const results = await postMessage(announcement);
    
    // socketDeclarator.emit("postAnnouncement", {
    //   status: true, });
  
  }

  useEffect(() => {
    try {
      setPostAnouncement(message[0].announcement)
    } catch (error) {
      
    }
    // console.log("message: ",message)
  },[message])

  return (
<div style={{height: "40%"}}>
    <Box style={{ padding: 10 }}>
       <TextField
        fullWidth
        margin="normal"
        id="outlined-multiline-static"
        label="Announcement"
        multiline
        rows={7}
        InputLabelProps={{
          shrink: true
        }}
        defaultValue={announcement}
        name="postAnounce"
        onChange={(e) => setPostAnouncement(e.target.value)}
        variant="outlined"
        />

<Button
            fullWidth
            style={{ margin: 2 }}
            variant="contained"
            color="primary"
            onClick={() => handleClick()}
            // className={`${classes.buttonGray} ${classes.ButtonCustom}`}
          >
            Announce
           
          </Button>

   </Box>
   </div>
  );
};

export default Announcement;