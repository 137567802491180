import React from "react";
import { Link } from "react-router-dom";
import AboutUSs from "./../../images/resources/aboutus.png";

const About = (props) => {
  return (
    <div className="boxed_wrapper">
      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${AboutUSs})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title">
                  <h1>How To Sell</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 60 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                      How to Sell
                    </li> 
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-style1-area">
        <div className="container">
          <div className="row">

            <div class="col-md-12" style={{marginBottom:30}}>
              <div class="inner-content" style={{ padding: 5,marginBottom:10 }}>
                <h3>Do you want to know the value of your pieces ?</h3>
                <p>Just follow the easy steps below and our experts will let you know your pieces’ value</p>
                <div style={{ paddingTop:20 ,paddingBottom:20 }}>

                <a class="btn-one" style={{ borderRadius: 5 }} href="/request">Request Estimate</a>
                </div>
              </div>
              <div class="sec-title">
                <div class="big-title"><h3>Request an estimate for your item</h3></div>
              </div>
              <div class="inner-content">
              <div class="sec-title">
            {/* <p>CURRENT EXHIBITIONS &amp; Display</p> */}
            <div class="big-title" style={{textAlign: 'center'}}><h1>How It Works</h1></div>
        </div>
                <div class="row">
                  <div class="col-md-4 col-xs-12 col-lg-4 text-center">
                    <i class="fa fa-camera-retro fa-5x" style={{ fontSize: 72, webkitTextStroke: '1px white', color: '#777777' }}></i>
                    <h6 style={{ fontWeight: 'bold',marginTop: 10  }}>Upload Photos</h6>
                    <p>Take photos of the front and back</p>
                  </div>
                  <div class="col-md-4 col-xs-12 col-lg-4 text-center">
                    <i class="fa fa-list-alt" style={{ fontSize: 72, webkitTextStroke: '1px white', color: '#777777' }}></i>
                    <h6 style={{ fontWeight: 'bold',marginTop: 10 }}>Tell us about your Item</h6>
                    <p>Provide details</p>
                  </div>
                  <div class="col-md-4 col-xs-12 col-lg-4 text-center">
                    <i class="fa fa-check-square" style={{ fontSize: 72, webkitTextStroke: '1px white', color: '#777777' }}></i>
                    <h6 style={{ fontWeight: 'bold',marginTop: 10  }}>Review and Submit</h6>
                    <p>Submit!Our experts will review your item and get back to you right away!</p>
                  </div>
                </div>
              </div>
              <hr style={{ borderTop: '1px solid rgba(0,0,0,.1)' }}></hr>
            </div>




  <section class="events-style3-area pdbottom0">
    <div class="container">
      
        <div class="row">
            <div class="col-xl-12">
                
                <div class="single-event-box-style3">
                    <div class="row">
                        <div class="col-xl-12">
                        <div class="big-title">
                    <h1 class="blog-title">How to sell</h1>
                  </div>
                            <div class="text para">
                            <p class="mar-bottom" >
                            Our experts at León Gallery will be at your side throughout
                            the entire auction process. We would be happy to assist you
                            in person, online, or on the phone. Once we have come to an
                            agreement to bring your piece to auction, our specialists
                            will help you in setting the ideal reserve price, doing the
                            research and cataloguing, and finding the perfect buyers.
                            Once you receive a valuation, a sellers agreement and
                            shipping arrangements will be made. It’s as easy as that.
                     </p>
                            </div> 
                        </div>
                        {/* <div class="col-xl-5">
                            <div class="img-holder">
                                <div class="inner">
                                    <img src="https://leon-gallery.com/assets/img/sell/HOW-TO-SELL-BUY3.jpg" alt="Awesome Image"/>
                                    <div class="overlay-style-one bg1"></div>
                                </div>
                            </div> 
                        </div> */}
                        {/* <div class="col-xl-2">
                            <div class="date-holder">
                                <div class="inner">
                                    <h1>28<span>Jun</span></h1>
                                    <h3>Wednesday</h3>
                                </div>    
                            </div>
                        </div> */}
                    </div>  
                </div>
         
                <div class="single-event-box-style3">
                    <div class="row">
                        <div class="col-xl-12">
                        <div class="big-title">
                    <h1 class="blog-title">Request for Valuation</h1>
                  </div>
                            <div class="text para">
                  
                            <p class="mar-bottom">
                    Because we value convenience and simplicity, we provide
                    several options to have your work evaluated. You may come in
                    and personally bring in your pieces, give us a call, or
                    upload photos online and send us the details. Or should we
                    call it Scheduling for Auction Once your submission is
                    approved, we’ll arrange a meeting to sit down with you to
                    discuss the value of your work and when is the best time to
                    sell it. León Gallery has four ‘live’ auctions each quarter,
                    in February, June, September and December of each year;
                    preceded or followed by lively online auctions. If the work
                    doesn’t fit in our regular auction calendar, we can also
                    create private events specifically for you. We are here to
                    answer any of your questions and guide you every step of the
                    way.
                  </p>
                            </div> 
                        </div>
                        {/* <div class="col-xl-5">
                            <div class="img-holder">
                                <div class="inner">
                                    <img  src="https://leon-gallery.com/assets/img/sell/REQUEST%20ESTIMATE.jpg"alt="Awesome Image"/>
                                    <div class="overlay-style-one bg1"></div>
                                </div>
                            </div> 
                        </div> */}
                        {/* <div class="col-xl-2">
                            <div class="date-holder">
                                <div class="inner">
                                    <h1>12<span>Feb</span></h1>
                                    <h3>Wednesday</h3>
                                </div>    
                            </div>
                        </div> */}
                    </div>  
                </div>
            </div>
        </div>   
    </div>
</section>
</div>
        </div>
      </section>

            {/* <div className="col-xl-6  col-lg-6" style={{background:'#fbf9f4',padding: 50 }}>
              <div className="about-style1-left-content clearfix" style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
                <div className="">
                  <img

                    src="https://leon-gallery.com/assets/img/sell/HOW-TO-SELL-BUY3.jpg"
                    alt="Awesome"
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-6  col-lg-6" style={{background:'#fbf9f4', padding: 50  }}>
              <div className="about-style1-content">
                <div class="">
                  <div class="big-title">
                    <h1 class="blog-title">How to sell</h1>
                  </div>
                </div>

                <div className="text para" >
                  <h3 class="blog-title" >Getting Started</h3>
                  <hr style={{ borderTop: '1px solid rgba(0,0,0,.1)' }}></hr>
                  <p class="mar-bottom" >
                    Our experts at León Gallery will be at your side throughout
                    the entire auction process. We would be happy to assist you
                    in person, online, or on the phone. Once we have come to an
                    agreement to bring your piece to auction, our specialists
                    will help you in setting the ideal reserve price, doing the
                    research and cataloguing, and finding the perfect buyers.
                    Once you receive a valuation, a sellers agreement and
                    shipping arrangements will be made. It’s as easy as that.
                  </p>
                </div>
              </div>
            </div> */}
       
        



      {/* <section className="about-style1-area">
        <div className="container">
          <div className="row" style={{ paddingBottom: 100 }}>

            <div className="col-xl-6 col-lg-6" style={{padding: 50 }}>
              <div className="about-style1-content">
                <div class="">
                  <div class="big-title">
                    <h1 class="blog-title">Request for Valuation</h1>
                  </div>
                </div>

                <div className="text para">
                  <hr style={{ borderTop: '1px solid rgba(0,0,0,.1)' }}></hr>
                  <p class="mar-bottom">
                    Because we value convenience and simplicity, we provide
                    several options to have your work evaluated. You may come in
                    and personally bring in your pieces, give us a call, or
                    upload photos online and send us the details. Or should we
                    call it Scheduling for Auction Once your submission is
                    approved, we’ll arrange a meeting to sit down with you to
                    discuss the value of your work and when is the best time to
                    sell it. León Gallery has four ‘live’ auctions each quarter,
                    in February, June, September and December of each year;
                    preceded or followed by lively online auctions. If the work
                    doesn’t fit in our regular auction calendar, we can also
                    create private events specifically for you. We are here to
                    answer any of your questions and guide you every step of the
                    way.
                  </p>
                </div>
              </div>
            </div>


            <div className="col-xl-6 col-lg-6" style={{ padding: 50 }}>
              <div className="about-style1-left-content clearfix" style={{
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
                <div className="">
                  <img
                    style={{ width: '100%' }}
                    src="https://leon-gallery.com/assets/img/sell/REQUEST%20ESTIMATE.jpg"
                    alt="Awesome"
                  />
                </div>
              </div>
            </div>





          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
