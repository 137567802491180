import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { auctionsLive, nextLot } from "../../Model/DeclaratorModel";
// import {socket} from "./../../Model/socket"
import NoImage from "../../images/resources/noImage.jpg";
const Current = ({ LiveData, Datastatus }) => {
  const [artist, setArtist] = useState("");
  const [title, setTitle] = useState("");
  const [signed_dated, setDate] = useState("");
  const [medium, setMedium] = useState("");
  const [size, setSize] = useState("");
  const [LotNumber, setLotNumber] = useState("");
  const [image, setImage] = useState([]);
  const [lot_group, setLotGroup] = useState("");
  const [auctionName, setAuctionName] = useState("");
  const [sold, setSold] = useState("");
  // const [highestBid, setHighestBid] = useState(0);
  // const [reserved, setReserved] = useState("");

  useEffect(() => {
    try {
      // console.log(LiveData.lot.details[0].lot_number)
      // setReserved(LiveData.lot.details[0].reserve_price);
      // setHighestBid(LiveData.bid_history[0].hammer);

      setAuctionName(LiveData.auction_details.auction_name);
      setImage(LiveData.lot.image_lot);
      setLotNumber(
        <b
          style={{
            backgroundColor: "#6c757d",
            color: "white",
            padding: " 5px 10px",
            borderRadius: 5,
          }}
        >
          {LiveData.lot.details[0].lot_number}
        </b>
      );
      setTitle(LiveData.lot.details[0].title);
      setArtist(LiveData.lot.details[0].artist);
      setDate(LiveData.lot.details[0].signed_dated);
      setMedium(LiveData.lot.details[0].medium);
      setSize(LiveData.lot.details[0].size);
      setLotGroup(LiveData.lot.details[0].lot_group);
      // statunsFunction(LiveData.lot.details[0].lot_status);

      if (LiveData.lot.details[0].lot_status === "SOLD") {
        setSold(
          <div class="overlayDeclaratorCurrent" style={{ color: "#59bb59" }}>
            SOLD
          </div>
        );
      } else if (LiveData.lot.details[0].lot_status === "UNSOLD") {
        setSold(
          <div
            className="overlayDeclaratorCurrent"
            style={{ color: "#ff4949" }}
          >
            Unsold
          </div>
        );
      } else if (LiveData.lot.details[0].lot_status === "RESERVE_NOT_MET") {
        setSold(
          <div
            className="overlayDeclaratorCurrent"
            style={{ color: "#ff4949", paddingTop: "30%" }}
          >
            RESERVE NOT MET
          </div>
        );
      } else if (LiveData.lot.details[0].lot_status === "PASS") {
        setSold(
          <div
            className="overlayDeclaratorCurrent"
            style={{ color: "#0bf8bf" }}
          >
            PASS
          </div>
        );
      } else {
        setSold("");
      }
    } catch (error) {
      setLotGroup("");
      setImage("");
      setLotNumber("");
      setTitle("");
      setArtist("");
      setDate("");
      setMedium("");
      setSize("");
    }
  }, [LiveData, Datastatus]);

  return (
    <div>
      <Grid
        container
        style={{
          backgroundColor: "rgb(248 248 248)",
          borderRadius: "2px",

          //   boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)", LotNumber
        }}
      >
        <Grid item sm={6}>
          <div style={{ float: "left", height: "20%", padding: "8px" }}>
            <Typography variant="h5">{auctionName}</Typography>
            <br />

            <Typography variant="h6">Lot No. {LotNumber}</Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: artist }}
            ></Typography>
            <Typography
              dangerouslySetInnerHTML={{ __html: title }}
            ></Typography>
            {/* <Typography variant="body2" gutterBottom>{medium}</Typography>
            <Typography variant="body2" gutterBottom>{size}</Typography>
            <Typography variant="body2" gutterBottom>{lot_group}</Typography> */}
          </div>
        </Grid>

        <Grid
          item
          md={6}
          style={{ margin: "auto", textAlign: "-webkit-center" }}
        >
          <Box className="centerImage" style={{ textAlign: "center" }}>
            <div
              style={
                {
                  // height: 250,
                  //   width: 260,
                  // border: "1px solid #ccc",
                }
              }
            >
              <img
                style={
                  {
                    //   height: 250,
                  }
                }
                className="centerImageOne"
                alt="aswome"
                src={image.length ? image[0].img_url : NoImage}
              />
              {Datastatus === "" ? (
                sold
              ) : Datastatus === "SOLD" ? (
                <div
                  class="overlayDeclaratorCurrent"
                  style={{ color: "green" }}
                >
                  {" "}
                  SOLD{" "}
                </div>
              ) : Datastatus === "RESERVE_NOT_MET" ? (
                <div
                  className="overlayDeclaratorCurrent"
                  style={{ color: "#ff4949", paddingTop: "30%" }}
                >
                 RESERVE NOT MET
                </div>
              ) :
              Datastatus === "UNSOLD" ? (
                <div
                  className="overlayDeclaratorCurrent"
                  style={{ color: "#ff4949" }}
                >
                  UNSOLD
                </div>
              ): ""
              }
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Current;
