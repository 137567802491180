import React from 'react';

function PrivateSales(props) {
    return (
        <div>
            
        </div>
    );
}

export default PrivateSales;