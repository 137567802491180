import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Margin } from "@mui/icons-material";
import CookieConsent from "react-cookie-consent";

const Footer = (props) => {

  const matches = useMediaQuery("(min-width:1000px)");


  return (
    <div
      className="footer-area"
      style={{ paddingTop: matches === true ? 10 : 20, marginTop: 50 ,  }}
    >
      <div className="container" >
        <div className="row">
          <div
            className="col-xl-5  wow fadeInUp animated"
            data-wow-delay="100ms"
            style={{
              visibility: "visible",
              animationDelay: "100ms",
              animationName: "fadeInUp",
     
            }}
          >
            <div className="single-footer-widget-style2 marbtm50">
              <div className="our-company-info">
                <div className="text">
                  <p style={{fontSize: 14}}>
                    The Philippines’ most trusted auction house. Specialists in
                    culturally important &amp; museum-quality Philippine art and
                    purveyors of fine Philippine antiques and historical
                    objects. We sell a myriad of unique items including Old
                    Master Drawings and Paintings, Modern and Contemporary Art,
                    Furniture, Silver, Rare Books, Maps and Documents, Jewelry,
                    Watches, Rugs and Carpets, Sculptures, Photographs and
                    Ephemera.
                  </p>
                  <ul>
                    <li>
                      <div className="icon">
                        <span className="flaticon-clock-1"></span>
                      </div>
                      <div className="title">
                        <h5 style={{fontSize: 14}}>Daily 9:00 am–6:00 pm Monday to Saturday</h5>
                        <h5 style={{fontSize: 14}}>Sundays: Closed or By Special Appointment</h5>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="flaticon-phone"></span>
                      </div>
                      <div className="title">
                        <h5>
                          <a  style={{fontSize: 14}} href="tel:16234569400">(02) 8856 2781</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <span className="flaticon-global"></span>
                      </div>
                      <div className="title">
                        <h5>
                          <a href="https://leon-gallery.com">
                            www.leon-gallery.com
                          </a>
                          <br />
                          <a href="mailto:info@leon-gallery.com">
                            info@leon-gallery.com
                          </a>
                        </h5>
                       

                       
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-xl-7  wow fadeInUp animated"
            data-wow-delay="100ms"
            style={{
              // visibility: "visible",
              // animationDelay: "100ms",
              // animationName: "fadeInUp",
            }}
          >
            <div className="single-footer-widget-style2">
              <div
                className="footer-map-holder"
                style={{ width: "100%", margin: "auto", paddingRight: "auto" }}
              >
                <div className="map-outer">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.764979830035!2d121.01449631528949!3d14.55542798983128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c90dd9192e7b%3A0x336db9c4dc478ca3!2sLe%C3%B3n%20Gallery!5e0!3m2!1sen!2sph!4v1588209457962!5m2!1sen!2sph"
                    height="300"
                    frameborder="0"
                    style={{ border: 0, width: "100%" }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
                {/* <div
                  className="address-box"
                  style={{ right: matches === true ? 0 : 75 }}
                >
                  <h3>Address :</h3>
                  <p>
                    G/F Eurovilla I, V.A. Rufino corner Legazpi Streets, Legazpi
                    Village, Makati City, Metro Manila Philippines 1500
                  </p>
                </div> */}
                <div className="footer-social-links" style={{marginBottom: 50,
    paddingBottom: 35}}>
                  <div className="title" style={{fontSize: 18}}>Follow Us : &nbsp;&nbsp; </div>
                  <ul
                    className="social-links-style1"
                    style={{ display: "contents" }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/LeonGalleryMakati/"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/leongalleryph"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@leon-gallery.com">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCcgsLbrD84mc26EuWzVptzA"
                        target="_blank"
                      >
                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       {/* <div classNameName="" >
        <div classNameName="">
          <div classNameName="copyright-text text-center" style={{padding:10}}>
            <p style={{color: "white"}}>©Leon Art Gallery 2024</p>
          </div>
        </div>
      </div> */}

      <CookieConsent
        location="bottom"
        buttonText="Confirm"
        cookieName="myAwesomeCookieName2"
        // style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#ffff", fontSize: "18px", background: 'rgb(107 105 95)' }}
        expires={150}
        style={{background: 'rgb(48 54 56)',width: '100%',
        // top: '39%', position: 'absolute'
      }}
      >
          <div classNameName="container">
          <h3 style={{color: 'white'}}>Cookie policy</h3>
            We use cookies to improve our services and improve your experience. If
            you do not accept the optional cookies below, this may affect your
            experience. 
            {/* If you want to know more, please read the cookie policy.
            <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
          </div>

      </CookieConsent>
    </div>
  );
};

export default Footer;
