import React, { useCallback, useEffect, useState } from "react";
import { actionByID } from "../../Model/Auction";
import Lot from "./Lot";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Banner from "./Banner";

const View = () => {
  const [loading, setLoading] = useState(false);
  const [showHp, setShowhp] = useState("");
  const auctionID = localStorage.getItem("items");
  const [data, setData] = useState(undefined);
  const [islive, setIslive] = useState("");
  
  // const FetchBanner = useCallback(async () => {
  //   setLoading(true);
  // }, []);

  useEffect(() => {
    const getBanner = async () => {
      const results = await actionByID(auctionID);

      setData(results);
      // console.log("Dsadasd: ",results)
      results.map((val) => {
        setIslive(val.isLive)
        setShowhp(val.showHp);
      });

      setLoading(false);
    };
    getBanner().catch(console.error);
  }, []);

  return (
    <div>
      {data ? (
        <>
          <Banner data={data} /> <Lot showHp={showHp} islive={islive} />
        </>
      ) : (
        <div
          style={{
            width: "100%",
            padding: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "10px",
                left: "2px",
                fontSize: 12,
              }}
            >
                Loading
            </span>
            <CircularProgress size="3rem" />
          </Box>
        </div>
      )}
    </div>
  );
};

export default React.memo(View);
