import React, { useCallback, useEffect, useState } from "react";
import { banner } from "../../Model/home";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";

function AuctionBanner(props) {
  const matches = useMediaQuery("(min-width:1000px)");
  // const [bannerImage, setBannerImage] = useState([]);
  // const [loading, setLoading] = useState(false);

  // const FetchBanner = useCallback(async () => {
  //   setLoading(true);
  //   async function getBanner() {
  //     const results = await banner();

  //     setBannerImage(results);
  //     setLoading(false);
  //   }
  //   getBanner().catch(console.error);
  // }, []);

  // useEffect(() => {
  //   FetchBanner();
  // }, [FetchBanner]);

  return (
    <section class="main-slider style1">
      <div>
        {/* <div class="sec-title text-center">
            <p>OUR LATEST NEWS</p>
            <div class="big-title"><h1>Interviews & Publications</h1></div>
        </div> */}

        <div class="row" style={{ backgroundColor: "#eaecef" }}>
          <div class="col-xl-12 ">
            <div
              style={{
                fontSize: matches === false ? 30 : 60,
                padding: 50,
                textAlign: "center",
              }}
            >
             Auction Results
            </div>

            <div class="col-md-12 text-center"></div>
          </div>
        </div>
      </div>

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </section>
  );
}

export default AuctionBanner;
