export const linksData = [


  // {
  //    to: "/auctions/room",
  //  // to: "/live",
  //   name: "LIVE AUCTION",
  //   handle: "handleClickLive",
  //   class: "",
   
  // },
  {
    to: "/home",
    name: "HOME",
    title: "Home",
    handle: "homehandle",
    icon: "fa fa-home",
    class: "",
  },
  {
    to: "/auctions",
    name: "AUCTIONS",
    title: "Auctions",
    handle: "upcommingEventsHandle",
    icon: 'fa fa-gavel',
    class: "",
    dropdown: [
      {
        to: "/auctions",
        name: "UPCOMING AUCTIONS",
        handle: "upcommingEventsHandle",
      },
      {
        to: "/results",
        name: "AUCTIONS RESULTS",
        handle: "handleResults",
      },
    ],
  },
  {
    to: "/exhibition",
    name: "EXHIBITION",
    title: "Exhibition",
    handle: "handleExhibition",
    icon: "fa fa-picture-o",
    class: ""

  },
  //   PRIVATE SALE  to: "/buy/jewelry?data=JEWELRY", /private-sale/PRIVATE_SALE
  {
  
    name: "PRIVATE SALE",
    title: "Private Sale",
    handle: "",
    icon: "fa fa-shield",
    class: "dropdown",
    dropdown: [
      {
        to: "/private/sale",
        name: "PAINTING",
        handle: "handlePrivatePainting",
        class: "",
      },
      {
        to: "/private/sale",
        name: "SCULPTURE",
        handle: "handlePrivateSCULPTURE",
        class: "",
      },
      {
        to: "/private/sale",
        name: "FURNITURE",
        handle: "handlePrivateFurniture",
        class: "",
      },
      {
        to: "/private/sale",
        name: "JEWELRY",
        handle: "handlePrivateJewelry",
        class: "",
      },
      {
        to: "/private/sale",
        name: "OBJET D' ARTS",
        handle: "handlePrivateARTS",
        class: "",
      },
      {
        to: "/private/sale",
        name: "MAPS",
        handle: "handlePrivateMAPS",
        class: "",
      },
      {
        to: "/private/sale",
        name: "WATCHES",
        handle: "handlePrivateWATCHES",
        class: "",
      },

      {
        to: "/private/sale",
        name: "MID-CENTURY",
        handle: "handlePrivateMID-CENTURY",
        class: "",
      },


      {
        to: "/private/sale",
        name: "BOOKS",
        handle: "handlePrivateBOOKS",
        class: "",
      },
    ],
  },

  {
    to: "/stories",
    name: "STORIES",
    title: "Stories",
    handle: "handleClickStories",
    icon: "fa fa-book",
    class: "",

  },
  {
    // to: "/auctions/room",

    name: "GALLERIES",
    title: "Galleries",
    // handle: "handleAuctionsRoom",
    icon: "fa fa-building",
    class: "dropdown",
    dropdown: [
      {
        to: "/location/main",
        name: "MAIN",
        handle: "handleMain",
        class: "",
      },
      {
        to: "/location/international",
        name: "INTERNATIONAL",
        handle: "handleInternational",
        class: "",
      },
      {
        to: "/location/lafuerza",
        name: "LA FUERZA",
        handle: "handleLafuerza",
        class: "",
      },
      // {
      //   to: "/location/maison",
      //   name: "MAISON",
      //   handle: "handleMaison",
      //   class: "",
      // },
    ],
  },
  // {
  //   to: "/faq",
  //   name: "FAQ",
  //   handle: "handleFaq",
  //   class: "",
  //   dropdown: [
  //     {
  //       to: "/how_to_sell",
  //       name: "HOW TO SELL",
  //       handle: "handleHowtosell",
  //     },
  //     {
  //       to: "/how_to_transfort",
  //       name: "HOW TO TRANSFORT",
  //       handle: "handleHowtotransfort",
  //     },
  //     {
  //       to: "/where_do_i_go",
  //       name: "WHERE DO I GO",
  //       handle: "handleWhereDO",
  //     },
  //     {
  //       to: "/how_to_consign",
  //       name: "HOW TO CONSIGN",
  //       handle: "handleHowToConsign",
  //     },
  //   ],
  // }
];
