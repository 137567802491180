import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import useInfiniteScroll from "./useInfiniteScroll";
import { auctionsList } from "../../Model/Auction";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const View = () => {
  // const [search, setSearch] = useState("");

  // function handleSearch() {
  //   alert(search);
  // }

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [online, setOnline] = useState(null);
  const [exhibition, setExhibition] = useState(null);
  const [live, setLive] = useState(null);
  const [countLast, setCountLast] = useState(null);
  const fetchData = (page, live, exhibition, online) => {
    // console.log("countLast: ", countLast);
    // console.log("items.length: ", items.length);
    setIsLoading(true);
    setError(null);
    console.log("countLast: ", countLast === items.length);
    console.log("items.length: ", items.length);
    if (countLast === items.length) {
      console.log('okkkk')
     
      setIsLoading(false);
    } else {
      try {
        // console.log('else')
        async function getAuction() {
          const results = await auctionsList(page, live, exhibition, online);
          //  console.log("results.count",results.ms);
          setItems((prevItems) => [...prevItems, ...results.msg]);
          setCountLast(results.count);
          // setPage(prevPage => prevPage + 1);
          setIsLoading(false);
        }
        getAuction().catch(console.error);

        const timer = setTimeout(() => {
          getAuction();
        }, 5000);

        return () => clearTimeout(timer);
      } catch (error) {
        setError(error);
      } finally {
        //setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(page, live, exhibition, online);
  }, []);

  // const handleScroll = () => {
  //   if (
  //     document.documentElement.scroll + window.scr ===
  //     document.documentElement.offsetHeight
  //   ) {
  //     fetchData(page, live, exhibition, online);
  //     setPage(page + 1);
  //   }
  // };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.scrollHeight ||
      isLoading
    ) {
      return;
    }
    fetchData(page + 1, live, exhibition, online);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  const handleCheck = (event) => {
    setCountLast(2);
    if (event.name === "live") {
      // console.log(event.value);
      setItems([]);
      setLive(event.value);
      return fetchData(1, event.value, exhibition, online);
    } else if (event.name === "exhibition") {
      setItems([]);
      setExhibition(event.value);
      return fetchData(1, live, event.value, online);
    } else if (event.name === "online") {
      setItems([]);
      setOnline(event.value);
      return fetchData(1, live, exhibition, event.value);
    } else {
      // setSearch(event.target.value);
    }
    //  fetchData(1,live,exhibition,online)
  };
  return (
    <div>
      <section className="blog-single-area" style={{ padding: "50px 0 120px" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className="sidebar-wrapper">
                <div className="sidebar-search-box">
                  {/* <div className="search-form">
                    <input
                      placeholder="Search"
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="submit" onClick={handleSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div> */}
                </div>
                <div className="single-sidebar">
                  <div className="title">
                    <h3>Categories</h3>
                  </div>
                  <form>
                    <ul
                      className="categories clearfix"
                      style={{ fontSize: 20 }}
                    >
                      <li>
                        <input
                          onChange={(e) =>
                            handleCheck({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          }
                          type="checkbox"
                          name="live"
                          value="LIVE_AUCTION"
                        />
                        <span> Live Auction</span>
                      </li>
                      <li>
                        <input
                          onChange={(e) =>
                            handleCheck({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          }
                          type="checkbox"
                          name="online"
                          value="ONLINE_AUCTION"
                        />
                        <span> Online Auction</span>
                      </li>
                      <li>
                        <input
                          onChange={(e) =>
                            handleCheck({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          }
                          type="checkbox"
                          name="exhibition"
                          value="EXHIBITION"
                        />
                        <span> Exhibition</span>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div className="row">
                {items.map((item, i) => {
                  var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var date = new Date(item.date_start);

                  return (
                    <div className="col-xl-4" key={i}>
                      <div className="containerImage">
                        <img
                          className="imageCenter"
                          src={
                            item.cover === null
                              ? "images/resources/welcome-image.png"
                              : item.cover
                          }
                          alt="Awesome"
                          style={{ paddingTop: 40 }}
                        />

                       
                         <a className="hide" href="/lot/view"> <h3 >View</h3></a>
                   
                      </div>
                      <div
                        // className="text"
                        style={{ paddingTop: 1 }}
                      >
                        <p style={{ marginBottom: 10, fontSize: 12 }}>
                          {item.c_type}
                        </p>
                        <p style={{ marginBottom: 0, fontSize: 20 }}>
                          {item.d_desc}
                        </p>
                        <p style={{ marginBottom: 0, fontSize: 20 }}>
                          {date.toLocaleDateString("en-US", options)}
                        </p>
                        {/* {item.d_desc} */}
                      </div>
                    </div>
                  );
                })}

                {/* {isLoading === true ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  ""
                )} */}
              </div>

              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "10%",
                  paddingTop: 25,
                }}
              >
                {isLoading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}
                {error && <p>Error: {error.message}</p>}
              </div>

              {/*           {items.map((item) => {
        //               return (
        //                 <div className="row ">
        //                      <div class="col">
        //               <div className="img-holder col-xl-3 col-lg-6 col-md-6 col-sm-12" key={item.id}>

        //                 <img src={item.cover} alt="Awesome" />
        //                 <div className="">
        //                   <div className="">
        //                     <Link
        //                       className="lightbox-image"
        //                       data-fancybox="gallery"
        //                       to="images/art/art-v2-1.jpg"
        //                     >
                          
        //                     </Link>
        //                   </div>
        //                 </div>
        //                 <div
        //                 className="text"
        //                 style={{ fontSize: "15px", padding: "5px" }}
        //               >
        //                 {item.d_desc}
        //               </div> 
        //               </div>                       
        //               </div>
        //               </div>
        //              )})}

                      {/* <div
                        className="button-box button button5"
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          display: "block",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        VIEW view
                      </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* {isLoading && (
        <Box sx={{ display: "flex"}}>
          <CircularProgress />
        </Box>
      )} */}
    </div>
  );
};

export default View;
