import { window } from "globalthis/implementation";

export function homehandle() {

  localStorage.removeItem("@imageSingle");
  return (window.location.href = `/home?data=home`);
}

export function abouthandle() {
  localStorage.removeItem("@imageSingle");
  return (window.location.href = `/about?data=about us`);
}

export function upcommingEventsHandle(data) {
  localStorage.removeItem("@imageSingle");
  return (window.location.href = `/auctions`);
}

export function handleResults(data) {

  return (window.location.href = `/results?data=${data}`);
}

export function handleJewelry(data) {
  //console.log(data)
  if (data === "JEWELRY" || data === "WATCHES") {
    return (window.location.href = `/buy/private-sale/jewelry?data=${data}`);
  } else {
    return (window.location.href = `/buy/jewelry?data=JEWELRY`);
  }
}

export function handleWatches(data) {
  return (window.location.href = `/buy/watches?data=${data}`);
}

export function handlebags(data) {
  return (window.location.href = `/buy/handbags?data=${data}`);
}

export function handleAuctionsRoom() {
  return (window.location.href = `/auctions/room`);
}

export function handleFaq() {
  localStorage.removeItem("imageSingle");
  return (window.location.href = `/faq?data=faq`);
}

// export function handlePrivate(data) {
//   return (window.location.href = `/private-sale/`);
// }

export function handlePrivatePainting(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handlePrivateFurniture(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handlePrivateJewelry(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handlePrivateARTS(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handlePrivateBOOKS(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handlePrivateSCULPTURE(data) {
  return (window.location.href = `/private/sale?data=${data}`);
}

export function handleMain(data) {
  return (window.location.href = `/location/main?data=${data}`);
}

export function handleInternational(data) {
  return (window.location.href = `/location/international?data=${data}`);
}

export function handleLafuerza(data) {
  return (window.location.href = `/location/lafuerza?data=${data}`);
}

// export function handleMaison(data) {
//   return (window.location.href = `/location/maison?data=${data}`);
// }

export function handleClickStories() {
  return (window.location.href = `/stories`);
}


export function handleExhibition() {
  localStorage.removeItem("@imageSingle");
  return (window.location.href = `/exhibition`);
}


export function handleClickLive() {

  return (window.location.href = `/auctions/room`);
  // return (window.location.href = `/live`);
}

