import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auction } from "../../Model/home";
import Carousel from "react-multi-carousel";
import CircularProgress from "@mui/material/CircularProgress";
import "react-multi-carousel/lib/styles.css";
import NoImage from "./../../images/Banner/no_image.jpeg";
import useMediaQuery from "@mui/material/useMediaQuery";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 3, // optional, default t1o 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ExploreAuction() {
  const matches = useMediaQuery("(min-width:480px)");
  const matchessss = useMediaQuery("(min-width:900px)");
  const [getauction, setAuctions] = useState([]);
  const [slideValue, setSlideValue] = useState(0);

  const [loading, setLoading] = useState(true);

  // function CustomRightArrow({ onClick }) {
  //   function handleClick() {
  //     // do whatever you want on the right button click
  //     setSlideValue(slideValue + 50);
  //     // ... and don't forget to call onClick to slide
  //     onClick();
  //   }

  //   return (
  //     <button
  //       onClick={handleClick}
  //       aria-label="Go to next slide"
  //       className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
  //     />
  //   );
  // }

  useEffect(() => {
    // react-multiple-carousel__arrow react-multiple-carousel__arrow--left

    try {
      async function getAuctionOne() {
        const results = await auction();
        if (results.msg === false) {
          setAuctions([]);
        } else {
          setAuctions(results);
        }
        setLoading(false);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  }, []);

  const GotoPage = (id, slug, type, imageCover,d_name) => {
    localStorage.setItem("items", id);
    localStorage.setItem("cover", imageCover);

    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${id}/${d_name}/${type}`;


    } else {
      window.location.href = `/auction/lot/${id}/${slug}/${type}`;
    }
  };

  return (
    <div>
      <section className="latest-blog-style3-area">
        <div className={matches === true ? "container" : ""}>
          <div className={matches === true ? "row" : ""}>
            <div className="col-xl-12">
              <div className="top">
                <div className="sec-title pull-left">
                  {/* <p>OUR LATEST NEWS</p> */}
                  <div className="big-title">
                    <h1>AUCTION</h1>
                  </div>
                </div>
                <div className="view-more-button pull-right">
                  <Link className="btn-two" to="/auctions">
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {loading === true ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Carousel
              
              responsive={responsive}
              itemClass="carousel-itemAction"
              style={{ flex: "1 1 auto", position: "relative", width: "468" }}
            >
              {getauction.length ? (
                getauction.map((row, index) => {
                  var options = {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  var date = new Date(row.date_start);

                  return (
                    <div
                      className={matches === true ? "col-xl-12" : ""}
                      key={index}
                      style={{
                        border: 0,
                        // width: row.c_type === "EXHIBITION" ? "100%" : "",
                      }}
                    >
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          GotoPage(row.id, row.c_slug, row.c_type, row.cover,row.d_name)
                        }
                      >
                        <div

                        // style={{ border: 0,height:' 350px', width: '400px'}}
                        >

<div   style={{backgroundImage: `url(${ row.cover === null
                                ? "https://placehold.co/321x454?text=IMAGE%20NOT%20AVAILABLE"
                                : row.cover})`,  
                              backgroundColor: '#ffffff',
                              width: '100%',
                              height:  matches === true ? 475 : 520,
                              display: 'inline-block',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              padding: 10,
                            }}></div> 



                          {/* <img
                            style={{ border: 0, width: "100%" }}
                            src={
                              row.cover === null
                                ? "https://placehold.co/321x454?text=IMAGE%20NOT%20AVAILABLE"
                                : row.cover
                            }
                            // className="card-img-top"
                            alt={index}
                          /> */}

                          <div style={{ paddingBottom: 15, lineHeight: "15px" }}>
                            <div
                              // class="card-body"
                              style={{
                                textAlign: "left",
                                paddingLeft: matches === true ? 0 : 15,
                              }}
                            >
                              <p
                                style={{
                                  fontSize: matches === true ? 14 : 16,
                                  color: "#2d353d",
                                }}
                              >
                                {row.c_type.replace("_", " ")}
                              </p>
                              

                              <p
                                class=""
                                style={{
                                  fontSize: matches === true ? 18 : 20,
                                  color: "#2d353d",
                                  lineHeight: 1,
                                }}
                              >
                                {row.d_name}
                              </p>
                              <p
                                style={{
                                  fontSize: 15,
                                  color: "#2d353d",
                                }}
                              >
                                {date.toLocaleDateString("en-US", options)}
                              </p>
                            </div>

                            {/* <div
                    
                          class="btn"
                          style={{
                            borderColor: "#000",
                            width: "-webkit-fill-available",
                          }}
                        ><p class="card-text" style={{color:'#2d353d'}}>
                          {date.toLocaleDateString("en-US", options)}
                        </p>
                          {row.c_type.replace(/_/g, " ")}
                        </div> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })
              ) : (
                <div style={{ withd: "100%" }} key={1}>
                  <div class="alert alert-danger" role="alert">
                    No Record Found
                  </div>
                </div>
              )}
            </Carousel>
          )}

          {/* </div>
          </div> */}
        </div>
        {/* <div class="slidecontainer">
          <input
            type="range"
            min="1"
            max="100"
            value={slideValue}
            class="slider"
          />
        </div> */}
      </section>
    </div>
  );
}

export default React.memo(ExploreAuction);
