import React, { useCallback, useEffect, useState } from "react";
import { exhibitionBannerByID } from "../../Model/Auction";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
// import BannerImage from "./exhibitionBanner.jpg"
function Banner(props) {
  const matches = useMediaQuery('(min-width:1000px)');
  var { id, slug, type } = useParams();

  const [bannerImage, setBannerImage] = useState("");
  const [artistImage, setArtistImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [end_date, setEndDate] = useState("");
  const [date_start, startDate] = useState("");
  const [description, setDescription] = useState("");

  const FetchBanner = useCallback(async () => {
    setLoading(true);
    async function getBanner() {
      const results = await exhibitionBannerByID(id);

      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      results.map((val) => {
        var startdDate = new Date(val.date_start);
        var endDate = new Date(val.date_end);
        setTitle(val.exhibit_name);
        setEndDate(endDate.toLocaleDateString("en-US", options));
        startDate(startdDate.toLocaleDateString("en-US", options));
        setBannerImage(val.banner_url)
        setArtistImage(val.artist_img_url);
        setDescription(val.exhibit_desc);
      });

      setLoading(false);
    }
    getBanner().catch(console.error);
  }, []);

  useEffect(() => {
    FetchBanner();
  }, [FetchBanner]);

  return (

    <section className="blog-single-area lotArea" style={{marginTop: matches === false ? 5:30, marginBottom: matches === false ? 5:30}} >
        <div className="container">
          <div className="row">
          <div class="col-xl-12 text-center">
            <div class="" style={{ marginTop: 20 }}>
              <div style={{fontSize: matches === false ? 25:35,padding:  matches === false ? 0:30}}>{title}</div>
              <span style={{fontSize: matches === false ? 15:30,padding: 15}}>{`${date_start} - ${end_date}`}</span>
            </div>
            <div class="col-md-12 text-center"></div>
          </div>
          </div>
        </div>
      </section>

  );
}

export default Banner;
