
import React, { useState, useRef, useCallback, useEffect } from "react";
import LotData from "./LotData";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { makeStyles } from "@mui/styles";
import { storeAuctionLot } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemIcon from "@mui/material/ListItemIcon";
// import { Button } from "@mui/material";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "pink",
      color: "red",
      "&:hover": {
        backgroundColor: "tomato",
      },
    },
  },
  selected: {},
});

export default function Lot({ data }) {
  const matches = useMediaQuery("(min-width:1000px)");
  var { slug, type } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [heart, setHeart] = React.useState(1);

  const [search, setSearch] = useState("");
  const [auctionID, setAuctionID] = useState("");

  const { lotdata, hasMore, loading, error, errorMsg } = LotData(
    pageNumber,
    data,
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );


 useEffect(() =>{
  try {
    setAuctionID(data.auction_details.auction_id)
  } catch (error) {
    
  }

 },[data])

  const gotSinglePage = (slug, title, auction_id, lotNumber) => {
    const dataArray = {
      lot_id: auction_id,
      lotNo: lotNumber,
      setDetails: 100,
    };


    storeAuctionLot(dataArray);
    window.location.href = `/auction/${slug}/${title.replace(
      /(<([^>]+)>)/gi,
      ""
    )}`;
  };


  return (
    <div style={{paddingTop:50}}>
      
      <section className="blog-single-area lotArea">
        <div className="container">

                    
        <div style={{paddingTop:50,paddingBottom:42}} className="sec-title pdbtm42 text-center">
                      <p>Ongoing Lots</p>
                      <div className="big-title"><h1>Lots</h1></div>
                  </div>

              <div
                className="row"
                style={{ marginTop: matches === false ? 0 : 10 }}
              >
                
                {lotdata.filter((obj1, i, arr) => arr.findIndex(obj2 => (obj2.data.id === obj1.data.id)) === i).map((value, i) => {
                  if (lotdata.filter((obj1, i, arr) => arr.findIndex(obj2 => (obj2.data.id === obj1.data.id)) === i
                  ).length === i + 1) {
                    return (
                      <div
                        ref={lastBookElementRef}
                        className="col"
                        key={value}
                        style={{ marginBottom: 25 }}
                      >
                        <button
                          className="lotDisplayCenter"
                          style={{
                            // width: "100%",
                            // display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          onClick={() =>
                            gotSinglePage(
                              slug,
                              value.data.title,
                              value.data.auction_id,
                              value.data.lot_number
                            )
                          }
                        >
                          <div
                            className="containerImage zoom"
                            style={{
                              width: matches === false ? "100%" : 280,
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              className={
                                matches === false
                                  ? ""
                                  : "example example-contain"
                              }
                            >
                              <img
                                className="imageCenter"
                                src={
                                  value.image.length
                                    ? value.image[0].img_url
                                    : "https://placehold.co/289x374?text=NO%20IMAGE%20AVAILABLE"
                                }
                                alt={value.image}
                                // style={{ paddingTop: 40 }}
                              />
                            </div>
                          </div>

                          <div style={{ paddingBottom: 50 }}>
                            <div style={{ paddingBottom: 50,fontSize: 18, textAlign: "start" }}>
                              <span
                                className="product_info"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  // width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Lot {value.data.lot_number}
                              </span>
                              <br />
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.title}
                              </span>
                              <br />
                              <p style={{ fontSize: 20, fontWeight: "bold" }}>
                                  {value.data.lot_status === 'SOLD' ? "SOLD PHP ": "PHP "}
                                  {value.data.lot_status === 'SOLD' ? value.data.hp === null || value.data.hp === 0 ?   `PHP ` +value.data.starting_price.toLocaleString(): value.data.hp.toLocaleString():value.data.starting_price.toLocaleString()}
                                </p>
                              {/* {showHp === 0 ? (
                                ""
                              ) : (
                                <p style={{ fontSize: 20, fontWeight: "bold" }}>
                                  {value.data.hammer_price === null ||
                                  value.data.hammer_price === 0
                                    ? `PHP: ` +
                                      value.data.starting_price.toLocaleString()
                                    : `SOLD: PHP ` +
                                      value.data.hammer_price.toLocaleString()}
                                </p>
                              )} */}
                              {/* <h5>
                                {value.data.hp === null
                                  ? `STARTING PRICE PHP:` +
                                    value.data.starting_price.toLocaleString()
                                  : `SOLD PHP: ` +
                                    value.data.hp.toLocaleString()}
                              </h5> */}
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="col"
                        key={value}
                        style={{ marginBottom: 25 }}
                      >
                        <button
                          className="lotDisplayCenter"
                          style={{
                            width: "100%",
                         // display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          onClick={() =>
                            gotSinglePage(
                              slug,
                              value.data.title,
                              value.data.auction_id,
                              value.data.lot_number
                            )
                          }
                        >
                          <div
                            className="containerImage zoom"
                            style={{
                              width: "100%",
                              // width: matches === false ? "100%" : 280,
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div
                              className={
                                matches === false
                                  ? ""
                                  : "example example-contain"
                              }
                            >
                              <img
                                className="imageCenter"
                                src={
                                  value.image.length
                                    ? value.image[0].img_url
                                    : "https://placehold.co/289x374?text=NO%20IMAGE%20AVAILABLE"
                                }
                                alt={value.image}
                                // style={{ paddingTop: 40 }}
                              />
                              {/* <h3>View</h3> */}
                            </div>
                          </div>

                          <div style={{ paddingBottom: 50 }}>
                            <div style={{ fontSize: 18, textAlign: "start", lineHeight: 1.3}}>
                            <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        
                        }}
                      >

                                <span className="product_info">
                                  Lot {value.data.lot_number}
                                </span>
                                <span className="product_info">
                                  {heart === 1 ?   <FavoriteIcon fontSize="small" style={{color: 'red'}} />:  <FavoriteBorderIcon fontSize="small" />
                                  }
                            
                                </span>
                                  </div>

                           
                              <br />
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.title === null
                                  ? ""
                                  : value.data.title.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )}
                              </span>
                              <br />

                              {value.data.artist === null ? (
                                ""
                              ) : (
                                <>
                                  <span className="product_info title_lot">
                                    {value.data.artist}
                                  </span>
                                  <br />
                                </>
                              )}
                              <p style={{ fontSize: 20, fontWeight: "bold" }}>
                                  {value.data.lot_status === 'SOLD' ? "SOLD PHP ": "PHP "}


                                  {value.data.lot_status === 'SOLD' ? value.data.hp === null || value.data.hp === 0 ?   `PHP ` +value.data.starting_price.toLocaleString(): value.data.hp.toLocaleString():value.data.starting_price.toLocaleString()}
                                </p>
                              
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  }
                })}

                {/* {isLoading === true ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  ""
                )} */}
              </div>

              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  // width: "10%",
                  // paddingTop: 105,
                }}
              >
                <div>{}</div>
                {/* {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}*/}
                {/* <div>{loading &&   <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>}</div> */}
                {error ? (
                  <p></p>
                ) : loading === true ? (
                  <div
                    style={{
                      width: "100%",
                      padding: 100,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", position: "relative" }}>
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "2px",
                          fontSize: 12,
                        }}
                      >
                        Loading
                      </span>
                      <CircularProgress size="3rem" />
                    </Box>
                  </div>
                ) : (
                  ""
                )}
                
              </div>
              {error === true ?   ( <div 
              style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "100%",
                  paddingTop: 50,
                  paddingBottom: 50,
                }}>{lotdata.length === 0 ? `No results for: ${search}`:"" }
                {error}
                </div>) : ""}
            </div>
     
      </section>

      {/* {isLoading && (
        <Box sx={{ display: "flex"}}>
          <CircularProgress />
        </Box>
      )} */}
    </div>
  );
}
