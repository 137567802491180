import React from 'react';
import View from './view'
import Page from '../../components/Page'
function index(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search); 
    const data = params.get('data'); 
    return (
        <Page title="Hands Bag">
            <View param={data}/>
        </Page>
    );
}

export default index;