import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Faq(props) {
  localStorage.removeItem("@storeAuctionLot");
  const matches = useMediaQuery("(min-width:1000px)");
  return (
    <div>
      <section class="main-slider style1">
        <div>
          {/* <div class="sec-title text-center">
            <p>OUR LATEST NEWS</p>
            <div class="big-title"><h1>Interviews & Publications</h1></div>
        </div> */}

          <div class="row" style={{ backgroundColor: "#eaecef" , width: '100%',margin: 'auto'}}>
            <div class="col-xl-12 ">
              <div
                style={{
                  fontSize: matches === false ? 30 : 60,
                  padding: 50,
                  textAlign: "center",
                }}
              >
                Frequently asked questions
              </div>

              <div class="col-md-12 text-center"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div style={{ padding: 50, paddingTop: 50, paddingBottom: 50 }}>



            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Where can I sign up to bid for your auctions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello! Please click on this link to find out how to bid on our lots. Thank you!  <br /><a href="/howtobuy">Read more</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  How can I buy pieces that is not in the auction?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello! You can visit us in our locations that you can find on our website. Click here:   <br /><a href="/home">Read more</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  I have a piece I would like to sell. Can you help?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello and YES we can help! Please click on this link to see what we can do for you.. <br /><a href="/howtosell">Read more</a>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Where can I see the schedules of your events?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello! You can visit on our website, which is updated regularly, to see our schedules of exhibits and auctions. Or you can like and follow our social media pages. Thank you! Click here:  <br /><a href="/exhibition">Read more</a>
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Do you have a schedule for your auctions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello! You can visit on our website, which is updated regularly, to see our schedules of exhibits and auctions. Or you can like and follow our social media pages. Thank you! Click here: <br /><a href="/exhibition">Read more</a>
                </Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Where are you located?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  You can find our locations on our website at: <a href="/home">Read more</a>  Thank you!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Is there parking?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  For Eurovilla: There's parking on both the Rufino side and Rodriguez side
                  For La Fuerza: Yes, there is parking right outside the gallery.
                  For LGI (Corinthian Plaza): There is limited parking outside. We suggest parking in Greenbelt 1.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                Do you only buy and sell art or do you buy and sell other items? 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <b>ANSWERS</b> <br />
                  Hello! We are interested in all design and art, which is not limited to the canvas or stone. We do keep a look out for jewelry pieces or objects d'arts for the home, as well as watches and time pieces as well. Send us a message please at this email with a CLEAR picture of the piece you would like to inquire about, and all the information you can give us regarding this piece. 
                </Typography>
              </AccordionDetails>
            </Accordion>

          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
