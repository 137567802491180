import axiosInstant from "../axiosInstant"

  export async function login(ArrayData) {
      const response = await axiosInstant
        .post(`Login/`, ArrayData)
        .then((res) => {
          //  console.log("login console : ",res.data);
          return res.data;
        })
        .catch((error) => {
        // console.log("login console : ",error.response.data.errors);
          // console.log(error.response.data.errors);
        return error.response.data
        });
      return response;
    }




