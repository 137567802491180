import { Axios } from "axios";
import axiosInstant from "../axiosInstant"

export async function banner() {
    const response = await axiosInstant
      .get(`home/banner`).then((res) => {
        // let row = {};
        // res.data.data.map((val) => {
        //     return (row = val);
        // });
        // return row;
        // console.log(res.data.data);
         return res.data.data;
      })
      .catch((error) => {
        console.log(error.response.data);
        //return error.response.data.message;
      });
    return response;
  }

  export async function publicationApi() {
    const response = await axiosInstant
      .get(`home/press`).then((res) => {
         return res.data.data;
      })
      .catch((error) => {
        console.log(error.response.data);
        //return error.response.data.message;
      });
    return response;
  }

  export async function videoApi() {
    const response = await Axios
      .get(`http://188.166.186.239/wp-json/wp/v2/posts?_embed=wp:featuredmedia,wp:term&_fields=id,date,title,excerpt,_links&order=desc&categories=3,4,5&per_page=6&page=2`).then((res) => {
        // console.log('videos: ',res.data.data )
         return res.data.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });
    return response;
  }


  // async function getUserAccount() {
  //   return await  axiosInstant.get('home/videos');
  // }
  
  // async function getUserPermissions() {
  //   return await axiosInstant.get('home/press');
  // }



  export async function GetTwoCall() {

   const Promise1 =  axiosInstant.get('home/press')
  //const Promise2 =  axiosInstant.get('home/videos')
    const response = await  Promise.all([Promise1]) .then(function (responses) {
    const resp1 = responses[0].data.data
    // const resp2 = responses[1].data.data

    const DAta = [
      {
        videoId: "AsaZDCwUYe4",
        title: "Arturo Luz&#39; &quot;Obu&quot;",
        publishedAt: "2023-11-09T05:21:34Z",
        description: "",
        thumbnails: {
          one: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/default.jpg",
            width: 90,
            height: 160,
          },
          two: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/mqdefault.jpg",
            width: 480,
            height: 120,
          },
          three: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
          four: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
        },
      },
      {
        videoId: "AsaZDCwUYe4",
        title: "Arturo Luz&#39; &quot;Obu&quot;",
        publishedAt: "2023-11-09T05:21:34Z",
        description: "",
        thumbnails: {
          one: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/default.jpg",
            width: 90,
            height: 160,
          },
          two: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/mqdefault.jpg",
            width: 480,
            height: 120,
          },
          three: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
          four: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
        },
      },
      {
        videoId: "AsaZDCwUYe4",
        title: "Arturo Luz&#39; &quot;Obu&quot;",
        publishedAt: "2023-11-09T05:21:34Z",
        description: "",
        thumbnails: {
          one: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/default.jpg",
            width: 90,
            height: 160,
          },
          two: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/mqdefault.jpg",
            width: 480,
            height: 120,
          },
          three: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
          four: {
            url: "https://i.ytimg.com/vi/AsaZDCwUYe4/hqdefault.jpg",
            width: 100,
            height: 50,
          },
        },
      },
    ];
    console.log('DataData: ', resp1)
     return  [...resp1, ...DAta];

  })
    return response;
  }



export async function auction() {
  const response = await axiosInstant
    .get(`home/auction`).then((res) => {
    
      const result = res.data.data.filter(
        (x) =>
          x.c_type === "LIVE_AUCTION" || x.c_type === "ONLINE_AUCTION" 
      );

      
       return result;
    })
    .catch((error) => {

      return Promise.reject(error);
      //console.log(error.response.data);
      //return error.response.data.message;
    });
  return response;
}