import React, { useCallback, useEffect, useState } from "react";
import { auctionsLive,nextAuction } from "../../Model/Auction";
import Lot from "./Lot";
import Box from "@mui/material/Box";
import AboutUSs from "./../../images/resources/aboutus.png";
import useMediaQuery from '@mui/material/useMediaQuery';

import Banner from "./Banner";

const View = ({data}) => {
  const matches = useMediaQuery('(min-width:900px)');
  const [message, setMessage] = useState("");
  const [auctionData, setAuctionData] = useState([]);
  const auctionID = localStorage.getItem("items");

  const [islive, setIslive] = useState("");
  
  // const FetchBanner = useCallback(async () => {
  //   setLoading(true); 
  // }, []);

  

  useEffect(() => {
    const getBanner = async () => {
      const results = await auctionsLive();
      if(results.success === false){
        setIslive(results.success )
        setMessage(results.msg)
      }else{
        setIslive(true)
        setAuctionData(results);
      }
      
    };
    getBanner().catch(console.error);
  }, [data]);




  return (
    <div style={{marginTop:70,marginBottom:50}}>
      
  {islive === false ? <section 
        className="breadcrumb-area"
        // style={{padding: "185px 0 25px"}}
        style={{ backgroundImage: `url(${AboutUSs})`,marginTop:0, filter:" grayscale(90%) " ,padding: matches === true ?  "":35  }}
      >
  
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="inner-content clearfix">
                <div className="title" style={{maxWidth: "100%"}}>
                {matches === true ?  <h1 style={{ whiteSpace: "pre-line"}}>{message}</h1>: <p style={{ whiteSpace: "pre-line", color: "white" ,fontSize: 20}}>{message}</p>}
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>:
        <div className="row">          
       <div className="col-xl-12">
     <Banner data={auctionData} />

      <Lot data={auctionData} />
      </div> </div>}



   
   

        {/* <div
          style={{
            width: "100%",
            padding: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "10px",
                left: "2px",
                fontSize: 12,
              }}
            >
                Loading
            </span>
            <CircularProgress size="3rem" />
          </Box>
        </div> */}


    </div>
  );
};

export default React.memo(View);
