import { Divider, Box, Typography, Button } from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Listcolor from "../assets/color";
import useMediaQuery from "@mui/material/useMediaQuery";
import BellSound from "../assets/bell_ring.mp3";
import useSound from "use-sound";
import { getUser } from "../../Model/LocalStorage";
import { auctionsLive, postPlaceBid } from "../../Model/Auction";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImageRows from "./ImageRows";
import NextSlot from "./nextlot";
import { keyframes } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MessageIcon from "@mui/icons-material/Message";
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { postStatus } from "../../Model/DeclaratorModel";
import {getToken} from "../../Model/User";
import { WebRTCPlayer } from "@eyevinn/webrtc-player";
const date = new Date();




function RightLayout({ details, mediadQuery, socket, room }) {
    const token = getToken();
  const soundRef = useRef();
  const [loading, setLoading] = React.useState(false);
  const [Data, setData] = useState([]);
  const phoneMatch = useMediaQuery("(max-width:600px)");
  // const phoneMatch = useMediaQuery("width: 430px and height: 932px");
  const [userId, setUserId] = useState("");
  const [onlineUserID, setOnlineStatus] = useState(null);

  //  const [play] = useSound(BellSound);
  const [highestBid, setHighestBid] = useState(0);
  // const [disable, setDisable] = useState("");
  const [announcement, setAnnouncement] = useState("");
  const [dataAuction, setDataAuction] = useState([]);
  const [status, setStatus] = useState("");
  const [hammerPrice, setHammerPrice] = useState("");
  const [username, setUsername] = useState("");

  const [lotNumber, setlotNumber] = useState("");
  const [getData, setGetData] = useState([]);
  const [startPrice, setStartPrice] = useState("");
  const [bidhistory, setBidHistory] = useState([]);
  const [highestNumber, setHighest] = useState("");
  const [getbp, setGetBP] = useState("");
  const [setID, setIDset] = useState("");
  const [auctionID, setAuctionID] = useState("");
  const [pause, setPause] = React.useState(false);

  const [detailsAll1, setDetailsAll1] = useState("");
  // const [detailsAll2, setDetailsAll2] = useState("");
  // const [detailsAll3, setDetailsAll3] = useState("");
  // const [detailsAll4, setDetailsAll4] = useState("");

  
  const incrementCount = (auctionID, setID, lotNumber, highestBid) => {
 
     setStatus("PAUSE")
    try {

      async function postBidFunction() {
        // setStatus(true)
        const ArrayData = {
          auction_id: auctionID,
          setId: setID,
          lot_number: lotNumber,
          amount: highestBid,
        };

    
        const results = await postPlaceBid(ArrayData);
            //  soundRef.current.play();
        if (results.success === true) {
          // alert(results.msg);
          const messageData = {
            room: room,
            status: results.success,
            amount: highestBid,
            increment: highestBid,
          };

          if(status === "FAIR_WARNING"){
            socket.emit("send_status", {
              message: "FAIR_WARNING",
        
         });
          }
   
        
          socket.emit("send_Bid_amount", messageData);



        } else if (results === false) {
          alert(`Error !: ${results}`);
        } else {
          window.location.reload();
          alert(`Error !: ${results}`);
          //alert(`Error dito!: ${results}`);
          // console.log("results: ", results);
          // alert('results');
        }
      }
      postBidFunction().catch(console.error);
    } catch (error) {
      // console.log("error in postBid: ", error);
    }



  };



  

  function SoundTrip(){


    var resp =  new Audio(BellSound).play();
    
      if (soundRef!== undefined) {
          resp.then(_ => {
            resp.autoplay = true;
            resp.current.focus();
          }).catch(error => {
            //show error
          //  console.log("error")
          });
      }

  }
  
  

  const playSound =  (data) => {
 
    const datass = data.bid_history.filter(
      (x) =>
        x.bidder_type === "INTERNET BIDDER"
    );
        function loopThroughSplittedText() {
        for (var i = 0; i < datass.length; i++) {
          // for each iteration console.log a word
          // and make a pause after it
          (function (i) {
              setTimeout(function () {
                document.getElementById("sound").click();
              }, 500 * i);
          })(i);
      };
      }
       loopThroughSplittedText();

  };
  

  const ListData = useCallback((val) => {


try {
    setLoading(true);
    async function getAuctionOne() {
      const results = await auctionsLive();
      setLoading(false);
      if (results.success === false) {
        alert(results.msg);
      } else {
   
        if(val === "NEXTSLOT" || val === "PASS"){  
          // setPause(results.lot.details[0].lot_status === "PAUSE" ? true : false);

        setGetData(results.lot.details);
        setData(results.auction_details);
        setBidHistory(results.bid_history);
        setIDset(results.auction_details.id);
        setAuctionID(results.auction_details.auction_id);
        setStatus(results.lot.details[0].lot_status);
        setDataAuction(results);
        setlotNumber(results.lot.details[0].lot_number);
        setGetBP(results.lot.details[0].bp);
        setStartPrice(results.lot.details[0].starting_price);
        setHighestBid(
          results.lot.details[0].hp === 0 || results.lot.details[0].hp === null
            ? results.lot.details[0].starting_price
            : results.increment
        );
        setHammerPrice(
          results.lot.details[0].hp === null ? 0 : results.lot.details[0].hp
        );
        setHighest(Math.max(...results.bid_history.map((o) => o.hammer)));
        setAnnouncement(results.announcement[0].announcement);
        return playSound(results);
        }else{
          setPause(results.lot.details[0].lot_status === "PAUSE" ? true : false);
          setGetData(results.lot.details);
          setData(results.auction_details);
          setBidHistory(results.bid_history);
          setIDset(results.auction_details.id);
          setAuctionID(results.auction_details.auction_id);
          setStatus(results.lot.details[0].lot_status);
          setDataAuction(results);
          setlotNumber(results.lot.details[0].lot_number);
          setGetBP(results.lot.details[0].bp);
          setStartPrice(results.lot.details[0].starting_price);
          setHighestBid(
            results.lot.details[0].hp === 0 || results.lot.details[0].hp === null
              ? results.lot.details[0].starting_price
              : results.increment
          );
          setHammerPrice(
            results.lot.details[0].hp === null ? 0 : results.lot.details[0].hp
          );
          setHighest(Math.max(...results.bid_history.map((o) => o.hammer)));
          setAnnouncement(results.announcement[0].announcement);
        //  return playSound(results);
        }
       
      }
    }
    getAuctionOne().catch(console.error);
  } catch (error) {
  
  }
  },[])

  // useEffect(() => {
  //   socket.on("receive_bid_amount", (data) => {
  //     setBidderList(data);
  //   });
  // }, [socket]);

  
  

  const [detailsShow, setShowDtails] = useState(false);

  const handleDetails = () => {
    setShowDtails(true);
  };

  const handleClose = () => {
    setShowDtails(false);
  };

  useEffect(() => {

    reconnectPlayer();
    // const myVideo = document.querySelector('video');
    // // Not all browsers return promise from .play().
    // const playPromise = myVideo.play() || Promise.reject('');
    // if (playPromise !== undefined) {
    //   playPromise.then(_ => {
    //   })
    //   .catch(error => {
    //   });
    // }


    soundRef.current = new Audio(BellSound);

    socket.on("receive_bid_amount", (data) => {

   
      if (data.status === true) {
        document.getElementById("sound").click();

        // window.scrollTo(0, 0)
        return ListData();
      } else {
      }
      
    });

    async function getUserList() {
      const getuser = await getUser();
      setUserId(getuser.id);
      setUsername(getuser.email);
    }
    getUserList().catch(console.error);

    try {
      setGetData(details.lot.details);
      setGetBP(details.lot.details[0].bp);
      setData(details.auction_details);
      setBidHistory(details.bid_history);
      // console.log("results tawag: ", results);
      setIDset(details.auction_details.id);
      setAuctionID(details.auction_details.auction_id);
      setPause(details.lot.details[0].lot_status === "PAUSE" ? true : false);
      setStatus(details.lot.details[0].lot_status);
      setDataAuction(details);
      // alert(results.lot.details[0].lot_number)
      setlotNumber(details.lot.details[0].lot_number);
      setStartPrice(details.lot.details[0].starting_price);
      setHighestBid(
        details.lot.details[0].hp === 0 || details.lot.details[0].hp === null
          ? details.lot.details[0].starting_price
          : details.increment
      );
      setHammerPrice(
        details.lot.details[0].hp === null ? 0 : details.lot.details[0].hp
      );
      setHighest(Math.max(...details.bid_history.map((o) => o.hammer)));
      // setPlayerID(
      //   details.bid_history.filter((val) => val.userId === userId)[0].userId
      // );
      setDetailsAll1(
        details.lot.details[0].signed_dated +
          details.lot.details[0].medium +
          details.lot.details[0].size +
          details.lot.details[0].property
      );

      setAnnouncement(details.announcement[0].announcement);
    } catch (error) {
      setBidHistory(
        details.bid_history === undefined ? bidhistory : details.bid_history
      );
      // setGetData((details.auction_details === undefined ? getData:details.lot.details))
    }

    socket.on("receiveMessage", (data) => {
      setAnnouncement(data.message);
    });

    socket.on("receive_status", (data) => {
      if (data.message === "NEXTSLOT" || data.message === "PASS" ) {
       
        setPause(false);
        setTimeout(() => {
          return ListData(data.message);
        }, 1000);
      } else if (data.message === "undo") {
        setPause(false);
        return ListData();
      } else if (data.message === "fresh") {
        window.location.reload();
        setPause(false);
      } else if (data.message === "PAUSE") {
        setPause(true);
      } else {
        setPause(false);
         setStatus(data.message);
        //return ListData();
        // console.log("error in view receive_status");  window.location.reload();
      }
    });

    return () =>{
     //socket.removeAllListeners();
      socket.off("receive_bid_amount");
      socket.off("receive_status");
      socket.off("receiveMessage");
      socket.off("send_Bid_amount");
    };
    // Socket.removeAllListeners();
    //  socketDeclarator.removeAllListeners("receive_status");
    //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
    //   // socketDeclarator.removeAllListeners('receive_bid_amount','disconnect','JointEvent');
    //  return () => socket.removeAllListeners("receiveMessage"),
    //           socket.removeAllListeners("receive_status") ,marginBottom: 200
  }, [details,BellSound, socket,]);

  //   useEffect(() => {

  // }, []);



  
  
  async function reconnectPlayer() {
  
    try {
      const videos = document.querySelector("video");
    const player = new WebRTCPlayer({
      video: videos,
      timeoutThreshold: 3000,
      type: "whep",
      
    });
  
    // console.log("player ",player)
  
    await player.load(
      new URL(
        "https://customer-gc73aqvl9oq83lia.cloudflarestream.com/359346f60f669e61d8b1efb7353292da/webRTC/play"
      )
    );
    player.unmute();
  
    player.on("no-media", () => {
      console.log("media timeout occured");
    });
    player.on("media-recovered", () => {
      console.log("media recovered");
    });
  
    // Subscribe for RTC stats: `stats:${RTCStatsType}`
    player.on("stats:inbound-rtp", (report) => {
      if (report.kind === "video") {
       // console.log("report");
        
      }
    
    });
    } catch (error) {
      
    }
    
    
  }

  return (
    <div
      style={{
        // padding: 1,
        height: "100lvh",
        top: "auto",
        bottom: "auto"
        // marginBottom: phoneMatch === true ? 200 : "auto",
      }}
    >

      
      <Box>

      <video style={{display: mediadQuery === false ? "none" : "block",width: '100%'}} loop controls autoPlay muted={true} playsInline></video>


     
     <div style={{ display: mediadQuery === false ? "block" : "none" }}>
       <Box
          style={{
            margin: 1,
            backgroundColor: "black",
            paddingTop: 10,
            paddingBottom: 10,
            color: "white",
            display: mediadQuery === false ? "none" : "",
          }}
        >
          <Typography style={{ marginLeft: 10 }}>
           Lot: {lotNumber}
          </Typography>
        </Box> 

         <Box style={{ margin: 10 }}>
          {getData.map((val, i) => {
            return (
              <div key={i}>
                <di style={{ display: "flex" }}>
                  {mediadQuery === false ? (
                    <di
                      style={{
                        backgroundColor: "gray",
                        // width: 40,
                        // height: 28,
                        borderRadius: 5,
                        marginRight: 5,
                      }}
                    >
                      {" "}
                      <Typography
                        style={{
                          fontSize: mediadQuery === false ? 20 : 18,
                          // marginLeft: 5,
                          color: "white",
                          padding: mediadQuery === false ? 5 : 0,
                        }}
                      >
                        {val.lot_number}
                      </Typography>
                    </di>
                  ) : (
                    ""
                  )}

                  <Typography
                    style={{ fontSize: mediadQuery === false ? 20 : 18 ,     padding: mediadQuery === false ? 5 : 0}}
                    dangerouslySetInnerHTML={{
                      __html: val.artist,
                    }}
                  ></Typography>
                </di>
                <Typography
                  style={{ fontSize: mediadQuery === false ? 16 : 16 ,textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: 350 }}
                >{val.title}</Typography>
              </div>
            );
          })}

          <p
            
            style={{
              textTransform: "capitalize",
              color: "blue",
              display: mediadQuery === false ? "block" : "none",
            
       
            }}
          >
            <b style={{cursor: "pointer",}} onClick={handleDetails}>
            Details
            </b>
       
          </p>
        </Box> 

         <div style={{ display: mediadQuery === false ? "block" : "none" }}>
          <Box style={{ margin: 10 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                Starting Bid
              </Typography>
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                Php: {startPrice.toLocaleString()}
              </Typography>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                Current Bid
              </Typography>

              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                Php: {hammerPrice.toLocaleString()}
              </Typography>
            </div>

          </Box>
        </div> 
        </div> 
      {/* end ng details */}
      </Box> 
      


      {token === null ? <div style={{ display: mediadQuery === false ? "block" : "none" }}>
        <Box>
         

          <Button
       variant="contained"
            onClick={(e) =>
              incrementCount(auctionID, setID, lotNumber, highestBid)
            }
            fullWidth={true}
            sx={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              fontSize: 20,
              fontWeight: "bold",
             
            }}
          >
           Login to bid
          </Button>
        </Box>
      </div> :<div style={{ display: mediadQuery === false ? "block" : "none" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#d9d9d9",
          }}
        >
  
     
          <Box style={{ width: "100%", margin: "auto", textAlign: "center" }}>
            <Typography variant="h6">{highestBid.toLocaleString()}</Typography>
          </Box>

          <Button
            disabled={
              bidhistory.filter( (val) =>
                  val.userId === userId &&
                  val.win_status === "WIN" &&
                  val.hammer === highestNumber
              ).length ||
              status === "PENDING" ||
              status === "PAUSE" ||
              status === "SOLD"
                ? true
                : false
            }
           
            onClick={(e) => incrementCount(auctionID, setID, lotNumber, highestBid)}
            fullWidth={true}
            sx={{
              // borderRadius:1,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              fontSize: 20,
              fontWeight: "bold",
              backgroundColor: bidhistory.filter(
                (val) =>
                  val.userId === userId &&
                  val.win_status === "WIN" &&
                  val.hammer === highestNumber
              ).length
                ? "green"
                : "#ce1b37",
              color: "#fff",
              ":hover": {
                bgcolor: "green",
                color: "white",
              },
            }}
          >
            {bidhistory.filter(
              (val) =>
                val.userId === userId &&
                val.win_status === "WIN" &&
                val.hammer === highestNumber
            ).length
              ? "highest bidder"
              : "Place bid"}
          </Button>
        </Box>
      </div>}


      <Box
        style={{
          backgroundColor: "black",
          paddingTop: 10,
          paddingBottom: 10,
          color: "white",
          marginTop: "10px",
          display: mediadQuery === true ? "block" : "none",
        }}
      >
        <Typography style={{ marginLeft: 10 }}>
          <HelpOutlineIcon /> Lot Data
        </Typography>
      </Box>
      <Button id="sound" onClick={SoundTrip} ></Button>
      <table
        style={{
          // marginTop: 10,
          // padding: 5,
          width: "100%",
          display: mediadQuery === true ? "block" : "none",
        }}
      >
        <tr>
          <td style={{ fontSize: 18, padding: 5, width: 155 }}>
           <Typography  style={{ fontWeight: "bold", fontSize: 14 }}>Starting Bid:</Typography>
          </td>
          <td style={{ fontSize: 18, padding: 5 }}>
          <Typography  style={{ fontWeight: "bold", fontSize: 14 }}>Php: {startPrice.toLocaleString()}</Typography>
          </td>
        </tr>
        <tr>
          <td style={{ fontSize: 18, padding: 5 }}><Typography  style={{ fontWeight: "bold", fontSize: 14 }}>Current Bid:</Typography></td>
          <td style={{ fontSize: 18, padding: 5 }}>
          <Typography  style={{ fontWeight: "bold", fontSize: 14 }}>Php: {hammerPrice.toLocaleString()}</Typography>
          </td>
        </tr>
        {/* <tr>
          <td style={{ fontSize: 18, padding: 5 }}><Typography>Buyers Premium:</Typography></td>
          <td style={{ fontSize: 18, padding: 5 }}><Typography>{getbp} %</Typography></td>
        </tr> */}
      </table>

      {token === null ? "":<Box
        style={{
          backgroundColor: "black",
          paddingTop: 10,
          paddingBottom: 10,
          color: "white",
          marginTop: "10px",
        }}
      >
        <Typography style={{ marginLeft: 10 }}>
          {" "}
          <AccessTimeIcon /> Bid History
        </Typography>
      </Box> }

      <Box
        style={{
          backgroundColor: Listcolor.headColor,
          color: Listcolor.textWhite,
        }}
        // title={("Bid History: ", `${username}`)}
      />

      <Divider style={{ marginTop: 10 }} />

      <Box style={{ height: mediadQuery === false ? "" : 250 }}>
        {/* <Box sx={{ display: 'flex' ,marginLeft: '50%'}}>
      <CircularProgress />
    </Box> */}
     {token === null ? <Box style={{ display: mediadQuery === false ? "none" : "block",}}><Typography style={{textAlign: 'center',padding:100,fontWeight:'bold',fontSize:30}}>Guest</Typography></Box>: <List
          style={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: mediadQuery === false ? 300 : 240,
            flexDirection: "column-reverse",
            // display: "-webkit-inline-flex",
            // "& ul": { padding: 0 },
          }}
        >
          {bidhistory.length ? (
            bidhistory.map((val, i) => {
              let StatusColor;
              let Onlintalaga;

              let nameUser;
              if (val.userId === userId) {
                nameUser = username;
              } else {
                nameUser = "";
              }

              if (
                val.userId === userId &&
                val.win_status === "WIN" &&
                val.hammer === highestNumber
              ) {
                Onlintalaga = true;
                StatusColor = "green";
              } else if (val.userId === userId && val.win_status === "LOSS") {
                StatusColor = "red";
              } else if (
                val.userId === userId &&
                val.win_status === "WIN" &&
                val.hammer !== highestNumber
              ) {
                StatusColor = "red";
              } else {
                StatusColor = "black";
                Onlintalaga = false;
              }

              //  console.log(onlineUserID === userId && val.win_status === "WIN" && val.hammer === highestNumber)

              return (
                <ListItem key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>

                  <ListItemText
                    // gutterBottom
                    align="left"
                    primary={
                      <React.Fragment>
                        <Typography
                          style={{
                            color: StatusColor,
                            fontWeight: Onlintalaga === true ? "bold" : "",
                            fontSize:
                              mediadQuery === false
                                ? Onlintalaga === true
                                  ? 16
                                  : 12
                                : Onlintalaga === true
                                ? 12
                                : 11,
                          }}
                          variant="body2"
                          color="text.primary"
                        >
                          {val.userId === userId ? nameUser : val.bidder_type}
                        </Typography>
                      </React.Fragment>
                    }
                  />

                  <ListItemText
                    align="right"
                    // gutterBottom
                    primary={
                      <React.Fragment>
                        <Typography
                          style={{
                            color: StatusColor,
                            fontWeight: Onlintalaga === true ? "bold" : "",
                            fontSize:
                              mediadQuery === false
                                ? Onlintalaga === true
                                  ? 16
                                  : 12
                                : Onlintalaga === true
                                ? 13
                                : 11,
                          }}
                          variant="body2"
                          color="text.primary"
                        >
                          {"Php " + val.hammer.toLocaleString()}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })
          ) : (
            ""
            // <Box sx={{ display: "flex", marginLeft: "45%", marginTop: 5 }}>
            //   {/* <CircularProgress /> */}
            // </Box>
          )}
        </List>}

        <Box
          style={{
            backgroundColor: "black",
            paddingTop: 10,
            paddingBottom: 10,
            color: "white",
            marginTop: "10px",
            display: mediadQuery === false ? "block" : "none",
          }}
        >
          <Typography style={{ marginLeft: 10 }}><UpcomingIcon/> Upcoming Lots</Typography>
        </Box>
        <Box
          style={{
            marginTop: 10,

            display: mediadQuery === false ? "block" : "none",
          }}
        >

              
      

          <ImageRows
              open={loading}
            //  socket={Socket}
            details={dataAuction}
            mediadQuery={mediadQuery}
            lotNumberNo={lotNumber}
          />
        </Box>
      </Box>
  

      <Box
        style={{
          backgroundColor: "black",
          paddingTop: 10,
          paddingBottom: 10,
          color: "white",
          marginTop: "10px",
          display: mediadQuery === false ? "block" : "none",
        }}
      >
        <Typography style={{ marginLeft: 10 }}>
          <MessageIcon /> Announcement :
        </Typography>
      </Box>

      <Box
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "space-between",
          padding: 5,
          display: mediadQuery === false ? "block" : "none",
        }}
      >
        <Typography
          gutterBottom
          style={{ fontSize: 14, whiteSpace: "pre-line" }}
        >
          {announcement}
        </Typography>
      </Box>

      <Divider />

      <Box
        style={{
          paddingTop: 10,
          paddingBottom: 10,

          marginTop: "10px",
          display: mediadQuery === false ? "block" : "none",
        }}
      >
        <Typography style={{ marginLeft: 10, textAlign: "center" }}>
          {" "}
          @{date.getFullYear()} Leon Gallery Auctions{" "}
        </Typography>
      </Box>

      {token === null ? <div
        style={{
          // padding: 10,
          // maxHeight:500,
          // marginTop: 5,
          display: mediadQuery === false ? "none" : "block",
        }}
      >
        <Box>
         

          <Button
       variant="contained"
            onClick={(e) =>
              incrementCount(auctionID, setID, lotNumber, highestBid)
            }
            fullWidth={true}
            sx={{
           borderRadius: 0,
              fontSize: 20,
              fontWeight: "bold",
             
            }}
          >
           Login to bid
          </Button>
        </Box>
      </div> :  <div
        style={{
          // padding: 10,
          // maxHeight:500,
          marginTop: 5,
          display: mediadQuery === false ? "none" : "block",
        }}
      >
        <Box>
          <Box
            style={{
              width: "100%",
              margin: "auto",
              padding: 5,
              textAlign: "center",
              height: 40,
              backgroundColor: "#d9d9d9", }}
          >
            <Typography variant="h5">{highestBid.toLocaleString()}</Typography>
          </Box>

          <Button
            disabled={
              bidhistory.filter(
                (val) =>
                  val.userId === userId &&
                  val.win_status === "WIN" &&
                  val.hammer === highestNumber
              ).length ||
              status === "PENDING" ||
              status === "PAUSE" ||
              status === "SOLD"
                ? true
                : false
            }
            onClick={(e) =>
              incrementCount(auctionID, setID, lotNumber, highestBid)
            }
            fullWidth={true}
            sx={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              fontSize: 20,
              fontWeight: "bold",
              backgroundColor: bidhistory.filter(
                (val) =>
                  val.userId === userId &&
                  val.win_status === "WIN" &&
                  val.hammer === highestNumber
              ).length
                ? "green"
                : "#ce1b37",
              color: "#fff",
              ":hover": {
                bgcolor: "green",
                color: "white",
              },
            }}
          >
            {bidhistory.filter(
              (val) =>
                val.userId === userId &&
                val.win_status === "WIN" &&
                val.hammer === highestNumber
            ).length
              ? "highest bidder"
              : "Place bid"}
          </Button>
        </Box>
      </div>}

      <Dialog
        open={detailsShow}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ marginBottom: 120 }}
      >
        <DialogTitle id="alert-dialog-title">{"Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              style={{ fontSize: 14, overflow: "auto", maxHeight: 500 }}
              dangerouslySetInnerHTML={{ __html: detailsAll1 }}
            ></Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pause}
      >
        <b style={{ fontSize: 30, color: "orange" }}> PAUSE</b>
      </Backdrop>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default RightLayout;
