import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AboutUSs from "./../../images/resources/aboutus.png";
// import PDFDOWNLOAD from './../../pdf/TKTA_2023.pdf';
import { getUpcommingAuction } from "../../Model/Auction"
import { Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import main from "./../../images/resources/main.png";
import lafuerza from "./../../images/resources/lafuerza.png";
import international from "./../../images/resources/international.png";



const About = (props) => {
  const matches = useMediaQuery("(min-width:1200px)");
  const matchesPhone = useMediaQuery("(min-width:600px)");
  const [cataglog, setCatalog] = useState('')
  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')
  const [catalogDownload, setCatalogDownload] = useState("");
  useEffect(() => {
    try {
      async function getAuctionOne() {
        const results = await getUpcommingAuction();

        const results1 = results.filter(
          (x) =>
            x.c_type === "LIVE_AUCTION" || x.c_type === "ONLINE_AUCTION" 
        );


        // console.log('results ',results1[0].cover)
        console.log(results1)
        // results.map((val) => {
        //     setCatalog(val.cover)
        // })
        // "date_start": "2023-12-02T00:00:00.000Z",
        // "date_end": "2023-12-02T00:00:00.000Z",


        setCatalog(results1[0].cover)
        setTitle(results1[0].d_name)
        setDetails(results1[0].d_desc)
      }
      getAuctionOne().catch(console.error);



      // const getBanner = async () => {
      //   const results = await actionByID(auctionID);
  
      //   setData(results);
      //   // console.log("Dsadasd: ",results)
      //   results.map((val) => {
      //     setIslive(val.isLive)
      //     setShowhp(val.showHp);
      //   });
  
      //   setLoading(false);
      // };
      // getBanner().catch(console.error);
    } catch (error) {


     }


  }, []);



  return (
    <div className="boxed_wrapper">

      <section
        className="breadcrumb-area"
        style={{
          backgroundImage: `url(${AboutUSs})`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">

              <div className="inner-content clearfix">
                <div className="title">
                  <h1>About Us</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 60 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                      About Us
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="about-style1-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-10">
              <div className="about-style1-left-content clearfix" style={{ maxWidth: 600 ,}}>
                <div className="" >

                  <div className="about-style1-content">
                    <h2 class="blog-title">Our Vision</h2>
                    <div className="text para">
                      <p class="mar-bottom">
                      Art is an important foundation of Filipino history and culture. It has the power to move, encourage and above all, inspire. We believe in a society surrounded by the power of art to constantly engage in conversation with ourselves, others and society at large. We therefore believe in art education, the cultivation of its appreciation, and through it, our country’s growth and development.
                      </p>
                    </div>
                  </div>
                  {/* <img

                    src="https://leon-gallery.com/assets/img/about/Jaime_Ponce_de_Leon.jpg"
                    alt="Awesome"
                  />
                  <p style={{ textAlign: "center" }} className="para">
                    Jaime Ponce de Leon <br />
                    Founder / Director
                  </p> */}
                </div>
              </div>

              {/* <div className="about-style1-left-content clearfix" style={{ maxWidth: 600, }}>
                <div style={{ textAlign: 'center' }}>
                  <img
                    style={{ width: matches === true ? '50%' : '100%', }}
                    src={cataglog}
                    alt="Awesome"
                  />

                </div>
                <div style={{ textAlign: 'center', marginTop: 10, }}>
                  <Button
                    style={{
                      padding: "5px 20px 5",

                      color: "white",
                      width: matches === true ? '50%' : '100%',
                      fontSize: 20,
                      textAlign: 'center'
                    }}
                    class="btn btn-secondary catalobButton"

                    href={PDFDOWNLOAD}
                    download=""

                  >
                    Download Catalog
                  </Button>
                </div>
              </div> */}
            </div>
            <div className="col-xl-6 col-lg-12">

              <div className="about-style1-content">
                <h2 class="blog-title">Our Mission</h2>
                <div className="text para">
                  <p class="mar-bottom">
                    Our mission is to make the best of Filipino art accessible
                    to the world. This is why we have made it our mission to
                    become the simple and convenient link between buying and
                    selling these pieces of art. We understand the level of
                    responsibility and trust required by this role. Hence, our
                    pool of experts work hard from the authentication and
                    documentation process, to each work’s storage and
                    preservation, to the actual pricing and bidding, so there is
                    little for you to worry about. Since 2010, we have
                    specialized in finding important museum-quality and
                    historically-important pieces of art. Our collections
                    encompass Old Master paintings such as those by Juan Luna,
                    Felix Resurreccion Hidalgo, and Fernando Amorsolo and the
                    previous centuries; to modernist works by Hernando R.
                    Ocampo, Anita Magsaysay-Ho, Vicente Manansala, Arturo Luz,
                    Fernando Zobel, and Federico Aguilar Alcuaz. We have also
                    earned the reputation of the best purveyor of Philippine
                    antiques, from the pre-Spanish to the colonial times.
                    Culturally significant furniture, ivory, silver, porcelain,
                    rugs, jewelry, rare books, maps and flags as well as
                    extraordinary documents that define us as Filipinos are
                    among the gallery’s estate and heirloom offerings. Find what
                    you love and take a piece of the country’s glorious history
                    and culture with you.
                  </p>
                </div>
              </div>

              {/* <div class="button" style={{ paddingTop: 10 }}>
                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginRight: 10,
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
                  id="view_ecatalog"
                    href="https://www.calameo.com/read/004656305be7a30217929"
                  target="_blank"
                >
                  View E-Catalog
                </a>
                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
             
                  href={PDFDOWNLOAD}
                  download=""
               
                >
                  Download Catalog
                </a>
         
              </div> */}
            </div>
          </div>
        </div>
      </section>


     
      <section className="events-single-area">
        <div className="container">

          <div className="row">
            <div className="col-xl-6">
              <div className="events-single-text-box">
                <h2>Leon Gallery Main</h2>
                <p>
                  León Gallery, established in 2010, quickly became known for its focus on high-quality Philippine art with historical significance.
                </p>
                <p>
                  The gallery's expertise lies in the works of Filipino Old Masters like Juan Luna, Félix Resurrección Hidalgo, Fabian de la Rosa, and Fernando Amorsolo. León Gallery has expanded its scope to include the Thirteen Moderns, featuring artists such as Carlos “Botong” V. Francisco, Anita Magsaysay-Ho, Hernando R. Ocampo, and Vicente Manansala. Additionally, it showcases prominent figures like Fernando Zóbel, Arturo Luz, and Federico Aguilar Alcuaz. León Gallery proudly exhibits the creations of almost all of the Philippines’ National Artists for Visual Arts and Sculpture, as well as noteworthy contemporary artists.

                </p>
              </div>
            </div>
            <div className="col-xl-6">
              <div style={{
                position: 'relative',
                display: 'block',
                background: '#dddddd',
                padding: '1px 1px 1px',
                
              }}>

                <img
                  src={main}
                  alt="Awesome"
                />

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="events-single-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">

              {matches === false ? <div className="events-single-text-box">
                <h2>Lafuerza</h2>
                <p>
                  LeonExchange serves as your digital portal to discover your next treasure. Conducted quarterly, it acts as a curated hub for vintage,
                  high-quality, and previously cherished items sourced from distinguished collections and esteemed estates. Whether you're a collector of art, jewelry,
                  furniture, decor, or more, each auction promises an opportunity to uncover your next beloved piece.
                </p>
              </div>: 
              <div style={{
                position: 'relative',
                display: 'block',
                background: 'rgb(0 0 0)',
                 padding: '1px 1px 1px',
                
              }}>

                <img
                  src={lafuerza}
                  alt="Awesome"
                />
              </div>}
            </div>
            <div className="col-xl-6">
            {matches === false ?  <div style={{
                position: 'relative',
                display: 'block',
                background: 'rgb(0 0 0)',
                 padding: '1px 1px 1px',
                
              }}>

                <img
                  src={lafuerza}
                  alt="Awesome"
                />
              </div> :
              <div className="events-single-text-box">
                <h2>Lafuerza</h2>
                <p>
                  LeonExchange serves as your digital portal to discover your next treasure. Conducted quarterly, it acts as a curated hub for vintage,
                  high-quality, and previously cherished items sourced from distinguished collections and esteemed estates. Whether you're a collector of art, jewelry,
                  furniture, decor, or more, each auction promises an opportunity to uncover your next beloved piece.
                </p>
              </div>}
            </div>
          </div>
        </div>
      </section>


      <section className="events-single-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="events-single-text-box">
                <h2>Leon Gallery International</h2>
                <p>
                  Situated in the heart of Makati City, the country’s financial district, Leon Gallery International is Leon Gallery’s
                  premier exhibition space. Since its inception, the space has been the site of a number of groundbreaking exhibitions.
                  From Filipino artists such as Augusto Albor, Enzo Razon, Oca Villamael, and Pete Jimenez to international names like Carlos Cruz-Diez,
                  Fernando Zobel, Nam June Paik, and Josef Albers.
                </p>
              </div>
            </div>
            <div className="col-xl-6">
              <div style={{
                position: 'relative',
                display: 'block',
                background: '#dddddd',
                 padding: '1px 1px 1px',
                
              }}>
                <img
                  src={international}
                  alt="Awesome"
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="google-map-area" style={{ paddingTop: 50 }}>
        <div className="container">
          <div
            className=""
            style={{ backgroundColor: "#0000", paddingBttom: 10 }}
          >
            {/* <p className="para">Read About</p> */}
            <div className="big-title">
              <h1>Simple and Convenient</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="text para">
                No matter what the art work is, we want to make the acquisition
                or de-accessioning of it as simple and convenient for you as
                possible. Whether you seek Old Master paintings, modernist
                works, or contemporary pieces by younger but important artists,
                sculptures, tapestries, architectural details or one-of-a-kind
                finds; whether you are in the market for fine furniture,
                porcelain, ivory, silver, or gold from the various centuries, we
                can facilitate the process of finding, and owning that must-have
                piece to round out your collection — or to take the first step
                in beginning one. And if you are seeking the right home for your
                treasures, we are here to help you select the perfect forum to
                find one. You are welcome to attend our live auctions at León
                Gallery, bid on the phone or online, or place an absentee bid.
                We’ll be with you every step of the way, whether you prefer the
                excitement of a ringside seat or prefer to bid from the comfort
                of your own home. In addition to our auctions, León Gallery
                regularly organizes previews, both selling and non-selling
                exhibitions, as well as participates in Art Fair Philippines. Be
                our honored guest on these occasions to meet other like-minded
                individuals and to enjoy the burgeoning Filipino art scene. We
                also regularly publish art books in collaboration with curators
                and international galleries.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map-area" style={{ paddingTop: "50px" }}>
        <div className="container">
          <div className="" style={{ backgroundColor: "#0000" }}>
            {/* <p>About Us</p>  */}
            <div className="big-title">
              <h1>The Gallery & Auction House</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="text para">
                León Gallery first opened its doors in 2010, quickly gaining a
                reputation for specializing in museum-quality and historically
                important Philippine Art. It is also a purveyor of meticulously
                selected Philippine antiques, dating from the Pre-Spanish period
                to the 19th Century. Antique furniture, Spanish-Filipino ivory
                and silver, precious Oriental ceramic and porcelain, rare
                carpets and jewelry constitute the gallery’s special heirloom
                and estate pieces. At the core of León Gallery’s expertise are
                works of the Filipino Old Masters, such as Juan Luna y Novicio,
                Félix Resurrección Hidalgo y Padilla, Fabian de la Rosa y Cueto
                and Fernando Amorsolo y Cueto. The Gallery has gone on to widen
                its field with the works of the Thirteen Moderns, including
                Carlos “Botong” V. Francisco, Anita Magsaysay-Ho, Hernando R.
                Ocampo, and Vicente Manansala; as well as leading lights such as
                Fernando Zóbel, Arturo Luz, and Federico Aguilar Alcuaz to name
                a few. León Gallery is also honored to offer almost all of the
                Philippines’ National Artists for the Visual Arts and Sculpture
                as well as the most exciting and important contemporary artists.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map-area" style={{ paddingTop: "50px" }}>
        <div className="container">
          <div class="" style={{ backgroundColor: "#0000" }}>
            {/* <p className="para">Read About</p> */}
            <div class="big-title">
              {" "}
              <h2 class="blog-title">Exhibitions</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="text para">
                In 2014, León Gallery organized the landmark exhibition, The Jim
                and Reed Pfeufer Collection: A Four-Decade Friendship with
                Fernando Zóbel. Featuring 73 works, the show was a look into the
                mind and artistic process of the maestro, Fernando Zóbel, as
                seen through sketches, private correspondence, and significant
                works amassed by his close friends, Reed Champion of the Boston
                School and her husband, Jim Pfeufer.
              </div>
              <div className="text para">
                This was followed by another milestone in June 2016: Filipinos
                in the Gilded Age: An Exhibition of Important Ivory, Furniture,
                and Paintings that assembled an astounding number of works from
                the turn of the century, including several recently repatriated
                masterpieces.
              </div>
              <div className="text para">
                León Gallery led the way in the field of bringing the concept of
                ‘curated auctions’ to the country, with Two Navels. Inspired by
                the seminal work of Philippine National Artist for Literature
                Nick Joaquin, it sought to continue the dialogue on the question
                of Filipino identity. Opened in August 2016, it celebrated the
                eve of Joaquin’s 100th birth year.
              </div>
              <div className="text para">
                As its first exhibition for 2017, León Gallery was privileged to
                present an iconic art collection with Trove : The Coseteng
                Collection, The Life and Art of Hernando R. Ocampo, 1945 to
                1978. Seventy-four stunning canvases, watercolors and works on
                paper — many of which had never been seen in public — told the
                magnificent story of this legendary Neo-Expressionist.
              </div>
              <div className="text para">
                The following month, León Gallery went on to present The Sons
                Return, an exhibit of two Filipino ex-patriate artists who would
                become two of our most beloved National Artists: Benedicto Reyes
                Cabrera, more popularly known as BenCab, and Federico Aguilar
                Alcuaz. This special exhibition would be expanded and travel to
                Silliman University in Dumaguete in 2019.
              </div>
              <div className="text para">
                In August 2017, the Gallery staged the landmark exhibition
                titled ‘Mid-Century Moderns: Important Modernist Painting from
                the Philippine Art Gallery,’ which was the first to focus on the
                iconic institution in more than three decades. The PAG was the
                country’s first-ever gallery to focus entirely on abstract art,
                and was established in 1952. Its roster featured the
                Philippines’ first non-objective artists and the legendary
                ‘Neo-Realists’ who sought to express the new reality after World
                War II : H.R. Ocampo, Vicente S. Manansala, Cesar F. Legaspi,
                Romeo V. Tabuena, Victor Oteyza, and Ramon A. Estella.
              </div>
              <div className="text para">
                The León Gallery next organized Elsewhere : 100 Years of
                Filipino Artists Abroad, featuring Miguel Zaragoza, Fabian de la
                Rosa, Nena Saguil, Macario Vitalis, Cesar F. Legaspi, Napoleon
                Abueva, among others.
              </div>
              <div className="text para">
                In October 2018, León Gallery presented Nam June Paik in Manila
                the first Philippine exhibition of works by the Korean-American
                ‘father of video art’ at its newest premises, León Gallery
                International. It was in collaboration with Gagosian Gallery
                which has represented the Nam June Paik estate since 2015. Nam
                June Paik (1932 - 2006) was a visionary artist who foresaw the
                influence of the television screen and the internet not only on
                art but on the world at large. He quickly and presciently
                grasped that advances in consumer technology were not fleeting
                oddities but would have a lasting impact on culture and
                politics. Nam June Paik in Manila feature 24 pieces from 1983 to
                2005, including several iconic objects that blur the lines
                between art and technology, the past and the future, philosophy,
                fame, and commercialism.
              </div>
              <div className="text para">
                At the Art Fair Philippines 2019 the exhibition in the spotlight
                was the powerful yet playful Color Theory. Featured artists were
                : Roberto Chabet and Nam June Paik (both Ford Foundation / ACC
                Grantees); Carlos Cruz-Diez; Yves Klein; Yayoi Kusama; Alfonso
                Ossorio; Leo Valledor; and Fernando Zóbel. It was only in the
                20th century that color came truly into its own as a recognized
                part of art practice. Thus, the exhibition presented artists
                from both the Philippines and abroad whose works have been
                characterized by the eloquent use of color.
              </div>
              <div className="text para">
                Most recently in 2020, León Gallery presented “Leo Valledor :
                The Outsider of Park Place” at Art Fair Philippines. A pioneer
                of the minimalist art movement, Valledor was also an important
                figure in the New York art scene of the 1960s that created the
                free-wheeling, multi-media Soho art scene. He drew from his
                formative years not just in Kerouac’s San Francisco but also the
                communal charateristics of his Filipino background.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="google-map-area" style={{ paddingTop: "30px" }}>
        <div className="container">
          <div class="" style={{ backgroundColor: "#0000" }}>
            {/* <p className="para">Read About</p> */}
            <div class="big-title">
              {" "}
              <h2 class="blog-title">Auction Highlights</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <div className="text para">
                León Gallery is best known as the Philippines’ most trusted
                auction house. Since its first auction in 2013 and through its
                regular quarterly auctions since then, León Gallery has
                continued to establish and then exceed record prices for
                Filipino Old Masters as well as modern and contemporary artists.
              </div>
              <div className="text para">
                Indeed, at the León Gallery Asian Cultural Council Auction in
                March 2018, a new world record was achieved for both José Joya
                as well as Philippine abstract art, when Joya’s extraordinary
                ‘Space Transfiguration’ (1959), his most famous and beloved
                work, achieved P112Million or USD 2.16Million, inclusive of
                buyer’s premium.The sum paid surpassed León Gallery’s own
                previous benchmarks.
              </div>
              <div className="text para">
                In the Spectacular Mid-Year Auction in June 2018, another world
                record was set for Fernando C. Amorsolo with the exquisite
                ‘Under the Mango Tree’, which once belonged to the Melian Zobel
                and the sister of the man who sent Amorsolo to Spain to study at
                the Royal Academy of San Fernando in Madrid. This painting
                reaped a staggering P46.7 Million, or USD 934,000, a world
                record for the artist that outstripped a previous level set
                internationally of just P28 Million, or USD 539,000.
              </div>
              <div className="text para" style={{ paddingBottom: 10 }}>
                León Gallery, in fact, has the honor of holding the record of
                auctioning the six top-selling artworks in the country,
                beginning with BenCab’s P46Million (USD 939,000) opus in June
                2014; the two remarkable paintings by Anita Magsaysay-Ho, the
                ‘Women amid the Banana Plantation’ at P49Million (USD 1 Million)
                in September 2017 and the larger ‘Fish Harvest at Dawn’ in 2014
                for P52Million or USD 1.1 Million, and the previous
                record-holder for the most expensive piece of Philippine art to
                be sold in Manila, the massive Ang Kiukok ‘Fishermen’, which
                fetched P65.4 Million (USD 1.28 Million) in June 2017.
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section class="about-style1-area" style={{paddingBottom:20}}>
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-12">
              <div class="about-style1-left-content clearfix" style={{  marginLeft: matchesPhone === false ?  '':'auto',
                marginRight: matchesPhone === false ?  '':'auto',}}>
                <div >
                  <img src={cataglog} alt="Awesome Image"  style={{width: '100%'}}/>
                </div>
              </div>
            </div>

            
            <div className="col-xl-6  col-lg-12" >
              <div className="about-style1-content">
                <div className="" style={{padding:5}}>
                <div className="">
                  {/* <p>Catalog</p> */}
                    <h3>{title}</h3>
                    <p>{details}</p>
                    </div>
                </div>
                <div className="inner-content">
      
                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginRight: 10,
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
                  id="view_ecatalog"

                  href={`https://www.calameo.com/read/004656305d5d14a74c885`}
                 // href={`/../../pdf/${catalogDownload}.pdf`}
                  target="_blank"
                >
                  View E-Catalog
                </a>

                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
             
                  href={`/../../pdf/${catalogDownload}.pdf`}
                  download=""
               
                >
                  Download Catalog
                </a>
         
              </div>
               
          
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;
