import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserDetails } from "../Model/User";

function View({ userID }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchdata() {
      const results = await getUserDetails(userID);
      console.log("result data", results);
    }

    fetchdata().catch(console.error);
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: 15,

        // height: mediadQuery.phoneMatch !== true ? 2000 : "",
      }}
    >
      <div>
        <Grid container spacing={3}>
          {/* <Grid item lg={4} md={4} xs={12}>
            <Card style={{ height: "300px" }}>
              <CardContent>
                <img
                  alt="Awsome"
                  src={
                    "https://placehold.co/295x418?text=NO%20IMAGE%20AVAILABLE"
                  }
                  style={{ width: "100%", height: "270px" }}
                />
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item lg={8} md={8} xs={12} style={{ margin: "auto" }}>
            <form>
              <Card>
                <CardHeader
                  subheader="The information can be edited"
                  title="Personal data"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="First Name"
                        name="name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        variant="outlined"
                        // value={banner}
                        //   onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Last Name"
                        name="lname"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Date Of Birth"
                        name="dob"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Nationality"
                        name="phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Email"
                        name="lname"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Phone Number"
                        name="phone"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Country"
                        name="address"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="State"
                        name="state"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="City"
                        name="city"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Address"
                        name="address"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="text"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Postal Code"
                    name="code"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    variant="outlined"
                  />
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button color="primary" variant="contained" type="submit">
                    Update
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default View;
