
export default function BlogSingleAreaSidebar({ categoriesList, pastPost }) {




  return (
    <div>

      <div className="sidebar-wrapper" style={{paddingTop: 20}}>

        {/*Start single sidebar*/}
        <div className="single-sidebar">
          <div className="title">
            <h3>Categories</h3>
          </div>
          <ul className="categories " >
            <li key={0} ><a href="/stories" >ALL</a></li>
            {

              categoriesList.map((data, key) => {
                return (
                  <li key={key} ><a href={`/stories?categories=${data.id}`} >{data.name.toUpperCase()}</a></li>
                )
              })
            }


          </ul>
        </div>
        {/*End single sidebar*/}
        {/*Start single sidebar*/}
        <div className="single-sidebar">
          <div className="title">
            <h3>Recent Posts</h3>
          </div>
          <div className="recent-post">
            <div className="recent-post-carousel owl-carousel owl-theme owl-nav-style-one">
              {
                pastPost.map((data, key) => {

                  let srcURL;

                  try {
       

                    if(data._embedded["wp:featuredmedia"][0].source_url === undefined || data._embedded["wp:featuredmedia"][0].source_url === null ){
                      console.log('data._embedded["wp:featuredmedia"][0].source_url Video ',data._embedded["wp:featuredmedia"][0].source_url)
                      srcURL = data._embedded["wp:featuredmedia"][0].source_url
                    }else{
                      srcURL =  data._embedded["wp:featuredmedia"][0].source_url.replace("188.166.186.239", "apiblog.leon-gallery.com");
                    }
                  } catch (error) {
                    
                  }
                  return (
                    <div className="single-recent-post">
                      <div className="img-holder">

                      <div   style={{backgroundImage: `url(${srcURL})`,  
backgroundColor: '#ffffff',
width: '100%',
height: 370,
display: 'inline-block',
backgroundSize: 'contain',
backgroundPosition: 'center center',
backgroundRepeat: 'no-repeat',
padding: 10,
}}></div>

                        {/* <img src={srcURL} alt={data._embedded['wp:featuredmedia'][0].alt_text} /> */}
                        <div className="overlay-style-one bg1" />
                      </div>
                      <div className="title-holder text-center">
                        <p>{data.title.rendered}</p>
TEST
                      </div>
                    </div>
                  )
                })
              }

              {/*Start Single Recent Post */}

              {/*End Single Recent Post */}

            </div>
          </div>
        </div>
        {/*End single sidebar*/}

        {/*End single sidebar*/}
        {/*Start single-sidebar*/}

        {/*End single-sidebar*/}
      </div>

    </div>
  );
}