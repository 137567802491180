import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom' 
// import { hydrateRoot, createRoot } from "react-dom/client";

 //import { hydrate, render } from "react-dom";


 
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<React.StrictMode>
//   <BrowserRouter>
//   <App />
//   </BrowserRouter>
// </React.StrictMode>);
// ReactDOM.hydrate

// hydrate(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));



// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate( <BrowserRouter><App /></BrowserRouter>, rootElement);
// } else {
//   render(<BrowserRouter><App /></BrowserRouter>, rootElement);
// }

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
  <App />
  </BrowserRouter>

)

// const rootElement = document.getElementById("root");
// // console.log("rootElement.hasChildNodes(): ",rootElement.hasChildNodes())
// if (rootElement.hasChildNodes()) {
//   hydrateRoot(<App />, rootElement);
// } else {
//   createRoot(<App />, rootElement);
// }


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//     <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
