import axiosInstant from "../../axiosInstant";

export async function login(ArrayData) {
  const response = await axiosInstant
    .post(`/login`, ArrayData)
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {

      return error.response.data.errors;
    });
  return response;
}


export async function getUserDetails(id) {
  const response = await axiosInstant
    .get(`/users/${id}`)
    .then((res) => {
      console.log(res.data);
      return res.data.data;
    })
    .catch((error) => {

      return error.response.data.errors;
    });
  return response;
}


export async function AddNewAccount(ArrayData) {
  const response = await axiosInstant
    .post(`/user/register`, ArrayData)
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      if (
        error.response.data.msg ===
        "Email already Exist! please verify your account"
      ) {
        return error.response.data;
      } else {
        return error.response.data.errors;
      }
      //console.log("error.response.data.errors",error.response.data.errors);
    });
  return response;
}

export function getToken(){
  const token = localStorage.getItem("token");
  return token;
  }