import React, { useState, useRef, useCallback, useEffect } from "react";
import ModeAuction from "./ModeAuction1";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Divider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import NoImage from "./../../images/Banner/no_image.jpeg";
import axios from "axios";
export default function Auction() {
  const matches = useMediaQuery("(min-width:1000px)");

  const matchesPhone = useMediaQuery("(min-width:600px)");
  const [live, setLive] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [online, setOnline] = useState(null);
  const [exhibition, setExhibition] = useState(null);

  const [checked, setChecked] = React.useState(true);
  const [checked1, setChecked1] = React.useState(true);
  const [checked2, setChecked2] = React.useState(true);

  const { books, hasMore, loading, error, errorMsg } = ModeAuction(
    pageNumber,
    live,
    exhibition,
    online
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // useEffect(() => {
  //   lastBookElementRef();
  // });
  // function handleSearch(e) {
  //   setQuery(e.target.value)
  //   setPageNumber(1)
  // }

  const handleChange = (event) => {
    if (event.target.checked === true) {
      setLive(event.target.value);
      setPageNumber(1);
    } else {
      setLive(null);
      setPageNumber(1);
    }
    setChecked(event.target.checked);
  };

  const handleChange1 = (event) => {
    if (event.target.checked === true) {
      setOnline(event.target.value);
      setPageNumber(1);
    } else {
      setOnline(null);
      setPageNumber(1);
    }
    setChecked1(event.target.checked);
  };

  const handleChange2 = (event) => {
    if (event.target.checked === true) {
      setExhibition(event.target.value);
      setPageNumber(1);
    } else {
      setExhibition(null);
      setPageNumber(1);
    }
    setChecked2(event.target.checked);
  };

  const GotoPage = (id, slug, type, imageCover) => {
    // localStorage.setItem("items", id);
    localStorage.setItem("cover", imageCover);

    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${slug}/${type}`;
    } else {
      window.location.href = `/auction/lot/${id}/${slug}/${type}`;
    }
  };


  const [imgsLoaded, setImgsLoaded] = useState(true);

  // useEffect(() => {
  //   const loadImage = (value,index) => {
  //     return new Promise((resolve, reject) => {
  //       const loadImg = new Image();
  //       loadImg.src = value.cover;
 
  //       loadImg.onload = () =>
  //         setTimeout(() => {
  //           resolve(value.cover);
  //         }, 2000);

  //       loadImg.onerror = (err) => reject(err);
  //     });
  //   };

  //   Promise.all(books.map((value,index) => loadImage(value,index)))
  //     .then(() => setImgsLoaded(!imgsLoaded))
  //     .catch((err) => console.log("Failed to load images", err));
  // }, []);

  return (
    <div>
      <section className="blog-single-area lotArea">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className=" ">
                <div className="sidebar-search-box">
                  {/* <div className="search-form">
                    <input
                      placeholder="Search"
                      type="text"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button type="submit" onClick={handleSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div> */}
                </div>
                <div className="single-sidebar">
                  <div style={{ textAlign: "left" }}>
                    <h2>SALES</h2>
                  </div>
                  <hr></hr>
                  {matches === true ? (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={"LIVE_AUCTION"}
                            checked={checked[0] && checked[1]}
                            indeterminate={checked[0] !== checked[1]}
                            onChange={handleChange}
                          />
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        label={
                          <span style={{ fontSize: 22 }}>Live Auction</span>
                        }
                        // label="Live Auction"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={"ONLINE_AUCTION"}
                            checked={checked1[0] && checked1[1]}
                            indeterminate={checked1[0] !== checked1[1]}
                            onChange={handleChange1}
                          />
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        label={
                          <span style={{ fontSize: 22 }}>Online Auction</span>
                        }
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          value={"EXHIBITION"}
                          checked={checked2[0] && checked2[1]}
                          indeterminate={checked2[0] !== checked2[1]}
                          onChange={handleChange2}
                        />
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }}}
                      label={<span style={{ fontSize: 22 }}>Exhibition</span>}
                    /> */}
                    </FormGroup>
                  ) : (
                    <Box style={{ display: "flex", paddingTop: 10 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={"LIVE_AUCTION"}
                            checked={checked[0] && checked[1]}
                            indeterminate={checked[0] !== checked[1]}
                            onChange={handleChange}
                          />
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        label={<span style={{ fontSize: 22 }}>Live</span>}
                        // label="Live Auction"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={"ONLINE_AUCTION"}
                            checked={checked1[0] && checked1[1]}
                            indeterminate={checked1[0] !== checked1[1]}
                            onChange={handleChange1}
                          />
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        label={<span style={{ fontSize: 22 }}>Online</span>}
                      />
                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          value={"EXHIBITION"}
                          checked={checked2[0] && checked2[1]}
                          indeterminate={checked2[0] !== checked2[1]}
                          onChange={handleChange2}
                        />
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      label={<span style={{ fontSize: 22 }}>Exhibition</span>}
                    /> */}
                    </Box>
                  )}
                </div>
              </div>
            </div>

            <div className="col-xl-9">
              <div
                className="row"
                style={{ marginTop: matches === false ? 0 : "" }}
              >
               
                  {books.map((value, i) => { 
                    var options = {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    };

                    // let lightboxImgElem = lightboxImgElem === null ? "":document.getElementById(i);
                    var date_start = new Date(value.date_start);
                    var date_end = new Date(value.date_end);
                    let imageComplete;
                    if (books.length === i + 1) {
                      //   Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
                      //     console.log('images finished loading');
                      // });

                      return (
                        <div
                          id="phoneSize"
                          className={"col-4"}
                          style={{ paddingTop: 45 }}
                          ref={lastBookElementRef}
                          key={value}
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              GotoPage(
                                value.id,
                                value.c_slug,
                                value.c_type,
                                value.cover
                              )
                            }
                          >
                 
                              <div
                                className="containerImage bg-image hover-zoom"
                                style={{
                                  // height: value.cover === null ? 468 : "",
                                }}
                              >
                
                      <div   style={{backgroundImage: `url(${ value.cover === null
                                      ? "https://placehold.co/330x470?text=NO%20IMAGE%20AVAILABLE"
                                      : value.cover})`,  
                              backgroundColor: '#ffffff',
                              width: '100%',
                              height:   matchesPhone === true ? 475 : 520,
                              display: 'inline-block',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              padding: 10,
                            
                            }}></div> 

                                {/* <img
                                  className="imageCenter"
                                  id={i}
                                  src={
                                    value.cover === null
                                      ? "https://placehold.co/328x478?text=NO%20IMAGE%20AVAILABLE"
                                      : value.cover
                                  }
                                  alt="Awesome"
                                  style={{
                                    width: " 100% ",
                                    height: 478,
                                    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                                  }}
                                /> */}
                              </div>
                              {/* )
                           : (
                            <div
                            className="containerImage bg-image hover-zoom"
                            style={{
                              height: 468,
                            }}
                          >
                            <img
                              className="imageCenter"
                              src={ "https://placehold.co/328x478?text=Loading%20images..."}
                              alt="Awesome"
                              style={{
                                width: " 100% ",
                                height: 478,
                                // opacity: '0.1'
                              }}
                            />
                          </div>
                            )} */}

                            <div
                              className="text"
                              style={{ padding: "0px 0px" }}
                            >
                              <p style={{ marginBottom: 10, fontSize: 12 }}>
                                {value.c_type.replace("_", " ")}
                              </p>
                         
                              <p
                                className="title"
                                style={{ marginBottom: 0, fontSize: 20 }}
                              >
                                {value.d_name}
                              </p>

                              <p
                                style={{
                                  marginBottom: 0,
                                  fontSize: 15,
                                }}
                              >
                                {date_start.toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                              {/* <p
                              style={{
                                marginBottom: 0,
                                fontSize: 20,
                              }}
                            >
                              {date_end.toLocaleDateString("en-US", options)}
                            </p> */}
                            </div>
                          </a>
                        </div>
                      );
                      // <div ref={lastBookElementRef} key={book}>{book}</div>
                    } else {
                      return (
                        <div
                          id="phoneSize"
                          className={"col-4"}
                          style={{ paddingTop: 45 }}
                          key={value}
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              GotoPage(
                                value.id,
                                value.c_slug,
                                value.c_type,
                                value.cover
                              )
                            }
                          >
                          <div
                              className="containerImage bg-image hover-zoom"
                              style={{ 
                                //  height: 479, width: 336, boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                                // height: value.cover === null ? 468 : "",
                              }}
                            >

<div   style={{backgroundImage: `url(${ value.cover === null
                                      ? "https://placehold.co/330x470?text=NO%20IMAGE%20AVAILABLE"
                                      : value.cover})`,  
                              backgroundColor: '#ffffff',
                              width: '100%',
                              height:   matchesPhone === true ? 475 : 520,
                              display: 'inline-block',
                              backgroundSize: 'contain',
                              backgroundPosition: 'center center',
                              backgroundRepeat: 'no-repeat',
                              padding: 10,
                            
                            }}></div> 


                          {/* <img
                                className="imageCenter"
                                id={i}
                                src={
                                  value.cover === null
                                    ? "https://placehold.co/448x634?text=NO%20IMAGE%20AVAILABLE"
                                    : value.cover
                                }
                                alt="Awesome"
                                style={{
                                  width: " 100% ",
                                  // height: 478,
                                  boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                                }}
                              /> */}
                        </div>
                              
                              {/* )
                            : (
                  <div
                  className="containerImage bg-image hover-zoom"
                  style={{
                    height: 468,
                  }}
                >
                  <img
                    className="imageCenter"
                    src={ "https://placehold.co/448x634?text=Loading%20images..."}
                    alt="Awesome"
                    style={{
                      width: " 100% ",
                      height: 478,
                      // opacity: '0.1'
                    }}
                  />
                </div>
                  )} */}

                            <div
                              className="text"
                              style={{ padding: "0px 0px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p style={{ marginBottom: 10, fontSize: 12 }}>
                                  {value.c_type.replace("_", " ")}
                                  {/* &nbsp;&nbsp;
                                <svg style={{height:'1rem', width: '1rem'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">  
  <path class="chr-icon__stroke--dark" d="M2.5 7c0-2.8235 2.447-5.5 5.5-5.5s5.5 2.6765 5.5 5.5c0 1.9604-1.1888 3.9874-2.5564 5.5654-.6757.7797-1.3753 1.4267-1.9515 1.8747-.2887.2246-.539.3934-.7338.5033-.0977.0551-.1738.091-.2284.1119A.6066.6066 0 018 15.0659a.6066.6066 0 01-.03-.0106c-.0545-.0209-.1306-.0568-.2283-.1119-.1949-.1099-.4451-.2787-.7338-.5033-.5762-.448-1.2758-1.095-1.9515-1.8747C3.6888 10.9874 2.5 8.9604 2.5 7z" stroke="#222"></path>
  <circle class="chr-icon__stroke--dark" cx="8" cy="7" r="2" stroke="#222"></circle>
</svg> Makati */}
                                </p>
                                <p
                                  style={{
                                    marginBottom: 10,
                                    fontSize: 12,
                                    color:
                                      value.auction_status === "UPCOMING"
                                        ? "red"
                                        : "",
                                  }}
                                >
                                  {value.auction_status}
                                </p>
                              </div>
                       
                              <p
                                className="title"
                                style={{ marginBottom: 0, fontSize: 20 }}
                              >
                                {value.d_name}
                              </p>
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontSize: 15,
                                }}
                              >
                                {date_start.toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                              {/* <p
                              style={{
                                marginBottom: 0,
                                fontSize: 15,
                              }}
                            >
                             End {date_end.toLocaleDateString("en-US", options)}
                            </p> */}
                            </div>
                          </a>
                        </div>
                        );
                      }
                    })
                  }

                {/* <div>{error && "Error"}</div> */}
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  // width: "10%",
                  // paddingTop: 50,
                  // paddingBottom:50
                }}
              >
                {/* {loading && (
                  <div sx={{ display: "flex" }}>
                    <CircularProgress /> Loading...
                  </div>
                )} */}
                <div className="loadingAuction">
                  {error ? (
                    <p></p>
                  ) : loading ? (
                    <div
                      style={{
                        width: "100%",
                        padding: 100,
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <Box sx={{ display: "flex", position: "relative" }}>
                        <span
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "2px",
                            fontSize: 12,
                          }}
                        >
                          Loading
                        </span>
                        <CircularProgress size="3rem" />
                      </Box>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    width: "100%",
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  {errorMsg === "" ? "" : "No More"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
