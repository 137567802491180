import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./../images/resources/dddd.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  homehandle,
  upcommingEventsHandle,
  handleResults,
  handleJewelry,
  handleWatches,
  handlebags,
  handlePrivatePainting,
  handlePrivateFurniture,
  handlePrivateJewelry,
  handlePrivateBOOKS,
  handlePrivateSCULPTURE,
  handlePrivateARTS,
  handleClickStories,
  handleMain,
  handleInternational,
  handleLafuerza,
  handleClickLive,
  handleFaq,
  handleExhibition
} from "./RouteFunction";
import { linksData } from "../Model/Navbar";

function StickyHeader(props) {
  const matches = useMediaQuery("(min-width:1000px)");
  const [title, setTitle] = useState(document.title);
  //   const search = window.location.search;
  //   const params = new URLSearchParams(search);
  //   const data = params.get('data');
  //   useEffect(() => {
  //     document.title = data;
  // }, [data]);
  const CallFunction = (event, handle, name) => {
    localStorage.removeItem("@imageSingle");
    if ("homehandle" === handle) {
      // localStorage.removeItem("@imageSingle");
      // return handleResults(name);
      return homehandle(name);
    } else if ("upcommingEventsHandle" === handle) {
      // localStorage.removeItem("@imageSingle");
      return upcommingEventsHandle(name);
    } else if ("handleResults" === handle) {
      
      return handleResults(name);
    } else if ("handleJewelry" === handle) {
      return handleJewelry(name);
    } else if ("handleWatches" === handle) {
      return handleWatches(name);
    } else if ("handlebags" === handle) {
      return handlebags(name);
    } else if ("handlePrivatePainting" === handle) {
      return handlePrivatePainting(name);
    } else if ("handlePrivateFurniture" === handle) {
      return handlePrivateFurniture(name);
    } else if ("handlePrivateJewelry" === handle) {
      return handlePrivateJewelry(name);
    } else if ("handlePrivateBOOKS" === handle) {
      return handlePrivateBOOKS(name);
    } else if ("handlePrivateSCULPTURE" === handle) {
      return handlePrivateSCULPTURE(name);
    } else if ("handlePrivateARTS" === handle) {
      return handlePrivateARTS(name);
    } else if ("handleClickStories" === handle) {
      return handleClickStories(name);
    } else if ("handleMain" === handle) {
      return handleMain(name);
    } else if ("handleInternational" === handle) {
      return handleInternational(name);
    // } else if ("handleMaison" === handle) {
    //   return handleMaison(name);
    } else if ("handleLafuerza" === handle) {
      return handleLafuerza(name);
    } else if ("handleFaq" === handle) {
      return handleFaq(name);
    } else if ("handleExhibition" === handle) {
      return handleExhibition(name);
    } else if ("handleClickLive" === handle) {
      return handleClickLive(name);
    } else {
      
    }
  };

  useEffect(()=> {
    setTitle(document.title)
  },[document.title])

  const [isActive, setActive] = useState(false);
  function searchbox() {
    setActive(!isActive);
  }

  const [search, setSearch] = useState("");

  function handleSearch(e) {
    e.preventDefault();
    // alert("alert")
    // let searching = search.replace([a-zA-Z0-9\s]+$)
    return (window.location.href = `/search/${search}`);
  }



  return (
    <div
      className="sticky-header"
      style={{ backgroundColor: "#ffffff" }}
    >
      <div className="container">
        <div className="clearfix">
          <div className="logo float-left">
            <Link onClick={homehandle} className="img-responsive">
              <img src={Logo} alt="" title="" id="logoLeonSticky" />
            </Link>
          </div>
          {/* margin-right: 50%;
    margin-left: 40%;
    position: absolute;
    height: 50%;
    width: 100%; */} 
          <div

              className="right-col float-right navvv"
         
            style={{ display: matches === false ? "none":'block',padding: "10px 0px" }}
          >
            <nav className="main-menu navbar-expand-lg">
              <div className="navbar-collapse collapse clearfix">
                <ul className="navigation clearfix">
                  {linksData.map((val, i) => (
                    <li key={i} className={val.class + " current"}  style={{marginRight: 20}}>
                      <a
                        onClick={(event) =>
                          CallFunction(event, val.handle, val.name)
                        }
                        style={{
                          fontFamily: "Bell MT Semi Bold",
                          fontSize: 17,
                          textDecoration: title === val.title ? "underline" : "",
                          textUnderlineOffset: 10,
                        }}

                   
                      >
                        {val.name}
                      </a>
                      <ul className="dropdown-content">
                        {val?.dropdown?.map((row, index) => (
                          <li key={index} className={row.class}>
                            <a
                              onClick={(event) =>
                                CallFunction(event, row.handle, row.name)
                              }
                            >
                              {row.name}
                            </a>
                            <ul>
                              {row?.dropdown2?.map((res, index) => (
                                <li key={index}>
                                  <a
                                    onClick={(event) =>
                                      CallFunction(event, res.handle, res.name)
                                    }
                                  >
                                    {res.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <div className="menu-right-content">
              <div
                          className="bbbccc"
                            style={{
                              lineHeight: -0.5,
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip:' padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                              height: 54,  
                              top: "-10px",
                              // width: 265,
                            }}
                    
                          >
                            <form onSubmit={handleSearch}>
                            <div className="innerForm">
                              <input
                                className="cccaaa"

                                type="search"
                                name="search"
                                placeholder="Search Here"
                                required
                                // style={{padding:5}}
                                onChange={(e) =>
                                  setSearch(e.target.value.trim())
                                }
                              />
                              <button type="submit" 
                              className="aaabbb"
                              style={{
                                height: 44,
                                color: '6c757d',
                                // marginLeft: -22,
                                width: 40,
                                // padding: 5,
                     
                                position: 'absolute',
                                borderRadiu: 5,
                                borderBottomRightRadius: 2,
                                borderTopRightRadius: 2,
                                cursor: 'pointer'
                              }} >
                                <i className="fa fa-search"></i>
                              </button>
                              </div>
                            </form>
                          </div>
                          </div>
              </div>
            
            </nav>
            <div className="menu-right-content">
              <div
                className="outer-search-box-style1"
                style={{ padding: "25px 0" }}
              >
                <div className="main-menu style4 navbar-expand-lg">
                  <div className="navbar-header">
                    <div className="seach-toggle" onClick={searchbox}>
                      <i className="fa fa-search"></i>
                    </div>
                    <ul className="search-box">
                      <li>
                        <form method="post" action="index.html">
                          <div className="form-group">
                            <input
                              type="search"
                              name="search"
                              placeholder="Search Here"
                              required
                            />
                            <button type="submit">
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </form>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="SerchBox">
                    <div
                      className="navbar-collapse collapse clearfix"
                      id="navbarSupportedContent"
                    >
                      <form method="post" action="index.html">
                        <div className="form-group form-control">
                          <input
                            type="search"
                            name="search"
                            placeholder="Search Here"
                            required
                            style={{ width: "200px" }}
                          />
                          <button type="submit">
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StickyHeader;
