import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Page from "../../../components/Page";
import { auctionsLive } from "../../Model/DeclaratorModel";
import View from "./View";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { getUser } from "../../Model/LocalStorage";
import "./Center.css";
import { useLocation } from "react-router-dom";
// import io from "socket.io-client";
import { hostname } from "../../../config"
import useMediaQuery from "@mui/material/useMediaQuery";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const socket = hostname;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    // background: `${Api.background.theme}`,
    minHeight: "100%",
    paddingBottom: 3,
    paddingTop: 3,
  },
}));

const SettingsPlayers = () => {
  const classes = useStyles();
  const location = useLocation();
  const phoneMatch = useMediaQuery("(min-width:900px)");
  // const [roomID, setRoom] = useState(null);
  // const DataData = location.state.key;

  const [LiveData, setGetLiveData] = useState([]);
  const [room, setRoom] = useState("");
  const [message, setMessage] = useState("");
  const ListData = () => {
    try {
      async function getAuctionOne() {
        const results = await auctionsLive();
     
        // console.log("disconnect: ",results)
        if(results.success === false){
          // alert(results.msg)
          setMessage(results.msg)
          setOnline(!isOnline)
        }else{
          setGetLiveData(results);
          setRoom(results.auction_details.auction_id);
          socket.emit("joint_room", results.auction_details.auction_id);
        }
        //console.log('results.auction_details.auction_name: ', )
        // setAuctionTitle(results.auction_details.auction_name);
      }
      getAuctionOne().catch((error) => {
        //alert("Network Error!");
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const [isOnline, setOnline] = useState(true);
  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
    setMessage("Network Error!...")
  };


  useEffect(() => {
    try {
      ListData();
      // console.log("socket   ",socket.connected)
    } catch (error) {
      // console.log("socket error   ",socket)
    }
    //  ListData();
    // socket.emit("joint_room", roomID);

    async function getUserList() {
      const getuser = await getUser();
      socket.emit("ConnectedUser", {
        userId: getuser.id,
        email: getuser.email,
      });
    }

    getUserList().catch(console.error);


  
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
      // window.addEventListener("click",onDisconnect)
  };

    // console.log("socket: ",socket)
    // return () => {
    //   socket.removeAllListeners();
    // };    
  }, [navigator.onLine,socket]);

  return (
    <Page className={classes.root} title="Console">
      <Container maxWidth={false} style={{paddingLeft: phoneMatch === true ? 16:0,paddingRight: phoneMatch === true ? 16:0,}}>
        {/* <Toolbar /> */}
        <Box>
          <View
            socketDeclarator={socket}
            LiveData={LiveData}
            // room={roomID}
          />
        </Box>
      </Container>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!isOnline}
      >
        <b style={{fontSize: 20}}> {message}...&nbsp;&nbsp;&nbsp;&nbsp; </b> <CircularProgress color="inherit" />
      </Backdrop>
    </Page>
  );
};

export default SettingsPlayers;
