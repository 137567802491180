import { Typography, Box } from "@mui/material";
import React, { useState, useEffect, memo } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getUser } from "../../Model/LocalStorage";
import { auctionsLive } from "../../Model/Auction";
import GridLayout from "./gridLayout";
import Page from "../../../components/Page";
import NoEvent from "./NoEvent";
import LayoutHeaderAuctions from "../header/Appbar";

import { hostname } from "../../../config";

const socket = hostname;
// const socket = io.connect("http://localhost:3005");

function View(props) {
  const status = true;

  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = useState("");
  const [getData, setGetData] = useState([]);
  const [type, setType] = useState("");

  const LiveAuction = () => {
    setLoading(true);
    try {
      async function getAuctionOne() {
        const results = await auctionsLive();
        // console.log("live auction")
        if (results.success === false) {
          setMessage(results.msg);
          setOnline(!isOnline);
        } else {
          setGetData(results);

          socket.emit("joint_room", results.auction_details.auction_id);
        }

        // setGetData(results);
        setLoading(false);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}

    fetch(`http://157.230.43.47:3005/type`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // alert(data.isDesktop)
        setType(data.isMobile);
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  const [isOnline, setOnline] = useState(true);
  const updateNetworkStatus = () => {
    setOnline(navigator.onLine);
    setMessage("Network Error!...");
  };

  useEffect(() => {
    //  return () =>  socket.removeAllListeners();
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
      // window.addEventListener("click",onDisconnect)
    };
  }, [navigator.onLine]);

  useEffect(() => {
    try {
      LiveAuction();
      // console.log("socket   ",socket.connected)
    } catch (error) {
      // console.log("socket error   ",socket)
    }

    async function getUserList() {
      const getuser = await getUser();

      socket.emit("ConnectedUser", {
        userId: getuser.id,
        email: getuser.email,
      });
    }

    // console.log("socket.connected : ",socket.connected)
    getUserList().catch(console.error);
    // if (socket.connected) {
    //   console.log('connect');
    //    socket.connect();
    //  } else {
    //    console.log('disconnect');
    //    alert('Socket is disconnected.');
    //  }
    // function onDisconnect() {
    //   if (socket.connected) {
    //     console.log('connect');
    //      socket.connect();
    //    } else {
    //      console.log('disconnect');
    //      alert('Socket is disconnected.');
    //    }
    // }
  }, [socket]);

  return (
    <>
      <Page title="Auctions Room">
        {status === true ? (
          <>
            {" "}
            <LayoutHeaderAuctions mediadQuery={props.mediadQuery} />{" "}
            <GridLayout
              LiveData={getData}
              Socket={socket}
              Type={type}
              mediadQuery={props.mediadQuery}
            />
          </>
        ) : (
          <NoEvent />
        )}
      </Page>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!isOnline}
      >
        <b style={{ fontSize: 20 }}> {message}...&nbsp;&nbsp;&nbsp;&nbsp; </b>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default View;
