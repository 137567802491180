import React, { useState, useRef, useCallback, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { mainAuction } from "../../../Model/Auction";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import main from "./../../../images/resources/main.png";

import main1 from "./../../../images/resources/main0.jpg";
import main2 from "./../../../images/resources/main2.jpg";
import main3 from "./../../../images/resources/main3.jpg";
const View = (props) => {
  const matches = useMediaQuery("(min-width:1000px)");
  const itemData = [
    {
      img: main3,
      title: "Breakfast",
      rows: 2,
      cols: 2,
    },
    {
      img: main2,
      title: "Coffee",
      author: "@arwinneil",

      cols: 2,
    },
    {
      img: main1,
      title: "Coffee",
      author: "@arwinneil",

      cols: 2,
    },
  ];

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const [auction, setAuction] = useState([]);

  const [loading, setLoading] = useState(false)

  const FetchData = (auction_tatus) => {

    try {
      setLoading(true)
      async function getAuctionOne() {
        const results = await mainAuction(auction_tatus);
        setAuction(results);
        setLoading(false)
      }
      getAuctionOne().catch(console.error);
    } catch (error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    FetchData()
  }, []);


  const [active, setActive] = useState(false)
  const [active1, setActive1] = useState(false)
  const handleUpcomming = (searchValue) => {
    if (searchValue === 'UPCOMING') {
      setActive(true)
      setActive1(false)
    } else {
      setActive(false)
      setActive1(true)
    }

    return FetchData(searchValue)
  };

  const GotoPage = (id, slug, type, imageCover) => {
    localStorage.setItem("items", id);
    localStorage.setItem("cover", imageCover);

    if (type === "EXHIBITION") {
      window.location.href = `/exhibition/${slug}/${type}`;
    } else {
      window.location.href = `/auction/lot/${id}/${slug}/${type}`;

    }
  };


  return (
    <div className="boxed_wrapper">
      <section
        className="breadcrumb-area"
    style={{padding: "185px 0 25px"}}
      >

        
        
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
            <img style={{ width: "100%" }} src={main} />
              {/* <div className="inner-content clearfix">
                <div className="title">
                  <h1>{props.param}</h1>
                </div>
                <div className="breadcrumb-menu">
                  <ul className="clearfix">
                    <li style={{ marginLeft: 50 }}>Home</li>
                    <li className="" style={{ color: "#000000" }}>
                      Main
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="events-single-area" style={{    padding: "50px 0 0"}}>
        <div className="container">
          {/* <div className="row">
            <div className="col-xl-12">
                <div className="events-single-image-box">
                    <img src="images/events/event_single_img_1.jpg" alt="Awesome Image"/>    
                </div>   
            </div>
        </div> */}
          <div className="row">
            <div className="col-xl-8">
              <div className="events-single-text-box">
                <h2>Leon Gallery Main</h2>
                <p>
                León Gallery, established in 2010, quickly became known for its focus on high-quality Philippine art with historical significance.
                </p>
                <p>
                The gallery's expertise lies in the works of Filipino Old Masters like Juan Luna, Félix Resurrección Hidalgo, Fabian de la Rosa, and Fernando Amorsolo. León Gallery has expanded its scope to include the Thirteen Moderns, featuring artists such as Carlos “Botong” V. Francisco, Anita Magsaysay-Ho, Hernando R. Ocampo, and Vicente Manansala. Additionally, it showcases prominent figures like Fernando Zóbel, Arturo Luz, and Federico Aguilar Alcuaz. León Gallery proudly exhibits the creations of almost all of the Philippines’ National Artists for Visual Arts and Sculpture, as well as noteworthy contemporary artists.

                </p>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="events-single-info-box">
                <ul className="events-info">
                  <li>
                    <p>Type :</p>
                    <span>Gallery Talk</span>
                  </li>
                  <li>
                    <p>Hours :</p>
                    <span>Monday–Saturday 9:00 AM - 6:00 PM</span>
                  </li>
                  <li>
                    <p>Venue :</p>
                    <span>
                      Eurovilla 1, Rufino corner Legazpi Street, Legazpi
                      Village, Makati, 1224
                    </span>
                  </li>
                </ul>
                <div className="social-links">
                  <p>Shear :</p>
                  <ul className="sociallinks-style-two">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ExploreLeonGallery/> */}

      <section className="gallery-area">
        <div className="layer-outer"></div>
        <div className="container">
          <div className="visit-day-select">
            <h1>Live Auction</h1>

            <div className="sec-title text-center" style={{ paddingTop: 20 }}>
              <ul className="tab-btns tab-buttons text-center clearfix">
                <li

                  data-tab="#today"
                  className={`tab-btn ${active === true ? 'active-btn' : ''}`}
                  onClick={() => handleUpcomming("UPCOMING")}
                >
                  <span>Upcoming</span>
                </li>
                <li
                  data-tab="#tomorrow"
                  className={`tab-btn ${active1 === true ? 'active-btn' : ''}`}
                  onClick={() => handleUpcomming("PREVIOUS")}
                >
                  <span>Past</span>
                </li>
                {/* <li data-tab="#select-date" className="tab-btn active-btn"><span>Select Date</span></li> */}
              </ul>
            </div>
          </div>
          {loading == true ? <Box sx={{ textAlign: "center" ,paddingBottom:10,paddingTop:10,position: 'relative'}}>
              {/* <span style={{position: 'absolute',fontSize:12}}>Loading</span> */}
                 <CircularProgress size="3rem"/> 
          </Box> :
            <div className="row items-container clearfix">

              {auction ? auction.map((val) => {
                var options = {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                var startDate = new Date(val.date_start);
                var endDate = new Date(val.date_end);
                return (
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 masonry-item all objects pic">
                    <div className="single-art-box-style2 marbtm50" >
                      <div className="img-holder"  onClick={() =>
                            GotoPage(
                              val.id,
                              val.c_slug,
                              val.c_type,
                              val.cover
                            )
                          }>
                        <img src={val.cover === null ? "https://placehold.co/440x623?text=NO%20IMAGE%20AVAILABLE":val.cover } alt="Awesome Image" />
                        <div className="overlay-content">
                          <div className="zoom-button">
                            <a
                              className="lightbox-image"
                              data-fancybox="gallery"
                              href={val.cover}
                            >
                              <i
                                className="fa fa-search-plus"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="text-holder"> 
                        <h3 >
                          <a style={{cursor: 'pointer'}}  onClick={() =>
                            GotoPage(
                              val.id,
                              val.c_slug,
                              val.c_type,
                              val.cover
                            )
                          }>{val.d_name}</a>
                        </h3>
                        <span>
                          {startDate.toLocaleDateString("en-US", options)} To{" "}
                        </span>
                        <span>
                          {" "}
                          {endDate.toLocaleDateString("en-US", options)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }): <div style={{textAlign: 'center',margin: 'auto',padding: 10}}> No Record Found </div>}
              </div>}
          <ImageList
            sx={{ width: " 100%", paddingBottom: 10 }}
            variant="quilted"
            cols={4}
            rowHeight={matches === true ? 300 : 120}
          >
            {itemData.map((item) => (
              <ImageListItem
                key={item.img}
                cols={item.cols || 1}
                rows={item.rows || 1}
              >
                <img
                  {...srcset(item.img, 121, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>

        </div>
      </section>
    </div>
  );
};

export default View;
