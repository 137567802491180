import Box from "@mui/material/Box";

import React, { useState, useRef, useCallback, useEffect } from "react";
import SearchAuction from "./SearchAuction";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import {
  getFilter,
  privateGroupFilter,
  categories,
  privatefilterArtist,
} from "../../Model/Auction";
import SearchIcon from "@mui/icons-material/Search";

import FormControl from "@mui/material/FormControl";
import FilterListIcon from "@mui/icons-material/FilterList";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import NoImage from "./../../images/Banner/no_image.jpeg";
import ListItemText from "@mui/material/ListItemText";

import ListItemButton from "@mui/material/ListItemButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { makeStyles } from "@mui/styles";
import { storePrivateID } from "../../Model/LocalStorage";
import { stroreAuctionList, getAuctionList } from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "pink",
      color: "red",
      "&:hover": {
        backgroundColor: "tomato",
      },
    },
  },
  selected: {},
});

export default function Sell() {
  const matches = useMediaQuery("(min-width:1000px)");
  var { id, slug, type } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const auctionID = localStorage.getItem("items");
  const [nameID, setNameID] = useState([]);
  // const styles = useStyles();
  const [search, setSearch] = useState("");
  const [loadingArtist, setLoadingArtist] = React.useState(false);
  const [loadingCategory, setloadingCategory] = React.useState(false);

  const [filter, setFilter] = useState("");
  const [expanded, setExpanded] = React.useState(true);
  const [expanded1, setExpanded1] = React.useState(true);
  // const [expanded2, setExpanded2] = React.useState(true);
  const search_url = window.location.search;
  const params = new URLSearchParams(search_url);
  const url_data = params.get("data");
  const [searchCat, setSearcHCategory] = useState(url_data);
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [loadingCat, setLoadingCat] = React.useState(false);
  const [countArtist, setCountArtist] = React.useState(null);
  const [getAuction, setGetAuctions] = useState([]);
  const [artistRowID, setArtistID] = useState("");
  

  const { loading, error, lotdata, hasMore, errorMsg } = SearchAuction(
    pageNumber,
    id,
    search,
    searchCat,
    artistRowID
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      // window.scroll(0,0)
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  // console.log("matches=== true ? false:true ", matches);

  useEffect(() => {
    try {
      async function getAuctionOne() {
        const results = await getFilter(id);

        setNameID(results);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  }, []);

  const [artistData, setArtistData] = useState([]);

  const listArtist = () => {
    setLoadingArtist(true);
    try {
      async function getAuctionOne() {
        const results = await privateGroupFilter(auctionID);
        setLoadingArtist(false);
        setArtistData(results.filter((o, i) => o.artist !== null));
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  };

  const [searchArtist, setSearchArtist] = useState("");
  const [searchArtist2, setSearchArtist2] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(filter);
    setPageNumber(1);
    setOpenFilter(false);
  };

  const [filteredResults, setFilteredResults] = useState([]);
  const FilterCategory = () => {
    setLoadingCat(true);
    try {
      async function getAuctionOne() {
        const results = await privatefilterArtist(id);
        // setCategory(results.categories);
        setFilteredResults(results.data.filter((o, i) => o.artist !== null));

        setCountArtist(results.data.length);
        // setFilteredResults(results.data)
        setLoadingCat(false);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  };

  const [categoryFilter, setCategoryFillter] = useState([]);

  const [categoryData, setCategory] = useState([]);
  const listCategory = () => {
    try {
      async function getAuctionOne() {
        const results = await categories();

        setCategory(results.data.filter((o, i) => o.categories !== null));
        // setCategory(results.categories);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  };

  useEffect(() => {
    if (category1 === "") {
      setSearch("");
      FilterCategory();
      listCategory();
    } else {
      setCategoryFillter(
        categoryData.filter((value) => value.categories.includes(category1))
      );
    }

    setCategoryFillter(categoryData);
  }, [category1]);

  const handlepickCategory = (searchValue) => {
    setCategory1(searchValue);
    setCategory2(searchValue);

    // const filteredData = categoryData.filter((item) => {
    //   return Object.values(item)
    //     .join("")
    //     .toLowerCase()
    //     .includes(category1.toLowerCase());
    // });

    // if(category1 !== ""){
    //   setCategory(filteredData);

    // }else{

    //  setSearch("");
    //  setFilteredResults(filteredData);
    // }
    // setPageNumber(1);
    // setSearch(searchCat);
  };

  const handlepickSelectionCategory = (event) => {
    setOpenFilter(false);
    setSearch("");
    setCategory2(event);
    setSearcHCategory(event);
    setCategory1("");
    setPageNumber(1);
    setSearchArtist2("");
    setArtistID("")
    window.scrollTo(0, 100);
  };

  const gotSinglePage = (title, auction_id, lot_group) => {
    const dataArray = {
      id: auction_id,
    };
    storePrivateID(dataArray);
    var niceURI = title.replace(/[^a-zA-Z0-9-_]/g, "");
    var URL = encodeURI(
      `/private/buy/${auction_id}/${lot_group}/${
        niceURI === "" || niceURI === null ? "untitled" : niceURI
      },'_blank'`
    );

    window.open(URL);
  };

  const [showmore, setMore] = React.useState(5);
  const [textMore, setTestMore] = React.useState("See more");

  const handleSHowmore = () => {
    setMore(countArtist);
  };

  const handleSHowless = () => {
    // setCountArtist()
    setMore(5);
    window.scrollTo(0, 100);
  };

  useEffect(() => {
    // listArtist();
    if (searchArtist === "") {
      setSearch("");
    }
  }, [searchArtist]);

  const searchItemsType = (searchValue) => {
    setSearchArtist(searchValue);
    setSearchArtist2(searchValue);
    setArtistID("")
    setSearcHCategory(url_data);
    setPageNumber(1)
  };

  const searchItems = (searchValue,rowid) => {
    setArtistID(rowid)
    setSearcHCategory("");
    setCategory2("");
    setSearch("");
    // setSearchArtist1("");
    setSearchArtist2(searchValue);
    // setFilteredResults(artistData);
    setPageNumber(1);
    setOpenFilter(false);
    window.scrollTo(0, 100);

    // setSearcHCategory("");
    // setCategory2("");
    // setSearch(searchValue);
    // setSearchArtist2(searchValue);
    // setSearchArtist("");
    // setPageNumber(1);
    // window.scrollTo(0,0);
  };

  const [openfilter, setOpenFilter] = useState(false);
  const handleFIlter = (data) => {
    setOpenFilter(data);
  };

  useEffect(() => {
    if (window.performance) {
      var navEntries = window.performance.getEntriesByType("navigation");
      if (navEntries.length > 0 && navEntries[0].type === "back_forward") {
        //  var toop =  localStorage.getItem("top")

        // setLoadingCat(true)
        if (hasMore === true) {
          setGetAuctions(lotdata);
          // stroreAuctionList(lotdata)
        } else {
          // setLoadingCat(true)
          // setLoadingCat(false)

          async function getAuct() {
            const getData = await getAuctionList();

            setGetAuctions(getData);
            // setLoadingCat(true)
          }
          getAuct().catch(console.error);
        }
      } else if (
        window.performance.navigation &&
        window.performance.navigation.type ==
          window.performance.navigation.TYPE_BACK_FORWARD
      ) {
        localStorage.setItem("top", document.documentElement.scrollHeight);
        setGetAuctions(lotdata);
        // stroreAuctionList(lotdata)
        console.log("original page");
      } else {
        // localStorage.setItem("top",document.documentElement.scrollHeight);
        setGetAuctions(lotdata);
        // stroreAuctionList(lotdata)
        // localStorage.setItem("top",document.documentElement.scrollHeight);
      }
    } else {
      // stroreAuctionList(lotdata)
      //  localStorage.setItem("top",document.documentElement.scrollHeight);
      // stroreAuctionList(lotdata)
    }

    localStorage.setItem("top", document.documentElement.scrollHeight);
  }, [lotdata]);

  const handleClear = () => {
    setSearcHCategory("");
    setCategory2("");
    setSearch("");
    setPageNumber(1);
    setSearchArtist2("");
    setArtistID("")
  };

  return (
    <div>
      <section className="blog-single-area lotArea" style={{ marginTop: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div style={{ marginTop: matches === false ? 10 : 0 }}>
                <ListItemButton
                  style={{ display: matches === false ? "block" : "none" }}
                >
                  {/* <ListItemText
                  primary="Filter"
                  onClick={(e) => handleFIlter(!openfilter)}
                /> */}
                  <div style={{ display: "flex" }}>
                    <ListItemIcon onClick={(e) => handleFIlter(!openfilter)}>
                      <FilterListIcon />
                      &nbsp; &nbsp;Filters&nbsp; &nbsp;
                    </ListItemIcon>
                    <button
                      onClick={(e) => handleClear()}
                      style={{ minWidth: 0 }}
                    >
                      <h6>All</h6>
                    </button>
                  </div>
                  {/* <ListItemText style={{float: 'right',textAlign: 'end'}}  primary={row.total} /> */}
                </ListItemButton>
              </div>

              <div
                className={`sidebar-wrapper ${
                  matches === false ? "filtering" : ""
                }`}
                style={{
                  width: "100%",
                  display: matches === openfilter ? "none" : "block",
                  overflow: "auto",
                  paddingTop: matches === false ? 0 : 0,
                }}
              >
                <div style={{ display: matches === true ? "block" : "none" }}>
                  <Button
                    onClick={(e) => handleClear()}
                    style={{ minWidth: 0 }}
                  >
                    <h3>All</h3>
                  </Button>
                </div>
                <div
                  style={{
                    position: "sticky",
                    top: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={(e) => handleFIlter(!openfilter)}
                    style={{
                      color: "#000000",
                      fontSize: 25,
                      // paddingTop: 10,
                      marginBottom: 20,
                      marginRight: 20,
                      display: matches === true ? "none" : "block",
                    }}
                  >
                    <ClearIcon style={{ fontSize: 35 }} />
                  </button>

                  <form onSubmit={handleSearch} style={{ width: "100%" }}>
                    <FormControl
                      sx={{ width: "100%", marginBottom: 3 }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        style={{ height: 50 }}
                        id="outlined-adornment-weight"
                        placeholder={"search"}
                        type="search"
                        onChange={(e) => setFilter(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon
                              type="submit"
                              onClick={(e) => handleSearch(e)}
                              style={{
                                height: 50,
                                color: "white",
                                marginLeft: -22,
                                width: 35,
                                padding: 5,
                                background: "#6c757d",
                                position: "absolute",
                                borderRadiu: 5,
                                borderBottomRightRadius: 2,
                                borderTopRightRadius: 2,
                                cursor: "pointer",
                              }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                  </form>
                </div>

                <Accordion
                  style={{ margin: 0 }}
                  expanded={expanded1}
                  // expanded={expanded1 === matches}
                  onChange={(e) => setExpanded1(!expanded1)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="title">
                      <h5 style={{ fontWeight: "bold" }}>ARTIST</h5>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: " 0px 0px 0px" }}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        style={{ height: 50 }}
                        id="outlined-adornment-weight"
                        placeholder={"search"}
                        value={searchArtist2}
                        type="search"
                        onChange={(e) => searchItemsType(e.target.value)}
                        // onChange={() => handleSearchArtist()}
                        // onChange={handleSearchArtist}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon style={{ height: 30 }} />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    <List>
                      <Box style={{ maxHeight: 500, overflow: "auto" }}>
                        {loadingCat === true ? (
                          <Box sx={{ textAlign: "center", padding: 10 }}>
                            <CircularProgress />
                          </Box>
                        ) : filteredResults.filter((item) => {
                            return Object.values(item)
                              .join("")
                              .toLowerCase()
                              .includes(searchArtist.toLowerCase());
                          }).length ? (
                          filteredResults
                            .filter((item) => {
                              return Object.values(item)
                                .join("")
                                .toLowerCase()
                                .includes(searchArtist.toLowerCase());
                            })
                            .sort((a, b) => (a.artist > b.artist ? 1 : -1))
                            .slice(0, showmore)
                            .map((row, index) => (
                              <ListItemButton
                                key={index}
                                style={{ cursor: "pointer" }}
                                disabled={
                                  searchArtist2 === row.artist
                                    ? true
                                    : category2 === ""
                                    ? false
                                    : searchArtist2 === row.artist
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  searchItems(row.artist,row.rowid);
                                }}
                              >
                                <ListItemText primary={row.artist} />
                                <ListItemText
                                  style={{ float: "right", textAlign: "end" }}
                                  primary={row.total}
                                />
                              </ListItemButton>
                            ))
                        ) : (
                          <ListItemText primary={"No record found"} />
                        )}

                        {filteredResults.filter((item) => {
                          return Object.values(item)
                            .join("")
                            .toLowerCase()
                            .includes(searchArtist.toLowerCase());
                        }).length ? (
                          <a
                            style={{
                              fontSize: 20,
                              // color: "#23448d",
                              cursor: "pointer",
                            }}
                            onClick={
                              filteredResults.length === showmore
                                ? handleSHowless
                                : handleSHowmore
                            }
                          >
                            <p style={{ textAlign: "center" }}>
                              {filteredResults.length === showmore
                                ? "less"
                                : "more"}
                            </p>
                            {/* {filteredResults.length}<br/>
                          {showmore} */}
                          </a>
                        ) : (
                          ""
                        )}
                      </Box>
                    </List>

                    {/* {artistData.slice(0, showmore).map((row, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={checked[0] && checked[1]}
                            onChange={handleGetArtist}
                            value={row.artist}
                          />
                        }
                        label={row.artist}
                      />
                    ))} */}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded}
                  // expanded={expanded === matches}
                  onChange={(e) => setExpanded(!expanded)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="title">
                      <h5 style={{ fontWeight: "bold" }}>CATEGORY</h5>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: " 0px 0px 0px" }}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        style={{ height: 50 }}
                        id="outlined-adornment-weight"
                        placeholder={"search"}
                        value={category2}
                        type="search"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handlepickCategory(e.target.value)}
                        // onChange={handlepickCategory}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon style={{ height: 30 }} />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>

                    <List>
                      {loadingCategory === true ? (
                        <Box sx={{ textAlign: "center", padding: 10 }}>
                          <CircularProgress />
                        </Box>
                      ) : categoryData.filter((item) => {
                          return Object.values(item)
                            .join("")
                            .toLowerCase()
                            .includes(category1.toLowerCase());
                        }).length ? (
                        categoryData
                          .filter((item) => {
                            return Object.values(item)
                              .join("")
                              .toLowerCase()
                              .includes(category1.toLowerCase());
                          })
                          .map((row, index) => (
                            <ListItemButton
                              key={index}
                              style={{ cursor: "pointer" }}
                              disabled={
                                category2 === row.categories
                                  ? true
                                  : searchArtist2 === ""
                                  ? false
                                  : category2 === row.categories
                                  ? true
                                  : false
                              }
                              // disabled={category2 === row.categories ? true : false}
                              onClick={(e) => {
                                handlepickSelectionCategory(row.categories);
                                e.target.disabled = true;
                              }}
                            >
                              <ListItemText primary={row.categories} />
                              <ListItemText
                                style={{ float: "right", textAlign: "end" }}
                                primary={row.total}
                              />
                            </ListItemButton>
                          ))
                      ) : (
                        <ListItemText primary={"No record Found"} />
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className="col">
              <div className="row" style={{ marginTop: 0 }}>
                {getAuction.map((value, i) => {
                  if (
                    getAuction.filter(
                      (obj1, i, arr) =>
                        arr.findIndex(
                          (obj2) => obj2.data.id === obj1.data.id
                        ) === i
                    ).length ===
                    i + 1
                  ) {
                    var img = document.getElementById("IMAGE");
                    let IMAGESHOW;
                    if (img === null) {
                      IMAGESHOW = true;
                    } else {
                      if (img.complete) {
                        IMAGESHOW = true;
                      } else {
                        IMAGESHOW = false;
                      }
                    }

                    // var img = document.querySelector('img')

                    // function loaded() {
                    //   console.log('loaded')
                    // }

                    // if (img.complete || img.decode()) {
                    //   loaded()
                    //  console.log("complete")
                    // } else {
                    //   img.addEventListener('load', loaded)

                    //   img.addEventListener('error', function() {
                    //       console.log('error')
                    //   })
                    // }

                    return (
                      <div
                        ref={lastBookElementRef}
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                        key={value}
                        style={{ marginBottom: 25 }}
                      >
                        <button
                          className="lotDisplayCenter"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          onClick={() =>
                            gotSinglePage(
                              value.data.title,
                              value.data.id,
                              value.data.id.lot_group
                            )
                          }
                        >
                          <div
                            // className="containerImage zoom"
                            style={{
                              width: "100%",
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              // height:300,
                              // backgroundColor: IMAGESHOW === true ? '':'red'
                            }}
                          >
                            <div
                            // className={
                            //   matches === false
                            //     ? "example example-contain"
                            //     : "example example-contain"
                            // }
                            // style={{width: 250,
                            //   maxHeight: 350,
                            //   // width: 200px;
                            //   // height: 200px;

                            //   display: 'flex',
                            //   justifyContent: 'center',
                            //   alignItems: 'center'
                            // }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    value.image.length
                                      ? value.image[0].img_url
                                      : "https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE"
                                  })`,
                                  backgroundColor: "#ffffff",
                                  width: "100%",
                                  height: 370,
                                  display: "inline-block",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center center",
                                  backgroundRepeat: "no-repeat",
                                  padding: 10,
                                }}
                              ></div>

                              {/* <h3>View</h3> */}
                            </div>
                          </div>

                          <div style={{ paddingBottom: 50 }}>
                            <div style={{ fontSize: 18, textAlign: "start" }}>
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.title === null
                                  ? ""
                                  : value.data.title.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )}
                              </span>

                              <br />
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.artist}
                              </span>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  } else {
                    var img = document.getElementById("IMAGE");
                    let IMAGESHOW;
                    if (img === null) {
                      IMAGESHOW = true;
                    } else {
                      if (img.complete) {
                        IMAGESHOW = true;
                      } else {
                        IMAGESHOW = false;
                      }
                    }

                    return (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                        key={value}
                        style={{ marginBottom: 25 }}
                      >
                        <button
                          className="lotDisplayCenter"
                          style={{
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          onClick={() =>
                            gotSinglePage(
                              value.data.title,
                              value.data.id,
                              value.data.lot_group
                            )
                          }
                        >
                          <div
                            // className="containerImage zoom"
                            style={{
                              width: "100%",
                              border: "solid 1px #e8e8e8",
                              boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              // backgroundColor: IMAGESHOW === true ? '':'red' ,
                              // height:300,
                            }}
                          >
                            <div
                            // className={
                            //   matches === false
                            //     ? "example example-contain"
                            //     : "example example-contain"
                            // }
                            // style={{width: 250,
                            //   maxHeight: 350,
                            //   // width: 200px;
                            //   // height: 200px;

                            //   display: 'flex',
                            //   justifyContent: 'center',
                            //   alignItems: 'center'
                            // }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    value.image.length
                                      ? value.image[0].img_url
                                      : "https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE"
                                  })`,
                                  backgroundColor: "#ffffff",
                                  width: "100%",
                                  height: 370,
                                  display: "inline-block",
                                  backgroundSize: "contain",
                                  backgroundPosition: "center center",
                                  backgroundRepeat: "no-repeat",
                                  padding: 10,
                                }}
                              ></div>

                              {/* <img
                                 style={{width: matches === false ? "75%":"100%"}}
                                 className={
                                  matches === false
                                    ? "imageCenter"
                                    : ""
                                }

                                  src={
                                    value.image.length
                                      ? value.image[0].img_url
                                      : NoImage
                                  }
                                  alt={value.image}
                                /> */}

                              {/* <h3>View</h3> */}
                            </div>
                          </div>

                          <div style={{ paddingBottom: 50 }}>
                            <div style={{ fontSize: 18, textAlign: "start" }}>
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.title === null
                                  ? ""
                                  : value.data.title.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )}
                              </span>

                              <br />
                              <span
                                className="product_info title_lot"
                                style={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                  width: 280,
                                  overflow: " hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value.data.artist}
                              </span>
                            </div>
                          </div>
                        </button>
                      </div>
                    );
                  }
                })}

                {/* {isLoading === true ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  ""
                )} */}
              </div>

              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  width: "10%",
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <div>{}</div>
                {/* {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}*/}
                {/* <div>{loading &&   <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>}</div> */}
                {error ? (
                  <p></p>
                ) : loading ? (
                  <Box sx={{ display: "flex", position: "relative" }}>
                    <span
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "2px",
                        fontSize: 12,
                      }}
                    >
                      Loading
                    </span>
                    <CircularProgress size="3rem" />
                  </Box>
                ) : (
                  ""
                )}
              </div>
              {error === true ? (
                <div
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    width: "100%",
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  {getAuction.length === 0 ? `No results for: ${search}` : ""}
                  {error}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      {/* {isLoading && (
        <Box sx={{ display: "flex"}}>
          <CircularProgress />
        </Box>
      )} */}
    </div>
  );
}
