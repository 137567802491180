import React, { useState, useRef, useCallback, useEffect } from "react";
import LotData from "./LotData";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { getFilter, filterArtist } from "../../Model/Auction";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FormControl from "@mui/material/FormControl";
import FilterListIcon from "@mui/icons-material/FilterList";
import WITHDRAWN_IMAGE from "./../../images/Banner/withdrawn.jpg";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
// import NoImage from "./../../images/Banner/no_image.jpeg";
import ListItemText from "@mui/material/ListItemText";

import ListItemButton from "@mui/material/ListItemButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { makeStyles } from "@mui/styles";

import {
  storeAuctionLot,
  stroreAuctionList,
  getAuctionList,
  storeMetadetails,
} from "../../Model/LocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { addfavorite, removefavorite } from "../../Model/Auction";
import { Button, Typography } from "@mui/material";
// import { SettingsCellSharp } from "@mui/icons-material";
// import { Stack, ToggleButton } from "@mui/material";

// import LayoutHeader from "../../mainLayouts/Layout";

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "pink",
      color: "red",
      "&:hover": {
        backgroundColor: "tomato",
      },
    },
  },
  selected: {},
});

export default function Lot({ showHp, islive }) {
  const matches = useMediaQuery("(min-width:1000px)");
  const matchesPhone = useMediaQuery("(min-width:480px)");
  var { id, slug, type } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const auctionID = localStorage.getItem("items");
  const [nameID, setNameID] = useState([]);
  const styles = useStyles();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [searchCat, setSearcHCategory] = useState("");
  const [category1, setCategory1] = useState("");
  const [category2, setCategory2] = useState("");
  const [selectDays, setSelectdays] = useState("");
  const [expanded, setExpanded] = React.useState(true);
  const [expanded1, setExpanded1] = React.useState(true);
  const [expanded2, setExpanded2] = React.useState(true);
  const [heart, setHeart] = React.useState(0);
  const [datalot, setDalot] = useState([]);
  const [datalot1, setDalot1] = useState([]);
  const [countArtist, setCountArtist] = React.useState(null);

  const [yourId, setYourId] = React.useState(null);
  const [loadingCat, setLoadingCat] = React.useState(false);
  const [getAuction, setGetAuctions] = useState([]);
  const { lotdata, hasMore, loading, error, errorMsg, totalLOts } = LotData(
    pageNumber,
    id,
    search,
    selectDays,
    searchCat
  );

  // stroreAuctionList(lotdata)
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          // localStorage.setItem("pageNumber",pageNumber);
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [artistData, setArtistData] = useState([]);
  const [categoryData, setCategory] = useState([]);

  // const listArtist = () => {
  //   setLoadingArtist(true);
  //   try {
  //     async function getAuctionOne() {
  //       const results = await filterArtist(auctionID);
  //       setArtistData(results.data.filter((o, i) => o.artist !== null));
  //       setLoadingArtist(false);
  //     }
  //     getAuctionOne().catch(console.error);
  //   } catch (error) {
  //     setArtistData([])
  //   }
  // };

  const [filteredResults, setFilteredResults] = useState([]);
  const FilterCategory = () => {
    setLoadingCat(true);
    try {
      async function getAuctionOne() {
        const results = await filterArtist(id);
        setCategory(results.categories);
        setFilteredResults(results.data.filter((o, i) => o.artist !== null));

        setCountArtist(results.data.length);
        // setFilteredResults(results.data)
        setLoadingCat(false);
      }
      getAuctionOne().catch(console.error);
    } catch (error) {}
  };

  // const searcArtistData = (val) => {
  //   setSearch(val);
  //   setSearchArtist(val);
  //   setPageNumber(1);
  // };

  const handleSearch = (e) => {
    e.preventDefault();
    // alert(filter)
    setSearch(filter);
    setPageNumber(1);
    setOpenFilter(false);
  };

  // const [searchArtist, setSearchArtist] = useState("");
  const [searchArtist1, setSearchArtist1] = useState("");
  const [searchArtist2, setSearchArtist2] = useState("");

  useEffect(() => {
    // listArtist();
    if (searchArtist1 === "") {
      setSearch("");
    } else {
    }
  }, [searchArtist1]);

  const searchItemsType = (searchValue) => {
    setSearchArtist1(searchValue);
    setSearchArtist2(searchValue);
  };

  const searchItems = (searchValue) => {
    setSearcHCategory("");
    // setCategory2("");
    setSearch(searchValue);
    // setSearchArtist1("");
    setSearchArtist2(searchValue);
    // setFilteredResults(artistData);
    setPageNumber(1);
    setOpenFilter(false);
    window.scrollTo(0, 100);
  };

  useEffect(() => {
    if (category2 === "") {
      setSearch("");
      FilterCategory();
    } else {
    }
  }, [category2]);

  const handlepickCategory = (searchValue) => {
    setCategory1(searchValue);
    setCategory2(searchValue);
  };

  const handlepickSelectionCategory = (event) => {
    setSearch("");
    setCategory2(event);
    setSearcHCategory(event);
    setCategory1("");
    setPageNumber(1);
    setSearchArtist2("");
    setOpenFilter(false);
    window.scrollTo(0, 100);

    //  setSearch("");
    // setSearchArtist2("");

    // setCategory2(event);
    // setSearcHCategory(event);
    // setCategory1("");
    // setPageNumber(1);
    // window.scrollTo(0,100);
  };

  const gotSinglePage = async (slug, title, auction_id, lotNumber, image) => {
    const dataArray = {
      lot_id: auction_id,
      lotNo: lotNumber,
      setDetails: nameID,
      imageSingle: image,
      title: title,
      slug: slug,
    };

    let dataSend = {
      title: title,
      discription: slug,
      lotNo: lotNumber,
      imagUrl: image,
    };

    await fetch(`https://leon-gallery.com/meta`, {
      method: "POST",
      body: JSON.stringify(dataSend),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())

      .then((data) => {
        console.log("data send here ", data);
      })
      .catch((error) => {
        // alert.error(error)
        console.log("error ", error);
      });

    storeMetadetails(dataArray);
    storeAuctionLot(dataArray);

    // var niceURI = title.replace(/[^a-zA-Z0-9-_]/g, '');
    // var URL = encodeURI(`/private/buy/${lot_group}/${niceURI === "" || niceURI === null ? "untitled":niceURI },'_blank'`)

    window.location.href = `/auction/${auction_id}/${lotNumber}/${slug}/${
      title === null ? "untitle" : title.replace(/[^a-zA-Z0-9-_]/g, "")
    }`;
  };

  const [showmore, setMore] = React.useState(5);
  const [textMore, setTestMore] = React.useState("See more");
  const handleSHowmore = () => {
    setMore(countArtist);
  };

  const handleSHowless = () => {
    // setCountArtist()
    setMore(5);
    window.scrollTo(0, 150);
  };

  const [day, setDay] = React.useState("");
  const [colorFav, setColorFav] = React.useState("");

  const handleSelectDays = (e, data) => {
    e.preventDefault();
    if (data === 2) {
      setDay("Day 1");
    } else if (data === 3) {
      setDay("Day 2");
    } else {
      setDay("All");
    }
    setSelectdays(data);
    setPageNumber(1);
  };

  // const handleChangeObject = (e,data) =>{
  //   alert(1+1)
  //   setDalot(data);

  // }

  const handlickFavorite = (e, lot) => {
    const data = {
      lot_id: lot,
    };
    // const sample = userData.findIndex(item => item.id === user_ID);
    // const newArena = userData;
    let result1 = lotdata.map((row) => row.data);
    let result2 = result1.findIndex((obj) => obj.id === lot);
    const newArena = result1;
    newArena[result2].fav = 1;
    setDalot1({ data: 1 });
    // let newDta = Object.assign(lotdata);
    // console.log(Object.assign(lotdata));

    async function addfavoriteFunction() {
      const result = await addfavorite(data);

      if (result.status === true) {
        return lotdata;
      } else {
      }
    }
    addfavoriteFunction().catch((error) => {
      console.log(error);
    });

    // setDalot(newDta);
  };

  //lotdata[newArena];
  // datalot.length ?  alert("datalot"): alert("datalot")
  // console.log('lot data ',lotdata)

  // console.log(lot)
  //    console.log('lot ', newArena)
  //     console.log('handlickFavorite ', lotdata)
  //   }

  const handlickFavoriteRemove = (lot) => {
    const data = {
      lot_id: lot,
    };
    // Object.values(data).filter(item=>item.city === "London")
    let result1 = lotdata.map((row) => row.data);
    let result2 = result1.findIndex((obj) => obj.id === lot);
    const newArena = result1;
    newArena[result2].fav = 0;
    setDalot1({ data: 1 });
    // let newDta = Object.assign(lotdata);
    // console.log(Object.assign(lotdata));
    async function addfavoriteFunction() {
      const result = await removefavorite(data);

      if (result.status === true) {
        return lotdata;
      } else {
      }
    }
    addfavoriteFunction().catch(console.error);
    // console.log('handlickFavorite ', data)
  };

  const Callfunciton = async () => {};

  var name = localStorage.getItem("pageNumber");

  // console.log('datalot1 ',datalot1)
  useEffect(() => {
    // listArtist();
    Callfunciton();
    FilterCategory();

    // async function getUserList() {
    //   const getuser = await getUser();
    //   setYourId(getuser.id);
    // }
    // getUserList().catch(console.error);
  }, [slug, type]);

  const [openfilter, setOpenFilter] = useState(false);
  const handleFIlter = (data) => {
    setOpenFilter(data);
  };
  // const [selected, setSelected] = React.useState(false);

  const [count, setCount] = useState(0);
  useEffect(() => {
    if (window.performance) {
      var navEntries = window.performance.getEntriesByType("navigation");
      if (navEntries.length > 0 && navEntries[0].type === "back_forward") {
        // stroreAuctionList(lotdata)
        // setGetAuctions(lotdata)
        //  setPageNumber(name)
        if (hasMore === true) {
          setGetAuctions(lotdata);
          stroreAuctionList(lotdata);
        } else {
          // setLoadingCat(true)
          // setLoadingCat(false)
          async function getAuct() {
            const getData = await getAuctionList();
            setGetAuctions(getData);
          }
          getAuct().catch(console.error);
        }
      } else if (
        window.performance.navigation &&
        window.performance.navigation.type ==
          window.performance.navigation.TYPE_BACK_FORWARD
      ) {
        localStorage.setItem("top", document.documentElement.scrollHeight);
        setGetAuctions(lotdata);
        stroreAuctionList(lotdata);
        // alert('original page')
      } else {
        // localStorage.setItem("top",document.documentElement.scrollHeight);

        setGetAuctions(lotdata);
        stroreAuctionList(lotdata);
      }
    } else {
      stroreAuctionList(lotdata);

      // stroreAuctionList(lotdata)
    }
  }, [lotdata]);

  const [imgsLoaded, setImgsLoaded] = useState(true);

  const handleClear = () => {
    setSearcHCategory("");
    setCategory2("");
    setSearch("");
    setPageNumber(1);
    // setSearchArtist1("");
    setSearchArtist2("");
  };

  return (
    <div>
      <section className="blog-single-area lotArea">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div style={{ marginTop: matches === false ? 10 : 0 }}>
                <ListItemButton
                  style={{ display: matches === false ? "block" : "none" }}
                >
                  {/* <ListItemText
                  primary="Filter"
                  onClick={(e) => handleFIlter(!openfilter)}
                /> */}
                  <div style={{ display: "flex" }}>
                    <ListItemIcon onClick={(e) => handleFIlter(!openfilter)}>
                      <FilterListIcon />
                      &nbsp; &nbsp;Filters&nbsp; &nbsp;&nbsp; &nbsp;
                    </ListItemIcon>
                    <button
                      onClick={(e) => handleClear()}
                      style={{ minWidth: 0 }}
                    >
                      <h6>All</h6>
                    </button>
                    &nbsp; &nbsp;&nbsp; &nbsp;
                    <button style={{ minWidth: 0 }}>
                      <h6>{totalLOts} Lots</h6>
                    </button>
                  </div>
                  {/* <ListItemText style={{float: 'right',textAlign: 'end'}}  primary={row.total} /> */}
                </ListItemButton>
              </div>

              <div
                className={`sidebar-wrapper ${
                  matches === false ? "filtering" : ""
                }`}
                style={{
                  width: "100%",
                  display: matches === openfilter ? "none" : "block",
                  overflow: "auto",
                  paddingTop: matches === false ? 0 : 0,
                }}
              >
                <div style={{ display: matches === true ? "block" : "none" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 5,
                    }}
                  >
                    <h3 onClick={(e) => handleClear()}>All</h3>

                    <h3>{totalLOts} Lots</h3>
                  </div>
                </div>

                <div
                  style={{
                    position: "sticky",
                    top: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={(e) => handleFIlter(!openfilter)}
                    style={{
                      color: "#000000",
                      fontSize: 25,
                      // paddingTop: 10,
                      marginBottom: 20,
                      marginRight: 20,
                      display: matches === true ? "none" : "block",
                    }}
                  >
                    <ClearIcon style={{ fontSize: 35 }} />
                  </button>

                  <form onSubmit={handleSearch} style={{ width: "100%" }}>
                    <FormControl
                      sx={{ width: "100%", marginBottom: 3 }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        style={{ height: 50 }}
                        // id="outlined-adornment-weight"
                        placeholder={"search"}
                        type="search"
                        name="search"
                        required={true}
                        onChange={(e) => setFilter(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon
                              type="submit"
                              onClick={(e) => handleSearch(e)}
                              style={{

                                height: 42,
                                color: 'white',
                                marginLeft: '-25px',
                                width: 35,
                                padding: 4,
                                background: 'rgb(108, 117, 125)',
                                position: 'absolute',
                                cursor: 'pointer',
                                borderRadius: 3

                                // height: 50,
                                // color: "white",
                                // marginLeft: -22,
                                // width: 35,
                                // padding: 5,
                                // background: "#6c757d",
                                // position: "absolute",
                                // borderRadiu: 5,
                                // borderBottomRightRadius: 2,
                                // borderTopRightRadius: 2,
                                // cursor: "pointer",
                              }}
                            />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />{" "}
                    </FormControl>
                  </form>
                </div>

                <Accordion
                  style={{ marginTop: 20 }}
                  expanded={expanded1}
                  onChange={(e) => setExpanded1(!expanded1)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="title">
                      <h5 style={{ fontWeight: "bold" }}>ARTIST</h5>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: " 0px 0px 0px" }}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        style={{ height: 50 }}
                        id="outlined-adornment-weight"
                        placeholder={"search artist"}
                        value={searchArtist2}
                        type="search"
                        onChange={(e) => searchItemsType(e.target.value)}
                        // onChange={(e) => setSearchArtist1(e.target.value)}
                        // onChange={() => handleSearchArtist()}
                        // onChange={handleSearchArtist}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon style={{ height: 30 }} />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>
                    <Box style={{ maxHeight: 500, overflow: "auto" }}>
                      <List>
                        {loadingCat === true ? (
                          <Box sx={{ textAlign: "center", padding: 10 }}>
                            <CircularProgress />
                          </Box>
                        ) : filteredResults.filter((item) => {
                            return Object.values(item)
                              .join("")
                              .toLowerCase()
                              .includes(searchArtist1.toLowerCase());
                          }).length ? (
                          filteredResults
                            .filter((item) => {
                              return Object.values(item)
                                .join("")
                                .toLowerCase()
                                .includes(searchArtist1.toLowerCase());
                            })
                            .sort((a, b) => (a.artist > b.artist ? 1 : -1))
                            .slice(0, showmore)
                            .map((row, index) => (
                              <ListItemButton
                                key={index}
                                style={{ cursor: "pointer" }}
                                disabled={
                                  searchArtist2 === row.artist
                                    ? true
                                    : category2 === ""
                                    ? false
                                    : searchArtist2 === row.artist
                                    ? true
                                    : false
                                }
                                onClick={(e) => {
                                  searchItems(row.artist);
                                }}
                              >
                                <ListItemText
                                  primary={row.artist.replace(
                                    /(<([^>]+)>)/gi,
                                    " "
                                  )}
                                />
                                <ListItemText
                                  style={{ float: "right", textAlign: "end" }}
                                  primary={row.total}
                                />
                              </ListItemButton>
                            ))
                        ) : (
                          <ListItemText primary={"No record found"} />
                        )}
                      </List>
                    </Box>
                    {filteredResults.filter((item) => {
                      return Object.values(item)
                        .join("")
                        .toLowerCase()
                        .includes(searchArtist1.toLowerCase());
                    }).length ? (
                      <a
                        style={{
                          fontSize: 20,
                          // color: "#23448d",
                          cursor: "pointer",
                        }}
                        onClick={
                          filteredResults.length < showmore ||
                          filteredResults.length === showmore
                            ? handleSHowless
                            : handleSHowmore
                        }
                      >
                        <p style={{ textAlign: "center", margin: "revert" }}>
                          {filteredResults.length < showmore ||
                          filteredResults.length === showmore
                            ? "See less"
                            : "See more"}
                        </p>

                        {/* {filteredResults.length}<br/>
                          {showmore} */}
                      </a>
                    ) : (
                      ""
                    )}
                    {/* {artistData.slice(0, showmore).map((row, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={checked[0] && checked[1]}
                            onChange={handleGetArtist}
                            value={row.artist}
                          />
                        }
                        label={row.artist}
                      />
                    ))} */}
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{ height: 500 }}
                  expanded={expanded}
                  onChange={(e) => setExpanded(!expanded)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="title">
                      <h5 style={{ fontWeight: "bold" }}>CATEGORY</h5>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: " 0px 0px 0px" }}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <OutlinedInput
                        style={{ height: 50 }}
                        id="outlined-adornment-weight"
                        placeholder={"Search Category"}
                        value={category2}
                        type="search"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handlepickCategory(e.target.value)}
                        // onChange={(e) => setCategory1(e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon style={{ height: 30 }} />
                          </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                      />
                    </FormControl>

                    <List>
                      {loadingCat === true ? (
                        <Box sx={{ textAlign: "center", padding: 10 }}>
                          <CircularProgress />
                        </Box>
                      ) : categoryData.filter((item) => {
                          return Object.values(item)
                            .join("")
                            .toLowerCase()
                            .includes(category1.toLowerCase());
                        }).length ? (
                        categoryData
                          .filter((item) => {
                            return Object.values(item)
                              .join("")
                              .toLowerCase()
                              .includes(category1.toLowerCase());
                          })
                          .map((row, index) => (
                            <ListItemButton
                              key={index}
                              style={{ cursor: "pointer" }}
                              disabled={
                                category2 === row.categories
                                  ? true
                                  : searchArtist2 === ""
                                  ? false
                                  : category2 === row.categories
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handlepickSelectionCategory(row.categories);
                              }}
                            >
                              <ListItemText primary={row.categories} />
                              <ListItemText
                                style={{ float: "right", textAlign: "end" }}
                                primary={row.total}
                              />
                            </ListItemButton>
                          ))
                      ) : (
                        <ListItemText primary={"No record found"} />
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
                {type === "ONLINE_AUCTION" ? (
                  <Accordion
                    expanded={expanded2 === matches}
                    onChange={(e) => setExpanded2(!expanded2)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="title">
                        <h5>DAYS</h5>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ textAlign: "center" }}>
                        {/* <Typography> FILTERS</Typography>  */}
                        <h3>{day} </h3>
                      </div>

                      <List>
                        <ListItemButton
                          key={1}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleSelectDays(e, "")}
                        >
                          <ListItemText primary="All" />
                        </ListItemButton>
                        {nameID.map((row, index) => (
                          <ListItemButton
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleSelectDays(e, row.id)}
                          >
                            <ListItemText primary={row.set_name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="col">
              <div
                className="row items-container clearfix"
                style={{ marginTop: 0 }}
              >
                {(datalot.length === 0 ? getAuction : datalot)
                  .filter(
                    (obj1, i, arr) =>
                      arr.findIndex((obj2) => obj2.data.id === obj1.data.id) ===
                      i
                  )
                  .map((value, i) => {
                    if (
                      (datalot.length === 0 ? getAuction : datalot).filter(
                        (obj1, i, arr) =>
                          arr.findIndex(
                            (obj2) => obj2.data.id === obj1.data.id
                          ) === i
                      ).length ===
                      i + 1
                    ) {
                      let favorite;

                      if (value.data.fav === 1) {
                        favorite = (
                          <FavoriteIcon
                            fontSize="small"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={(e) =>
                              handlickFavoriteRemove(value.data.id)
                            }
                          />
                        );
                      } else {
                        favorite = (
                          <FavoriteBorderIcon
                            onClick={(e) => handlickFavorite(e, value.data.id)}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        );
                      }

                      let STATUS;

                      if (
                        value.data.lot_status === "SOLD " &&
                        value.data.hammer_price > 1
                      ) {
                        STATUS = "Sold ";
                      } else if (value.data.hammer_price > 1) {
                        STATUS = "Sold ";
                      } else {
                        STATUS = "";
                      }
                      // var img = document.querySelector('img')
                      // let IMAGESHOW;
                      // if (img.complete) {
                      //   IMAGESHOW =  true
                      //   console.log("complete")
                      // } else {
                      //   IMAGESHOW = false
                      //   console.log("not complete")
                      // }

                      return (
                        <div
                          ref={lastBookElementRef}
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                          key={i}
                          style={{
                            marginBottom: 25,
                            paddingLeft: matchesPhone === true ? "" : 0,
                            paddingRight: matchesPhone === true ? "" : 0,
                          }}
                        >
                          <a
                            className="lotDisplayCenter"
                            style={{
                              width: "100%",
                              // display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            href={`/auction/${value.data.auction_id}/${value.data.lot_number}/${slug}/${
                              value.data.title === null ? "untitle" : value.data.title.replace(/[^a-zA-Z0-9-_]/g, "")
                            }`}

                            // onClick={() =>
                            //   gotSinglePage(
                            //     slug,
                            //     value.data.title,
                            //     value.data.auction_id,
                            //     value.data.lot_number,
                            //     value.image.length
                            //       ? value.image[0].img_url
                            //       : "https://placehold.co/400x360?text=NO%20IMAGE%20AVAILABLE"
                            //   )
                            // }
                          >
                            <div
                              // className="containerImage zoom"
                              style={{
                                width: "100%",
                                border: "solid 1px #e8e8e8",
                                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div
                              // style={{
                              //   maxWidth: matchesPhone === true ? 327 : "",
                              //   height: matchesPhone === true ? 327 : "",
                              // }}
                              >
                                {imgsLoaded ? (
                                  <div
                                  // style={{ height: "100%" }}
                                  // className={
                                  //   matches === false
                                  //     ? ""
                                  //     : "example example-contain"
                                  // }
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          value.data.isWithdrawn === 1
                                            ? WITHDRAWN_IMAGE
                                            : value.image.length
                                            ? value.image[0].img_url
                                            : "https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE"
                                        })`,
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        height: 370,
                                        display: "inline-block",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        padding: 10,
                                      }}
                                    ></div>
                                  </div>
                                ) : (
                                  <div
                                    // className="containerImage bg-image hover-zoom"
                                    style={
                                      {
                                        // height: 468,
                                      }
                                    }
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE)`,
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        height: 370,
                                        display: "inline-block",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        padding: 10,
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div style={{ paddingBottom: 50, paddingTop: 10 }}>
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "start",
                                  lineHeight: 1.3,
                                  paddingLeft: matchesPhone === true ? "" : 15,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // marginBottom: 10,
                                  }}
                                >
                                  <span className="product_info">
                                    Lot {value.data.lot_number}
                                  </span>
                                  <span className="product_info">
                                    {/* <ToggleButton>
                                    <ToggleButton selected={selected+i}
                                    onChange={() => setSelected((prevSelected) => !prevSelected)}/>
                                  </ToggleButton> */}

                                    {/* {favorite} */}
                                  </span>
                                </div>

                                {value.data.artist === null ? (
                                  ""
                                ) : (
                                  <span
                                    className="product_info title_lot"
                                    style={{
                                      // fontWeight: "bold",
                                      display: "inline-block",
                                      width: 280,
                                      overflow: " hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {value.data.artist.replace(/(<([^>]+)>)/gi)}
                                  </span>
                                )}
                                {/* <br /> */}
                                {value.data.title === null ? (
                                  ""
                                ) : (
                                  <>
                                    <span
                                      className="product_info title_lot"
                                      dangerouslySetInnerHTML={{
                                        __html: value.data.title,
                                      }}
                                    >
                                      {/* {value.data.artist} */}
                                    </span>
                                    <br />
                                  </>
                                )}
                                {/* { yourId === value.data.userId ? */}
                                {value.myCountBid.length ? (
                                  value.myCountBid[0].myBidAmount >=
                                  value.data.hp ? (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "green",
                                      }}
                                    >
                                      Your bid Win :{" "}
                                      {value.myCountBid.length
                                        ? value.myCountBid[0].myBidAmount.toLocaleString()
                                        : ""}
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "red",
                                      }}
                                    >
                                      Out bid:{" "}
                                      {value.data.starting_price.toLocaleString()}
                                    </p>
                                  )
                                ) : (
                                  ""
                                )}

                                <p style={{ fontSize: 20 }}>
                                  {value.data.isWithdrawn === 1 ||
                                  value.data.hammer_price === 0 ||
                                  value.data.hammer_price === null
                                    ? `${STATUS}Php: ${
                                        value.data.isWithdrawn === 1
                                          ? 0
                                          : value.data.starting_price === null
                                          ? 0
                                          : value.data.starting_price.toLocaleString()
                                      }`
                                    : `${STATUS} Php: ${
                                        value.data.isWithdrawn === 1
                                          ? 0
                                          : value.data.hammer_price === null
                                          ? 0
                                          : value.data.hammer_price.toLocaleString()
                                      }`}

                                  {/* {
                                   value.data.isWithdrawn === 1 || value.data.lot_status === "PASS" ?  `PHP ` + value.data.starting_price.toLocaleString() :  
                                   value.data.lot_status === "SOLD" ? `SOLD PHP ${value.data.hammer_price === null || value.data.hp === 0 ? value.data.starting_price.toLocaleString() :value.data.hp.toLocaleString() }` : `PHP ${value.data.starting_price === null ? 0: value.data.starting_price.toLocaleString()}`
                                 
                                       } */}

                                  {/* <p style={{ fontSize: 16, fontWeight: "bold",  }}>
                                            {value.data.hp === null  || value.data.hp ===  0? "": " Current bid: "+value.data.hp.toLocaleString()} 
                                        </p>  */}
                                </p>
                              </div>
                            </div>
                          </a>

                          <ul>
                            <li></li>
                          </ul>
                        </div>
                      );
                    } else {
                      let favorite;

                      if (value.data.fav === 1) {
                        favorite = (
                          <FavoriteIcon
                            fontSize="small"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={(e) =>
                              handlickFavoriteRemove(value.data.id)
                            }
                          />
                        );
                      } else {
                        favorite = (
                          <FavoriteBorderIcon
                            onClick={(e) => handlickFavorite(e, value.data.id)}
                            style={{ cursor: "pointer" }}
                            fontSize="small"
                          />
                        );
                      }

                      let STATUS;
                      if (value.data.isWithdrawn === 1) {
                        STATUS = "Withdraw ";
                      } else if (value.data.hammer_price > 1) {
                        STATUS = "Sold ";
                      } else if (
                        value.data.lot_status === "SOLD" &&
                        value.data.hammer_price > 1
                      ) {
                        STATUS = "Sold ";
                      } else {
                        STATUS = "";
                      }

                      // var img = document.querySelector('img')
                      // let IMAGESHOW;
                      // if (img.complete) {
                      //   IMAGESHOW =  true
                      //   console.log("complete")
                      // } else {
                      //   IMAGESHOW = false
                      //   console.log("not complete")
                      // }

                      return (
                        <div
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 masonry-item all art insta"
                          key={i}
                          style={{
                            marginBottom: 25,
                            paddingLeft: matchesPhone === true ? "" : 0,
                            paddingRight: matchesPhone === true ? "" : 0,
                          }}
                        >
                          <a
                            className="lotDisplayCenter"
                            style={{
                              width: "100%",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}

                            href={`/auction/${value.data.auction_id}/${value.data.lot_number}/${slug}/${
                              value.data.title === null ? "untitle" : value.data.title.replace(/[^a-zA-Z0-9-_]/g, "")
                            }`}
                            // onClick={() =>
                            //   gotSinglePage(
                            //     slug,
                            //     value.data.title,
                            //     value.data.auction_id,
                            //     value.data.lot_number,
                            //     value.image.length
                            //       ? value.image[0].img_url
                            //       : "https://placehold.co/560x500?text=NO%20IMAGE%20AVAILABLE"
                            //   )
                            // }
                          >
                            <div
                              // className="containerImage zoom"
                              style={{
                                width: "100%",
                                border: "solid 1px #e8e8e8",
                                boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div

                              // style={{
                              //   maxWidth: matchesPhone === true ? 327 : "",
                              //   height: matchesPhone === true ? 327 : "",
                              // }}
                              >
                                {imgsLoaded ? (
                                  <div
                                  // style={{ height: "100%" }}
                                  // className={
                                  //   matches === false
                                  //     ? ""
                                  //     : "example example-contain"
                                  // }
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url(${
                                          value.data.isWithdrawn === 1
                                            ? WITHDRAWN_IMAGE
                                            : value.image.length
                                            ? value.image[0].img_url
                                            : "https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE"
                                        })`,
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        height: 370,
                                        display: "inline-block",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        padding: 10,
                                      }}
                                    ></div>

                                    {/* <h3>View</h3> */}
                                  </div>
                                ) : (
                                  <div
                                  // className="containerImage bg-image hover-zoom"
                                  // style={
                                  //   {
                                  //     // height: 468,
                                  //   }
                                  // }
                                  >
                                    <div
                                      style={{
                                        backgroundImage: `url("https://placehold.co/360x370?text=NO%20IMAGE%20AVAILABLE")`,
                                        backgroundColor: "#ffffff",
                                        width: "100%",
                                        height: 370,
                                        display: "inline-block",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        padding: 10,
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div style={{ paddingBottom: 50, paddingTop: 10 }}>
                              <div
                                style={{
                                  fontSize: 18,
                                  textAlign: "start",
                                  lineHeight: 1.3,
                                  paddingLeft: matchesPhone === true ? "" : 15,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // marginBottom: 10,
                                  }}
                                >
                                  <span className="product_info">
                                    Lot {value.data.lot_number}
                                  </span>
                                  <span className="product_info">
                                    {/* <ToggleButton>
                                    <ToggleButton selected={selected+i}
                                    onChange={() => setSelected((prevSelected) => !prevSelected)}/>
                                  </ToggleButton> */}
                                    {/* {favorite} */}
                                  </span>
                                </div>

                                {/* <br /> */}
                                {value.data.artist === null ? (
                                  ""
                                ) : (
                                  <span
                                    className="product_info title_lot"
                                    style={{
                                      // fontWeight: "bold",
                                      display: "inline-block",
                                      width: 280,
                                      overflow: " hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {value.data.artist.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )}
                                  </span>
                                )}
                                {/* <br /> */}
                                {value.data.title === null ? (
                                  ""
                                ) : (
                                  <>
                                    <span
                                      className="product_info title_lot"
                                      dangerouslySetInnerHTML={{
                                        __html: value.data.title,
                                      }}
                                    >
                                      {/* {value.data.artist} */}
                                    </span>
                                    <br />
                                  </>
                                )}
                                {/* { yourId === value.data.userId ? */}
                                {value.myCountBid.length ? (
                                  value.myCountBid[0].myBidAmount >=
                                  value.data.hp ? (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "green",
                                      }}
                                    >
                                      Your bid Win :{" "}
                                      {value.myCountBid.length
                                        ? value.myCountBid[0].myBidAmount.toLocaleString()
                                        : ""}
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "red",
                                      }}
                                    >
                                      Out bid:{" "}
                                      {value.data.starting_price.toLocaleString()}
                                    </p>
                                  )
                                ) : (
                                  ""
                                )}

                                <p style={{ fontSize: 20 }}>
                                  {value.data.isWithdrawn === 1 ||
                                  value.data.hammer_price === 0 ||
                                  value.data.hammer_price === null
                                    ? `${STATUS}Php: ${
                                        value.data.isWithdrawn === 1
                                          ? 0
                                          : value.data.starting_price === null
                                          ? 0
                                          : value.data.starting_price.toLocaleString()
                                      }`
                                    : `${STATUS} Php: ${
                                        value.data.isWithdrawn === 1
                                          ? 0
                                          : value.data.hammer_price === null
                                          ? 0
                                          : value.data.hammer_price.toLocaleString()
                                      }`}

                                  {/* {
                                   value.data.isWithdrawn === 1 || value.data.lot_status === "PASS" ?  `PHP ` + value.data.starting_price.toLocaleString() :  
                                   value.data.lot_status === "SOLD" ? `SOLD PHP ${value.data.hammer_price === null || value.data.hp === 0 ? value.data.starting_price.toLocaleString() :value.data.hp.toLocaleString() }` : `PHP ${value.data.starting_price === null ? 0: value.data.starting_price.toLocaleString()}`
                                 
                                       } */}

                                  {/* <p style={{ fontSize: 16, fontWeight: "bold",  }}>
                                            {value.data.hp === null  || value.data.hp ===  0? "": " Current bid: "+value.data.hp.toLocaleString()} 
                                        </p>  */}
                                </p>
                              </div>
                            </div>
                          </a>

                          <ul>
                            <li></li>
                          </ul>
                        </div>
                      );
                    }
                  })}
              </div>

              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  // width: "10%",
                  // paddingTop: 105,
                }}
              >
                <div>{}</div>
                {/* {loading && (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                )}*/}
                {/* <div>{loading &&   <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>}</div> */}
                {error ? (
                  <p></p>
                ) : loading === true ? (
                  <div
                    style={{
                      width: "100%",
                      padding: 100,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", position: "relative" }}>
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "2px",
                          fontSize: 12,
                        }}
                      >
                        Loading
                      </span>
                      <CircularProgress size="3rem" />
                    </Box>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {error === true ? (
                <div
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    width: "100%",
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  {getAuction.length === 0 ? `No results for: ${search}` : ""}
                  {error}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      {/* {isLoading && (
        <Box sx={{ display: "flex"}}>
          <CircularProgress />
        </Box>
      )} */}
    </div>
  );
}
