import { Button, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { homehandle, upcommingEventsHandle,handleResults,handleJewelry,handleWatches,handlebags,handleAuctionsRoom} from "./RouteFunction";
import { linksData } from "../Model/Navbar";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import HomeIcon from "@mui/icons-material/Home";
import { homehandle } from "./RouteFunction";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import DirectionsIcon from "@mui/icons-material/Directions";
export default function Menu() {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(false);
  const [menuName, setMenuName] = useState("");

  const [title, setTitle] = useState(document.title);

  // const search = window.location.search;
  //   const params = new URLSearchParams(search);
  //   const data = params.get('data');
  //   useEffect(() => {
  //     document.title = data;
  // }, [data]);

  function handleSearch(e) {
    e.preventDefault();

    // let searching = search.replace([a-zA-Z0-9\s]+$)
    if (search === "") {
    } else {
      window.location.href = `/search/${search}`;
    }
  }
  // const CallFunction = (event, handle,name) => {
  //   if ("homehandle" === handle) {
  //     return homehandle;
  //   } else if ("upcommingEventsHandle" === handle) {
  //     return upcommingEventsHandle(name);
  //   } else if ("handleResults" === handle) {
  //     return handleResults(name);
  //   } else if ("handleJewelry" === handle) {
  //     return handleJewelry(name);
  //   } else if ("handleWatches" === handle) {
  //     return handleWatches(name);
  //   } else if ("handlebags" === handle) {
  //     return handlebags(name);
  //   } else if ("handleAuctionsRoom" === handle) {
  //     return handleAuctionsRoom();
  //   } else {
  //   }
  // };

  const OnclickStatus = (name) => {
    if (name === "PRIVATE SALE") {
      setMenuName(name);
      setStatus(!status);
    } else if (name === "GALLERIES") {
      setMenuName(name);
      setStatus(!status);
    } else {
    }
  };

  const [leftside, setleftside] = useState(false);
  const ShowLefSide = () => {
    // console.log('ok',leftside)
    setleftside(!leftside);
  };

  const [token, setToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("token");
    const initialValue = saved;
    return initialValue || "";
  });

  const onCLickLogout = () => {
    localStorage.removeItem("@storage_Key");
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  useEffect(() => {
    setTitle(document.title);
  }, [document.title]);
  return (
    <nav className="main-menu style4 navbar-expand-lg">
      <div className="navbar-header">
        {/*Toggle Link*/}
        <Link
          type="Link"
          className="navbar-toggle"
          // data-toggle="collapse"
          // data-target="#navbarSupportedContent"
          // aria-controls="navbarSupportedContent"
          // aria-expanded="false"
          onClick={ShowLefSide}
          aria-label="Toggle navigation"
        >
          <MenuIcon style={{ color: "#030202", background: "#ffff" }} />
        </Link>
      </div>

      <div
        className="navbar-collapse collapse clearfix"
        // id="navbarSupportedContent"
      >
        {/* onClick={event => CallFunction(event, row.handle,row.name)} */}
        <ul className="navigation clearfix" id="navOne">
          {linksData.map((val, i) => (
            <li key={i} className={val.class}>
              <a
                //  onMouseOver={()=>OnclickStatus(val.name)}   text-decoration: underline;
                //  id="mydiv"
                style={{
                  fontFamily: "Bell MT Semi Bold",
                  fontSize: 17,
                  textDecoration: title === val.title ? "underline" : "",
                  textUnderlineOffset: 10,
                }}
                href={val.to}
              >
                {val.name}
              </a>
              <ul class="dropdown-content">
                {val?.dropdown?.map((row, index) => (
                  <li key={index} className={row.class}>
                    <a
                      style={{ fontFamily: "Bell MT Semi Bold", fontSize: 17 }}
                      href={`${row.to}?data=${row.name}`}
                    >
                      {row.name}{" "}
                    </a>
                    <ul>
                      {row?.dropdown2?.map((res, index) => (
                        <li key={index}>
                          <a
                            style={{
                              fontFamily: "Bell MT Semi Bold",
                              fontSize: 17,
                            }}
                            href={`${res.to}?data=${res.name}`}
                          >
                            {res.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>

      <div style={{ maxHeight: 800 }}>
        <div
          className="right-align"
          style={{
            display: leftside === true ? "block" : "none",
            position: "fixed",
            top: 0,
            width: "100%",
            height: "100%",
            background: "#ffffff",
            zIndex: 9999999999,
            transition: "all 700ms ease",
            left: leftside === true ? 0 : -400,
            boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.5)",
          }}
          id="inputSearch"
        >
          <div
            className="hidden-bar-closer"
            style={{ marginLeft: 10, display: "flex" }}
          >
            <div
              style={{
                height: "auto",
                marginTop: 30,
                marginBottom: "1rem",
                width: "95%",
              }}
            >
              <form onSubmit={handleSearch}>
                <Paper
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
        <MenuIcon />
      </IconButton> */}

                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search "
                    onChange={(e) => setSearch(e.target.value)}
                    required={true}
                    // type="search"
                    name="search"
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={ShowLefSide}
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                  >
                    <ClearIcon />
                  </IconButton>
                </Paper>
              </form>
              {/* <Button type="submit" onClick={handleSearch} >
              <i className="fa fa-search" style={{fontSize:20}}></i>
            </Button> */}
            </div>
            {/* <button
            onClick={ShowLefSide}
            style={{
             margin: 'auto'
            }}
          >
            <ClearIcon style={{fontSize: 35,paddingTop:10 }}/>
          </button> */}
          </div>
          <hr className="singleline" />
          <div>
            <div className="hidden-bar-wrapper">
              <div
                className={"navbar-collapse collapse clearfix show"}
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix">
                  {linksData.map((val, i) => (
                    <li key={i} className={val.class}>
                      <a
                        style={{
                          fontFamily: "Bell MT Semi Bold",
                          fontSize: 15,
                          color: "black",
                        }}
                        onClick={() => OnclickStatus(val.name)}
                        href={val.to}
                      >
                        <i
                          style={{
                            fontSize: 20,
                            paddingRight: val.name === "PRIVATE SALE" ? 20 : 15,
                            color: "#212529",
                          }}
                          className={val.icon}
                        />{" "}
                        {val.name}
                      </a>

                      <ul
                        style={{
                          backgroundColor: "#f1f1f1",
                          paddingLeft: 50,
                          display:
                            val.name === menuName
                              ? status === true
                                ? "block"
                                : "none"
                              : "none",
                        }}
                      >
                        {val?.dropdown?.map((row, index) => (
                          <li key={index}>
                            <a
                              onClick={() => OnclickStatus()}
                              style={{
                                fontFamily: "Bell MT Semi Bold",
                                fontSize: 15,
                              }}
                              href={`${row.to}?data=${row.name}`}
                            >
                              {" "}
                              {row.name}{" "}
                            </a>
                            <ul>
                              {row?.dropdown2?.map((res, index) => (
                                <li key={index}>
                                  <a
                                    style={{
                                      fontFamily: "Bell MT Semi Bold",
                                      fontSize: 15,
                                    }}
                                    href={`${res.to}?data=${res.name}`}
                                  >
                                    {res.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <hr className="singleline" />

                <ul class="navigation clearfix">
                  <li>
                    <a
                      style={{ fontFamily: "Bell MT Semi Bold", fontSize: 15 }}
                      href="/about"
                    >
                      ABOUT US
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontFamily: "Bell MT Semi Bold", fontSize: 15 }}
                      href="/contact"
                    >
                      CONTACT US
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ fontFamily: "Bell MT Semi Bold", fontSize: 15 }}
                      href="/howtobuy"
                    >
                      HOW TO BUY
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ fontFamily: "Bell MT Semi Bold", fontSize: 15 }}
                      href="/howtosell"
                    >
                      HOW TO SELL
                    </a>
                  </li>

                  {/* {token !== "" ? (
                      <li>
                      
                      <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:15}} onClick={onCLickLogout} href="">LOGOUT</a>
                      </li>
                    ) : (
                      <li>
                        <a style={{fontFamily:  "Bell MT Semi Bold",fontSize:15}} href="/login" href="">LOGIN</a>
                      </li>
                    )} */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
