import React, { useCallback, useEffect, useState } from "react";
import { actionByID } from "../../Model/Auction";
// import PDFDOWNLOAD from './../../pdf/TKTA_2023.pdf';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Carousel from "react-material-ui-carousel";
import useMediaQuery from '@mui/material/useMediaQuery';
import ViewListIcon from '@mui/icons-material/ViewList';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ExplicitIcon from '@mui/icons-material/Explicit';
function Banner({data}) {
  // const {data} = props.data;
  // const { titleBanner } = data.title;
 
  const matches = useMediaQuery('(min-width:1000px)');
  const matchesPhone = useMediaQuery('(min-width:600px)');
  const auctionID = localStorage.getItem("items");
  const [bannerImage, setBannerImage] = useState("");
  var { id } = useParams();
  const [title, setTitle] = useState("");
  const [date_start, setDate] = useState("");
  const [catalog, setCatalog] = useState("");
  const [catalogDownload, setCatalogDownload] = useState("");
  const [catalogView, setCatalogView] = useState("");
  const [loading, setLoading] = useState(false);

  const FetchBanner =  () => {
    setLoading(true);
    async function getBanner() {

      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const results = await actionByID(id);

      var date = new Date(results[0].date_start);
      setDate(date.toLocaleDateString("en-US", options));
      setCatalogDownload(results[0].catalog_download)
      setCatalogView(results[0].catalog_url)
      console.log(results)
      setTitle(results[0].auction_name);
      setBannerImage(results[0].auction_cover);
      setLoading(false);
    }
    getBanner().catch(console.error);
  };

  useEffect(() => {
  
    FetchBanner()

  }, [data]);


  
  return (
    
    <section class="main-slider style1" style={{paddingBottom: matches === true ?50:0,}}>
      <div>
        {/* <div class="sec-title text-center">
            <p>OUR LATEST NEWS</p>
            <div class="big-title"><h1>Interviews & Publications</h1></div>
        </div> */}

        <div class="row" style={{backgroundColor: '#eaecef',padding: 20,width: '100%',margin: 'auto'}}>
          <div class="col-xl-12 text-center">
            <div class="" style={{ margin: "auto", marginTop: 20 }}>
              <div style={{fontSize: matches === false ? 30:60,padding: 10,lineHeight: 1}}>{title}</div>
              <span>{date_start}</span>
              <br />
              <span>
                G/F Eurovilla 1, Rufino corner Legazpi Street, Legazpi Village,
                Makati City, Philippines
              </span>
         

        {matchesPhone === false ? 
              <div class="button"  style={{ display: 'flex',justifyContent: 'space-around',padding: 10 }}>
       
                <a
                  style={{
                    // padding: "14px 38px 12px",

                    marginRight: 10,
                    marginTop:10,
                    color: "white",
                    fontSize: 16,
                  }}
                  class="btn btn-secondary catalobButton"
                  id="view_ecatalog"
                    href={catalogView}
                  target="_blank"
                >
                  E-Catalog
                </a>

                <a
                  style={{
                    // padding: "14px 38px 12px",
                    marginTop:10,
                    color: "white",
                    fontSize: 16,
                  }}
                  class="btn btn-secondary catalobButton"
             
                  href={`/../../pdf/${catalogDownload}.pdf`}
                  download=""
               
                >
                    Download PDF
                </a>
         
              </div> : 
               
              <div class="button" style={{ paddingTop: 10 }}>
                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginRight: 10,
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
                  id="view_ecatalog"
                    href={catalogView}
                  target="_blank"
                >
                  E-Catalog
                </a>

                <a
                  style={{
                    padding: "14px 38px 12px",
                    marginTop:10,
                    color: "white",
                    fontSize: 20,
                  }}
                  class="btn btn-secondary catalobButton"
             
                  href={`/../../pdf/${catalogDownload}.pdf`}
                  download=""
               
                >
                   <DownloadIcon style={{ 
                        }} /> Download PDF
                </a>
         
              </div> }
            </div>
            <div class="col-md-12 text-center"></div>
          </div>
        </div>
      </div>

      {/* <div
          className="rev_slider_wrapper fullwidthbanner-container"
          id="rev_slider_one_wrapper"
          data-source="gallery"
          style={{ width: "40%" }}
        >
          <div className="bannerWithText">
            <img    src={bannerImage} />
            <button className="botton1">Download</button>
            <button className="botton1">View E Catalog</button>
          </div>
        </div>
      </div> */}

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </section>
  );
}


export default Banner;
