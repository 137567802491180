import axios from "axios";
import { useEffect, useState } from "react";
import axiosInstant from "../../axiosInstant";

export default function SearchAuction(pageNumber,id, search, searchCat) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lotdata, setLot] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    
    setLot([]);
  }, [ id, search, searchCat]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axiosInstant({
      method: "GET",
      url: `private-sales/lot?id=${id}&pgroup=EXHIBITION&search=${
        search === undefined ? "" : search
      }&cat=${searchCat === undefined ? "" : searchCat}&page=${pageNumber === undefined ? "" : pageNumber}`,
      //params: { q: query, page: pageNumber },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {

        setLot((prevlotdata) => {
          return [...new Set([...prevlotdata, ...res.data.msg])];
        });
        setHasMore(res.data.msg.length > 0);
        setLoading(false);
      })
      .catch((e) => {
      
      console.log("e.response.data.msg",e.message)
        if (axios.isCancel(e)) return;
        setError(true);
     
      });
    return () => cancel();
    
  }, [pageNumber, id, search, searchCat]);


  return {lotdata, hasMore, loading, error };
}
